import React, { useEffect, useRef, useState } from 'react';

import { animated, useSpring } from '@react-spring/web';
import { fileTypes } from 'views/AssetPickerField.js';
import { getRandomId, ROOT_NODE } from '@craftjs/utils';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import AddressLookupField from 'views/AddressLookupField.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import { Canvas, Editor, Element, Frame, useEditor, useNode } from '@craftjs/core';
import Checkbox from 'views/Checkbox.js';
import CommLink from 'classes/CommLink.js';
import { CommLinkDetails } from 'managers/OmniShield.js';
import ContentEditable from 'react-contenteditable';
import DatePickerField from 'views/DatePickerField.js';
import { DealershipDetails } from 'managers/Dealerships.js';
import DealershipLookupField from 'views/DealershipLookupField.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { CollapseArrow, LayerItem, LayerItemSpacing } from 'structure/Layer.js';
import { Layers as EditorLayers, useLayer } from '@craftjs/layers';
import ListField from 'views/ListField.js';
import LottieView from 'views/Lottie.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import ReactFlow, { Controls, ControlButton, Handle, ReactFlowProvider, getMarkerEnd, getSmoothStepPath } from 'react-flow-renderer';
import Request from 'files/Request.js';
import Sector from 'classes/Sector.js';
import { SectorDetails } from 'managers/Sectors.js';
import SectorLookupField from 'views/SectorLookupField.js';
import Support from 'classes/Support.js';
import TagLookupField from 'views/TagLookupField.js';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import UserLookupField from 'views/UserLookupField.js';
import Utils, { useLoading, useResultsManager } from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';
import WorkflowItemLookupField from 'views/WorkflowItemLookupField.js';

const ApplicationContext = React.createContext();

const nodeLandingButtonHeight = 40;
const nodeLandingHeight = 55;
const nodeRuleHeight = 55;
const nodeRuleRouteHeight = 55;
const nodeWidth = 350;

export const AddEditKBArticle = ({ generateContent, isNewTarget, onArticleAdded, request }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_kb_article' : `edit_kb_article_${abstract.getID()}`;

    const [article, setArticle] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [layoutType, setLayoutType] = useState('mobile');
    const [loading, setLoading] = useState(false);
    const [toolboxHeight, setToolboxHeight] = useState(0);

    const onChangeTheme = isDarkMode => {
        setLoading('theme');
        setTimeout(utils.theme.set.bind(this, isDarkMode ? 'dark' : 'light'), 1000);
        setTimeout(setLoading.bind(this, false), 1000);
    }

    const onCloseLayer = () => {
        utils.events.emit('close_data_request_layer');
    }

    const onLayoutChange = val => {
        setLayoutType(val);
    }

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            // submit details to server
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The article has been ${isNewTarget ? 'created and is available for use.' : 'updated and changes will take effect immediately. Would you like to continue editing this article?'}`,
                buttons: [{
                    key: 'cancel',
                    title: 'Okay',
                    style: 'cancel',
                    visible: isNewTarget
                },{
                    key: 'edit',
                    title: 'Continue Editing',
                    style: 'default',
                    visible: isNewTarget ? false : true
                },{
                    key: 'close',
                    title: 'Finished Editing',
                    style: 'default',
                    visible: isNewTarget ? false : true
                }],
                onClick: key => {

                    // notify subscribers of data change
                    if(isNewTarget === true && typeof(onArticleAdded) === 'function') {
                        setLayerState('close');
                        onArticleAdded(abstract.object);
                    }

                    // close layer if applicable
                    if(key === 'close') {
                        setLayerState('close');
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this article. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onToolboxHeightChange = height => {
        setToolboxHeight(height);
    }

    const onUpdateContent = ({ serialize }) => {

        // apply changes to target edits
        onUpdateTarget({
            content: {
                ...article.content,
                layout: serialize()
            }
        });
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setArticle(edits);
        } catch(e) {
            console.error(e.message);
        }
    }

    const getBuilder = () => {
        if(!article || loading === 'theme') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <img
                    src={'images/editor-waiting-icon.png'}
                    style={{
                        width: 100,
                        height: 100,
                        marginBottom: 8
                    }} />
                    <span style={{
                        ...Appearance.textStyles.title()
                    }}>{'Waiting'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle()
                    }}>{'Refreshing content for theme change...'}</span>
                </div>
            );
        }
    
        return (
            <ApplicationContext.Provider value={{
                layoutType: layoutType,
                request: request,
                utils: utils
            }}>
                <Editor
                onNodesChange={onUpdateContent}
                resolver={{ ButtonNode, Canvas, Container, Element, Field, Image, Text, Video }}>
                    <EditorToolbox
                    channel={'kb_article'}
                    generateContent={generateContent}
                    onChangeTheme={onChangeTheme}
                    onHeightChange={onToolboxHeightChange}
                    onLayoutChange={onLayoutChange}
                    onSaveContent={onSubmit}
                    utils={utils} />
                    <div style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        height: '100%',
                        width: '100%'
                    }}>
                        <div style={{
                            alignItems: 'center',
                            backgroundColor: Appearance.colors.background(),
                            display: 'flex',
                            flexGrow: 1,
                            flexDirection: 'column',
                            width: '100%'
                        }}>
                            <div style={{
                                backgroundColor: Appearance.colors.layerBackground(),
                                border: `1px solid ${Appearance.colors.divider()}`,
                                borderRadius: 15,
                                height: '100%',
                                marginTop: 12,
                                maxHeight: `calc(100% - ${toolboxHeight + 15 + 12}px)`,
                                maxWidth: layoutType === 'desktop' ? 1000 : 500,
                                overflowY: 'scroll',
                                paddingBottom: toolboxHeight + 15,
                                position: 'relative',
                                width: '100%'
                            }}>
                                <Frame data={article.content ? article.content.layout : null}>
                                    {getDefaultBuilderContent()}
                                </Frame>
                            </div>
                        </div>
                        <EditorSettingsPanel style={{
                            paddingBottom: toolboxHeight + 15
                        }}/>
                    </div>
                </Editor>
            </ApplicationContext.Provider>
        )
    }

    const getDefaultBuilderContent = () => {
        return (
            <Element
            is={'div'}
            canvas={true}
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 20,
                textAlign: 'left',
                width: '100%'
            }}>
                <Container
                alignItems={'center'}
                borderColor={Appearance.colors.transparent}
                display={'flex'}
                flexDirection={'row'}
                height={'auto'}
                justifyContent={'flex-end'}
                padding={{ 
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: 0
                }}
                width={'100%'}>
                    <Image
                    borderRadius={0}
                    height={25}
                    imageAsset={5}
                    objectFit={'contain'}
                    padding={{ right: 4 }}
                    width={25} />
                </Container>

                <Text
                color={'#232323'}
                flexGrow={1}
                fontSize={20}
                fontWeight={800}
                margin={{ bottom: 4 }}
                textAlign={'left'}
                value={'Article Name'} 
                width={'auto'}/>

                <Text
                {...Appearance.textStyles.subTitle()}
                margin={{ bottom: 24 }}
                textAlign={'left'}
                value={'A knowledge base article is a collection of text and images that help explain the how to solve a problem. Use this article to explain the causes of the issue and the recommended steps to resolve it.'} />

            </Element>
        )
    }

    const getFields = () => {
        if(!article) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                component: 'textfield',
                description: 'The name for a knowledge base article will be used when representing this article in public and private settings.',
                key: 'name',
                onChange: text => {
                    onUpdateTarget({
                        name: text,
                        user_set_name: true
                    });
                },
                title: 'Name',
                value: article.name
            }]
        }];
    }
    
    const setupTarget = () => {
        let edits = abstract.object.open();
        setArticle(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        utils={utils}
        title={isNewTarget ? 'New Knowledge Base Article' : `Editing ${abstract.getTitle()}`}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            onPreparingClose: onCloseLayer,
            sizing: 'fullscreen'
        }}>
            <div className={'row m-0'}>
                <div
                className={'col-12 col-lg-3 col-xl-2 px-0 pt-2 pb-0 pr-lg-2 py-lg-0 pl-lg-1'}
                style={{
                    maxHeight: window.innerHeight - 68,
                    overflowY: 'scroll'
                }}>
                    <AltFieldMapper
                    utils={utils}
                    fields={getFields()} />
                </div>
                <div className={'col-12 col-lg-9 col-xl-10 px-0 pt-2 pb-0 pl-lg-1 py-lg-0 pr-lg-0'}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        width: '100%',
                        height: window.innerHeight - 68,
                    }}>
                        {getBuilder()}
                    </div>
                </div>
            </div>
        </Layer>
    )
}

export const AddEditSupportWorkflowLanding = ({ session, isNewTarget, onLandingAdded, onLandingUpdated }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_workflow_landing' : `edit_workflow_landing_${abstract.getID()}`;

    const [landing, setLanding] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [layoutType, setLayoutType] = useState('mobile');
    const [loading, setLoading] = useState(false);
    const [template, setTemplate] = useState(null);
    const [toolboxHeight, setToolboxHeight] = useState(0);

    const onChangeTheme = isDarkMode => {
        setLoading('theme');
        setTimeout(utils.theme.set.bind(this, isDarkMode ? 'dark' : 'light'), 1000);
        setTimeout(setLoading.bind(this, false), 1000);
    }

    const onCloseLayer = () => {
        utils.events.emit('close_data_request_layer');
    }

    const onLayoutChange = val => {
        setLayoutType(val);
    }
    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            // submit details to server
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The workflow landing has been ${isNewTarget ? 'created and is available for use.' : 'updated and changes will take effect during the next session. Would you like to continue editing this landing?'}`,
                buttons: [{
                    key: 'cancel',
                    title: 'Okay',
                    style: 'cancel',
                    visible: isNewTarget
                },{
                    key: 'edit',
                    title: 'Continue Editing',
                    style: 'default',
                    visible: isNewTarget ? false : true
                },{
                    key: 'close',
                    title: 'Finished Editing',
                    style: 'default',
                    visible: isNewTarget ? false : true
                }],
                onClick: key => {

                    // notify subscribers of data change
                    if(isNewTarget === true && typeof(onLandingAdded) === 'function') {
                        setLayerState('close');
                        onLandingAdded(abstract.object);
                    }
                    if(isNewTarget === false && typeof(onLandingUpdated) === 'function') {
                        onLandingUpdated(abstract.object);
                    }

                    // close layer if applicable
                    if(key === 'close') {
                        setLayerState('close');
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this workflow landing. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubmitTemplate = () => {
        let title = null;
        utils.alert.show({
            title: 'Save as Template',
            message: 'What would you like to call your new template?',
            content: (
                <div style={{
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingBottom: 12,
                    width: '100%'
                }}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        padding: 12
                    }}>
                        <TextField
                        placeholder={'Template Name'}
                        onChange={text => title = text} />
                    </div>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(title && key === 'confirm') {
                    onSubmitTemplateConfirm(title);
                    return;
                }
            }
        })
    }

    const onSubmitTemplateConfirm = async title => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            // submit details to server
            await validateRequiredFields(getFields);
            await Request.post(utils, '/support/', {
                type: 'new_workflow_landing_template',
                content: Support.Workflow.Landing.content.compress(landing.content),
                content_type: landing.content_type,
                title: title
            });

            setLoading(false);
            utils.content.fetch('workflow_landing_templates');
            utils.alert.show({
                title: 'All Done!',
                message: 'Your template has been saved and is available for future use.'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this workflow landing. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onToolboxHeightChange = height => {
        setToolboxHeight(height);
    }

    const onUpdateContent = ({ getNodes, serialize }) => {

        // serialize content into json string
        let json = serialize();
        let nodes = Object.values(getNodes());

        // declare previous button targets
        let buttons = landing.content && landing.content.buttons ? landing.content.buttons : [];

        // collect button and field references
        // spread in previous button props to preseve action across saves
        let components = nodes.reduce((object, node) => {
            if(node.data.name === 'ButtonNode') {
                object.buttons.push({
                    ...buttons.find(button => button.id === node.id),
                    id: node.id,
                    color: node.data.props.backgroundColor,
                    linkable: node.data.props.linkable,
                    ignore_field_requirements: node.data.props.ignoreFieldRequirements,
                    open_in_new_window: node.data.props.openInNewWindow,
                    text: node.data.props.text
                })
            }
            if(node.data.name === 'Field') {
                object.fields.push({
                    id: node.id,
                    component: node.data.props.component,
                    identifier: node.data.props.identifier,
                    required: node.data.props.required || false,
                    title: node.data.props.title
                })
            }
            return object;
        }, { buttons: [], fields: [] });

        // apply changes to target edits
        onUpdateTarget({
            content: {
                ...landing.content,
                ...components,
                layout: json
            }
        });
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setLanding(edits);
        } catch(e) {
            console.error(e.message);
        }
    }

    const getBuilder = () => {
        if(!landing || loading === 'theme') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <img
                    src={'images/editor-waiting-icon.png'}
                    style={{
                        width: 100,
                        height: 100,
                        marginBottom: 8
                    }} />
                    <span style={{
                        ...Appearance.textStyles.title()
                    }}>{'Waiting'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle()
                    }}>{'Refreshing content for theme change...'}</span>
                </div>
            );
        }
        return (
            <ApplicationContext.Provider value={{
                layoutType: layoutType,
                session: session,
                utils: utils
            }}>
                <Editor
                onNodesChange={onUpdateContent}
                resolver={{ ButtonNode, Canvas, Container, Element, Field, Image, Text, Video }}>
                    <EditorToolbox
                    onChangeTheme={onChangeTheme}
                    onHeightChange={onToolboxHeightChange}
                    onLayoutChange={onLayoutChange}
                    onSaveContent={onSubmit}
                    onSaveContentTemplate={onSubmitTemplate}
                    template={template}
                    utils={utils} />
                    <div style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        height: '100%'
                    }}>
                        <div style={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            backgroundColor: Appearance.colors.background()
                        }}>
                            <div style={{
                                backgroundColor: Appearance.colors.layerBackground(),
                                border: `1px solid ${Appearance.colors.divider()}`,
                                borderRadius: 15,
                                height: '100%',
                                marginTop: 12,
                                maxHeight: `calc(100% - ${toolboxHeight + 15 + 12}px)`,
                                maxWidth: layoutType === 'desktop' ? 750 : 500,
                                overflowY: 'scroll',
                                paddingBottom: toolboxHeight + 15,
                                position: 'relative',
                                width: '100%',
                            }}>
                                <Frame data={landing.content ? landing.content.layout : null}>
                                    {getDefaultBuilderContent()}
                                </Frame>
                            </div>
                        </div>
                        <EditorSettingsPanel style={{
                            paddingBottom: toolboxHeight + 15
                        }}/>
                    </div>
                </Editor>
            </ApplicationContext.Provider>
        )
    }

    const getDefaultBuilderContent = () => {
        let textview = Support.Workflow.Landing.components.get().textview;
        return (
            <Element
            is={'div'}
            canvas={true}
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 20,
                textAlign: 'left',
                width: '100%'
            }}>
                <Text
                {...Appearance.textStyles.layerItemTitle()}
                margin={{ bottom: 4 }}
                textAlign={'left'}
                value={'Page Name'} />

                <Text
                {...Appearance.textStyles.subTitle()}
                margin={{ bottom: 24 }}
                textAlign={'left'}
                value={'A landing is where you can ask the user for some information to determine where to route them next. Click and drag an element from the list on the left to get started.'} />

                <Field
                component={{ 
                    code: textview,
                    text: Support.Workflow.Landing.components.toText(textview),
                    image: `images/landing-editor-icon-${textview}.png` 
                }}
                margin={{ bottom: 24 }} 
                placeholder={'This is a sample text view to gather user feedback'}/>

                <ButtonNode
                backgroundColor={Appearance.colors.primary()}
                color={'#FFFFFF'}
                text={'Continue'} />
            </Element>
        )
    }

    const getFields = () => {
        if(!landing) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                component: 'textfield',
                description: 'The name for a workflow landing will be used when representing this landing inside of support sessions.',
                key: 'name',
                onChange: text => {
                    onUpdateTarget({
                        name: text,
                        user_set_name: true
                    });
                },
                title: 'Name',
                value: landing.name
            },{
                component: 'workflow_landing_template_lookup',
                description: 'Using a content template can help you put together a working journey quickly. You start with a template and make changes to fit your specific use-case. Some templates may contain placeholder values contained inside of two brackets and those values should not be changed.',
                key: 'template',
                onChange: template => {
                    setTemplate(template);
                    onUpdateTarget({ content_type: template && template.type });
                },
                required: false,
                title: 'Content Template'
            }]
        }];
    }
    
    const setupTarget = () => {
        let edits = abstract.object.open();
        setLanding(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        utils={utils}
        title={isNewTarget ? 'New Workflow Landing' : `Editing ${abstract.getTitle()}`}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            onPreparingClose: onCloseLayer,
            sizing: 'fullscreen'
        }}>
            <div className={'row m-0'}>
                <div
                className={'col-12 col-lg-3 col-xl-2 px-0 pt-2 pb-0 pr-lg-2 py-lg-0 pl-lg-1'}
                style={{
                    maxHeight: window.innerHeight - 68,
                    overflowY: 'scroll'
                }}>
                    <AltFieldMapper
                    utils={utils}
                    fields={getFields()} />
                </div>
                <div className={'col-12 col-lg-9 col-xl-10 px-0 pt-2 pb-0 pl-lg-1 py-lg-0 pr-lg-0'}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        width: '100%',
                        height: window.innerHeight - 68,
                    }}>
                        {getBuilder()}
                    </div>
                </div>
            </div>
        </Layer>
    )
}

export const AddEditSupportWorkflowRule = ({ isNewTarget, onRuleAdded, onRuleUpdated }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_support_workflow_rule' : `edit_support_workflow_rule_${abstract.getID()}`;

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [rule, setRule] = useState(null);

    const onAddNewRouteClick = () => {
        utils.layer.open({
            id: 'new_support_workflow_rule_route',
            Component: AddEditSupportWorkflowRuleRoute.bind(this, {
                isNewTarget: true,
                onChange: target => {
                    onUpdateTarget({
                        routes: update(rule.routes, {
                            $push: [target]
                        })
                    });
                },
                target: Support.Workflow.Rule.Route.new()
            })
        });
    }

    const onAddNewTaskClick = () => {
        utils.layer.open({
            id: 'new_support_workflow_rule_task',
            Component: AddEditSupportWorkflowRuleTask.bind(this, {
                isNewTarget: true,
                onChange: target => {
                    onUpdateTarget({
                        tasks: update(rule.tasks, {
                            $push: [target]
                        })
                    });
                },
                target: Support.Workflow.Rule.Task.new()
            })
        });
    }

    const onClearContent = async () => {
        try {
            setLoading('clear');
            let edits = await Abstract.clear(utils, abstract);
            setLoading(false);
            setRule(edits);
        } catch(e) {
            setLoading(false);
            console.error(e.message);
        }
    }

    const onCloseLayer = () => {
        utils.events.emit('close_data_request_layer');
    }
    
    const onRouteClick = index => {
        utils.layer.open({
            id: `edit_support_workflow_rule_route_${rule.routes[index].id}`,
            Component: AddEditSupportWorkflowRuleRoute.bind(this, {
                isNewTarget: false,
                onChange: target => {
                    onUpdateTarget({
                        routes: update(rule.routes, {
                            [index]: {
                                $set: target
                            }
                        })
                    });
                },
                target: rule.routes[index]
            })
        });
    }

    const onSubmit = async () => {
        try {
            setLoading('submit');
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The workflow rule has been ${isNewTarget ? 'created and is immediately available for use.' : 'updated and changes will take effect during the next session.'}`,
                onClick: () => {
                    setLayerState('close');
                    if(isNewTarget === true && typeof(onRuleAdded) === 'function') {
                        onRuleAdded(abstract.object);
                    }
                    if(isNewTarget === false && typeof(onRuleUpdated) === 'function') {
                        onRuleUpdated(abstract.object);
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this workflow rule. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onTaskClick = index => {
        utils.layer.open({
            id: `edit_support_workflow_rule_task_${rule.tasks[index].id}`,
            Component: AddEditSupportWorkflowRuleTask.bind(this, {
                isNewTarget: false,
                onChange: target => {
                    onUpdateTarget({
                        tasks: update(rule.tasks, {
                            [index]: {
                                $set: target
                            }
                        })
                    });
                },
                target: rule.tasks[index]
            })
        });
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setRule(edits);
    }

    const getButtons = () => {
        return [{
            key: 'clear',
            text: 'Clear',
            color: 'dark',
            onClick: onClearContent,
        },{
            key: 'submit',
            text: isNewTarget ? 'Create' : 'Save Changes',
            color: 'primary',
            onClick: onSubmit
        }];
    }

    const getFields = () => {

        // prevent moving forward if rule state has not been set
        if(!rule) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                component: 'textfield',
                description: 'The name for a workflow rule will be used when representing this rule internally.',
                key: 'name',
                onChange: text => onUpdateTarget({ name: text }),
                title: 'Name',
                value: rule.name
            }]
        }];
    }

    const getRoutes = () => {
        return rule && (
            <LayerItem 
            title={'Routes'}
            style={{
                marginTop: LayerItemSpacing
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {rule.routes.map((route, index) => {
                        let target = route.edits || route;
                        return (
                            Views.entry({
                                bottomBorder: true,
                                hideIcon: true,
                                key: index,
                                onClick: onRouteClick.bind(this, index),
                                subTitle: Support.Workflow.Rule.Route.overview.get(target),
                                title: target.name || 'Untitled Route'
                            })
                        )
                    })}
                    {Views.entry({
                        bottomBorder: false,
                        hideIcon: true,
                        title: 'Add New Route',
                        onClick: onAddNewRouteClick
                    })}
                </div>
            </LayerItem>
        )
    }

    const getTasks = () => {
        return rule && (
            <LayerItem 
            title={'Tasks'}
            style={{
                marginTop: LayerItemSpacing
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {rule.tasks.map((task, index) => {
                        let target = task.edits || task;
                        return (
                            Views.entry({
                                bottomBorder: true,
                                hideIcon: true,
                                key: index,
                                onClick: onTaskClick.bind(this, index),
                                subTitle: Support.Workflow.Rule.Task.overview.get(target),
                                title: target.name || 'Untitled Task'
                            })
                        )
                    })}
                    {Views.entry({
                        bottomBorder: false,
                        hideIcon: true,
                        title: 'Add New Task',
                        onClick: onAddNewTaskClick
                    })}
                </div>
            </LayerItem>
        )
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setRule(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        utils={utils}
        title={isNewTarget ? 'New Workflow Rule' : `Editing ${abstract.getTitle()}`}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            onPreparingClose: onCloseLayer,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils} />

            {getRoutes()}
            {getTasks()}
        </Layer>
    )
}

export const AddEditSupportWorkflowRuleRoute = ({ isNewTarget, onChange, target }, { index, options, utils }) => {

    const layerID = isNewTarget ? 'new_support_workflow_rule_route' : `edit_support_workflow_rule_route_${target.id}`;

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [operators, setOperators] = useState([]);
    const [route, setRoute] = useState(null);
    const [types, setTypes] = useState([]);

    const onClearContent = async () => {
        let edits = target.open();
        setRoute(edits);
    }

    const onCloseLayer = () => {
        utils.events.emit('close_data_request_layer');
    }

    const onSubmit = async () => {
        try {

            // verify that required fields have been completed
            await validateRequiredFields(getFields);
            
            // notify subscribers of data change
            setLayerState('close');
            if(typeof(onChange) === 'function') {
                onChange(target);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this workflow rule route. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        let edits = target.set(props);
        setRoute(edits);
    }

    const getActions = () => {
        let values = Object.values(Support.Workflow.Action.types.get());
        return values.map(value => ({
            id: value,
            title: Support.Workflow.Action.types.toText(value)
        })).sort((a,b) => {
            return a.title.localeCompare(b.title);
        });
    }

    const getBrowserTypes = () => {
        return [{
            id: -1,
            title: 'Undetermined'
        },{
            id: 1,
            title: 'Chrome'
        },{
            id: 2,
            title: 'Microsoft Edge'
        },{
            id: 3,
            title: 'Firefox'
        },{
            id: 4,
            title: 'Internet Explorer'
        },{
            id: 5,
            title: 'Opera'
        },{
            id: 6,
            title: 'Safari'
        }];
    }

    const getButtons = () => {
        return [{
            key: 'clear',
            text: 'Clear',
            color: 'dark',
            onClick: onClearContent,
        },{
            key: 'submit',
            text: isNewTarget ? 'Create' : 'Save Changes',
            color: 'primary',
            onClick: onSubmit
        }];
    }

    const getDestinationComponents = () => {

        // declare possible actions and prevent moving forward if an action has not been set
        let actions = Support.Workflow.Action.types.get();
        if(!route.action) {
            return [];
        }

        // loop through actions and create component object
        switch(route.action.type) {
            case actions.redirect_to_workflow_landing:
            return [{
                component: 'workflow_landing_lookup',
                description: `This is the landing where the user is routed to if the route logic is met successfully.`,
                key: 'landing',
                onChange: landing => {
                    onUpdateTarget({
                        action: {
                            ...route.action,
                            target: landing
                        },
                        destination: Support.Workflow.destinations.get().workflow_landing
                    });
                },
                props: {
                    disableAssigned: true
                },
                required: false,
                title: 'Redirect to Workflow Landing',
                value: route.action && route.action.target
            }]

            case actions.redirect_to_workflow_rule:
            return [{
                component: 'workflow_rule_lookup',
                description: `This is the rule where the user is routed to if the route logic is met successfully.`,
                key: 'rule',
                onChange: rule => {
                    onUpdateTarget({
                        action: {
                            ...route.action,
                            target: rule
                        },
                        destination: Support.Workflow.destinations.get().workflow_rule
                    });
                },
                props: {
                    disableAssigned: true
                },
                required: false,
                title: 'Redirect to Workflow Rule',
                value: route.action && route.action.target
            }]

            case actions.redirect_to_public_web_page:
            return [{
                component: 'textfield',
                description: `This is the url where the user will be routed to if the route logic is met successfully.`,
                key: 'url',
                onChange: text => {
                    onUpdateTarget({
                        action: {
                            ...route.action,
                            target: { url: text }
                        },
                        destination: Support.Workflow.destinations.get().public_web_page
                    });
                },
                required: false,
                title: 'Open Public Web Page',
                value: route.action && route.action.target && route.action.target.url
            }]

            case actions.download_local_file:
            return [{
                component: 'file_picker',
                description: `The file for a workflow rule will be provided as a download to the user if the rule's logic is met successfully and these is no additional routing setup.`,
                key: 'file',
                onChange: result => {
                    onUpdateTarget({
                        action: {
                            ...route.action,
                            target: result
                        },
                        destination: Support.Workflow.destinations.get().local_file
                    });
                },
                required: false,
                title: 'Download File from Server',
                value: route.action && route.action.target
            }]

            default:
            return [];
        }
    }

    const getFields = () => {

        // prevent moving forward if route state has not been set
        if(!route) {
            return [];
        }

        // return default editing components
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                component: 'textfield',
                description: 'The name for a workflow rule route will be used when representing this route internally.',
                key: 'name',
                onChange: text => {
                    onUpdateTarget({
                        name: text,
                        user_set_name: true
                    });
                },
                required: false,
                title: 'Name',
                value: route.name
            }]
        },{
            key: 'logic',
            title: 'Logic',
            items: [{
                component: 'list',
                description: `The logic type for a workflow rule determines which data points we analyze from the user's scan session.`,
                items: types,
                key: 'type',
                onChange: item => {

                    // prevent moving forward if no type was selected
                    if(!item) {
                        onUpdateTarget({ type: null });
                        return;
                    }

                    // update type, name state if applicable, and reset target
                    onUpdateTarget({
                        action: { 
                            ...route.action,
                            target: null
                        },
                        name: route.user_set_name === true ? route.name : Support.Workflow.Rule.types.toText(item.id),
                        type: item.id
                    });
                },
                title: 'Type',
                value: route.type && {
                    id: route.type,
                    title: Support.Workflow.Rule.types.toText(route.type)
                }
            },{
                component: 'textfield',
                key: 'key',
                onChange: text => onUpdateTarget({ key: text }),
                value: route.key,
                ...getRouteCustomizationKeyProps()
            },{
                component: 'list',
                description: `The operator determines how we apply the logic type to the user's scan session.`,
                key: 'operator',
                items: getOperators(),
                onChange: item => onUpdateTarget({ operator: item && item.id || null }),
                required: routeAcceptsCustomOperator(),
                title: 'Operator',
                value: route.operator && {
                    id: route.operator,
                    title: Support.Workflow.Rule.operators.toText(route.operator)
                },
                visible: routeAcceptsCustomOperator()
            },{
                ...getRouteCustomizationValueProps(),
                key: 'value',
                required: routeAcceptsCustomValue(), 
                title: 'Value',
                value: route.value,
                visible: routeAcceptsCustomValue()
            }]
        },{
            key: 'action',
            title: 'Action',
            items: [{
                component: 'list',
                description: `The destination type determines what type of action will be executed if the route logic is satisfied.`,
                items: getActions(),
                key: 'type',
                onChange: item => {
                    onUpdateTarget({
                        action: { 
                            ...route.action,
                            target: null,
                            type: item && item.id || null
                        },
                        operator: null,
                        value: null
                    });
                },
                required: false,
                title: 'Type',
                value: route.action && route.action.type && {
                    id: route.action.type,
                    title: Support.Workflow.Action.types.toText(route.action.type)
                }
            }].concat(getDestinationComponents())
        }];
    }

    const getOperators = () => {

        // declare types and operators
        let types = Support.Workflow.Rule.types.get();

        // date/time range, zone comparison, and direct account comparision types only support "in" and "not_in"
        if([ types.coarse_location_zone, types.date_range, types.fine_location_zone, types.time_range, types.user_appears_in_list ].includes(route.type)) {
            return operators.filter(operator => {
                return [
                    Support.Workflow.Rule.operators.get().in,
                    Support.Workflow.Rule.operators.get().not_in
                ].includes(operator.id);
            });
        }
        return operators;
    }

    const getOperatingSystems = () => {
        return [{
            id: -1,
            title: 'Undetermined'
        },{
            id: 1,
            title: 'Android'
        },{
            id: 2,
            title: 'iOS'
        },{
            id: 3,
            title: 'iPad OS'
        },{
            id: 4,
            title: 'Linux'
        },{
            id: 5,
            title: 'Mac OS'
        },{
            id: 6,
            title: 'Watch OS'
        },{
            id: 7,
            title: 'Windows'
        }];
    }

    const getRouteCustomizationKeyProps = () => {

        if(route.type === Support.Workflow.Rule.types.get().session_variable) {
            return {
                description: `The identifier represents the session variable that will be referenced when the route logic is executed.`,
                title: 'Session Identifier',
                required: true
            }
        }
        return {
            description: `The key represents the variable that will be referenced when the route logic is executed.`,
            title: 'Key',
            required: false,
            visible: false
        }
    }

    const getRouteCustomizationValueProps = () => {

        // declare types and operators
        let types = Support.Workflow.Rule.types.get();
        let operators = Support.Workflow.Rule.operators.get();

        // declare flag for multiple selection availability
        let allowMultipleSelection = routeAcceptsCollectionValue() && [operators.in, operators.not_in].includes(route.operator) ? true : false;

        // loop through logic type and determine whether a specialty component should be returned
        switch(route.type) {
            case types.workflow_count:
            case types.browser_version:
            case types.os_version:
            return {
                component: allowMultipleSelection ? 'multiple_textfield' : 'textfield',
                props: { format: 'number' }
            }

            case types.linear_feet:
            case types.linear_miles:
            return {
                component: 'address_lookup',
                onChange: result => onUpdateTarget({ value: result }),
                props: {
                    insetLabel: 'Address',
                    distance: { insetLabel: route.type === types.linear_feet ? 'Feet' : 'Miles' }
                }
            }

            case types.coarse_location_zone:
            case types.fine_location_zone:
            return {
                component: 'zone_lookup',
                onChange: result => onUpdateTarget({ value: result }),
                props: { insetLabel: 'Address' }
            }

            case types.time:
            return {
                component: 'time_picker',
                onChange: date => onUpdateTarget({ value: date.format('HH:mm:ss') })
            }

            case types.time_range:
            return {
                component: 'dual_time_picker',
                value: route.value && route.value.end && route.value.start,
                props: {
                    endTime: route.value ? route.value.end : null,
                    startTime: route.value ? route.value.start : null,
                    onEndTimeChange: date => {
                        onUpdateTarget({
                            value: {
                                ...route.value,
                                end: date.format('HH:mm:ss')
                            }
                        });
                    },
                    onStartTimeChange: date => {
                        onUpdateTarget({
                            value: {
                                ...route.value,
                                start: date.format('HH:mm:ss')
                            }
                        });
                    }
                }
            }

            case types.date:
            return {
                component: 'date_picker',
                onChange: date => onUpdateTarget({ value: date.format('YYYY-MM-DD') })
            }

            case types.browser_type:
            let browsers = getBrowserTypes();
            if(allowMultipleSelection) {
                return {
                    component: 'multiple_list',
                    items: browsers,
                    onChange: items => onUpdateTarget({ value: items.map(item => item.id) }),
                    value: route.value && browsers.filter(browser => route.value.includes(browser.id))
                }
            }
            return {
                component: 'list',
                items: browsers,
                value: route.value ? browsers.find(browser => browser.id === route.value) : null,
                onChange: item => onUpdateTarget({ value: item && item.id }),
                props: {
                    multiple: allowMultipleSelection
                }
            }

            case types.os_type:
            let systems = getOperatingSystems();
            if(allowMultipleSelection) {
                return {
                    component: 'multiple_list',
                    items: systems,
                    onChange: items => onUpdateTarget({ value: items.map(item => item.id) }),
                    value: route.value && systems.filter(system => route.value.includes(system.id))
                }
            }
            return {
                component: 'list',
                items: systems,
                value: route.value ? systems.find(system => system.id === route.value) : null,
                onChange: item => onUpdateTarget({ value: item && item.id })
            }

            case types.session_variable:
            return {
                component: 'textfield',
                onChange: text => onUpdateTarget({ value: text }),
                value: route.value
            }

            case types.user_appears_in_list:
            return {
                title: 'Users',
                component: 'multiple_user_lookup',
                value: route.value && route.value.users,
                props: {
                    ids: route.value && route.value.user_ids
                },
                onChange: users => {
                    onUpdateTarget({
                        value: {
                            users: users
                        }
                    });
                }
            }
        }

        return { component: 'textfield' }
    }

    const routeAcceptsCustomOperator = () => {
        return route && Support.Workflow.Rule.accepts.operator(route);
    }

    const routeAcceptsCustomValue = () => {
        return route && Support.Workflow.Rule.accepts.value(route);
    }

    const routeAcceptsCollectionValue = () => {
        return route && Support.Workflow.Rule.accepts.collection(route);
    }

    const setupTarget = () => {

        // prepare list items for operators
        setOperators(Object.values(Support.Workflow.Rule.operators.get()).map(val => ({
            id: val,
            title: Support.Workflow.Rule.operators.toText(val)
        })));

        // prepare list items for logic types
        setTypes(Object.values(Support.Workflow.Rule.types.get()).map(val => ({
            id: val,
            title: Support.Workflow.Rule.types.toText(val)
        })).sort((a,b) => {
            return a.title.localeCompare(b.title);
        }));

        // setup target editing
        let edits = target.edits || target.open();
        setRoute({
            ...edits,
            user_set_name: isNewTarget ? false : true
        });
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? 'New Workflow Rule Route' : `Editing Support Workflow Rule Route ${target.id}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            onPreparingClose: onCloseLayer,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils} />
        </Layer>
    )
}

export const AddEditSupportWorkflowRuleTask = ({ isNewTarget, onChange, target }, { index, options, utils }) => {

    const layerID = isNewTarget ? 'new_support_workflow_rule_task' : `edit_support_workflow_rule_task_${target.id}`;

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [task, setTask] = useState(null);
    const [types, setTypes] = useState([]);

    const onClearContent = async () => {
        let edits = target.open();
        setTask(edits);
    }

    const onCloseLayer = () => {
        utils.events.emit('close_data_request_layer');
    }

    const onSubmit = async () => {
        try {

            // verify that required fields have been completed
            await validateRequiredFields(getFields);
            
            // notify subscribers of data change
            setLayerState('close');
            if(typeof(onChange) === 'function') {
                onChange(target);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this workflow rule task. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        let edits = target.set(props);
        setTask(edits);
    }

    const getActionComponents = () => {
        switch(task.type) {
            case Support.Workflow.Rule.Task.types.get().email:
            return [{
                component: 'multiple_textfield',
                description: 'These are the email addresses that will be contacted when this task executes.',
                key: 'value.email_addresses',
                onChange: results => {
                    onUpdateTarget({
                        value: {
                            ...task.value,
                            email_addresses: results   
                        }
                    });
                },
                title: 'Recipients',
                value: task.value && task.value.email_addresses
            },{
                component: 'textfield',
                description: 'This is the text that will be shown in the subject line for the email.',
                key: 'value.subject',
                onChange: text => {
                    onUpdateTarget({
                        value: {
                            ...task.value,
                            subject: text   
                        }
                    });
                },
                title: 'Subject',
                value: task.value && task.value.subject
            },{
                component: 'textview',
                description: 'This is the content that will be shown in the body of the email.',
                key: 'value.content',
                onChange: text => {
                    onUpdateTarget({
                        value: {
                            ...task.value,
                            content: text   
                        }
                    });
                },
                title: 'Content',
                value: task.value && task.value.content
            }];

            case Support.Workflow.Rule.Task.types.get().push_notification:
            return [{
                component: 'multiple_user_lookup',
                description: 'These are the users that will be contacted when this task executes. A user must have the AFT mobile app installed on their device and have granted permission for push notifications.',
                key: 'value.users',
                onChange: results => {
                    onUpdateTarget({
                        value: {
                            ...task.value,
                            users: results   
                        }
                    });
                },
                title: 'Recipients',
                value: task.value && task.value.users
            },{
                component: 'textfield',
                description: 'This is the text that will be shown at the top of a push notification once it is delivered.',
                key: 'value.title',
                onChange: text => {
                    onUpdateTarget({
                        value: {
                            ...task.value,
                            title: text   
                        }
                    });
                },
                title: 'Title',
                value: task.value && task.value.title
            },{
                component: 'textview',
                description: 'This is the content that will be shown for the body of a push notification once it is delivered.',
                key: 'value.content',
                onChange: text => {
                    onUpdateTarget({
                        value: {
                            ...task.value,
                            content: text   
                        }
                    });
                },
                title: 'Content',
                value: task.value && task.value.content
            }];

            case Support.Workflow.Rule.Task.types.get().sms:
            return [{
                component: 'multiple_textfield',
                description: 'These are the phone numbers that will be contacted when this task executes.',
                key: 'value.phone_numbers',
                onChange: results => {
                    onUpdateTarget({
                        value: {
                            ...task.value,
                            phone_numbers: results   
                        }
                    });
                },
                props: { format: 'phone_number' },
                title: 'Recipients',
                value: task.value && task.value.phone_numbers
            },{
                component: 'textview',
                description: 'This is the content that will be shown in the body of the text message once it is delivered.',
                key: 'value.content',
                onChange: text => {
                    onUpdateTarget({
                        value: {
                            ...task.value,
                            content: text   
                        }
                    });
                },
                title: 'Content',
                value: task.value && task.value.content
            }];  

            case Support.Workflow.Rule.Task.types.get().webhook:
            return [{
                component: 'textfield',
                description: 'This is the destination url where session data will be sent when this task executes.',
                key: 'value.url',
                onChange: text => {
                    onUpdateTarget({
                        value: {
                            ...task.value,
                            url: text   
                        }
                    });
                },
                title: 'URL',
                value: task.value && task.value.url
            }];  

            default:
            return [];
        }
    }

    const getButtons = () => {
        return [{
            key: 'clear',
            text: 'Clear',
            color: 'dark',
            onClick: onClearContent,
        },{
            key: 'submit',
            text: isNewTarget ? 'Create' : 'Save Changes',
            color: 'primary',
            onClick: onSubmit
        }];
    }

    const getFields = () => {

        // prevent moving forward if task state has not been set
        if(!task) {
            return [];
        }

        // return default editing components
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                component: 'textfield',
                description: 'The name for a workflow rule task will be used when representing this task internally.',
                key: 'name',
                onChange: text => {
                    onUpdateTarget({
                        name: text,
                        user_set_name: true
                    });
                },
                required: false,
                title: 'Name',
                value: task.name
            }]
        },{
            key: 'type',
            title: 'Action',
            items: [{
                component: 'list',
                description: `The action for a workflow task determines which what happens when the parent rule is finished executing.`,
                items: types,
                key: 'type',
                onChange: item => {

                    // prevent moving forward if no type was selected
                    if(!item) {
                        onUpdateTarget({ 
                            type: null,
                            value: null 
                        });
                        return;
                    }
                    onUpdateTarget({
                        name: task.user_set_name === true ? task.name : Support.Workflow.Rule.Task.types.toText(item.id),
                        type: item.id,
                        value: null
                    });
                },
                title: 'Action',
                value: task.type && {
                    id: task.type,
                    title: Support.Workflow.Rule.Task.types.toText(task.type)
                }
            }].concat(getActionComponents())
        }];
    }

    const setupTarget = async () => {
        try {
            
            // prepare list of types
            setTypes(() => {
                let items = Object.values(Support.Workflow.Rule.Task.types.get()).map(value => ({
                    id: value,
                    title: Support.Workflow.Rule.Task.types.toText(value)
                }));
                return items.sort((a,b) => {
                    return a.title.localeCompare(b.title);
                });
            });
    
            // setup edits for target task
            let edits = target.edits || target.open();
            setTask({
                ...edits,
                user_set_name: isNewTarget ? false : true
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the editing process for this task. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? 'New Workflow Rule Task' : `Editing Support Workflow Rule Task ${target.id}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            onPreparingClose: onCloseLayer,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils} />
        </Layer>
    )
}

export const AddEditSupportWorkflow = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_support_workflow' : `edit_support_workflow_${abstract.getID()}`;
    const dragging = useRef(null);
    const flowRef = useRef(null);
    const itemsRef = useRef([]);
    const itemsEditorRef = useRef(null);
    const layoutRef = useRef({});
    const userInteraction = useRef(false);

    const [collapsed, setCollapsed] = useState(false);
    const [elements, setElements] = useState([]);
    const [fullscreen, setFullscreen] = useState(false);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useLoading();
    const [session, setWorkflow] = useState(null);

    const onAddItem = abstract => {

        // check if element already exists in flow
        let match = elements.find(item => item.id === abstract.getTag());
        if(match) {
            utils.alert.show({
                title: 'Just a Second',
                message: `It looks like you have already added ${abstract.getTitle()} to your support workflow.`
            });
            return;
        }

        // update user interaction ref
        userInteraction.current = true;

        // update elements state
        setElements(props => {

            // determine if new item should be automatically selected as a root item
            let count = props.filter(entry => ['support_workflow_landings', 'support_workflow_rules'].includes(entry.type)).length;
            abstract.object.root = count === 0;

            // generate items from target
            let items = createItem(abstract, count);
            return update(props, { $push: items });
        });
    }

    const onAddNewLanding = () => {

        // prepare new landing
        let landing = Support.Workflow.Landing.new();

        // present workflow landing edit layer
        utils.layer.open({
            id: 'new_workflow_landing',
            abstract: Abstract.create({
                type: 'support_workflow_landings',
                object: landing
            }),
            Component: AddEditSupportWorkflowLanding.bind(this, {
                session: abstract.object,
                isNewTarget: true,
                onLandingAdded: landing => {
                    onAddItem(Abstract.create({
                        type: 'support_workflow_landings',
                        object: landing
                    }));
                }
            })
        });
    }

    const onAddNewItemClick = evt => {

        // present options to create new session item
        utils.sheet.show({
            items: [{
                key: 'landing',
                title: 'New Landing',
                style: 'default'
            },{
                key: 'rule',
                title: 'New Rule',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            switch(key) {
                case 'landing':
                onAddNewLanding();
                break;

                case 'rule':
                onAddNewRule();
                break;
            }
        })
    }

    const onAddNewRule = () => {

        // prepare new rule
        let rule = Support.Workflow.Rule.new();

        // present workflow rule edit layer
        utils.layer.open({
            id: 'new_support_workflow_rule',
            abstract: Abstract.create({
                type: 'support_workflow_rules',
                object: rule
            }),
            Component: AddEditSupportWorkflowRule.bind(this, {
                isNewTarget: true,
                onRuleAdded: rule => {
                    onAddItem(Abstract.create({
                        type: 'support_workflow_rules',
                        object: rule
                    }));
                }
            })
        });
    }

    const onClearContent = async () => {
        try {
            setLoading('clear');
            let edits = await Abstract.clear(utils, abstract);
            setLoading(false);
            setWorkflow(edits);
        } catch(e) {
            setLoading(false);
            console.error(e.message);
        }
    }

    const onConnect = evt => {

        // prepare values for new connection
        let { button, route, source, target } = evt;
        setElements(props => onRequestNewConnection({ button, elements: props, source, target, route }));
    }

    const onConnectEnd = () => {
        setElements(props => {

            // update elements
            let next = props.map(entry => {
                if(!entry.data) {
                    return entry;
                }
                return update(entry, {
                    data: {
                        $set: {
                            ...entry.data,
                            active: false,
                            activeHandleId: null,
                            hover: false,
                            isConnecting: false,
                            selected: false
                        }
                    }
                });
            });

            // prevent moving forward if an active hover source and target are not found
            let source = props.find(entry => entry.data && entry.data.selected === true);
            let target = props.find(entry => entry.data && entry.data.hover === true);
            if(!source || !target) {
                console.warn('no hover source and/or target found');
                return next;
            }

            // update connections
            return onRequestNewConnection({ elements: next, source, target });
        });
    }

    const onConnectStart = (_, props) => {
        let { handleId, nodeId } = props;
        setElements(props => {
            return props.map(entry => {
                if(!entry.data) {
                    return entry;
                }
                return update(entry, {
                    data: {
                        $set: {
                            ...entry.data,
                            active: true,
                            isConnecting: true,
                            ...entry.id === nodeId && {
                                selected: true,
                                activeHandleId: handleId,
                            }

                        }
                    }
                });
            });
        });
    }

    const onDragMove = (_, props) => {

        // prepare parent offset for drag start
        let offsets = {
            x: props.position.x - dragging.current.position.x, 
            y: props.position.y - dragging.current.position.y
        };

        // update positions for state elements
        setElements(elements => {

            // loop through child elements and update position if applicable
            dragging.current.children.forEach(child => {

                // prepare element index and parent offsets 
                let index = elements.findIndex(el => el.id === child.id);

                // update position for element using parent offsets
                elements = update(elements, {
                    [index]: {
                        position: {
                            $set: {
                                x: child.position.x + offsets.x,
                                y: child.position.y + offsets.y,
                            }
                        }
                    }
                })
            });
            return elements;
        });
    }

    const onDragStart = (_, props) => {

        // update user interaction ref
        userInteraction.current = true;

        // set target ref and determine which child elements to target
        dragging.current = {
            ...props,
            children: []
        };

        // determine if landing buttons need to be moved
        if(props.type === 'support_workflow_landings') {
            elements.forEach(element => {
                if(element.type === 'support_workflow_landings_buttons' && element.data.abstract.getTag() === props.id) {
                    dragging.current.children.push({
                        id: element.id,
                        position: element.position
                    });
                }
            });
        }

        // determine if landing buttons need to be moved
        if(props.type === 'support_workflow_rules') {
            elements.forEach(element => {
                if(element.type === 'support_workflow_rules_routes' && element.data.abstract.getTag() === props.id) {
                    dragging.current.children.push({
                        id: element.id,
                        position: element.position
                    });
                }
            });
        }
    }

    const onDragStop = (_, node) => {
        let { id, position } = node;
        onUpdateLayout({
            ...layoutRef.current,
            [id]: {
                ...layoutRef.current[id],
                id: id,
                position: position
            }
        });
    }

    const onFitIntoView = () => {
        if(flowRef.current) {
            flowRef.current.fitView();
        }
    }

    const onItemClick = (abstract, evt) => {

        // prevent moving forward if abstract is already set as root
        if(abstract.object.root === true) {
            switch(abstract.type) {
                case 'support_workflow_landings':
                onShowLandingDetails(abstract);
                break;

                case 'support_workflow_rules':
                onShowRuleDetails(abstract);
                break;
            }
            return;
        }

        // present options for session abstract
        utils.sheet.show({
            items: [{
                key: 'landing_details',
                title: 'Edit Landing',
                style: 'default',
                visible: abstract.type === 'support_workflow_landings'
            },{
                key: 'rule_details',
                title: 'Edit Rule',
                style: 'default',
                visible: abstract.type === 'support_workflow_rules'
            },{
                key: 'root',
                title: 'Set as Entry Point',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'landing_details') {
                onShowLandingDetails(abstract);
                return;
            }
            if(key === 'rule_details') {
                onShowRuleDetails(abstract);
                return;
            }
            if(key === 'root') {
                onSetRuleAsRoot(abstract);
                return;
            }
        });
    }

    const onItemUpdated = abstract => {

        // update user interaction ref
        userInteraction.current = true;

        // update abstract in list of items
        let next = itemsRef.current.map(prev => prev.getTag() === abstract.getTag() ? abstract : prev);

        // set layout with newly updated items
        // this will create connections based on the updated items's else/next conditional values if applicable
        onSetLayout(next);
    }

    const onLoad = instance => {
        flowRef.current = instance;
        instance.fitView();
    }

    const onNodeMouseEnter = (_, props) => {
        let { id } = props;
        setElements(props => {
            return props.map(entry => {
                if(!entry.data || entry.data.active !== true) {
                    return entry;
                }

                if(entry.id === id) {
                    console.warn(`hover set to ${id}`);
                }
                return update(entry, {
                    data: {
                        hover: {
                            $set: entry.id === id
                        }
                    }
                });
            })
        });
    }

    const onNodeMouseLeave = () => {
        setElements(props => {
            return props.map(entry => {
                if(!entry.data) {
                    return entry;
                }
                return update(entry, {
                    data: {
                        hover: {
                            $set: false
                        }
                    }
                });
            })
        });
    }

    const onRemoveEdge = (props, evt) => {

        // prevent parent click from triggering
        evt.stopPropagation();

        // update elements and remove connection
        let { id, source, target } = props;
        setElements(elements => {

            // prevent moving forward if element if not found in elements collection
            let index = elements.findIndex(entry => entry.id === `${source}-${target}`);
            if(index < 0) {
                return elements;
            }

            // loop through elements and determine if additional logic needs to be applied
            elements.forEach(element => {

                // determine if current element matches source element
                if(element.id === source) {

                    // check if source element is a button and remove action if applicable
                    if(source.includes('support_workflow_landings_buttons')) {
                        element.data.abstract.object.content.buttons.forEach(button => {
                            if(button.id === element.data.button.id) {
                                button.action = null;
                                return;
                            }
                        });
                    }

                    // check if source element is a rule route and remove action if applicable
                    if(source.includes('support_workflow_rules_routes')) {
                        element.data.abstract.object.routes.forEach(route => {
                            if(route.id === element.data.route.id) {
                                route.action.target = null;
                                return;
                            }
                        })
                    }
                }

                // determine if current element matches target element
                if(element.id === target) {

                    // check if target element is a button and remove action if applicable
                    if(target.includes('support_workflow_landings_buttons')) {
                        element.data.abstract.object.content.buttons.forEach(button => {
                            if(button.id === element.data.button.id) {
                                button.action = null;
                                return;
                            }
                        })
                    }

                    // check if target element is a logic item and remove action if applicable
                    if(target.includes('support_workflow_rules_routes')) {
                        element.data.abstract.object.routes.forEach(route => {
                            if(route.id === element.route.data.id) {
                                route.action.target = null;
                                return;
                            }
                        })
                    }
                }
            });

            return update(elements, { $splice: [[ index, 1 ]] });
        });

        // update layout and remove connection
        delete layoutRef.current[id];
    }

    const onRemoveItem = (index, evt) => {

        evt.stopPropagation();

        // prevent moving forward if element can not be located
        let element = elements.find(el => {
            return [ 'support_workflow_landings', 'support_workflow_rules' ].includes(el.type) && el.data.abstract.getTag() === items[index].getTag();
        });
        if(!element) {
            return;
        }

        // check if confirmation needs to be requested with elements that contain at least one connection
        let connections = elements.filter(el => el.source === element.id || el.target === element.id);
        if(connections.length === 0) {
            onRemoveItemConfirm(element);
            return;
        }

        // request confirmation for removal if at least one connection was found
        utils.alert.show({
            title: `Remove ${element.type === 'support_workflow_landings' ? 'Landing' : 'Rule'}`,
            message: `Are you sure that you want to remove this ${element.type === 'support_workflow_landings' ? 'landing' : 'rule'}? This ${element.type === 'support_workflow_landings' ? 'landing' : 'rule'} has ${connections.length} ${connections.length === 1 ? 'connection' : 'connections'} to ${connections.length === 1 ? 'another element' : 'other elements'} and ${connections.length === 1 ? 'that connection' : 'those connections'} will be removed as well.`,
            buttons: [{
                key: 'confirm',
                title: 'Remove',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveItemConfirm(element, connections);
                    return;
                }
            }
        });
    }

    const onRemoveItemConfirm = (el, connections = []) => {

        // update user interaction ref
        userInteraction.current = true;

        // update elements state by removing the requested item
        setElements(elements => {

            // loop through types and remove element if applicable
            let next = elements.filter(prev => {
                switch(prev.type) {

                    case 'support_workflow_landings':
                    case 'support_workflow_landings_buttons':
                    case 'support_workflow_rules':
                    case 'support_workflow_rules_routes':
                    return prev.data.abstract.getTag() !== el.id;

                    case 'support_workflow_edge':
                    return prev.source !== el.id && prev.target !== el.id;

                    default:
                    return prev.source !== el.id && prev.target !== el.id;
                }
            });

            // check if connections reference an action and remove if applicable
            if(connections.length > 0) {
                connections.forEach(connection => {
                    let source = next.find(el => el.id === connection.source);
                    if(source) {
                        switch(source.type) {
                            case 'support_workflow_landings_buttons':
                            source.data.abstract.object.content.buttons = source.data.abstract.object.content.buttons.map(button => {
                                if(button.id === source.data.button.id) {
                                    button.action = null;
                                }
                                return button;
                            });
                            break;

                            case 'support_workflow_rules_routes':
                            source.data.abstract.object.routes = source.data.abstract.object.routes.map(route => {
                                if(route.id === source.data.route.id) {
                                    route.action.target = null;
                                }
                                return route;
                            });
                            break;
                        }
                        
                    }
                });
            }
            return next;
        });
    }

    const onRequestNewConnection = ({ elements, route, source, target }) => {

        // declare connection props
        let connection = createConnection({
            handle: source.data.activeHandleId,
            route: route,
            source: source.id, 
            target: target.id, 
            type: 'support_workflow_edge'
        });

        // determine next steps based on source type
        switch(source.type) {
            case 'support_workflow_landings_buttons':
            elements = elements.map(el => {
                if(el.id === source.id) {

                    // loop through buttons and create an action linking the button to the destination
                    let { buttons = [] } = el.data.abstract.object.content;
                    el.data.abstract.object.content.buttons = buttons.filter(button => {
                        return button.linkable !== false;
                    }).map(button => {
                        if(button.id === source.data.button.id) {
                            button.action = createButtonActionFromConnection(target);
                            el.data.button = button;
                        }
                        return button;
                    });
                }
                return el;
            });
            break;

            case 'support_workflow_rules_routes':
            elements = elements.map(el => {
                if(el.id === source.id) {

                    // loop through routes and create an action linking the route to the destination
                    let { routes = [] } = el.data.abstract.object;
                    el.data.abstract.object.routes = routes.map(route => {
                        if(route.id === source.data.route.id) {
                            route.action = {
                                target: target.data.abstract.object,
                                type: route.action.type
                            };
                            el.data.route = route;
                        }
                        return route;
                    });
                }
                return el;
            });
            break;
        }

        // prevent moving forward if a matching previous connection is not found
        let index = elements.findIndex(entry => entry.id === connection.id);
        if(index < 0) {
            return elements.concat([connection]);
        }

        // remove old connection and replace with new connection
        console.warn(`updating connection ${index}`);
        return update(elements, {
            $splice: [
                [index, 1],
                [0, 0, connection]
            ]
        });
    }

    const onSetLayout = items => {
        setElements(() => {

            // prepare standard item nodes
            let nodes = items.reduce((array, abstract) => array.concat(createItem(abstract)), []);

            // prepare next/else node connections
            let connections = items.reduce((array, abstract) => {

                // create button connections for workflow landings
                if(abstract.type === 'support_workflow_landings') {
                    let { buttons = [] } = abstract.object.content || {};
                    buttons.forEach(button => {
                        if(button.action) {
                            switch(button.action.type) {
                                case Support.Workflow.Action.types.get().redirect_to_workflow_landing:
                                array.push(createConnection({
                                    button: button,
                                    handle: 'action',
                                    source: `${abstract.type}_buttons-${button.id}`,
                                    target: `support_workflow_landings-${button.action.content.landing_id}`,
                                    type: 'support_workflow_edge'
                                }));
                                break;

                                case Support.Workflow.Action.types.get().redirect_to_workflow_rule:
                                array.push(createConnection({
                                    button: button,
                                    handle: 'action',
                                    source: `${abstract.type}_buttons-${button.id}`,
                                    target: `support_workflow_rules-${button.action.content.rule_id}`,
                                    type: 'support_workflow_edge'
                                }));
                                break;
                            }
                        }
                    })
                }

                // create landing and rule connections for workflow rules
                if(abstract.type === 'support_workflow_rules') {

                    // determine if any route action connections need to be created
                    let routes = abstract.object.routes || [];
                    routes.forEach(route => {
                        if(route.action && route.action.target) {
                            switch(route.action.type) {
                                case Support.Workflow.Action.types.get().redirect_to_workflow_landing:
                                array.push(createConnection({
                                    handle: 'action',
                                    route: route,
                                    source: `${abstract.type}_routes-${route.id}`,
                                    target: `support_workflow_landings-${route.action.target.id}`,
                                    type: 'support_workflow_edge'
                                }));
                                break;

                                case Support.Workflow.Action.types.get().redirect_to_workflow_rule:
                                array.push(createConnection({
                                    handle: 'action',
                                    route: route,
                                    source: `${abstract.type}_routes-${route.id}`,
                                    target: `support_workflow_rules-${route.action.target.id}`,
                                    type: 'support_workflow_edge'
                                }));
                                break;
                            }
                        }
                    });
                }
                return array;

            }, []);

            return connections.concat(nodes);
        });
    }

    const onSetRuleAsRoot = abstract => {
        utils.alert.show({
            title: 'Set as Entry Point',
            message: `Are you sure that you want to set this ${abstract.type === 'support_workflow_landings' ? 'landing' : 'rule'} as the entry point for this session? This will be the first item referenced when a customer interacts with this session. Changing the entry point may alter the connections you currently have setup.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Change',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetRuleAsRootConfirm(abstract);
                    return;
                }
            }
        });
    }

    const onSetRuleAsRootConfirm = abstract => {

        // update user interaction ref
        userInteraction.current = true;

        // update elements with new root item
        setElements(elements => {
            return elements.map(el => {
                if([ 'support_workflow_landings', 'support_workflow_rules' ].includes(el.type)) {
                    el.data.abstract.object.root = el.data.abstract.getTag() === abstract.getTag();
                }
                return el;

            }).filter((el, _, next) => {

                // prevent moving forward if element is not a connection
                if([ 'support_workflow_landings', 'support_workflow_landings_buttons', 'support_workflow_rules', 'support_workflow_rules_routes' ].includes(el.type)) {
                    return true;
                }

                // remove connections where the requested root target matches
                if(el.targetHandle === 'top' && el.target === abstract.getTag()) {
                    return false;
                }

                return isConnectionValid(el, abstract, next);
            });
        })
    }

    const onShowLandingDetails = abstract => {
        utils.layer.open({
            id: `edit_workflow_landing_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditSupportWorkflowLanding.bind(this, {
                isNewTarget: false,
                onLandingUpdated: landing => {
                    abstract.object = landing;
                    onItemUpdated(abstract);
                }
            })
        });
    }

    const onShowRuleDetails = async abstract => {
        try {

            // fetch routes and tasks for rule
            setLoading(true);
            let { routes, tasks } = await Request.get(utils, '/support/', {
                type: 'workflow_rule_details',
                id: abstract.getID()
            });

            // update workflow object for parent
            setLoading(false);
            abstract.object.routes = routes.map(route => Support.Workflow.Rule.Route.create(route));
            abstract.object.tasks = tasks.map(task => Support.Workflow.Rule.Task.create(task));

            // open rule editing layer
            utils.layer.open({
                id: `edit_support_workflow_rule_${abstract.getID()}`,
                abstract: abstract,
                Component: AddEditSupportWorkflowRule.bind(this, {
                    isNewTarget: false,
                    onRuleUpdated: rule => {
                        abstract.object = rule;
                        onItemUpdated(abstract);
                    }
                })
            });
        }  catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this workflow rule. ${e.message || 'An unknown error occurred'}`
            });
        }       
    }

    const onSizingChange = val => {

        // update fullscreen flag and scroll editor into view if applicable
        setFullscreen(val === 'fullscreen');
        if(itemsEditorRef.current) {
            itemsEditorRef.current.scrollIntoView();
        }
    }

    const onSubmit = async () => {
        try {

            // start loading and validate standard required fields
            setLoading('submit');
            await Utils.sleep(1);
            await validateRequiredFields(getFields);

            // check that and entry point has been set for the session
            let root = items.find(abstract => abstract.object.root === true);
            if(!root) {
                throw new Error('It looks like you have not set an entry point for this session. The entry point will be the first piece of logic that is executed during a session or the first landing a customer will see when they start their session.');
            }

            // prepare items for payload
            let props = items.reduce((object, abstract) => {
                switch(abstract.type) {
                    case 'support_workflow_landings':
                    object.landings.push({
                        buttons: abstract.object.content && abstract.object.content.buttons,
                        id: abstract.getID(),
                        root: abstract.object.root
                    });
                    break;

                    case 'support_workflow_rules':
                    object.rules.push({
                        id: abstract.getID(),
                        root: abstract.object.root,
                        routes: abstract.object.routes.map(route => {
                            return route.toJSON();
                        })
                    });
                    break;
                }
                return object;

            }, { landings: [], rules: [] });

            // submit request to server
            await abstract.object.apply(utils, isNewTarget, {
                layout: layoutRef.current,
                ...props
            });

            // reset user interaction ref
            userInteraction.current = false;

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The support session has been ${isNewTarget ? 'created and is immediately available for use.' : 'updated and changes will take effect during the next session.'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this support session. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onToggleCollapseState = () => {
        setCollapsed(val => !val);
    }

    const onUpdateCollapseState = () => {
        setElements(elements => {
            return elements.map(element => {
                if(['support_workflow_landings', 'support_workflow_rules'].includes(element.type)) {
                    element.data.collapsed = collapsed;
                }
                return element;
            });
        });
    }

    const onUpdateLayout = async props => {
        layoutRef.current = {
            ...layoutRef.current,
            ...props
        }
    }

    const onUpdateItems = () => {
        setItems(items => {
            return elements.reduce((array, element) => {

                // prevent moving forward if element is a button or connection
                if(['support_workflow_landings', 'support_workflow_rules'].includes(element.type) === false) {
                    return array;
                }

                // look through current connections and find matching else/next items
                let e = elements.find(el => el.sourceHandle === 'else' && el.source === element.id);
                let n = elements.find(el => el.sourceHandle === 'next' && el.source === element.id);

                // declare item ids if matches were found
                let elseTarget = e && items.find(abstract => abstract.getTag() === e.target);
                let nextTarget = n && items.find(abstract => abstract.getTag() === n.target);

                // attach full abstracts if matches were found
                let target = element.data.abstract;
                target.object.else_landing = elseTarget && items.find(abstract => elseTarget.type === 'support_workflow_landings' && elseTarget.getTag() === abstract.getTag());
                target.object.else_rule = elseTarget && items.find(abstract => elseTarget.type === 'support_workflow_rules' && elseTarget.getTag() === abstract.getTag());
                target.object.next_landing = nextTarget && items.find(abstract => nextTarget.type === 'support_workflow_landings' && nextTarget.getTag() === abstract.getTag());
                target.object.next_rule = nextTarget && items.find(abstract => nextTarget.type === 'support_workflow_rules' && nextTarget.getTag() === abstract.getTag());

                // reduce full abstracts down to class objects
                target.object.else_landing = target.object.else_landing ? target.object.else_landing.object : null;
                target.object.else_rule = target.object.else_rule ? target.object.else_rule.object : null;
                target.object.next_landing = target.object.next_landing ? target.object.next_landing.object : null;
                target.object.next_rule = target.object.next_rule ? target.object.next_rule.object : null;

                // update id values for item
                target.object.else_landing_id = target.object.else_landing && target.object.else_landing.id;
                target.object.else_rule_id = target.object.else_rule && target.object.else_rule.id;
                target.object.next_landing_id = target.object.next_landing && target.object.next_landing.id;
                target.object.next_rule_id = target.object.next_rule && target.object.next_rule.id;

                // update destinations for source item using elseTarget if applicable
                if(elseTarget) {
                    switch(elseTarget.type) {
                        case 'support_workflow_landings':
                        target.object.else_destination = Support.Workflow.destinations.get().workflow_landing;
                        break;

                        case 'support_workflow_rules':
                        target.object.else_destination = Support.Workflow.destinations.get().workflow_rule;
                        break;
                    }
                }

                // update destinations for source item using nextTarget if applicable
                if(nextTarget) {
                    switch(nextTarget.type) {
                        case 'support_workflow_landings':
                        target.object.next_destination = Support.Workflow.destinations.get().workflow_landing;
                        break;

                        case 'support_workflow_rules':
                        target.object.next_destination = Support.Workflow.destinations.get().workflow_rule;
                        break;
                    }
                }

                // add item to array of items
                array.push(target);
                return array;

            }, []).sort((a,b) => {
                return a.object.name.localeCompare(b.object.name);
            })
        });
    }

    const onUpdateTarget = props => {

        // update user interaction ref
        userInteraction.current = true;

        // set edits for abstract target and update state
        let edits = abstract.object.set(props);
        setWorkflow(edits);
    }

    const onZoom = val => {
        if(!flowRef.current) {
            return;
        }
        if(val === 1) {
            flowRef.current.zoomIn();
        } else {
            flowRef.current.zoomOut();
        }
    }

    const getButtons = () => {
        return [{
            key: 'clear',
            text: 'Clear',
            color: 'dark',
            onClick: onClearContent
        },{
            key: 'submit',
            text: isNewTarget ? 'Create' : 'Save Changes',
            color: 'primary',
            loading: loading === 'submit',
            onClick: onSubmit
        }]
    }

    const getEdgeColor = handle => {
        switch(handle) {
            case 'else':
            return Appearance.colors.red;

            case 'next':
            return Appearance.colors.green;

            default:
            return Appearance.colors.grey();
        }
    }

    const getFields = () => {
        if(!session) {
            return [];
        }

        return [{
            key: 'details',
            title: 'About this Workflow',
            items: [{
                key: 'name',
                title: 'Name',
                description: 'The name for a session will be shown when representing the session in public and private settings.',
                component: 'textfield',
                value: session.name,
                onChange: text => onUpdateTarget({ name: text })
            }]
        },{
            key: 'options',
            title: 'Options',
            items: [{
                key: 'redirection_delay',
                required: false,
                title: 'Redirection Delay',
                description: `Enabling a redirection delay will show the user a message explaining they will bne redirected within the next 10 seconds. By default, we use a redirection delay to inform the customer of the upcoming action.`,
                component: 'bool_list',
                value: session.options.redirection_delay,
                onChange: val => {
                    onUpdateTarget({
                        options: update(session.options, {
                            redirection_delay: {
                                $set: val
                            }
                        })
                    });
                }
            },{
                key: 'request_location',
                required: false,
                title: 'Request User Location',
                description: `Requesting the user's current location will give you a better understanding of where your support sessions originate from .`,
                component: 'bool_list',
                value: session.options.request_location,
                onChange: val => {
                    onUpdateTarget({
                        options: update(session.options, {
                            request_location: {
                                $set: val
                            }
                        })
                    });
                }
            },{
                key: 'visibility',
                required: false,
                title: 'Visibility',
                description: `Setting visibility determines how the support workflow will be shown. This is helpful for automatically including a support workflow in one of the Graci web or mobile products.`,
                component: 'multiple_list',
                items: getVisibilityItems(),
                value: session.options.visibility && getVisibilityItems().filter(item => session.options.visibility.includes(item.id)),
                onChange: items => {
                    onUpdateTarget({
                        options: update(session.options, {
                            visibility: {
                                $set: items && items.map(item => item.id)
                            }
                        })
                    });
                }
            }]
        },{
            key: 'options.display',
            title: 'Display',
            items: [{
                key: 'background_color',
                required: false,
                title: 'Background Color',
                description: 'This color represents the background color for the support workflow. This colors appears behind the workflow content.',
                component: 'color_picker',
                value: session.options.display && session.options.display.background_color,
                onChange: color => {
                    onUpdateTarget({
                        options: {
                            ...session.options,
                            display: update(session.options.display || {}, {
                                background_color: {
                                    $set: color
                                }
                            })
                        }
                    });
                }
            },{
                key: 'embedded',
                required: false,
                title: 'Embedded',
                description: 'When embedding a support workflow into another page, we will provide additional configuration settings to help you control how the workflow is displayed.',
                component: 'bool_list',
                value: session.options.display && session.options.display.embedded ? 'Yes' : 'No',
                onChange: val => {
                    onUpdateTarget({
                        options: {
                            ...session.options,
                            display: update(session.options.display || {}, {
                                embedded: {
                                    $set: val
                                }
                            })
                        }
                    });
                }
            },{
                key: 'embedded_max_width',
                required: false,
                title: 'Maximum Width',
                description: 'This value determines the maximum allowed width when this workflow is embedded in another webpage.',
                component: 'textfield',
                value: session.options.display && session.options.display.embedded_max_width,
                visible: session.options.display && session.options.display.embedded || false,
                onChange: val => {
                    onUpdateTarget({
                        options: {
                            ...session.options,
                            display: update(session.options.display || {}, {
                                embedded_max_width: {
                                    $set: val
                                }
                            })
                        }
                    });
                }
            },{
                key: 'embedded_min_width',
                required: false,
                title: 'Minimum Width',
                description: 'This value determines the minimum allowed width when this workflow is embedded in another webpage.',
                component: 'textfield',
                value: session.options.display && session.options.display.embedded_min_width,
                visible: session.options.display && session.options.display.embedded || false,
                onChange: val => {
                    onUpdateTarget({
                        options: {
                            ...session.options,
                            display: update(session.options.display || {}, {
                                embedded_min_width: {
                                    $set: val
                                }
                            })
                        }
                    });
                }
            }]
        }];
    }

    const getFlowControls = () => (
        <Controls
        showZoom={false}
        showFitView={false}
        showInteractive={false}
        style={{
            overflow: 'hidden',
            borderRadius: 10,
            border: `1px solid ${Appearance.colors.softBorder()}`,
            backgroundColor: Appearance.colors.panelBackground(),
            boxShadow: 'none'
        }}>
            <ControlButton onClick={onZoom.bind(this, 1)}>
                <img
                className={'text-button'}
                src={'images/plus-icon-grey-small.png'}
                style={{
                    padding: 8,
                    width: 30,
                    height: 30,
                    backgroundColor: Appearance.colors.panelBackground(),
                    borderBottom: `1px solid ${Appearance.colors.soft()}`,
                    objectFit: 'contain',
                    opacity: 0.75
                }}/>
            </ControlButton>
            <ControlButton onClick={onZoom.bind(this, -1)}>
                <img
                className={'text-button'}
                src={'images/minus-icon-grey-small.png'}
                style={{
                    padding: 8,
                    width: 30,
                    height: 30,
                    backgroundColor: Appearance.colors.panelBackground(),
                    borderBottom: `1px solid ${Appearance.colors.soft()}`,
                    objectFit: 'contain',
                    opacity: 0.75
                }}/>
            </ControlButton>
            <ControlButton onClick={onFitIntoView}>
                <img
                className={'text-button'}
                src={'images/fit-view-grey-small.png'}
                style={{
                    padding: 8,
                    width: 30,
                    height: 30,
                    backgroundColor: Appearance.colors.panelBackground(),
                    borderBottom: `1px solid ${Appearance.colors.soft()}`,
                    objectFit: 'contain',
                    opacity: 0.75
                }}/>
            </ControlButton>
            <ControlButton onClick={onToggleCollapseState}>
                <img
                className={'text-button'}
                src={collapsed ? 'images/down-arrow-grey-small.png' : 'images/up-arrow-grey-small.png'}
                style={{
                    padding: 8,
                    width: 30,
                    height: 30,
                    backgroundColor: Appearance.colors.panelBackground(),
                    objectFit: 'contain',
                    opacity: 0.75
                }}/>
            </ControlButton>
        </Controls>
    )

    const getItems = () => {
        if(items.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'There are no landings or rules for this support session',
                    title: 'No Landings or Rules Found'
                })
            );
        }
        return items.map((abstract, index) => {
            return (
                Views.entry({
                    ...abstract.object.getDescription(),
                    ...abstract.type === 'support_workflow_rules' && abstract.object.getExtDescription(utils),
                    badge: null,
                    bottomBorder: index !== items.length - 1,
                    key: index,
                    onClick: onItemClick.bind(this, abstract),
                    rightContent: Views.icon.right('red-x', onRemoveItem.bind(this, index))
                })
            )
        });
    }

    const getVisibilityItems = () => {
        return [{
            id: 'aft.mobile.android',
            title: 'AFT Android'
        },{
            id: 'aft.mobile.ios',
            title: 'AFT iOS'
        },{
            id: 'aft.web',
            title: 'AFT Web App'
        },{
            id: 'global_data.mobile.android',
            title: 'Global Data Android'
        },{
            id: 'global_data.mobile.ios',
            title: 'Global Data iOS'
        },{
            id: 'global_data.web',
            title: 'Global Data Web'
        },{
            id: 'hsa.web',
            title: 'Home Safe Alerts Website'
        },{
            id: 'omnishield.mobile.android',
            title: 'OmniShield Android'
        },{
            id: 'omnishield.mobile.ios',
            title: 'OmniShield iOS'
        }];
    }

    const getWorkflowLandingsAndRulesContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 12
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getItemsEditor()}
                {getSearchField()}
                {getItems()}
            </div>
        )
    }

    const getItemsEditor = () => {
        if(elements.length === 0) {
            return null;
        }
        return (
            <div 
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                height: fullscreen ? window.innerHeight - 125 : 400,
                width: '100%'
            }}>
                <ReactFlowProvider>
                    <ReactFlow
                    connectionLineType={'straight'}
                    edgeTypes={{
                        support_workflow_edge: SupportWorkflowEdge
                    }}
                    elements={elements}
                    elementsSelectable={true}
                    maxZoom={1}
                    minZoom={0.1}
                    nodesConnectable={true}
                    nodeTypes={{
                        support_workflow_landings: SupportWorkflowLanding,
                        support_workflow_landings_buttons: SupportWorkflowLandingButton,
                        support_workflow_rules: SupportWorkflowRule,
                        support_workflow_rules_routes: SupportWorkflowRuleRoute,
                    }}
                    onConnect={onConnect}
                    onConnectEnd={onConnectEnd}
                    onConnectStart={onConnectStart}
                    onLoad={onLoad}
                    onNodeDragStart={onDragStart}
                    onNodeDrag={onDragMove}
                    onNodeDragStop={onDragStop}
                    onNodeMouseEnter={onNodeMouseEnter}
                    onNodeMouseLeave={onNodeMouseLeave}
                    preventScrolling={false}
                    zoomOnScroll={false}>
                        {getFlowControls()}
                    </ReactFlow>
                </ReactFlowProvider>
            </div>
        )
    }

    const getNodePosition = abstract=> {
        let pos = { x: 0, y: 0 };
        if(!layoutRef.current) {
            return pos;
        }
        return layoutRef.current[abstract.getTag()] ? layoutRef.current[abstract.getTag()].position : pos;
    }

    const getSearchField = () => {
        if(!session) {
            return null;
        }
        return (
            <div style={{
                alignItems: 'center',
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'row',
                padding: '12px 8px 12px 12px',
                width: '100%'
            }}>
                <WorkflowItemLookupField
                utils={utils}
                disableAssigned={true}
                onChange={onAddItem}
                preserveResult={false}
                session={abstract.object} />
                <img
                className={'text-button'}
                src={'images/new-button-clear-small.png'}
                onClick={onAddNewItemClick}
                style={{
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.primary(),
                    overflow: 'hidden',
                    marginLeft: 8
                }}/>
            </div>
        )
    }

    const createButtonActionFromConnection = target => {
        switch(target.type) {
            case 'support_workflow_landings':
            return {
                type: Support.Workflow.Action.types.get().redirect_to_workflow_landing,
                content: {
                    landing_id: target.data.abstract.getID()
                }
            }

            case 'support_workflow_rules':
            return {
                type: Support.Workflow.Action.types.get().redirect_to_workflow_rule,
                content: {
                    rule_id: target.data.abstract.getID()
                }
            }

            default:
            return null;
        }
    }

    const createConnection = ({ button, handle, route, source, target, type }) => {
        return {
            animated: true,
            data: { button, onRemoveEdge, route },
            id: `${source}-${target}`,
            source: source.toString(),
            sourceHandle: handle,
            style: { stroke: getEdgeColor(handle) },
            target: target.toString(),
            targetHandle: 'top',
            type: type
        }
    }

    const createItem = (abstract, count = 0) => {

        // calculate position and declare button height
        let position = getNodePosition(abstract, count);

        // prepare default node
        let targets = [{
            data: {
                abstract: abstract,
                onItemClick: onItemClick,
                utils: utils
            },
            id: abstract.getTag(),
            position: position,
            type: abstract.type
        }]

        // check if additional nodes need to be created to represent workflow landing buttons
        if(abstract.type === 'support_workflow_landings') {

            let { buttons } = abstract.object.content || {};
            if(buttons && buttons.length > 0) {
                targets = targets.concat(buttons.filter(button => {
                    return button.linkable !== false;
                }).map((button, index) => {
                    let offset = index + 1;
                    return {
                        data: {
                            abstract: abstract,
                            button: button,
                            utils: utils
                        },
                        draggable: false,
                        id: `${abstract.type}_buttons-${button.id}`,
                        position: {
                            x: position.x,
                            y: position.y + (nodeLandingButtonHeight * offset) + (8 * offset) + 15
                        },
                        selectable: false,
                        type: 'support_workflow_landings_buttons'
                    }
                }));
            }
        }

        // check if additional nodes need to be created to represent workflow rule data items
        if(abstract.type === 'support_workflow_rules') {

            let routes = abstract.object.routes || [];
            if(routes.length > 0) {
                targets = targets.concat(routes.map((route, index) => {
                    let offset = index + 1;
                    return {
                        data: {
                            abstract: abstract,
                            route: route,
                            utils: utils
                        },
                        draggable: false,
                        id: `${abstract.type}_routes-${route.id}`,
                        position: {
                            x: position.x,
                            y: position.y + (nodeRuleRouteHeight * offset) + (8 * offset)
                        },
                        selectable: false,
                        type: 'support_workflow_rules_routes'
                    }
                }));
            }
        }

        return targets;
    }

    const fetchItems = async () => {
        try {
            let { landings, layout, rules } = await Request.get(utils, '/support/', {
                type: 'workflow_items',
                id: abstract.getID()
            });

            setLoading(false);
            let targets = landings.map(landing => {
                return Abstract.create({
                    type: 'support_workflow_landings',
                    object: Support.Workflow.Landing.create(landing)
                })
            }).concat(rules.map(rule => {
                return Abstract.create({
                    type: 'support_workflow_rules',
                    object: Support.Workflow.Rule.create(rule)
                })
            }));

            // set flow layout and convert items to nodes and connections
            layoutRef.current = layout || {};
            onSetLayout(targets);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the landings and rules for this support session. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const isConnectionValid = (connection, abstract, elements) => {

        let edges = elements.filter(el => {
            return el.type === 'support_workflow_edge';
        }).reduce((array, el, _, edges) => {
            let valid = isConnectionValidRecursive(el, abstract.getTag(), edges);
            if(valid) {
                array.push(el.id);
            }
            return array;
        }, []);
        return edges.includes(connection.id);
    }

    const isConnectionValidRecursive = (el, id, elements) => {
        if(el.target) {
            if(el.target === id) {
                return false;
            }
            let next = elements.find(el => el.id === el.source);
            if(next) {
                return isConnectionValidRecursive(next, el.id, elements);
            }
        }
        return true;
    }

    const setupTarget = () => {

        // open edits for abstract target
        let edits = abstract.object.open();
        setWorkflow(edits);

        // fetch rules and landings for targets that have already been created
        if(isNewTarget === false) {
            setTimeout(fetchItems, 500);
        }

        // end loading if target is new 
        if(isNewTarget === true) {
            setLoading(false);
        }
    }

    useEffect(() => {
        onUpdateCollapseState();
    }, [collapsed]);

    useEffect(() => {
        onUpdateItems();
    }, [elements]);

    useEffect(() => {
        itemsRef.current = items;
    }, [items]);

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? 'New Support Workflow' : `Editing ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            onRequestClose: () => userInteraction.current === true, 
            onSizingChange: onSizingChange,
            sizing: 'large',
            supports_fullscreen: true
        }}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />

            <LayerItem
            title={'Workflow Landings and Rules'}
            ref={itemsEditorRef}
            style={{
                marginTop: LayerItemSpacing
            }}>
                {getWorkflowLandingsAndRulesContent()}
            </LayerItem>
        </Layer>
    )
}

const ButtonNode = props => {

    const { backgroundColor, borderRadius, color, fontSize, fontWeight, height, margin, padding, text, width } = props;

    const { actions: { setProp }, connectors: { connect, drag }, selected } = useNode(state => ({ selected: state.events.selected }));
    const { enabled } = useEditor(state => ({ enabled: state.options.enabled }));

    const [hover, setHover] = useState(null);

    const getBackgroundColor = () => {
        return backgroundColor || Appearance.colors.primary();
    }

    useEffect(() => {
        if(!backgroundColor) {
            setProp(props => props.backgroundColor = getBackgroundColor());
        }
    }, []);

    return (
        <button
        ref={ref => connect(drag(ref))}
        className={'btn btn-pill'}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            border: `2px solid ${getBackgroundColor()}`,
            background: Utils.hexToRGBA(getBackgroundColor(), hover && enabled ? 1 : 0.85),
            color: color,
            fontWeight: fontWeight,
            fontSize: fontSize,
            width: width,
            height: height,
            borderRadius: borderRadius,
            ...getSelectionStyles(selected, props),
            ...formatEditorMargin(margin),
            ...formatEditorPadding(padding)
        }}>
            <span>{text}</span>
        </button>
    )
}

const ButtonNodeSettings = () => {

    const { utils } = React.useContext(ApplicationContext) || {};
    const { backgroundColor, borderRadius, color, fontSize, fontWeight, height, ignoreFieldRequirements, linkable, margin, openInNewWindow, padding, text, width, actions: { setProp } } = useNode(node => ({ ...node.data.props }));

    const getFields = () => {
        return [{
            key: 'colors',
            title: 'Colors',
            items: [
                getEditorItem({ ...utils }, 'backgroundColor', backgroundColor, setProp),
                getEditorItem({ ...utils }, 'color', color, setProp)
            ]
        },{
            key: 'config',
            title: 'Configuration',
            visible: utils.user.get().level <= User.levels.get().admin ? true : false,
            items: [
                getEditorItem(utils, 'linkable', linkable, setProp),
                getEditorItem(utils, 'ignoreFieldRequirements', ignoreFieldRequirements, setProp),
                getEditorItem(utils, 'openInNewWindow', openInNewWindow, setProp)
            ]
        },{
            key: 'formatting',
            title: 'Formatting',
            items: [
                getEditorItem(utils, 'borderRadius', borderRadius, setProp),
                getEditorItem(utils, 'fontSize', fontSize, setProp),
                getEditorItem(utils, 'fontWeight', fontWeight, setProp),
                getEditorItem(utils, 'text', text, setProp)
            ]
        },{
            key: 'spacing',
            title: 'Spacing',
            items: [
                getEditorItem(utils, 'margin', margin, setProp),
                getEditorItem(utils, 'padding', padding, setProp)
            ]
        },{
            key: 'size',
            title: 'Size',
            items: [
                getEditorItem(utils, 'height', height, setProp),
                getEditorItem(utils, 'width', width, setProp)
            ]
        }];
    }

    return (
        <AltFieldMapper
        utils={utils}
        fields={getFields()} />
    )
}

ButtonNode.craft = {
    custom: { name: 'Button' },
    displayName: 'Button',
    props: {
        borderRadius: 15,
        backgroundColor: null,
        color: '#FFFFFF',
        fontSize: 13,
        fontWeight: 700,
        height: 30,
        ignoreFieldRequirements: false,
        linkable: true,
        openInNewWindow: false,
        text: 'Button',
        width: '100%'
    },
    related: {
        settings: ButtonNodeSettings
    }
}

export const Container = props => {

    const { children, margin, padding } = props;

    const { connectors: { connect, drag }, selected } = useNode(state => ({
        selected: state.events.selected
    }));

    return (
        <div
        ref={ref => connect(drag(ref))}
        style={{
            ...props,
            ...getSelectionStyles(selected, props),
            ...formatEditorMargin(margin),
            ...formatEditorPadding(padding)
        }}>
            {children}
        </div>
    )
}

const ContainerSettings = () => {

    const { utils } = React.useContext(ApplicationContext) || {};
    const { alignItems, borderColor, borderWidth, display, flexDirection, flexWrap, height, identifier, justifyContent, margin, maxHeight, maxWidth, minHeight, minWidth, padding, width, actions: { setProp } } = useNode(node => ({ ...node.data.props }));

    const getFields = () => {
        let placement = [ getEditorItem(utils, 'display', display, setProp) ];
        if(display === 'flex') {
            placement = placement.concat([
                getEditorItem(utils, 'flexDirection', flexDirection, setProp),
                getEditorItem(utils, 'flexWrap', flexWrap, setProp),
                getEditorItem(utils, 'alignItems', alignItems, setProp),
                getEditorItem(utils, 'justifyContent', justifyContent, setProp)
            ]);
        }
        return [{
            key: 'formatting',
            title: 'Formatting',
            items: [
                getEditorItem(utils, 'borderColor', borderColor, setProp),
                getEditorItem(utils, 'borderWidth', borderWidth, setProp),
                getEditorItem(utils, 'identifier', identifier, setProp)
            ]
        },{
            key: 'placement',
            title: 'Placement',
            items: placement
        },{
            key: 'spacing',
            title: 'Spacing',
            items: [
                getEditorItem(utils, 'margin', margin, setProp),
                getEditorItem(utils, 'padding', padding, setProp)
            ]
        },{
            key: 'size',
            title: 'Size',
            items: [
                getEditorItem(utils, 'minWidth', minWidth, setProp),
                getEditorItem(utils, 'maxWidth', maxWidth, setProp),
                getEditorItem(utils, 'minHeight', minHeight, setProp),
                getEditorItem(utils, 'maxHeight', maxHeight, setProp),
                getEditorItem(utils, 'width', width, setProp),
                getEditorItem(utils, 'height', height, setProp)
            ]
        }];
    }

    return (
        <AltFieldMapper
        utils={utils}
        fields={getFields()} />
    )
}

Container.craft = {
    props: {
        borderWidth: 1,
        borderColor: Appearance.colors.divider(),
        height: 250,
        minHeight: 30,
        maxHeight: null,
        maxWidth: null,
        minWidth: null,
        padding: {
            left: 12,
            right: 12,
            top: 12,
            bottom: 12
        },
        width: '100%'
    },
    related: {
        settings: ContainerSettings
    },
    rules: {
        canDrop: () => true
    }
}

export const DataRequestTester = ({ getFields, getRequest }, { index, options, utils }) => {

    const layerID = 'data_request_tester';

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onCloseLayer = () => {
        setLayerState('close');
    }

    const onSubmit = async () => {
        try {
            setLoading('submit');
            await Utils.sleep(1);
            let { response } = await Request.post(utils, '/support/', {
                type: 'test_rule_data_request',
                props: getRequest()
            });

            setLoading(false);
            utils.alert.show({
                title: `${response.scheme} Response (${response.http_code})`,
                message: `Request returned: ${response.value}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue testing your request. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        return [{
            key: 'submit',
            text: 'Submit Request',
            color: 'primary',
            onClick: onSubmit
        }]
    }

    useEffect(() => {
        utils.events.on('close_data_request_layer', onCloseLayer);
        return () => {
            utils.events.off('close_data_request_layer', onCloseLayer);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        utils={utils}
        title={'Data Request Tester'}
        buttons={getButtons()}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const EditorSettingsPanel = ({ style }) => {

    const [animations, setAnimations] = useSpring(() => ({
        opacity: 0,
        width: 0,
        config: { mass: 1, tension: 180, friction: 16 }
    }));

    const { utils } = React.useContext(ApplicationContext) || {};

    const { actions, query, selected } = useEditor(state => {
        let selected;
        let currentNodeId = state.events.selected ? state.events.selected.values().next().value : null;
        if(currentNodeId && state.nodes[currentNodeId]) {
            if(state.nodes[currentNodeId].data.type === 'div') {
                currentNodeId = state.nodes[currentNodeId].data.parent;
            }
            selected = {
                id: currentNodeId,
                name: state.nodes[currentNodeId].data.name,
                settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
                isDeletable: query.node(currentNodeId).isDeletable()
            };
        }
        return { selected };
    });

    const onDeleteItem = () => {

        // only delete item if item is not required
        //current the only required item is the login button from the account login template
        if(![ 'owp-VTswTl' ].includes(selected.id)) {
            actions.delete(selected.id);
            return;
        }

        utils.alert.show({
            title: 'Just a Second',
            message: 'This item is required for this landing to work correctly. You should create a new landing with your own content if you wish to remove this item.'
        });
    }

    const getContent = () => {
        if(!selected) {
            return null;
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <LayerItem title={'Details'}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        {getDetails().map((item, index, items) => (
                            Views.row({
                                key: index,
                                bottomBorder: index !== items.length - 1,
                                ...item
                            })
                        ))}
                    </div>
                </LayerItem>
                {getSettings()}
                {selected.isDeletable && (
                    <Button
                    color={'danger'}
                    label={'Delete'}
                    onClick={onDeleteItem}
                    type={'large'}
                    utils={utils}
                    style={{
                        boxShadow: null
                    }}/>
                )}
            </div>
        )
    }

    const getDetails = () => {
        return [{
            label: 'ID',
            value: selected.id
        },{
            label: 'Element Type',
            value: selected.name
        }];
    }

    const getSettings = () => {
        if(!selected.settings) {
            return null;
        }
        return React.createElement(selected.settings)
    }

    useEffect(() => {
        setAnimations({
            opacity: selected ? 1 : 0,
            width: selected ? 425 : 0
        });
    }, [selected]);

    return (
        <animated.div style={{
            borderLeft: `1px solid ${Appearance.colors.divider()}`,
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 12,
            height: '100%',
            minWidth: 325,
            maxHeight: '100%',
            overflowY: 'scroll',
            ...style,
            ...animations
        }}>
            <div style={{
                maxHeight: window.innerHeight / 2.5,
                overflowY: 'scroll'
            }}>
                <LayerItem title={'Layers'}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        <EditorLayers renderLayer={EditorLayerItem}/>
                    </div>
                </LayerItem>
            </div>
            {getContent()}
        </animated.div>
    )
}

const EditorLayerItem = () => {

    let { id, connectors: { drag } } = useLayer(layer => ({
        expanded: layer.expanded,
        hovered: layer.event.hovered
    }));

    let { actions: { selectNode, setCustom, setHidden }, hasChildCanvases, query, selectedNodeID } = useEditor((state, query) =>  ({
        hasChildCanvases: query.node(id).isParentOfTopLevelNodes(),
        selectedNodeID: state.events.selected ? state.events.selected.values().next().value : null
    }));
    const [searchText, setSearchText] = useState(null);

    const onChangeSearchText = text => {
        setSearchText(text);
    }

    const onHideElement = id => {
        let { hidden } = query.node(id).get().data;
        setHidden(id, !hidden);
    }

    const onRenderNode = (nodeID, props, depth) => {

        let name = props.custom.name || props.name;
        if(searchText && !name.toLowerCase().includes(searchText.toLowerCase())) {
            return null;
        }
        return (
            <div
            ref={drag}
            key={nodeID}
            className={`view-entry ${window.theme}`}
            onClick={selectNode.bind(this, nodeID)}
            style={{
                alignItems: 'center',
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: 8,
                paddingLeft: searchText ? 8 : ((depth + 1) * 12),
                paddingRight: 8,
                paddingTop: 8,
                width: '100%'
            }}>
                <img
                className={'text-button'}
                src={props.hidden ? 'images/non-visible-eye-grey.png' : 'images/visible-eye-grey.png'}
                onClick={onHideElement.bind(this, nodeID)}
                style={{
                    width: 20,
                    height: 20,
                    objectFit: 'contain',
                    marginRight: 8
                }} />
                <ContentEditable
                html={name}
                onChange={onTextChange.bind(this, nodeID)}
                tagName={'p'}
                style={{
                    ...Appearance.textStyles.key(),
                    cursor: 'text',
                    margin: 0,
                    ...selectedNodeID === nodeID && {
                        color: Appearance.colors.primary()
                    }
                }}/>
            </div>
        )
    }

    const onTextChange = (id, evt) => {
        let value = evt.target.value.replace(/<\/?[^>]+(>|$)/g, "");
        setCustom(id, props => props.name = value);
    }

    const getChildren = (id, depth = 0) => {
        let nodes = query.node(id).childNodes();
        if(nodes.length === 0 && depth === 0) {
            return (
                <div style={{
                    paddingBottom: 8,
                    paddingLeft: 12,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.key(),
                        cursor: 'text',
                        margin: 0
                    }}>{'No layers found'}</span>
                </div>
            )
        }
        return nodes.map((childID, index, childrenIDs) => {
            let node = query.node(childID).get();
            let children = query.node(childID).childNodes();
            return (
                <div key={index}>
                    {onRenderNode(childID, node.data, depth)}
                    {children.length > 0 && getChildren(childID, depth + 1)}
                </div>
            )
        });
    }

    return (
        <>
        <div style={{
            borderBottom: `1px solid ${Appearance.colors.divider()}`,
            padding: 8
        }}>
            <TextField
            placeholder={'Search by layer name...'}
            value={searchText}
            onChange={onChangeSearchText} />
        </div>
        {getChildren(id)}
        </>
    );
}

const EditorToolbox = ({ channel, onChangeTheme, generateContent, onLayoutChange, onSaveContent, onSaveContentTemplate, onHeightChange, template, utils }) => {

    const ref = useRef(null);
    const editor = useEditor(state => ({ nodes: state.nodes }));

    const components = Support.Workflow.Landing.components.get();
    const [values, setValues] = useState([]);

    const onToolClick = tool => {
        switch(tool.key) {
            case 'desktop':
            case 'mobile':
            onLayoutChange(tool.key);
            break;

            case 'undo':
            editor.actions.history.undo();
            break;

            case 'redo':
            editor.actions.history.redo();
            break;

            case 'save_template':
            onSaveContentTemplate();
            break;

            case 'save':
            onSaveContent();
            break;

            case 'theme':
            onChangeTheme(window.theme === 'dark' ? false : true);
            break;
        }
    }

    const getComponentNode = component => {
        switch(component.code) {
            case components.button:
            return (
                <ButtonNode />
            )

            case components.container:
            return (
                <Container />
            )

            case components.image:
            return (
                <Image />
            )

            case components.text:
            return (
                <Text />
            )

            case components.video:
            return (
                <Video />
            )

            default:
            return (
                <Field component={component} />
            )
        }
    }

    const getTools = () => {
        return [{
            key: 'mobile',
            text: 'Mobile Layout',
            image: `images/landing-editor-tool-icon-mobile.png`
        },{
            key: 'desktop',
            text: 'Desktop Layout',
            image: `images/landing-editor-tool-icon-desktop.png`
        },{
            key: 'theme',
            text: window.theme === 'dark' ? 'Light Mode' : 'Dark Mode',
            image: window.theme === 'dark' ? `images/landing-editor-tool-icon-5-light.png` : `images/landing-editor-tool-icon-5-dark.png`
        },{
            key: 'undo',
            text: 'Undo',
            image: `images/landing-editor-tool-icon-1.png`
        },{
            key: 'redo',
            text: 'Redo',
            image: `images/landing-editor-tool-icon-2.png`
        },{
            key: 'save_template',
            text: 'Save as Template',
            image: `images/landing-editor-tool-icon-3.png`,
            visible: channel !== 'kb_article'
        },{
            key: 'save',
            text: 'Save',
            image: `images/landing-editor-tool-icon-4.png`
        }];
    }

    const createChildElementNode = (containerID, options) => {
        switch(options.component) {
            case 'text':
            return {
                displayName: 'Text',
                isCanvas: false,
                parent: containerID,
                props: {
                    color: '#9B9B9B',
                    textAlign: 'left',
                    value: options.value,
                    whiteSpace: 'normal',
                    width: '100%',
                    ...options.style
                },
                type: { resolvedName: 'Text' }
            }

            default:
            throw new Error('unsupported component type');
        }
    }

    const setupToolbox = () => {

        // determine if generated content needs to be processed
        if(generateContent) {

            // loop through sections and generate content
            generateContent.forEach(section => {
    
                // generate node id for container
                let containerElementID = getRandomId(10);

                // prepare container node for node tree
                let nodes = {
                    [containerElementID]: {
                        displayName: 'Container',
                        isCanvas: true,
                        nodes: [],
                        parent: ROOT_NODE,
                        props: {
                            borderColor: Appearance.colors.transparent,
                            display: 'flex',
                            flexDirection: 'column',
                            padding: {
                                bottom: 0,
                                left: 0,
                                right: 0,
                                top: 0
                            },
                            width: '100%',
                            ...section.style
                        },
                        type: { resolvedName: 'Container' }
                    }
                }

                // add text element for section title if applicable
                if(section.title) {
                    let nextNodeID = getRandomId(10);
                    let props = createChildElementNode(containerElementID, {
                        component: 'text',
                        style: {
                            ...Appearance.textStyles.layerItemTitle(),
                            magin: { bottom: 4 },
                            textAlign: 'left'
                        },
                        value: section.title
                    });
                    nodes[nextNodeID] = editor.query.parseSerializedNode(props).toNode(node => (node.id = nextNodeID));

                    // add new node id to container list of nodes
                    nodes[containerElementID].nodes.push(nextNodeID);
                }
                
                // loop through section items and create elements
                section.items.forEach(item => {

                    // create node from value prop
                    let nextNodeID = getRandomId(10);
                    let props = createChildElementNode(containerElementID, item);
                    nodes[nextNodeID] = editor.query.parseSerializedNode(props).toNode(node => (node.id = nextNodeID));

                    // add new node id to container list of nodes
                    nodes[containerElementID].nodes.push(nextNodeID);
                })

                // parse serialized node for container node
                nodes[containerElementID] = editor.query.parseSerializedNode(nodes[containerElementID]).toNode(node => (node.id = containerElementID));

                // add node tree to parent and return modified entry with node_id
                setTimeout(() => {
                    editor.actions.addNodeTree({
                        rootNodeId: containerElementID,
                        nodes: nodes
                    }, ROOT_NODE);
                }, 500);
            });
        }

        // prepare list of available components
        let values = Object.values(components).filter(component => {
            switch(channel) {
                case 'kb_article':
                return [
                    Support.Workflow.Landing.components.get().button,
                    Support.Workflow.Landing.components.get().container,
                    Support.Workflow.Landing.components.get().image,
                    Support.Workflow.Landing.components.get().text
                ].includes(component);

                default:
                return true;
            }

        }).map(component => ({
            code: component,
            text: Support.Workflow.Landing.components.toText(component),
            image: `images/landing-editor-icon-${component}.png`
        })).sort((a,b) => {
            return a.text.localeCompare(b.text);
        });
        setValues(values);
    }

    useEffect(() => {
        if(ref.current) {
            onHeightChange(ref.current.clientHeight);
        }
    }, [ref.current]);

    useEffect(() => {
        setupToolbox();
    }, []);

    return (
        <div
        ref={ref}
        style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            borderBottom: `1px solid ${Appearance.colors.divider()}`
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 1,
                width: '100%',
                padding: 12
            }}>
                {values.map(component => {
                    return (
                        <div
                        key={component.code}
                        ref={ref => editor.connectors.create(ref, getComponentNode(component))}
                        className={'text-button'}
                        title={component.text}
                        style={{
                            width: 40,
                            height: 40,
                            padding: 4
                        }}>
                            <img
                            src={component.image}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                backgroundColor: Appearance.colors.divider(),
                                borderRadius: 12,
                                overflow: 'hidden'
                            }} />
                        </div>
                    )
                })}
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
                padding: 12,
                borderLeft: `1px solid ${Appearance.colors.divider()}`
            }}>
                {getTools().filter(tool => {
                    return tool.visible !== false;
                }).map((tool, index) => {
                    return (
                        <div
                        key={index}
                        className={'text-button'}
                        onClick={onToolClick.bind(this, tool)}
                        title={tool.text}
                        style={{
                            width: 40,
                            height: 40,
                            padding: 4
                        }}>
                            <img
                            src={tool.image}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                backgroundColor: Appearance.colors.divider(),
                                borderRadius: 12,
                                overflow: 'hidden'
                            }} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const Field = props => {

    const { component, height, icon, maxHeight, maxWidth, margin, minHeight, minWidth, title, width } = props;

    const { connectors: { connect, drag }, selected } = useNode(state => ({ selected: state.events.selected }));
    const { enabled } = useEditor(state => ({ enabled: state.options.enabled }));

    return (
        <div
        ref={ref => connect(drag(ref))}
        className={`dummy-field ${window.theme} ${enabled ? 'text-button' : ''}`}
        style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: title ? 'flex-start' : 'center',
            flexGrow: 1,
            height: height,
            width: width,
            maxHeight: maxHeight,
            minHeight: minHeight,
            maxWidth: maxWidth,
            minWidth: minWidth,
            backgroundColor: Appearance.colors.textField(),
            borderColor: Appearance.colors.soft(),
            ...getSelectionStyles(selected, props),
            ...formatEditorMargin(margin)
        }}>
            {typeof(icon) === 'string'
                ?
                <i
                className={icon}
                style={{
                    color: Appearance.colors.grey(),
                    fontSize: 14,
                    width: 15,
                    marginRight: 8
                }} />
                :
                <img
                src={component.image}
                style={{
                    width: 30,
                    height: 30,
                    marginRight: 8
                }} />
            }
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
            }}>
                <label style={{
                    ...Appearance.textStyles.subTitle(),
                    fontSize: 9,
                    lineHeight: 1,
                    marginBottom: 2
                }}>{title}</label>
                <span style={{
                    borderWidth: 0,
                    fontSize: 12,
                    fontWeight: 500,
                    color: title ? Appearance.colors.text() : Appearance.colors.subText(),
                    backgroundColor: Appearance.colors.transparent,
                    lineHeight: 1,
                    marginBottom: 0
                }}>{`${component.text} Editor Placeholder`}</span>
            </div>
        </div>
    )
}

const FieldSettings = () => {

    const components = Support.Workflow.Landing.components.get();
    const { utils } = React.useContext(ApplicationContext) || {};
    const { component, height, icon, identifier, list, margin, maxHeight, maxWidth, minHeight, minWidth, placeholder, required, secure, textfield_format, title, width, actions: { setProp } } = useNode(node => ({ ...node.data.props }));

    const getFields = () => {

        let formatting = [
            getEditorItem(utils, 'identifier', identifier, setProp),
            getEditorItem(utils, 'required', required, setProp),
            getEditorItem(utils, 'secure', secure, setProp)
        ];

        switch(component.code) {
            case components.list:
            case components.multiple_list:
            formatting.push(getEditorItem(utils, 'list', list, setProp));
            break;

            case components.textfield:
            formatting.push(getEditorItem(utils, 'textfield_format', textfield_format, setProp));
            break;

            case components.textview:
            formatting.push(getEditorItem(utils, 'placeholder', placeholder, setProp));
            break;
        }

        return [{
            key: 'field',
            title: 'Content',
            items: [
                getEditorItem(utils, 'icon', icon, setProp),
                getEditorItem(utils, 'title', title, setProp)
            ]
        },{
            key: 'formatting',
            title: 'Formatting',
            items: formatting
        },{
            key: 'spacing',
            title: 'Spacing',
            items: [
                getEditorItem(utils, 'margin', margin, setProp)
            ]
        },{
            key: 'size',
            title: 'Size',
            items: [
                getEditorItem(utils, 'height', height, setProp),
                getEditorItem(utils, 'maxHeight', maxHeight, setProp),
                getEditorItem(utils, 'minHeight', minHeight, setProp),
                getEditorItem(utils, 'maxWidth', maxWidth, setProp),
                getEditorItem(utils, 'minWidth', minWidth, setProp),
                getEditorItem(utils, 'width', width, setProp)
            ]
        }];
    }

    return (
        <AltFieldMapper
        utils={utils}
        fields={getFields()} />
    )
}

Field.craft = {
    props: {
        height: 40,
        icon: null,
        secure: false,
        title: null,
        width: '100%'
    },
    related: {
        settings: FieldSettings
    }
}

export const formatEditorMargin = margin => {
    if(!margin) {
        return null;
    }
    return {
        marginBottom: margin.bottom,
        marginLeft: margin.left,
        marginRight: margin.right,
        marginTop: margin.top
    }
}

export const formatEditorPadding = padding => {
    if(!padding) {
        return null;
    }
    return {
        paddingBottom: padding.bottom,
        paddingLeft: padding.left,
        paddingRight: padding.right,
        paddingTop: padding.top
    }
}

export const getEditorItem = (utils, key, value, setProp, props) => {
    switch(key) {
        case 'action':
        return {
            key: key,
            required: false,
            title: 'Action',
            component: 'workflow_action_builder',
            value: value,
            onChange: action => setProp(props => props[key] = action),
            props: utils.__props
        }

        case 'alignItems':
        return {
            key: key,
            required: false,
            title: 'Align Items',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: value.capitalize()
            },
            items: [{
                id: 'center',
                title: 'Center'
            },{
                id: 'flex-end',
                title: 'Flex-end'
            },{
                id: 'flex-start',
                title: 'Flex-start'
            },{
                id: 'stretch',
                title: 'Stretch'
            }]
        }

        case 'alignSelfDesktop':
        case 'alignSelfMobile':
        return {
            key: key,
            required: false,
            title: 'Align',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: value.capitalize()
            },
            items: [{
                id: 'center',
                title: 'Center'
            },{
                id: 'flex-end',
                title: 'End'
            },{
                id: 'flex-start',
                title: 'Start'
            }]
        }

        case 'autoPlay':
        case 'controls':
        case 'loop':
        case 'muted':
        return {
            key: key,
            animate: false,
            required: false,
            title: key.capitalize(),
            component: 'bool_list',
            value: value,
            onChange: val => setProp(props => props[key] = val)
        }

        case 'backgroundColor':
        return {
            key: key,
            required: false,
            title: 'Background Color',
            component: 'color_picker',
            value: value,
            onChange: val => setProp(props => props[key] = val),
            props: utils.__props
        }

        case 'borderRadius':
        return {
            key: key,
            required: false,
            title: 'Border Radius',
            component: 'textfield',
            value: value,
            onChange: val => setProp(props => props[key] = parseInt(val)),
            props: {
                format: 'number',
                useDelay: true
            }
        }

        case 'borderColor':
        return {
            key: key,
            required: false,
            title: 'Border Color',
            component: 'color_picker',
            value: value,
            onChange: val => setProp(props => props[key] = val),
            props: utils.__props
        }

        case 'borderWidth':
        return {
            key: key,
            required: false,
            title: 'Border Width',
            component: 'textfield',
            value: value,
            onChange: val => setProp(props => props[key] = isNaN(val) ? val : parseFloat(val))
        }

        case 'bottom':
        case 'columns':
        case 'left':
        case 'right':
        case 'rows':
        case 'top':
        return {
            key: key,
            required: false,
            title: key.capitalize(),
            component: 'textfield',
            value: value,
            props: { format: 'number' },
            onChange: val => setProp(props => props[key] = val >= 1 ? parseFloat(val) : null),
        }

        case 'color':
        return {
            key: key,
            required: false,
            title: 'Color',
            component: 'color_picker',
            value: value,
            onChange: val => setProp(props => props[key] = val),
            props: utils.__props
        }

        case 'darkModeColor':
        return {
            key: key,
            required: false,
            title: 'Dark Mode Color',
            component: 'color_picker',
            value: value,
            onChange: val => setProp(props => props[key] = val),
            props: utils.__props
        }

        case 'display':
        return {
            key: key,
            required: false,
            title: 'Display',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: value.capitalize()
            },
            items: [{
                id: 'flex',
                title: 'Flex'
            },{
                id: 'none',
                title: 'None'
            }]
        }

        case 'flexDirection':
        return {
            key: key,
            required: false,
            title: 'Flex Direction',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: value.capitalize()
            },
            items: [{
                id: 'column',
                title: 'Column'
            },{
                id: 'row',
                title: 'Row'
            }]
        }

        case 'flexWrap':
        return {
            key: key,
            required: false,
            title: 'Flex Wrap',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: value.capitalize()
            },
            items: [{
                id: 'nowrap',
                title: 'Nowrap'
            },{
                id: 'wrap',
                title: 'Wrap'
            },{
                id: 'wrap-reverse',
                title: 'Wrap-reverse'
            }]
        }

        case 'fontSize':
        return {
            key: key,
            required: false,
            title: 'Font Size',
            component: 'textfield',
            value: value,
            onChange: val => setProp(props => props[key] = parseInt(val)),
            props: {
                format: 'number'
            }
        }

        case 'fontWeight':
        return {
            key: key,
            required: false,
            title: 'Font Weight',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: `${value}`
            },
            items: [...new Array(9)].map((_, index) => ({
                id: (index + 1) * 100,
                title: `${(index + 1) * 100}`
            }))
        }

        case 'height':
        return {
            key: key,
            required: false,
            title: 'Height',
            component: 'textfield',
            value: value,
            onChange: val => setProp(props => props[key] = isNaN(val) ? val : parseFloat(val))
        }

        case 'icon':
        return {
            key: key,
            required: false,
            title: 'Icon',
            component: 'icon_picker',
            onChange: icon => setProp(props => props[key] = icon),
            value: value
        }

        case 'ignoreFieldRequirements':
        return {
            key: key,
            required: false,
            title: 'Ignore Field Requirements',
            component: 'bool_list',
            value: value,
            onChange: val => setProp(props => props[key] = val)
        }

        case 'imageAsset':
        return {
            key: key,
            required: false,
            title: 'Source',
            component: 'asset_picker',
            value: value,
            onChange: asset => setProp(props => props[key] = asset && asset.id),
            props: { 
                types: [fileTypes.jpg, fileTypes.png] 
            }
        }

        case 'justifyContent':
        return {
            key: key,
            required: false,
            title: 'Justify Content',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: value.capitalize()
            },
            items: [{
                id: 'center',
                title: 'Center'
            },{
                id: 'flex-end',
                title: 'Flex-end'
            },{
                id: 'flex-start',
                title: 'Flex-start'
            },{
                id: 'space-around',
                title: 'Space-around'
            },{
                id: 'space-between',
                title: 'Space-between'
            },{
                id: 'space-evenly',
                title: 'Space-evenly'
            }]
        }

        case 'list':
        return {
            key: key,
            required: false,
            title: 'Items',
            component: 'multiple_textfield',
            value: value,
            onChange: items => setProp(props => props[key] = items),
            props: { useIdentifiers: true }
        }

        case 'linkable':
        return {
            key: key,
            required: false,
            title: 'Linkable in Group Editor',
            component: 'bool_list',
            value: value,
            onChange: val => setProp(props => props[key] = val)
        }

        case 'margin':
        return {
            key: key,
            required: false,
            title: 'Margin',
            component: 'object_editor',
            onChange: val => setProp(props => props[key] = val),
            props: {
                format: 'number',
                reduced: true
            },
            value: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                ...value
            }
        }

        case 'maxHeight':
        return {
            key: key,
            required: false,
            title: 'Max Height',
            component: 'textfield',
            value: value,
            onChange: val => setProp(props => props[key] = isNaN(val) ? val : parseFloat(val))
        }

        case 'maxWidth':
        return {
            key: key,
            required: false,
            title: 'Max Width',
            component: 'textfield',
            value: value,
            onChange: val => setProp(props => props[key] = isNaN(val) ? val : parseFloat(val))
        }

        case 'minHeight':
        return {
            key: key,
            required: false,
            title: 'Min Height',
            component: 'textfield',
            value: value,
            onChange: val => setProp(props => props[key] = isNaN(val) ? val : parseFloat(val))
        }

        case 'minWidth':
        return {
            key: key,
            required: false,
            title: 'Min Width',
            component: 'textfield',
            value: value,
            onChange: val => setProp(props => props[key] = isNaN(val) ? val : parseFloat(val))
        }

        case 'objectFit':
        return {
            key: key,
            required: false,
            title: 'Object Fit',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: value.capitalize()
            },
            items: [{
                id: 'contain',
                title: 'Contain'
            },{
                id: 'cover',
                title: 'Cover'
            },{
                id: 'fill',
                title: 'Fill'
            },{
                id: 'none',
                title: 'None'
            }]
        }

        case 'openInNewWindow':
        return {
            key: key,
            required: false,
            title: 'Open in New Window',
            component: 'bool_list',
            value: value,
            onChange: val => setProp(props => props[key] = val)
        }

        case 'overflow':
        return {
            key: key,
            required: false,
            title: 'Overflow',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: value.capitalize()
            },
            items: [{
                id: 'hidden',
                title: 'Hidden'
            },{
                id: 'scroll',
                title: 'Scroll'
            },{
                id: 'visible',
                title: 'Visible'
            }]
        }

        case 'padding':
        return {
            key: key,
            required: false,
            title: 'Padding',
            component: 'object_editor',
            onChange: val => setProp(props => props[key] = val),
            props: {
                format: 'number',
                reduced: true
            },
            value: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                ...value
            }
        }

        case 'position':
        return {
            key: key,
            required: false,
            title: 'Position',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: value.capitalize()
            },
            items: [{
                id: 'absolute',
                title: 'Absolute'
            },{
                id: 'fixed',
                title: 'Fixed'
            },{
                id: 'relative',
                title: 'Relative'
            }]
        }

        case 'required':
        return {
            key: key,
            required: false,
            title: 'Required',
            component: 'bool_list',
            value: value,
            onChange: val => setProp(props => props[key] = val)
        }

        case 'secure':
        return {
            key: key,
            required: false,
            title: 'Secure',
            component: 'bool_list',
            value: value,
            onChange: val => setProp(props => props[key] = val)
        }

        case 'src':
        return {
            key: key,
            required: false,
            title: 'Source',
            component: 'textfield',
            value: value,
            onChange: val => setProp(props => props[key] = val)
        }

        case 'textAlign':
        return {
            key: key,
            required: false,
            title: 'Text Align',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: value.capitalize()
            },
            items: [{
                id: 'justify',
                title: 'Justify'
            },{
                id: 'left',
                title: 'Left'
            },{
                id: 'center',
                title: 'Center'
            },{
                id: 'right',
                title: 'Right'
            }]
        }

        case 'textfield_format':
        return {
            key: key,
            required: false,
            title: 'Text Format',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: value === 'number' ? 'Number' : 'Phone Number'
            },
            items: [{
                id: 'number',
                title: 'Number'
            },{
                id: 'phone_number',
                title: 'Phone Number'
            }]
        }

        case 'textOverflow':
        return {
            key: key,
            required: false,
            title: 'Text Overflow',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: value.capitalize()
            },
            items: [{
                id: 'clip',
                title: 'Clip'
            },{
                id: 'ellipsis',
                title: 'Ellipsis'
            },{
                id: 'initial',
                title: 'Initial'
            },{
                id: 'revert',
                title: 'Revert'
            },{
                id: 'unset',
                title: 'Unset'
            }]
        }

        case 'videoAsset':
        return {
            key: key,
            required: false,
            title: 'Source',
            component: 'asset_picker',
            value: value,
            onChange: asset => setProp(props => props[key] = asset && asset.id),props: { 
                types: [fileTypes.m4v, fileTypes.mov, fileTypes.mp4] 
            }
        }

        case 'whiteSpace':
        return {
            key: key,
            required: false,
            title: 'White Space',
            component: 'list',
            onChange: item => setProp(props => props[key] = item && item.id),
            value: value && {
                id: value,
                title: value.capitalize()
            },
            items: [{
                id: 'break-spaces',
                title: 'Break Spaces'
            },{
                id: 'initial',
                title: 'Initial'
            },{
                id: 'normal',
                title: 'Normal'
            },{
                id: 'nowrap',
                title: 'No Wrap'
            },{
                id: 'pre',
                title: 'Pre'
            },{
                id: 'pre-line',
                title: 'Pre-Line'
            },{
                id: 'pre-wrap',
                title: 'Pre-Wrap'
            },{
                id: 'revert',
                title: 'Revert'
            },{
                id: 'unset',
                title: 'Unset'
            }]
        }

        case 'width':
        return {
            key: key,
            required: false,
            title: 'Width',
            component: 'textfield',
            value: value,
            onChange: val => setProp(props => props[key] = isNaN(val) ? val : parseFloat(val))
        }

        default:
        return {
            key: key,
            required: false,
            title: key.capitalize(),
            component: 'textfield',
            value: value,
            onChange: val => setProp(props => props[key] = val)
        }
    }
}

export const Image = props => {

    const { layoutType, utils } = React.useContext(ApplicationContext) || {};
    const { alignSelfDesktop, alignSelfMobile, borderColor, borderWidth, imageAsset, margin, padding } = props;

    const { connectors: { connect, drag }, selected } = useNode(state => ({ selected: state.events.selected }));
    const { enabled } = useEditor(state => ({ enabled: state.options.enabled }));

    const getBorderProps = () => {
        return {
            ...borderColor && borderWidth && {
                borderColor: borderColor,
                borderStyle: 'solid',
                borderWidth: borderWidth
            }
        }
    }

    const getContent = () => {
        if(imageAsset) {
            let { url } = utils.support.assets.get().find(a => a.id === imageAsset) || {};
            return (
                <img
                className={enabled ? 'text-button' : ''}
                ref={ref => connect(drag(ref))}
                src={url}
                style={{
                    ...props,
                    alignSelf: layoutType === 'mobile' ? alignSelfMobile : alignSelfDesktop,
                    ...getBorderProps(),
                    ...getSelectionStyles(selected, props),
                    ...formatEditorPadding(padding),
                    ...formatEditorMargin(margin)
                }} />
            )
        }
        return (
            <div
            className={enabled ? 'text-button' : ''}
            ref={ref => connect(drag(ref))}
            style={{
                ...props,
                alignItems: 'center',
                alignSelf: layoutType === 'mobile' ? alignSelfMobile : alignSelfDesktop,
                backgroundColor: Appearance.colors.divider(),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                ...getBorderProps(),
                ...getSelectionStyles(selected, props),
                ...formatEditorPadding(padding),
                ...formatEditorMargin(margin)
            }}>
                <img
                src={`images/landing-editor-icon-${Support.Workflow.Landing.components.get().image}.png`}
                style={{
                    height: 50,
                    objectFit: 'contain',
                    width: 50
                }}/>
            </div>
        )
    }

    return getContent()
}

const ImageSettings = () => {

    const { layoutType, utils } = React.useContext(ApplicationContext) || {};
    const { alignSelfDesktop, alignSelfMobile, backgroundColor, borderColor, borderRadius, borderWidth, bottom, height, left, margin, maxHeight, maxWidth, objectFit, overflow, padding, position, right, src, top, width, actions: { setProp } } = useNode(node => ({ ...node.data.props }));

    const getFields = () => {
        return [{
            key: 'formatting',
            title: 'Formatting',
            items: [
                getEditorItem({ ...utils }, 'backgroundColor', backgroundColor, setProp),
                getEditorItem({ ...utils }, 'borderColor', borderColor, setProp),
                getEditorItem(utils, 'borderRadius', borderRadius, setProp),
                getEditorItem(utils, 'borderWidth', borderWidth, setProp),
                getEditorItem(utils, 'objectFit', objectFit, setProp),
                getEditorItem(utils, 'overflow', overflow, setProp),
                getEditorItem(utils, 'position', position, setProp),
                getEditorItem(utils, 'imageAsset', src, setProp)
            ]
        },{
            key: 'placement',
            title: 'Placement',
            items: [
                layoutType === 'mobile' ? getEditorItem(utils, 'alignSelfMobile', alignSelfMobile, setProp) : getEditorItem(utils, 'alignSelfDesktop', alignSelfDesktop, setProp),
                getEditorItem(utils, 'bottom', bottom, setProp),
                getEditorItem(utils, 'left', left, setProp),
                getEditorItem(utils, 'right', right, setProp),
                getEditorItem(utils, 'top', top, setProp)
            ]
        },{
            key: 'spacing',
            title: 'Spacing',
            items: [
                getEditorItem(utils, 'margin', margin, setProp),
                getEditorItem(utils, 'padding', padding, setProp)
            ]
        },{
            key: 'size',
            title: 'Size',
            items: [
                getEditorItem(utils, 'maxHeight', maxHeight, setProp),
                getEditorItem(utils, 'maxWidth', maxWidth, setProp),
                getEditorItem(utils, 'height', height, setProp),
                getEditorItem(utils, 'width', width, setProp)
            ]
        }];
    }

    return (
        <AltFieldMapper
        utils={utils}
        fields={getFields()} />
    )
}

Image.craft = {
    props: {
        borderRadius: 12,
        height: 150,
        objectFit: 'contain',
        overflow: 'hidden',
        position: 'relative',
        src: null,
        width: 150
    },
    related: {
        settings: ImageSettings
    }
}

export const KBArticles = ({ index, options, utils }) => {

    const panelID = 'kb_articles';

    const limit = 10;
    const offset = useRef(null);

    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useLoading();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onArticleClick = article => {
        utils.layer.open({
            id: `kb_article_details_${article.id}`,
            abstract: Abstract.create({
                type: 'kb_articles',
                object: article
            }),
            Component: KBArticleDetails
        });
    }

    const onNewArticleClick = () => {
        utils.layer.open({
            id: 'new_kb_article',
            abstract: Abstract.create({
                type: 'kb_articles',
                object: Support.Article.new()
            }),
            Component: AddEditKBArticle.bind(this, {
                isNewTarget: true
            })
        });
    }

    const getButtons = () => {
        return [{
            key: 'new',
            title: 'New Knowledge Base Article',
            style: 'default',
            onClick: onNewArticleClick
        }]
    }

    const getContent = () => {
        if(articles.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'There were no knowledge base articles found in the system',
                    title: 'No Knowledge Base Articles Found',
                })
            )
        }
        return articles.map((article, index) => {
            return (
                Views.entry({
                    bottomBorder: index !== articles.length - 1,
                    key: index,
                    onClick: onArticleClick.bind(this, article),
                    ...article.getDescription()
                })
            )
        })
    }

    const fetchArticles = async () => {
        try {
            setLoading(true);
            let { articles, paging } = await Request.get(utils, '/support/', {
                limit: limit,
                search_text: searchText,
                type: 'all_kb_articles'
            });

            setLoading(false);
            setPaging(paging);
            setArticles(articles.map(article => Support.Article.create(article)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the knowledge base articles list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchArticles();
    }, [searchText]);

    useEffect(() => {
        utils.content.subscribe(panelID, ['kb_articles'], {
            onFetch: fetchArticles,
            onUpdate: abstract => {
                setArticles(articles => {
                    return articles.map(article => {
                        return abstract.compare(article);
                    });
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        id={panelID}
        index={index}
        name={'Knowledge Base Articles'}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading === true,
            paging: paging && {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchArticles();
                }
            },
            removePadding: true,
            search: {
                placeholder: 'Search by name or id...',
                onChange: setSearchText
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const KBArticleDetails = ({ abstract, index, options, utils }) => {

    const layerID = `kb_article_details_${abstract.getID()}`;
    const limit = 5;
    const offset = useRef(0);

    const [article, setArticle] = useState(abstract.object);
    const [content, setContent] = useState(null);
    const [feedback, setFeedback] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_kb_article_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditKBArticle.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onEmailClick = response => {
        window.open(`mailto:${response.email_address}?Subject=${encodeURIComponent(abstract.object.name)}%20Article%20Feedback`);
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'active',
                title: `${article.active ? 'Deactivate' : 'Activate'} Article`,
                style: article.active ? 'destructive' : 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'active') {
                onSetActiveStatus();
                return;
            }
        });
    }

    const onSetActiveStatus = () => {
        utils.alert.show({
            title: `${article.active ? 'Deactivate' : 'Activate'} Article`,
            message: `Are you sure that you want to ${article.active ? 'deactivate' : 'activate'} this knowledge base article? ${article.active ? 'This will prevent customers from accessing this information.' : 'This will allow customers to access this article.'}`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: article.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: `Do Not ${article.active ? 'Deactivate' : 'Activate'}`,
                style: article.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetActiveStatusConfirm();
                    return;
                }
            }
        });
    }

    const onSetActiveStatusConfirm = async () => {
        try {
            setLoading('options');
            await Utils.sleep(1);

            let next_status = !article.active;
            await Request.post(utils, '/support/', {
                active: next_status,
                id: abstract.getID(),
                type: 'set_kb_article_active_status'
            });

            abstract.object.active = next_status;
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${article.name}" knowledge base article has been ${next_status ? 'activated' : 'deactivated'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the active status for this article. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            text: 'Edit'
        }]
    }

    const getContentPreview = () => {
    
        // prevent moving forward if article state has not been set
        if(!content) {
            return null;
        }
        return (
            <LayerItem title={'Content Preview'}>
                <div 
                className={'custom-scrollbars'}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    maxHeight: 500,
                    overflowY: 'scroll'
                }}>
                    <ApplicationContext.Provider value={{ utils: utils }}>
                        <Editor
                        enabled={false}
                        resolver={{ ButtonNode, Canvas, Container, Element, Field, Image, Text }}>
                            <Frame data={content} />
                        </Editor>
                    </ApplicationContext.Provider>
                </div>
            </LayerItem>
        )
    }

    const getFeedback = () => {
        if(loading === true || loading === 'init') {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    padding: 12
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        if(feedback.length === 0) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        hideIcon: true,
                        subTitle: 'There are no available feedback responses to view for this knowledge base article',
                        title: 'No Feedback Found'
                    })}
                </div>
            )
        }
        return (
            <>
            {feedback.map((response, index) => {
                return (
                    <div 
                    key={index}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        marginBottom: index !== feedback.length - 1 ? 8 : 0
                    }}>
                        {Views.entry({
                            bottomBorder: false,
                            icon: { path: response.value ? 'images/checkmark-green.png' : 'images/red-x-icon.png' },
                            rightContent: response.email_address && (
                                <AltBadge 
                                onClick={onEmailClick.bind(this, response)}
                                content={{
                                    color: Appearance.colors.grey(),
                                    text: 'Contact'
                                }} />
                            ),
                            subTitle: Utils.formatDate(response.date),
                            title: `Article was ${response.value ? 'helpful' : 'not helpful'}`
                        })}
                        {typeof(response.message) === 'string' && (
                            <div style={{
                                borderTop: `1px solid ${Appearance.colors.divider()}`,
                                padding: '8px 12px 8px 12px',
                                width: '100%'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    whiteSpace: 'normal'
                                }}>{response.message}</span>
                            </div>
                        )}
                    </div>
                )
            })}
            {paging && (
                <PageControl
                data={paging}
                limit={limit}
                loading={loading === 'paging'}
                offset={offset.current}
                onClick={next => {
                    offset.current = next;
                    setLoading('paging');
                    fetchFeedback();
                }}/>
            )}
            </>
        )
    }

    const getFields = () => {

        // prevent moving forward if article state has not been set
        if(!article) {
            return [];
        }

        return [{
            key: 'details',
            lastItem: false,
            title: 'Details',
            items: [{
                key: 'date',
                title: 'Date Created',
                value: article.date && Utils.formatDate(article.date)
            },{
                key: 'id',
                title: 'ID',
                value: article.id
            },{
                key: 'last_updated',
                title: 'Last Updated',
                value: article.last_updated && Utils.formatDate(article.last_updated)
            },{
                key: 'name',
                title: 'Name',
                value: article.name
            },{
                key: 'active',
                title: 'Status',
                value: article.active ? 'Active' : 'Not Active'
            },{
                key: 'url',
                onClick: window.open.bind(this, article.url),
                title: 'URL',
                value: article.url
            }]
        }];
    }

    const fetchFeedback = async () => {
        try {

            let { paging, responses } = await Request.get(utils, '/support/', {
                id: abstract.getID(),
                limit: limit,
                offset: offset.current,
                type: 'kb_article_feedback'
            });

            setLoading(false);
            setPaging(paging);
            setFeedback(responses.map(response => ({
                ...response,
                date: moment.utc(response.date).local()
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the feedback responses for this knowledge base article. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setContent(article && article.content && article.content.layout);
    }, [article]);

    useEffect(() => {
        setTimeout(fetchFeedback, 250);
        utils.content.subscribe(layerID, ['kb_articles'], {
            onUpdate: next => {
                setArticle(next.compare(abstract));
                if(abstract.getID() === next.getID()) {
                    setContent(null);
                    setTimeout(setContent.bind(this, next.object.content.layout), 250);
                }
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        utils={utils}
        title={`Details for ${abstract.getTitle()}`}
        buttons={getButtons()}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            {getContentPreview()}

            <FieldMapper
            fields={getFields()} 
            utils={utils}/>

            <LayerItem title={'Feedback'}>
                {getFeedback()}
            </LayerItem>
        </Layer>
    )
}

export const NewSupportRequestResponse = ({ requests }, { index, options, utils }) => {

    const layerID = 'new_support_request_response';

    const [destinations, setDestinations] = useState([]);
    const [edits, setEdits] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState();

    const onClearContent = () => {
        setEdits({});
    }

    const onConfigureArticleContent = () => {

        utils.layer.open({
            id: 'new_kb_article',
            abstract: Abstract.create({
                object: Support.Article.new(),
                type: 'kb_articles'
            }),
            Component: AddEditKBArticle.bind(this, {
                generateContent: edits.content && edits.content.message && [{
                    key: 'placeholder',
                    title: 'Section Title',
                    items: [{
                        component: 'text',
                        value: edits.content.message 
                    }]
                }],
                isNewTarget: true,
                request: hasMultipleRequests() ? null : requests[0]
            })
        });
    }

    const onChangeContactMethod = item => {
    
        // prevent moving forward if push notification is selected but no user account is available
        // this is only applicable if a single request is being targeted
        if(hasMultipleRequests() === false && item && item.id === 'push_notification' && !requests[0].targets.user) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'There is no user account associated with this support request. Please choose email or text message to continue.'
            });
            return;
        }

        // update edits with new method selection
        onUpdateTarget({ 
            contact: {
                ...edits.contact,
                destination: null,
                method: item && item.id
            }
        });
    }

    const onFieldItemClick = (index, { onRemoveItem }, evt) => {
        utils.sheet.show({
            items: [{
                key: 'update',
                title: 'Update',
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            switch(key) {
                case 'update':
                onUpdateFieldItem(index);
                break;

                case 'remove':
                onRemoveItem(index);
                break;
            }
        });
    }

    const onMoveFieldItem = (index, value, evt) => {
        evt.stopPropagation();
        let item = edits.content.value[index];
        onUpdateTarget({ 
            content: {
                ...edits.content,
                value: update(edits.content.value, {
                    $splice: [[ 
                        index, 1
                    ],[
                        index + value, 0, item
                    ]]
                })
            } 
        });
    }
    
    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'clear',
                title: 'Clear Edits',
                style: 'destructive'
            },{
                key: 'kb_article',
                title: 'Generate Knowledge Base Article',
                style: 'default',
                visible: utils.user.get().level <= User.levels.get().admin
            }],
            target: evt.target
        }, key => {
            switch(key) {
                case 'clear':
                onClearContent();
                break;

                case 'kb_article':
                onConfigureArticleContent();
                break;
            }
        });
    }

    const onRenderFieldItem = (item, index, items, props) => {
        return (
            <div
            className={`view-entry ${window.theme}`}
            key={index}
            onClick={onFieldItemClick.bind(this, index, props)}
            style={{
                alignItems: 'center',
                borderBottom: index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                display: 'flex',
                flexDirection: 'row',
                padding: '8px 12px 8px 12px',
                width: '100%'
            }}>
                <span style={{
                    ...Appearance.textStyles.key(),
                    flexGrow: 1
                }}>{`${item.title} (${item.type ? Support.Workflow.Landing.components.toText(item.type) : 'Text Field'})`}</span>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <AltBadge 
                    content={{
                        color: item.required ? Appearance.colors.red : Appearance.colors.grey(),
                        text: item.required ? 'Required' : 'Optional'
                    }} 
                    onClick={evt => {
                        evt.stopPropagation();
                        onUpdateTarget({ 
                            content: {
                                ...edits.content,
                                value: update(edits.content.value, {
                                    [index]: {
                                        required: {
                                            $set: edits.content.value[index].required ? false : true
                                        }
                                    }
                                })
                            } 
                        });
                    }}
                    style={{ 
                        marginLeft: 12,
                        marginRight: 0
                    }}/>
                    <img
                    src={`images/down-arrow-grey-small.png`}
                    className={'text-button'}
                    onClick={onMoveFieldItem.bind(this, index, 1)}
                    style={{
                        height: 18,
                        marginLeft: 4,
                        objectFit: 'contain',
                        opacity: 1,
                        width: 18
                    }} />
                    <img
                    src={`images/up-arrow-grey-small.png`}
                    className={'text-button'}
                    onClick={onMoveFieldItem.bind(this, index, -1)}
                    style={{
                        height: 18,
                        marginLeft: 4,
                        objectFit: 'contain',
                        opacity: 1,
                        width: 18
                    }} />
                </div>
            </div>
        )
    }

    const onSubmit = async targets => {
        try {
            
            // start loading and verify that all required fields have been completed
            setLoading('submit');
            await validateRequiredFields(getFields);

            // prepare payload for server request
            let payload = {
                request_ids: requests.map(request => request.id),
                type: 'new_internal_request_response',
                ...edits
            }

            // determine if a kb article needs to be formatted
            if(payload.options && payload.options.type === 'kb_article') {
                payload.content.value = { kb_article_id: edits.content.value.id };
            }

            // determine if a specific list of requests was provided
            if(targets) {
                payload.request_ids = targets;
            }

            // send request to server
            let { errors } = await Request.post(utils, '/support/', payload);

            // end loading and check to see if any errors were generated
            setLoading(false);
            if(errors.length > 0) {
                utils.layer.open({
                    id: 'new_support_request_response_errors',
                    Component: NewSupportRequestResponseErrors.bind(this, {
                        errors: errors,
                        onRetry: onSubmit
                    })
                });
                return;
            }

            // notify subscribers of new content and show confirmation alert
            utils.content.fetch('support_request_responses');
            utils.alert.show({
                title: 'All Done!',
                message: `The response has been sent ${hasMultipleRequests() ? 'for your selection of support requests' : 'for the support request'}.`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your support response. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateFieldItem = index => {

        // prepare list of component types
        let components = Support.Workflow.Landing.components.get();
        let items = [
            components.address_lookup,
            components.comm_link_serial_number_field,
            components.date_picker,
            components.duration_picker,
            components.email_address_field,
            components.file_picker,
            components.image_picker,
            components.phone_number_field,
            components.textfield,
            components.textview,
            components.time_picker
        ].map(code => ({
            id: code,
            title: Support.Workflow.Landing.components.toText(code)
        }));

        // declare field type, field title, and selecte field type item
        let title = edits.content.value[index].title;
        let type = edits.content.value[index].type;
        let selected = items.find(item => item.id === type);

        // show alert with list field of component options
        utils.alert.show({
            title: 'Update',
            message: 'What would you like to change for this item?',
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <TextField
                    containerStyle={{ marginBottom: 8 }}
                    placeholder={'Title'}
                    onChange={text => title = text}
                    value={title} />

                    <ListField 
                    items={items}
                    onChange={item => type = item && item.id} 
                    value={selected && selected.title || 'Text Field'}/>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onUpdateTarget({ 
                        content: {
                            ...edits.content,
                            value: update(edits.content.value, {
                                [index]: {
                                    title: {
                                        $set: title
                                    },
                                    type: {
                                        $set: type
                                    }
                                }
                            })
                        } 
                    });
                    return;
                }
            }
        });
    }


    const onUpdateTarget = props => {
        setEdits(prev => ({
            ...prev,
            ...props
        }));
    }

    const getButtons = () => {
        return [{
            key: 'options',
            text: 'Options',
            color: 'dark',
            onClick: onOptionsClick,
        },{
            key: 'submit',
            text: 'Submit',
            color: 'primary',
            onClick: onSubmit.bind(this, null)
        }];
    }

    const getContactItems = () => {
        return [{
            id: 'email',
            title: 'Email'
        },{
            id: 'push_notification',
            title: 'Push Notification'
        },{
            id: 'sms',
            title: 'Text Message'
        }];
    }

    const getContactValue = () => {
        let selected = getContactItems().find(item => edits.contact && item.id === edits.contact.method);
        return selected ? selected.title : null;
    }

    const getContentPreview = () => {

        // prevent moving forward if a destination has not been set
        if(!edits.contact || !edits.contact.method) {
            return null;
        }

        // prepare options for edits
        let { type } = edits.options || {};

        // prepare user object and optional first name variable
        let user = hasMultipleRequests() ? null : requests[0].targets.user;
        let firstName = user ? user.first_name : requests[0].values && requests[0].values.first_name;

        return (
            <LayerItem title={'Preview'}>
                {getContactContentPreview({ 
                    app: edits.contact.destination,
                    footer: 'We appreciate you taking the time to reach out to support and we look forward to working with you.',
                    generated: getResponseGeneratedMessage(type),
                    header: firstName ? `Hello ${firstName},` : 'Hello {{first_name}},',
                    message: edits.content && edits.content.message,
                    origin: requests[0].origin,
                    response: getResponseLinks(edits.contact.method, type, requests[0].origin),
                    type: edits.contact.method,
                    utils: utils
                })}
            </LayerItem>
        )
    }

    const getDestinationValue = () => {
        let selected = destinations.find(item => edits.contact && item.id === edits.contact.destination);
        return selected ? selected.title : null;
    }

    const getFields = () => {

        // prevent moving forward if edits state has not set
        if(!edits) {
            return [];
        }

        // prepare dealer facing fields if applicable
        if(utils.user.get().level > User.levels.get().admin) {
            return [{
                key: 'contact',
                title: 'Contact',
                items: getTypeComponents().concat([{
                    component: 'list',
                    key: 'options.status',
                    items: getStatusItems(),
                    onChange: item => {
                        onUpdateTarget({ 
                            options: {
                                ...edits.options,
                                status: item && item.id
                            } 
                        })
                    },
                    required: false,
                    title: 'Status',
                    value: getStatusValue()
                }])
            }];
        }

        // prepare admin facing formatted key value line items
        return [{
            key: 'contact',
            title: 'Contact',
            items: [{
                component: 'list',
                items: getContactItems(),
                key: 'contact.method',
                onChange: onChangeContactMethod,
                title: 'Method',
                value: getContactValue()
            },{
                component: 'list',
                items: destinations,
                key: 'contact.destination',
                onChange: item => {
                    onUpdateTarget({ 
                        contact: {
                            ...edits.contact,
                            destination: item && item.id
                        }
                    });
                },
                required: edits.contact && edits.contact.method === 'push_notification' ? true : false,
                title: 'Send to Mobile App',
                value: getDestinationValue()
            }]
        },{
            key: 'response',
            title: 'Response',
            items: [{
                component: 'list',
                key: 'options.type',
                items: getTypeItems(),
                onChange: item => {
                    onUpdateTarget({ 
                        options: {
                            ...edits.options,
                            type: item && item.id,
                            value: null
                        } 
                    })
                },
                title: 'Type',
                value: getTypeValue()
            }].concat(getTypeComponents())
        },{
            key: 'options',
            title: 'Options',
            items: [{
                component: 'list',
                key: 'options.status',
                items: getStatusItems(),
                onChange: item => {
                    onUpdateTarget({ 
                        options: {
                            ...edits.options,
                            status: item && item.id
                        } 
                    })
                },
                required: false,
                title: 'Status',
                value: getStatusValue()
            },{
                component: 'bool_list',
                key: 'options.save_as_template',
                onChange: val => {
                    onUpdateTarget({ 
                        options: {
                            ...edits.options,
                            save_as_template: val
                        }
                    });
                },
                required: false,
                title: 'Save as Template',
                value: edits.options && edits.options.save_as_template
            },{
                component: 'textfield',
                key: 'options.template_name',
                onChange: text => {
                    onUpdateTarget({ 
                        options: {
                            ...edits.options,
                            template_name: text
                        }
                    });
                },
                required: edits.options && edits.options.save_as_template === true ? true : false,
                title: 'Template Name',
                value: edits.options && edits.options.template_name,
                visible: edits.options && edits.options.save_as_template === true ? true : false
            }]
        }];
    }

    const getStatusItems = () => {
        return Object.values(Support.Request.status.get()).filter(code => code !== null).map(code => ({
            id: code,
            title: Support.Request.status.toText(code)
        }));
    }

    const getStatusValue = () => {
        let selected = getStatusItems().find(item => edits.options && item.id === edits.options.status);
        return selected ? selected.title : null;
    }

    const getTypeComponents = () => {

        // determine which components, if any, are needed
        let type = edits.options && edits.options.type;
        switch(type) {
            case 'additional_info':
            return [{
                component: 'multiple_textfield',
                key: 'content.value',
                onChange: results => {
                    onUpdateTarget({ 
                        content: {
                            ...edits.content,
                            value: results
                        } 
                    })
                },
                props: {
                    useIdentifiers: true,
                    onRenderItem: onRenderFieldItem
                },
                title: 'Info Fields',
                value: edits.content && edits.content.value
            },{
                component: 'textview',
                key: 'content.message',
                onChange: text => {
                    onUpdateTarget({ 
                        content: {
                            ...edits.content,
                            message: text
                        } 
                    })
                },
                placeholder: 'Use this area to write a custom message for the user if you want to include additional information.',
                title: 'Context for Webpage',
                value: edits.content && edits.content.message
            }]

            case 'kb_article':
            return [{
                component: 'kb_article_lookup',
                key: 'content.value',
                onChange: result => {
                    onUpdateTarget({ 
                        content: {
                            ...edits.content,
                            value: result
                        } 
                    })
                },
                title: 'Knowledge Base Article',
                value: edits.content && edits.content.value
            },{
                component: 'textview',
                key: 'content.message',
                onChange: text => {
                    onUpdateTarget({ 
                        content: {
                            ...edits.content,
                            message: text
                        } 
                    })
                },
                placeholder: 'Use this area to write a custom message for the user if you want to include additional information.',
                title: 'Context for Knowledge Base Article',
                value: edits.content && edits.content.message
            }];

            case 'webpage':
            return [{
                component: 'textfield',
                key: 'content.value',
                onChange: result => {
                    onUpdateTarget({ 
                        content: {
                            ...edits.content,
                            value: result
                        } 
                    })
                },
                title: 'Webpage',
                value: edits.content && edits.content.value
            },{
                component: 'textview',
                key: 'content.message',
                onChange: text => {
                    onUpdateTarget({ 
                        content: {
                            ...edits.content,
                            message: text
                        } 
                    })
                },
                placeholder: 'Use this area to write a custom message for the user if you want to include additional information.',
                title: 'Context for Webpage',
                value: edits.content && edits.content.message
            }];

            default:
            return [{
                component: 'textview',
                key: 'content.message',
                onChange: text => {
                    onUpdateTarget({ 
                        content: {
                            ...edits.content,
                            message: text
                        } 
                    })
                },
                placeholder: 'Use this area to write a custom message for the user if you want to include additional information.',
                title: 'Message for Customer',
                value: edits.content && edits.content.message
            }];
        }
    }

    const getTypeItems = () => {
        return [{
            id: 'additional_info',
            title: 'Ask for additional information'
        },{
            id: 'issue_under_investigation',
            title: 'Inform that issue is under investigation'
        },{
            id: 'kb_article',
            title: 'Share a knowledge base article'
        },{
            id: 'webpage',
            title: 'Share link to a webpage'
        },{
            id: 'freeform',
            title: 'Write a freeform message'
        }];
    }

    const getTypeValue = () => {
        let selected = getTypeItems().find(item => edits.options && edits.options.type === item.id);
        return selected ? selected.title : null;
    }

    const hasMultipleRequests = () => {
        return requests.length > 1 ? true : false;
    }

    const setupTargets = () => {

        // set mobile app destinations
        setDestinations([{
            id: 'aft',
            title: 'Applied Fire Technologies'
        },{
            id: 'global_data',
            title: 'Global Data'
        },{
            id: 'omnishield',
            title: 'OmniShield'
        }]);

        // set default status for updates
        setEdits({
            contact: {
                method: utils.user.get().level > User.levels.get().admin ? 'email' : null
            },
            options: {
                type: utils.user.get().level > User.levels.get().admin ? 'freeform' : null,
                status: Support.Request.status.get().internal_update
            }
        });
    }

    useEffect(() => {
        setupTargets();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'New Support Request Response'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            {getContentPreview()}
            <AltFieldMapper
            fields={getFields()} 
            utils={utils}/>
        </Layer>
    )
}

export const NewSupportRequestResponseErrors = ({ errors = [], onRetry }, { index, options, utils }) => {

    const layerID = 'new_support_request_response_errors';
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);

    const onCheckboxChange = (id, val, evt) => {
        evt.stopPropagation();
        setSelected(ids => {
            if(val) {
                return ids.concat([id]);
            }
            return ids.filter(prev => prev !== id);
        });
    }

    const onRequestClick = async id => {
        try {
            setLoading(id);
            let request = await Support.Request.get(utils, id);

            setLoading(false);
            utils.layer.open({
                id: `support_request_details_${request.id}`,
                abstract: Abstract.create({
                    type: 'support_requests',
                    object: request
                }),
                Component: SupportRequestDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this support request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubmit = () => {
        setLayerState('close')
        if(typeof(onRetry) === 'function') {
            setTimeout(onRetry.bind(this, selected), 500);
        }
    }

    const getButtons = () => {
        if(selected.length === 0) {
            return [];
        }
        return [{
            key: 'submit',
            text: `Retry ${selected.length} ${selected.length === 1 ? 'Request' : 'Requests'}`,
            color: 'primary',
            onClick: onSubmit
        }];
    }

    useEffect(() => {
        setSelected(errors.map(error => error.id));
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'New Support Request Response'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {errors.map((error, index) => {
                    return (
                        Views.entry({
                            bottomBorder: index !== errors.length - 1,
                            icon: { path: 'images/support-request-response-error.png' },
                            key: index,
                            loading: loading === error.id,
                            onClick: onRequestClick.bind(this, error.id),
                            rightContent: (
                                <div style={{
                                    marginLeft: 12
                                }}>
                                    <Checkbox
                                    checked={selected.includes(error.id)}
                                    color={Appearance.colors.darkGrey}
                                    onChange={onCheckboxChange.bind(this, error.id)} />
                                </div>
                            ),
                            subTitle: error.message,
                            title: `Request ${error.id}`,
                            style: {
                                subTitle: {
                                    whiteSpace: 'normal'
                                }
                            }
                        })
                    )
                })}
            </div>
        </Layer>
    )
}

export const PayloadValueScriptEditor = ({ onChange, value }, { index, options, utils }) => {

    const layerID = 'payload_value_script_editor';

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState('');

    const onSubmit = () => {
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange(text);
        }
    }

    const getButtons = () => {
        return [{
            key: 'submit',
            text: 'Save Changes',
            color: 'primary',
            onClick: onSubmit
        }]
    }

    useEffect(() => {
        setText(value || '');
    }, [value]);

    return (
        <Layer
        id={layerID}
        index={index}
        title={'Payload Value Script Editor'}
        utils={utils}
        buttons={getButtons()}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <TextField
            expandWithText={true}
            onChange={text => setText(text)}
            value={text} />
        </Layer>
    )
}

export const SupportRequestContactUsers = ({ onSubmit }, { index, options, utils }) => {

    const layerID = 'support_request_contact_users';
    const [edits, setEdits] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const onClearContent = () => {
        setEdits({});
    }

    const onProcessSubmission = () => {

        // determine which contact variable is needed based on the method of contact
        let method = '';
        switch(edits.method) {
            case 'email':
            method = 'an email address';
            break;

            case 'push_notification':
            method = 'a registered AFT account';
            break;

            case 'sms':
            method = 'a phone number';
            break;

            default:
            return;
        }

        // show alert requesting user confirmation
        utils.alert.show({
            title: 'About Your Submission',
            message: `We'll send your messages to every support request user that has ${method} on file with their request. Users who don't have ${method} on file will not receive your message.`,
            buttons: [{
                key: 'confirm',
                title: 'Send',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Send',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onProcessSubmissionConfirm();
                    return;
                }
            }
        });
    }

    const onProcessSubmissionConfirm = async () => {
        try {
            setLoading(true);
            await onSubmit(edits);

            setLoading(false);
            setLayerState('close');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending your messages. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits(prev => ({
            ...prev,
            ...props
        }));
    }

    const getButtons = () => {

        // determine which type of label should be shown
        let label = 'Send';
        switch(edits.method) {
            case 'email':
            label = 'Send Emails';
            break;

            case 'push_notification':
            label = 'Send Push Notifications';
            break;

            case 'sms':
            label = 'Send Text Messages';
            break;

            default:
            return [{
                key: 'clear',
                text: 'Clear',
                color: 'dark',
                onClick: onClearContent,
            }]
        }

        return [{
            key: 'clear',
            text: 'Clear',
            color: 'dark',
            onClick: onClearContent,
        },{
            key: 'submit',
            text: label,
            color: 'primary',
            onClick: onProcessSubmission
        }];
    }
    
    const getContentPreview = () => {
        return (
            <LayerItem title={'Preview'}>
                {getContactContentPreview({
                    footer: 'Please reach out if you have any questions or concerns,',
                    header: `Hello {{first_name}},`,
                    message: edits.message,
                    type: edits.method,
                    utils: utils
                })}
            </LayerItem>
        )
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                component: 'list',
                items: getMethodItems(),
                key: 'method',
                onChange: item => {
                    onUpdateTarget({ 
                        content: null,
                        method: item && item.id,
                        subject: null,
                        title: null
                    })
                },
                title: 'Method',
                value: getMethodValue()
            }].concat(getMethodComponents())
        }];
    }

    const getMethodComponents = () => {
        switch(edits.method) {
            case 'email':
            return [{
                component: 'textfield',
                key: 'subject',
                onChange: text => onUpdateTarget({ subject: text }),
                title: 'Subject',
                value: edits.subject
            },{
                component: 'textview',
                key: 'content',
                onChange: text => onUpdateTarget({ content: text }),
                title: 'Content',
                value: edits.content
            }];

            case 'push_notification':
            return [{
                component: 'textfield',
                key: 'title',
                onChange: text => onUpdateTarget({ title: text }),
                title: 'Title',
                value: edits.title
            },{
                component: 'textview',
                key: 'content',
                onChange: text => onUpdateTarget({ content: text }),
                title: 'Content',
                value: edits.content
            }];

            case 'sms':
            return [{
                component: 'textview',
                key: 'content',
                onChange: text => onUpdateTarget({ content: text }),
                title: 'Content',
                value: edits.content
            }]; 

            default:
            return [];
        }
    }

    const getMethodItems = () => {
        return [{
            id: 'email',
            title: 'Email'
        },{
            id: 'push_notification',
            title: 'Push Notification'
        },{
            id: 'sms',
            title: 'Text Message'
        }];
    }

    const getMethodValue = () => {
        let selected = getMethodItems().find(item => edits.method && edits.method === item.id);
        return selected ? selected.title : null;
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Contact Support Request Users'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            {getContentPreview()}
            <AltFieldMapper
            fields={getFields()} 
            utils={utils}/>
        </Layer>
    )
}

export const SupportRequests = ({ index, options, utils }) => {

    const panelID = 'supoort_requests';

    const limit = 10;
    const offset = useRef(null);

    const [loading, setLoading, loadingProps] = useLoading();
    const [paging, setPaging] = useState(null);
    const [requests, setRequests] = useState([]);
    const [searchKey, setSearchKey] = useState(null);
    const [searchKeys, setSearchKeys] = useState([]);
    const [searchValue, setSearchValue] = useState(undefined);
    const [selecting, setSelecting] = useState(false);
    const [selected, setSelected] = useState([]);
    const [selectedOffset, setSelectedOffset] = useState(0);
    const [selectedPaging, setSelectedPaging] = useState(null);

    const onClearRequests = () => {
        utils.alert.show({
            title: 'Clear Requests',
            message: 'Are you sure that you want to clear your list of selected requests?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setSelected([]);
                    return;
                }
            }
        })
    }

    const onContinueClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'response',
                title: 'Create Response',
                style: 'default',
                visible: utils.user.get().level <= User.levels.get().admin
            },{
                key: 'tags',
                title: 'Manage Tags',
                style: 'default'
            },{
                key: 'status',
                title: 'Set Status',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            switch(key) {
                case 'response':
                onCreateResponse();
                break;

                case 'tags':
                onManageTags();
                break;

                case 'status':
                onSetStatus();
                break;
            }
        })
    }

    const onCreateResponse = () => {
        utils.layer.open({
            id: 'new_support_request_response',
            Component: NewSupportRequestResponse.bind(this, {
                requests: selected
            })
        });
    }

    const onManageTags = () => {

        let tags = [];
        utils.alert.show({
            title: 'Manage Tags',
            message: 'You can assign one or more tags below to your selection of support requests. Your selection will overwrite the current tags assigned to your selection of support requests.',
            content: (
                <div style={{
                    padding: 20,
                    width: '100%'
                }}>
                    <TagLookupField onChange={results => tags = results} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Update Tags',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onUpdateTags(tags);
                    return;
                }
            }
        });
    }

    const onRemoveRequest = request => {
        let next = update(selected, {
            $apply: requests => requests.filter(target => {
                return request.id !== target.id;
            })
        });
        setSelected(next);
    }

    const onRequestClick = request => {

        // add to list of selected leads if "selecting" is enabled
        if(selecting) {

            // prevent moving forward if request is already selected
            let current = selected.find(target => target.id === request.id);
            if(current) {
                return;
            }

            // update list of selected requests
            let next = update(selected, { $unshift: [request] });
            setSelected(next);
            return;
        }

        // fallback to opening details for request
        utils.layer.open({
            abstract: Abstract.create({
                object: request,
                type: 'support_requests'
            }),
            Component: SupportRequestDetails,
            id: `support_request_details_${request.id}`
        });
    }

    const onSearchKeyChange = item => {

        // update search key and search value
        let id = item && item.id;
        setSearchKey(id);

        // status value should be undefined by default since the "pending" code is null
        setSearchValue(id === '__private_status' ? undefined : null);
    }
    
    const onSetStatus = () => {

        let status = [];
        let items = Object.values(Support.Request.status.get()).map(code => ({
            id: code,
            title: Support.Request.status.toText(code)
        }));

        utils.alert.show({
            title: 'Set Status',
            message: 'You can batch assign a status code to your selection of support requests using the list below. Your selection will overwrite the current status assigned to your selection of support requests.',
            content: (
                <div style={{
                    padding: 20,
                    width: '100%'
                }}>
                    <ListField
                    items={items}
                    onChange={item => status = item && item.id}/>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Update Status',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onUpdateStatus(status);
                    return;
                }
            }
        });
    }

    const onUpdateStatus = async status => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            await Request.post(utils, '/support/', {
                request_ids: selected.map(request => request.id),
                status: status,
                type: 'update_support_request_status'
            });

            setLoading(false);
            utils.content.fetch('support_requests');
            utils.alert.show({
                title: 'All Done!',
                message: 'The status for your selection of support requests have been updated'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for your selection of support requests. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTags = async tags => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            await Request.post(utils, '/support/', {
                request_ids: selected.map(request => request.id),
                tags: tags,
                type: 'update_support_request_tags'
            });

            setLoading(false);
            utils.content.fetch('support_requests');
            utils.alert.show({
                title: 'All Done!',
                message: 'The tags for your selection of support requests have been updated'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the tags for your selection of support requests. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            key: 'clear',
            onClick: () => {
                setSearchValue(undefined);
                setSearchKey(searchKeys[0].id);
            },
            title: 'Clear Filters',
            style: 'grey',
            visible: searchValue !== undefined ? true : false && utils.user.get().level <= User.levels.get().admin
        },{
            key: 'selecting',
            onClick: () => setSelecting(val => !val),
            style: selecting ? 'cancel' : 'default',
            title: `${selecting ? 'Select One' : 'Select Batches'}`
        }];
    }

    const getContent = () => {

        // return none-found placeholder if no requests have been submitted
        if(requests.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'There were no support requests found in the system',
                    title: 'No Support Requests Found',
                })
            )
        }

        // return standard list of leads if "selecting" is not enabled
        if(selecting === false) {
            return (
                <div className={`col-12 p-0 m-0`}>
                    {getRequests()}
                    <PageControl
                    data={paging}
                    limit={limit}
                    offset={offset.current}
                    onClick={next => {
                        offset.current = next;
                        fetchRequests();
                    }}/>
                </div>
            )
        }

        // return selection resources if "selecting" is enabled
        return (
            <div 
            className={'row m-0'}
            style={{
                padding: 12
            }}>
                <div className={`col-12 col-md-6 col-lg-8 pr-md-1 p-0 m-0`}>
                    <div style={Appearance.styles.unstyledPanel()}>
                        {getRequests()}
                        <PageControl
                        data={paging}
                        limit={limit}
                        offset={offset.current}
                        onClick={next => {
                            offset.current = next;
                            fetchRequests();
                        }}/>
                    </div>
                </div>

                <div className={'col-12 col-md-6 col-lg-4 p-0 pl-md-1 m-0'}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        height: '100%'
                    }}>
                        <div
                        className={'px-3 py-2'}
                        style={{
                            borderBottom: `1px solid ${Appearance.colors.divider()}`
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                            }}>{'Selected Requests'}</span>
                        </div>
                        <div style={{
                            flexGrow: 1
                        }}>
                            {getSelectedRequests()}
                        </div>
                        <PageControl
                        data={selectedPaging}
                        limit={limit}
                        offset={selectedOffset}
                        onClick={setSelectedOffset}/>

                        {selected.length > 0 && (
                            <div
                            className={'row m-0'}
                            style={{
                                borderTop: `1px solid ${Appearance.colors.divider()}`,
                                padding: 12,
                                width: '100%'
                            }}>
                                <div className={`col-12 col-md-6 p-1 px-md-1 py-md-0`}>
                                    <Button
                                    color={'dark'}
                                    label={`Clear`}
                                    onClick={onClearRequests}
                                    type={'large'}/>
                                </div>
                                <div className={'col-12 col-md-6 p-1 px-md-1 py-md-0'}>
                                    <Button
                                    color={'primary'}
                                    label={`Continue with ${selected.length} ${selected.length === 1 ? 'Request' : 'Requests'}`}
                                    onClick={onContinueClick}
                                    type={'large'}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const getRequests = () => {
        return requests.map((request, index) => {
            return (
                Views.entry({
                    ...request.getDescription(),
                    bottomBorder: index !== requests.length - 1,
                    key: index,
                    onClick: onRequestClick.bind(this, request)
                })
            )
        });
    }

    const getSearchComponent = () => {

        // declare currently selected search key
        let selected = searchKey && searchKeys.find(entry => entry.id === searchKey);

        // prepare search variables
        let component = selected && selected.component || null;
        let fieldComponent = null;
        let placeholder = `Search ${selected ? `by ${selected.title.toLowerCase()}` : 'for something'}...`;

        // determine which type of search component is needed
        switch(component) {
            case Support.Workflow.Landing.components.get().address_lookup:
            fieldComponent = (
                <AddressLookupField
                onChange={result => setSearchValue(result)}
                placeholder={placeholder}
                utils={utils} />
            )
            break;

            case Support.Workflow.Landing.components.get().date_picker:
            fieldComponent = (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <DatePickerField
                    placeholder={'Start Date'}
                    onDateChange={date => {
                        setSearchValue(props => ({
                            ...props,
                            start: date && date.utc().unix()
                        }));
                    }}
                    onRemoveDate={() => {
                        setSearchValue(props => ({
                            ...props,
                            start: null
                        }));
                    }}
                    removeable={true}
                    style={{ 
                        marginRight: 4,
                        width: '50%' 
                    }}
                    utils={utils} />

                    <DatePickerField
                    placeholder={'End Date'}
                    onDateChange={date => {
                        setSearchValue(props => ({
                            ...props,
                            end: date && date.utc().unix()
                        }));
                    }}
                    onRemoveDate={() => {
                        setSearchValue(props => ({
                            ...props,
                            end: null
                        }));
                    }}
                    removeable={true}
                    style={{ 
                        marginLeft: 4,
                        width: '50%' 
                    }}
                    utils={utils} />
                </div>
            )
            break;

            case Support.Workflow.Landing.components.get().division_lookup:
            case Support.Workflow.Landing.components.get().region_lookup:
            fieldComponent = (
                <SectorLookupField
                icon={'search'}
                onChange={result => setSearchValue(result && result.id)}
                placeholder={placeholder}
                type={component === Support.Workflow.Landing.components.get().division_lookup ? Sector.types.division : Sector.types.region}
                utils={utils} />
            )
            break;

            case Support.Workflow.Landing.components.get().list:
            let items = selected && selected.values || [];
            fieldComponent = (
                <ListField
                items={items.map(item => ({
                    id: item,
                    title: item
                }))}
                onChange={item => setSearchValue(item && item.id)}
                placeholder={placeholder}
                utils={utils} />
            )
            break;

            case Support.Workflow.Landing.components.get().phone_number_field:
            fieldComponent = (
                <TextField
                format={'phone_number'}
                icon={'search'}
                onChange={setSearchValue}
                placeholder={placeholder}
                useDelay={true}
                containerStyle={{
                    flexGrow: 1
                }} />
            )
            break;

            default:
            fieldComponent = (
                <TextField
                icon={'search'}
                onChange={setSearchValue}
                placeholder={placeholder}
                useDelay={true}
                containerStyle={{
                    flexGrow: 1
                }} />
            )
        }

        // determine if a tag lookup field is required
        if(selected && selected.id === '__private_dealership_id') {
            fieldComponent = (
                <DealershipLookupField
                addNewDealership={false}
                inline={true}
                onChange={result => setSearchValue(result && result.id)}
                placeholder={'Search by dealership...'}
                utils={utils} />
            )
        }

        // determine if an address lookup field is required
        if(selected && selected.id === '__private_location') {
            fieldComponent = (
                <AddressLookupField
                onChange={result => setSearchValue(result)}
                placeholder={placeholder}
                utils={utils} />
            )
        }

        // determine if a tag lookup field is required
        if(selected && selected.id === '__private_tags') {
            fieldComponent = (
                <TagLookupField
                onChange={results => setSearchValue(results && results.length > 0 ? results : null)}
                placeholder={'Search by tags, type something and press enter when finished'}
                utils={utils} 
                value={searchValue}/>
            )
        }

        // determine if a tag lookup field is required
        if(selected && selected.id === '__private_status') {
            fieldComponent = (
                <ListField
                items={Object.values(Support.Request.status.get()).map(code => ({
                    id: code,
                    title: Support.Request.status.toText(code)
                }))}
                onChange={item => setSearchValue(item && item.id)}
                placeholder={'Search by status...'}
                utils={utils} 
                value={Support.Request.status.toText(searchValue)} />
            )
        }

        // determine if a user lookup field is required
        if(selected && selected.id === '__private_user_id') {
            fieldComponent = (
                <UserLookupField
                onChange={result => setSearchValue(result && result.user_id)}
                placeholder={placeholder}
                restrictToDealership={false}
                showInactive={true}
                utils={utils} />
            )
        }

        return (
            <div style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'column',
                padding: 15,
                width: '100%',
            }}>
                <div style={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                }}>
                    {fieldComponent}
                    <ListField 
                    disablePlaceholder={true}
                    items={searchKeys}
                    onChange={onSearchKeyChange}
                    placeholder={'Select a category'}
                    style={{ 
                        marginLeft: 8,
                        width: 250
                    }}
                    value={selected} />
                </div>
            </div>
        )
    }

    const getSelectedRequests = () => {
        if(selected.length === 0) {
            return (
                <div
                className={'px-3 py-2'}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    height: 36,
                    width: '100%',
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        flexGrow: 1
                    }}>{'No Requests Selected'}</span>
                </div>
            )
        }

        return selected.filter((_, index) => {
            if(selectedPaging.number_of_pages === 1) {
                return true;
            }
            let min = (limit * selectedPaging.current_page) - limit;
            let max = limit * selectedPaging.current_page;
            return index >= min && index < max;

        }).map((request, index, requests) => {
            return (
                <div
                key={index}
                className={'px-3 py-2'}
                style={{
                    alignItems: 'center',
                    borderBottom: index !== requests.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                    display: 'flex',
                    flexDirection: 'row',
                    height: 36,
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        flexGrow: 1
                    }}>{request.getDescription().title}</span>
                    <img
                    className={'text-button'}
                    src={'images/red-x-icon.png'}
                    onClick={onRemoveRequest.bind(this, request)}
                    style={{
                        height: 15,
                        marginLeft: 8,
                        objectFit: 'contain',
                        width: 15
                    }}/>
                </div>
            )
        })
    }

    const fetchRequests = async () => {
        try {

            let { paging, requests, search_keys } = await Request.get(utils, '/support/', {
                limit: limit,
                request_search_keys: searchKeys.length === 0,
                search_key: searchKey || 'full_name',
                search_value: searchValue,
                type: 'all_requests'
            });

            setLoading(false);
            setPaging(paging);
            setRequests(requests.map(request => Support.Request.create(request)));

            // set default search key and search key options if applicable for administrators
            if(search_keys) {
                setSearchKey(search_keys[0] && search_keys[0].id);
                setSearchKeys(search_keys);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the support requests list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setSelectedPaging({
            current_page: (selectedOffset / limit) + 1,
            number_of_pages: selected.length > limit ? Math.ceil(selected.length / limit) : 1
        })
    }, [selected, selectedOffset]);

    useEffect(() => {
        if(selecting === false) {
            setSelected([]);
        }
    }, [selecting]);

    useEffect(() => {
        setLoading(true, loading !== loadingProps.initVal);
        fetchRequests();
    }, [searchValue]);

    useEffect(() => {
        utils.content.subscribe(panelID, ['support_requests'], {
            onFetch: fetchRequests,
            onUpdate: abstract => {
                setRequests(requests => {
                    return requests.map(request => {
                        return abstract.compare(request);
                    });
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        id={panelID}
        index={index}
        name={'Support Requests'}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading === true,
            removePadding: true
        }}>
            {getSearchComponent()}
            {getContent()}
        </Panel>
    )
}

export const SupportRequestDetails = ({ abstract, index, options, utils }) => {

    const layerID = `support_request_details_${abstract.getID()}`;
    const limit = 5;
    const offset = useRef(0);

    const [active, setActive] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState('init');
    const [paging, setPaging] = useState(null);
    const [request, setRequest] = useState(abstract.object);
    const [responses, setResponses] = useState([]);

    const onAddressClick = value => {
        let address = Utils.formatAddress(value);
        window.open(`https://www.google.com/maps/place/${encodeURIComponent(address)}`);
    }

    const onCommLinkSerialNumberClick = async sn => {
        try {
            setLoading(true);
            let commLink = await CommLink.get(utils, null, { serial_number: sn });

            setLoading(false);
            utils.layer.open({
                id: `comm_link_details_${commLink.id}`,
                abstract: Abstract.create({
                    type: 'comm_link',
                    object: commLink
                }),
                Component: CommLinkDetails
            });
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this comm link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onCreateResponse = () => {
        utils.layer.open({
            abstract: abstract,
            id: 'new_support_request_response',
            Component: NewSupportRequestResponse.bind(this, {
                requests: [abstract.object]
            })
        });
    }

    const onDealershipClick = dealership => {
        utils.layer.open({
            abstract: Abstract.create({
                object: dealership,
                type: 'dealership'
            }),
            Component: DealershipDetails,
            id: `dealership_details_${dealership.id}`
        });
    }

    const onDeleteRequest = () => {
        utils.alert.show({
            title: 'Delete Request',
            message: `Are you sure that you want to delete this support request? This can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteRequestConfirm();
                    return;
                }
            }
        });
    }

    const onDeleteRequestConfirm = async () => {
        try {

            // start loading and send request to server after brief timeout
            setLoading('options');
            await Utils.sleep(0.25);
            await Request.post(utils, '/support/', {
                id: abstract.getID(),
                type: 'delete_request'
            });

            // notify subscribers that data needs to be fetched
            utils.content.fetch('support_requests');

            // show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'This support request has been deleted'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this support request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEmailAddressClick = emailAddress => {
        window.open(`mailto:${emailAddress}`);
    }

    const onFileClick = file => {
        window.open(file.url);
    }

    const onManageTags = () => {

        let tags = abstract.object.tags ||[];
        utils.alert.show({
            title: 'Manage Tags',
            message: 'You can assign one or more tags below to this support request. Your selection will overwrite the current tags assigned to this support request.',
            content: (
                <div style={{
                    padding: 20,
                    width: '100%'
                }}>
                    <TagLookupField 
                    onChange={results => tags = results} 
                    value={tags} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Update Tags',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onUpdateTags(tags);
                    return;
                }
            }
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'name',
                title: request.name ? 'Update Custom Name' : 'Add Custom Name',
                style: 'default'
            },{
                key: 'response',
                title: 'Create Response',
                style: 'default',
                visible: utils.user.get().level <= User.levels.get().dealer
            },{
                key: 'tags',
                title: 'Manage Tags',
                style: 'default'
            },{
                key: 'status',
                title: 'Set Status',
                style: 'default'
            },{
                key: 'events',
                title: 'View History',
                style: 'default',
                visible: utils.user.get().level <= User.levels.get().admin
            }].sort((a,b) => {
                return a.title.localeCompare(b.title);
            }),
            target: evt.target
        }, key => {
            switch(key) {
                case 'delete':
                onDeleteRequest();
                break;

                case 'events':
                onViewEvents();
                break;

                case 'name':
                onSetCustomName();
                break;

                case 'response':
                onCreateResponse();
                break;

                case 'tags':
                onManageTags();
                break;

                case 'status':
                onSetStatus();
                break;
            }
        });
    }

    const onPhoneNumberClick = phoneNumber => {
        window.open(`tel:${phoneNumber}`);
    }

    const onResponseCollapseChange = id => {
        setActive(active => active === id ? null : id);
    }

    const onSectorClick = sector => {
        utils.layer.open({
            id: `sector_details_${sector.id}`,
            abstract: Abstract.create({
                type: 'sector',
                object: sector
            }),
            Component: SectorDetails
        })
    }

    const onSetCustomName = () => {
        let name = request.name;
        utils.alert.show({
            title: request.name ? 'Update Custom Name' : 'Add Custom Name',
            message: `A custom name can make it easier to keep track of your support requests. You can ${request.name ? 'change the' : 'add a'} custom name using the text field below.`,
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <TextField
                    onChange={text => name = text}
                    placeholder={'Custom Name'} 
                    value={name}/>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm' && name) {
                    onSetCustomNameConfirm(name);
                    return;
                }
            }
        });
    }

    const onSetCustomNameConfirm = async name => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            await Request.post(utils, '/support/', {
                id: abstract.getID(),
                name: name,
                type: 'update_request_name'
            });

            setLoading(false);
            abstract.object.name = name;
            utils.content.update(abstract);
            utils.alert.show({
                title: 'All Done!',
                message: 'The name for this support request has been updated'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the name for this support request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetStatus = () => {

        let status = [];
        let items = Object.values(Support.Request.status.get()).map(code => ({
            id: code,
            title: Support.Request.status.toText(code)
        }));

        utils.alert.show({
            title: 'Set Status',
            message: 'You can batch assign a status code to your selection of support requests using the list below. Your selection will overwrite the current status assigned to your selection of support requests.',
            content: (
                <div style={{
                    padding: 20,
                    width: '100%'
                }}>
                    <ListField
                    items={items}
                    onChange={item => status = item && item.id}/>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Update Status',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onUpdateStatus(status);
                    return;
                }
            }
        });
    }

    const onUpdateStatus = async status => {
        try {

            // send request to server after slight delay to account for previous alert
            setLoading(true);
            await Utils.sleep(0.25);
            let response = await Request.post(utils, '/support/', {
                request_ids: [abstract.getID()],
                status: status,
                type: 'update_support_request_status'
            });

            // update abstract target with new status code
            abstract.object.status = response.status;
            utils.content.update(abstract);

            // notify subscriber of successful status change
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'The status for your this support request have been updated'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this support request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTags = async tags => {
        try {

            // send request to server after slight delay to account for previous alert
            setLoading(true);
            await Utils.sleep(0.25);
            await Request.post(utils, '/support/', {
                request_ids: [abstract.getID()],
                tags: tags,
                type: 'update_support_request_tags'
            });

            // update abstract target with new status code
            abstract.object.tags = tags;
            utils.content.update(abstract);

            // notify subscriber of successful tags change
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'The tags for this support request have been updated'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the tags for this support request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUserClick = user => {
        utils.layer.open({
            id: `user_details_${user.user_id}`,
            abstract: Abstract.create({
                type: 'user',
                object: user
            }),
            Component: UserDetails
        })
    }

    const onViewEvents = () => {
        utils.layer.open({
            id: `support_request_events_${abstract.getID()}`,
            abstract: abstract,
            Component: SupportRequestEvents
        });
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options'
        }]
    }

    const getResponses = () => {
        return (
            <LayerItem 
            collapsed={false}
            title={'Responses'}>
                {getResponsesContent()}
            </LayerItem>
        );
    }

    const getResponsesContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    padding: 12
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        if(responses.length === 0) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        hideIcon: true,
                        subTitle: 'There are no available responses to view for this support request',
                        title: 'No Responses Found'
                    })}
                </div>
            )
        }
        return (
            <>
            {responses.map((response, index) => {
                return (
                    <div 
                    key={index}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        marginBottom: index !== responses.length - 1 ? 8 : 0
                    }}>
                        {Views.entry({
                            ...response.getDescription(),
                            bottomBorder: false,
                            hideOnClickArrow: true,
                            onClick: onResponseCollapseChange.bind(this, response.id),
                            rightContent: (
                                <CollapseArrow collapsed={active !== response.id} />
                            )
                        })}
                        {active === response.id && response.channel === 'internal' && (
                            <div style={{
                                borderTop: `1px solid ${Appearance.colors.divider()}`,
                                width: '100%'
                            }}>
                                <div style={{
                                    padding: 12
                                }}>
                                    {getContactContentPreview({
                                        app: response.destination,
                                        footer: 'We appreciate you taking the time to reach out to support and we look forward to working with you.',
                                        generated: getResponseGeneratedMessage(response.type),
                                        header: getContentPreviewHeader(),
                                        message: response.content && response.content.message,
                                        origin: abstract.object.origin,
                                        response: getResponseLinks(response.method, response.type, abstract.object.origin),
                                        type: response.method,
                                        utils: utils
                                    })}
                                </div>
                                {response.content.value && response.content.value.kb_article && (
                                    <div style={{
                                        borderTop: `1px solid ${Appearance.colors.divider()}`
                                    }}>
                                        {Views.row({
                                            label: response.content.value.kb_article.name,
                                            onClick: window.open.bind(this, response.content.value.kb_article.url),
                                            value: 'Click to view article'
                                        })}
                                    </div>
                                )}
                                {response.content.value && typeof(response.content.value.url) === 'string' && (
                                    <div style={{
                                        borderTop: `1px solid ${Appearance.colors.divider()}`
                                    }}>
                                        {Views.row({
                                            label: response.content.value.title || 'Untitled Webpage',
                                            onClick: window.open.bind(this, response.content.value.url),
                                            value: 'Click to open webpage'
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                        {active === response.id && response.channel === 'external' && (
                            <div style={{
                                borderTop: `1px solid ${Appearance.colors.divider()}`,
                                width: '100%'
                            }}>
                                {typeof(response.message) === 'string' && (
                                    Views.row({
                                        label: 'Message',
                                        value: response.message
                                    })
                                )}
                                {getResponseCustomFields(response)}
                                {response.attachment && (
                                    <div style={{
                                        borderTop: `1px solid ${Appearance.colors.divider()}`
                                    }}>
                                        {Views.row({
                                            label: response.attachment.name,
                                            onClick: window.open.bind(this, response.attachment.url),
                                            value: Utils.convertBytes(response.attachment.size)
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )
            })}
            {paging && (
                <PageControl
                data={paging}
                limit={limit}
                loading={loading === 'paging'}
                offset={offset.current}
                onClick={next => {
                    offset.current = next;
                    setLoading('paging');
                    fetchResponses();
                }}
                style={{
                    borderTop: null
                }} />
            )}
            </>
        )
    }

    const getResponseCustomFields = response => {

        // prevent moving forward if no values are present
        if(!response.values || response.values.length === 0) {
            return null;
        }

        return response.values.map((entry, index, values) => {

            // determine which type of formatting is required based on the component type
            let props = { value: entry.value };
            switch(entry.type) {
                case Support.Workflow.Landing.components.get().address_lookup:
                props.value = Utils.formatAddress(entry.value);
                break;

                case Support.Workflow.Landing.components.get().comm_link_serial_number_field:
                props.onClick = entry.value ? onCommLinkSerialNumberClick.bind(this, entry.value) : null;
                break;

                case Support.Workflow.Landing.components.get().date_picker:
                props.value = Utils.formatDate(entry.value);
                break;

                case Support.Workflow.Landing.components.get().email_address_field:
                props.onClick = entry.value ? onEmailAddressClick.bind(this, entry.value) : null;
                break;

                case Support.Workflow.Landing.components.get().phone_number_field:
                props = {
                    ...props,
                    onClick: entry.value ? onPhoneNumberClick.bind(this, entry.value) : null,
                    value: Utils.formatPhoneNumber(entry.value)
                }
                break;
            }

            return (
                Views.row({
                    ...props,
                    bottomBorder: response.attachment || index!== values.length - 1,
                    key: index,
                    label: entry.title
                })
            )
        });
    }

    const getContentPreviewHeader = () => {
        let target = abstract.object.targets && abstract.object.targets.user || abstract.object.values || {};
        return target && target.first_name ? `Hello ${target.first_name},` : 'Hello,';
    }
    
    const getTopFields = () => {

        // prevent moving forward if request state has not updated
        if(!request) {
            return [];
        }

        // prepare formatted key value line items
        let location = request.interaction && request.interaction.location;
        let items = [{
            key: 'location',
            title: 'Location',
            visible: location ? true : false,
            items: [{
                key: 'location',
                title: 'Location',
                component: 'map',
                value: location && {
                    latitude: location.lat,
                    longitude: location.long
                }
            },{
                key: 'address',
                title: 'Address',
                value: Utils.formatAddress(location && location.place)
            },{
                key: 'maps',
                onClick: () => {
                    let address = Utils.formatAddress(location && location.place);
                    window.open(`https://www.google.com/maps/place/${encodeURIComponent(address)}`);
                },
                title: 'Directions',
                value: 'Click to View'
            }]
        },{
            key: 'about',
            title: 'About this Support Request',
            items: [{
                key: 'date',
                title: 'Submitted',
                value: Utils.formatDate(request.date)
            },{
                key: 'dealership',
                onClick: request.targets.dealership ? onDealershipClick.bind(this, request.targets.dealership) : null,
                title: 'Dealership',
                value: request.targets.dealership && request.targets.dealership.name,
                visible: request.targets.dealership ? true : false
            },{
                key: 'id',
                title: 'ID',
                value: request.id
            },{
                key: 'name',
                title: 'Name',
                value: request.name
            },{
                color: request.status && request.status.color,
                key: 'status',
                title: 'Status',
                value: request.status && request.status.text
            },{
                key: 'tags',
                title: 'Tags',
                value: request.tags ? Utils.oxfordImplode(request.tags) : null
            },{
                key: 'user',
                onClick: request.targets.user ? onUserClick.bind(this, request.targets.user) : null,
                title: 'User',
                value: request.targets.user && request.targets.user.full_name,
                visible: request.targets.user ? true : false
            }]
        }];

        // loop through map and prepare landing sections
        return items.concat(request.map.map(entry => {
            return {
                key: entry.id,
                lastItem: false,
                title: entry.name,
                items: Object.keys(entry.values).map(key => {

                    // prepare default line item entry
                    let value = request.values[key];
                    let obj = {
                        key: key,
                        title: entry.values[key] || `Untitled Input "${key}"`,
                        value: value
                    }

                    // determine if value warrants any additional formatting
                    switch(request.types[key]) {
                        case Support.Workflow.Landing.components.get().address_lookup:
                        return {
                            ...obj,
                            onClick: value ? onAddressClick.bind(this, value) : null,
                            value: value && Utils.formatAddress(value)
                        }

                        case Support.Workflow.Landing.components.get().date_picker:
                        return {
                            ...obj,
                            value: value && moment(value).format('MMMM Do, YYYY')
                        }

                        case Support.Workflow.Landing.components.get().division_lookup:
                        case Support.Workflow.Landing.components.get().region_lookup:
                        return {
                            ...obj,
                            onClick: value ? onSectorClick.bind(this, value) : null,
                            value: value && value.name
                        }

                        case Support.Workflow.Landing.components.get().email_address_field:
                        return {
                            ...obj,
                            onClick: value ? onEmailAddressClick.bind(this, value) : null
                        }

                        case Support.Workflow.Landing.components.get().file_picker:
                        case Support.Workflow.Landing.components.get().image_picker:
                        return {
                            ...obj,
                            onClick: value ? onFileClick.bind(this, value) : null,
                            value: value ? (value.name || value.file_name) : null
                        }

                        case Support.Workflow.Landing.components.get().phone_number_field:
                        return {
                            ...obj,
                            onClick: value ? onPhoneNumberClick.bind(this, value) : null,
                            value: value && Utils.formatPhoneNumber(value)
                        }

                        case Support.Workflow.Landing.components.get().comm_link_serial_number_field:
                        return {
                            ...obj,
                            onClick: value ? onCommLinkSerialNumberClick.bind(this, value) : null
                        }

                        default:
                        return obj;
                    }
                })
            }
        }));
    }

    const fetchDetails = async () => {
        try {
            setLoading(true);
            let { request } = await Request.get(utils, '/support/', {
                id: abstract.getID(),
                type: 'request_details'
            });

            setLoading(false);
            abstract.object = Support.Request.create(request);
            setRequest(abstract.object);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this support request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchResponses = async () => {
        try {
            let { paging, responses } = await Request.get(utils, '/support/', {
                id: abstract.getID(),
                limit: limit,
                offset: offset.current,
                type: 'request_responses'
            });

            setLoading(false);
            setPaging(paging);
            setResponses(responses.map(response => Support.Request.Response.create(response)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this support request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {

        fetchDetails();
        fetchResponses();

        utils.content.subscribe(layerID, ['support_workflows', 'support_requests', 'support_request_responses'], {
            onFetch: type => {
                if(type === 'support_request_responses') {
                    offset.current = 0;
                    fetchResponses();
                }
            },
            onUpdate: next => {
                setRequest(next.compare(abstract, fetchDetails));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        utils={utils}
        title={`Details for ${abstract.getTitle()}`}
        buttons={getButtons()}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <FieldMapper
            fields={getTopFields()} 
            utils={utils}/>
            {getResponses()}
        </Layer>
    )
}

export const SupportRequestEvents = ({ abstract, index, options, utils }) => {

    const layerID = `support_request_events_${abstract.getID()}`;
    const limit = 10;

    const [events, setEvents] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    
    const getEvents = () => {
        if(loading === true) {
            return (
                <div style={{
                    padding: 12
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        if(events.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'There are no available events to view for this support request',
                    title: 'No Events Found'
                })
            )
        }
        return (
            <>
            {events.map((evt, index) => {
                return (
                    Views.entry({
                        bottomBorder: paging ? true : (index !== events.length - 1),
                        icon: { path: evt.user && evt.user.avatar || 'images/user-placeholder.png' },
                        key: index,
                        subTitle: evt.message,
                        supportingTitle: Utils.formatDate(evt.date),
                        title: evt.user ? `${evt.user.full_name}: ${evt.title}` : evt.title
                    })
                )
            })}
            {paging && (
                <PageControl
                data={paging}
                limit={limit}
                loading={loading === 'paging'}
                offset={offset}
                onClick={next => {
                    setLoading('paging');
                    setOffset(next);
                }}/>
            )}
            </>
        )
    }

    const fetchEvents = async () => {
        try {
            let { events, paging } = await Request.get(utils, '/support/', {
                id: abstract.getID(),
                limit: limit,
                offset: offset,
                type: 'request_events'
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => ({
                ...evt,
                date: moment.utc(evt.date).local()
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the events for this support request. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchEvents();
    }, [offset]);

    return (
        <Layer
        id={layerID}
        index={index}
        title={`Events for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
               {getEvents()} 
            </div>
            
        </Layer>
    )
}

export const SupportWorkflows = ({ index, options, utils }) => {

    const panelID = 'supoort_sessions';
    const limit = 10;

    const [loading, setLoading, loadingProps] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [sessions, setWorkflows] = useState([]);

    const onWorkflowClick = session => {
        utils.layer.open({
            id: `support_workflow_details_${session.id}`,
            abstract: Abstract.create({
                type: 'support_workflows',
                object: session
            }),
            Component: SupportWorkflowDetails
        });
    }

    const onNewWorkflowClick = () => {
        let session = Support.Workflow.new();
        utils.layer.open({
            id: 'new_support_workflow',
            abstract: Abstract.create({
                type: 'support_workflows',
                object: session
            }),
            Component: AddEditSupportWorkflow.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getButtons = () => {
        return [{
            key: 'new',
            title: 'New Support Workflow',
            style: 'default',
            onClick: onNewWorkflowClick
        }]
    }

    const getContent = () => {
        if(sessions.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'There were no support sessions found in the system',
                    title: 'No Support Workflows Found',
                })
            )
        }
        return sessions.map((session, index) => {
            return (
                Views.entry({
                    ...session.getDescription(),
                    bottomBorder: index !== sessions.length - 1,
                    icon: { path: 'images/support-workflow-grey.png' },
                    key: index,
                    onClick: onWorkflowClick.bind(this, session)
                })
            )
        })
    }

    const fetchWorkflows = async () => {
        try {
            let { sessions, paging } = await Request.get(utils, '/support/', {
                type: 'all_workflows',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setWorkflows(sessions.map(session => Support.Workflow.create(session)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the support sessions list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setLoading(true, loading !== loadingProps.initVal);
        fetchWorkflows();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, ['support_workflows'], {
            onFetch: fetchWorkflows,
            onUpdate: abstract => {
                setWorkflows(sessions => sessions.map(session => {
                    return abstract.compare(session);
                }));
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        id={panelID}
        index={index}
        name={'Support Workflows'}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading === true,
            removePadding: true,
            search: {
                onChange: onSearchTextChange,
                placeholder: 'Search by id or name...'
            },
            paging: paging && {
                data: paging,
                limit: limit,
                onClick: next => setManager('offset', next),
                offset: manager.offset
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const SupportWorkflowLanding = ({ data, isConnectable }) => {

    const {
        abstract,
        hover = false,
        onItemClick
    } = data;

    const containerRef = useRef(null);
    const clickWatch = useRef(true);
    const size = 12;

    const onMouseDown = evt => {
        if(evt.target.className && shouldIgnoreMouseAction(evt.target.className) === true) {
            return;
        }
        clickWatch.current = new Date().getTime();
    }

    const onMouseUp = evt => {
        if(evt.target.className && shouldIgnoreMouseAction(evt.target.className) === true) {
            return;
        }
        let duration = new Date().getTime() - clickWatch.current;
        if(duration > 200) {
            return;
        }
        if(typeof(onItemClick) === 'function') {
            onItemClick(abstract);
        }
    }

    const shouldIgnoreMouseAction = (string = '') => {
        return ['layer-item-arrow', 'text-button', 'warning-icon'].find(key => string.includes(key)) ? true : false;
    }

    return (
        <div style={{
            position: 'relative'
        }}>
            <div
            ref={containerRef}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            style={{
                ...Appearance.styles.unstyledPanel(),
                border: `1px solid ${Appearance.colors.softBorder()}`,
                display: 'flex',
                flexDirection: 'column',
                height: nodeLandingHeight,
                justifyContent: 'center',
                width: nodeWidth,
                ...hover === true && {
                    backgroundColor: Appearance.colors.softBorder()
                }
            }}>
                {Views.entry({
                    ...abstract.object.getDescription(),
                    badge: null,
                    bottomBorder: false,
                    onClick: () => {
                        // click for node is handled up stream
                        // callback is here to force the view entry object to style as if this was the actual click callback
                    }
                })}
            </div>
            {abstract.object.root === true && (
                <img
                src={window.theme === 'dark' ? 'images/node-entry-point-white.png' : 'images/node-entry-point-grey.png'}
                style={{
                    position: 'absolute',
                    top: -46,
                    left: 'calc(50% - 15px)',
                    width: 30,
                    height: 30,
                    objectFit: 'contain'
                }} />
            )}
            {abstract.object.root === false && (
                <Handle
                id={'top'}
                isConnectable={false}
                position={'top'}
                type={'target'}
                style={{
                    background: Appearance.colors.primary(),
                    height: size,
                    width: size
                }} />
            )}
            <Handle
            id={'bottom'}
            isConnectable={isConnectable}
            position={'bottom'}
            type={'source'}
            style={{
                background: Appearance.colors.divider(),
                height: 0,
                width: 0
            }}/>
        </div>
    )
}

export const SupportWorkflowLandingButton = ({ data, isConnectable }) => {

    const {
        button,
        hover = false
    } = data;

    const size = 12;

    return (
        <div style={{
            ...Appearance.styles.unstyledPanel(),
            alignItems: 'center',
            border: `1px solid ${Appearance.colors.softBorder()}`,
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            height: nodeLandingButtonHeight,
            overflow: 'visible',
            padding: '8px 12px 8px 12px',
            width: nodeWidth,
            ...hover === true && {
                backgroundColor: Appearance.colors.softBorder()
            }
        }}>
            <span style={{
                ...Appearance.textStyles.title()
            }}>{`${button.text} Button`}</span>
            <Handle
            id={'action'}
            isConnectable={isConnectable}
            position={'right'}
            type={'source'}
            style={{
                background: button.color,
                height: size,
                top: 2,
                width: size
            }}/>
        </div>
    )
}

export const SupportWorkflowEdge = props => {

    const {
        arrowHeadType,
        data,
        id,
        markerEndId,
        sourcePosition,
        sourceX,
        sourceY,
        style = {},
        targetPosition,
        targetX,
        targetY
    } = props;

    const size = 30;
    const { onRemoveEdge } = data || {};
    const edgePath = getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

    return (
        <>
        <path
        className={`react-flow__edge-path`}
        d={edgePath}
        id={id}
        markerEnd={markerEnd}
        style={{
            ...style,
            color: Appearance.colors.softBorder(),
            strokeWidth: 3
        }} />
        <foreignObject
        className={'cursor-pointer edgebutton-foreignobject'}
        height={size}
        requiredExtensions={'http://www.w3.org/1999/xhtml'}
        width={size}
        x={targetX - (size / 2)}
        y={sourceY - (size / 2)}>
            <div
            onClick={onRemoveEdge.bind(this, props)}
            style={{
                alignItems: 'center',
                background: Appearance.colors.panelBackground(),
                borderRadius: '50%',
                display: 'flex',
                height: size,
                justifyContent: 'center',
                padding: 5,
                overflow: 'hidden',
                width: size
            }}>
                <img
                src={'images/delete-node-edge.png'}
                style={{
                    height: '100%',
                    objectFit: 'contain',
                    width: '100%'
                }} />
            </div>
        </foreignObject>
        </>
    )
}

export const SupportWorkflowRule = ({ data }) => {

    const {
        abstract,
        collapsed = false,
        hover = false,
        onItemClick
    } = data;

    const clickWatch = useRef(true);
    const containerRef = useRef(null);
    const [collapsedVal, setCollapsedVal] = useState(collapsed);

    const size = 12;
    const { else_content, next_content } = abstract.object || {};

    const onArrowClick = val => {
        setCollapsedVal(val);
    }

    const onMouseDown = evt => {
        if(evt.target.className && shouldIgnoreMouseAction(evt.target.className) === true) {
            return;
        }
        clickWatch.current = new Date().getTime();
    }

    const onMouseUp = evt => {
        if(evt.target.className && shouldIgnoreMouseAction(evt.target.className) === true) {
            return;
        }
        let duration = new Date().getTime() - clickWatch.current;
        if(duration > 200) {
            return;
        }
        if(typeof(onItemClick) === 'function') {
            onItemClick(abstract);
        }
    }

    const getConditionalContentOverview = () => {
        if(collapsedVal === true) {
            return null;
        }
        return (
            <>
            {else_content && (
                <div
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    borderTop: `1px solid ${Appearance.colors.softBorder()}`,
                    marginTop: 4,
                    width: nodeWidth
                }}>
                    {Support.Workflow.Rule.format.content('else', abstract.object, {
                        clickHandler: false
                    })}
                </div>
            )}
            {next_content && (
                <div
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    borderTop: `1px solid ${Appearance.colors.softBorder()}`,
                    marginTop: 4,
                    width: nodeWidth
                }}>
                    {Support.Workflow.Rule.format.content('next', abstract.object, {
                        clickHandler: false
                    })}
                </div>
            )}
            </>
        )
    }

    const hasConditionalContent = () => {
        return abstract.object.else_content || abstract.object.next_content ? true : false;
    }

    const shouldIgnoreMouseAction = (string = '') => {
        return ['layer-item-arrow', 'text-button', 'warning-icon'].find(key => string.includes(key)) ? true : false;
    }

    useEffect(() => {
        setCollapsedVal(collapsed);
    }, [collapsed]);

    return (
        <div style={{
            position: 'relative'
        }}>
            <div
            ref={containerRef}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            style={{
                ...Appearance.styles.unstyledPanel(),
                border: `1px solid ${Appearance.colors.softBorder()}`,
                height: nodeRuleHeight,
                width: nodeWidth,
                ...hover === true && {
                    backgroundColor: Appearance.colors.primary()
                }
            }}>
                {abstract.object.root === true && (
                    <img
                    src={window.theme === 'dark' ? 'images/node-entry-point-white.png' : 'images/node-entry-point-grey.png'}
                    style={{
                        height: 30,
                        left: 'calc(50% - 15px)',
                        objectFit: 'contain',
                        position: 'absolute',
                        top: -46,
                        width: 30
                    }} />
                )}
                <Handle
                id={'top'}
                isConnectable={false}
                position={'top'}
                type={'target'}
                style={{
                    background: Appearance.colors.primary(),
                    height: size,
                    width: size
                }} />
                {Views.entry({
                    ...abstract.object.getDescription(),
                    badge: null,
                    bottomBorder: false,
                    onClick: () => {
                        // click for node is handled up stream
                        // callback is here to force the view entry object to style as if this was the actual click callback
                    },
                    rightContent: hasConditionalContent() === true && (
                        <CollapseArrow
                        className={'layer-item-arrow'}
                        collapsed={collapsedVal}
                        onClick={onArrowClick}
                        style={{
                            marginLeft: 8
                        }}/>
                    )
                })}
            </div>
            {getConditionalContentOverview()}
        </div>
    )
}

export const SupportWorkflowRuleRoute = ({ data, isConnectable }) => {

    const { route } = data;
    const size = 12;

    const getActionHandle = () => {
        if(route && route.action && route.action.type === Support.Workflow.Action.types.get().redirect_to_public_web_page) {
            return null;
        }
        return (
            <Handle
            id={'action'}
            isConnectable={isConnectable}
            position={'right'}
            type={'source'}
            style={{
                background: getRouteColor(),
                height: size,
                top: 2,
                width: size
            }}/>
        )
    }

    const getRouteColor = () => {
        let { type } = route.action || {};
        switch(type) {
            case Support.Workflow.Action.types.get().redirect_to_workflow_landing:
            return Appearance.colors.primary();

            case Support.Workflow.Action.types.get().redirect_to_workflow_rule:
            return Appearance.colors.secondary();

            case Support.Workflow.Action.types.get().download_local_file:
            return Appearance.colors.tertiary();

            default:
            return Appearance.colors.grey();
        }
    }

    return (
        <div style={{
            ...Appearance.styles.unstyledPanel(),
            alignItems: 'center',
            border: `1px solid ${Appearance.colors.softBorder()}`,
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            height: nodeRuleRouteHeight,
            overflow: 'visible',
            width: nodeWidth
        }}>
            {Views.entry({
                bottomBorder: false,
                hideIcon: true,
                subTitle: Support.Workflow.Rule.Route.overview.get(route),
                title: route.name || 'Untitled Route'
            })}
            {getActionHandle()}
        </div>
    )
}

export const SupportWorkflowStaticEdge = props => {

    const {
        arrowHeadType,
        id,
        markerEndId,
        sourcePosition,
        sourceX,
        sourceY,
        style = {},
        targetPosition,
        targetX,
        targetY
    } = props;

    const edgePath = getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

    return (
        <path
        className={`react-flow__edge-path`}
        d={edgePath}
        id={id}
        markerEnd={markerEnd}
        style={{
            ...style,
            strokeWidth: 3
        }} />
    )
}

export const SupportWorkflowRuleDetails = ({ abstract, index, options, utils }) => {

    const layerID = `support_workflow_rule_details_${abstract.getID()}`;

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading, initVal] = useLoading();
    const [rule, setRule] = useState(null);

    const onDeleteRule = () => {
        utils.alert.show({
            title: 'Delete Tag',
            message: 'Are you sure that you want to delete this workflow rule? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteRuleConfirm();
                    return;
                }
            }
        });
    }

    const onDeleteRuleConfirm = async () => {
        try {
            setLoading('options');
            await Utils.sleep(1);

            await Request.post(utils, '/support/',{
                type: 'delete_rule',
                id: abstract.getID()
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This workflow rule has been deleted`,
                onClick: () => {
                    utils.content.fetch('support_workflow_rules');
                    setLayerState('close');
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this workflow rule. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_support_workflow_rule_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditSupportWorkflowRule.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            switch(key) {
                case 'delete':
                onDeleteRule();
                break;
            }
        });
    }

    const getButtons = () => {
        return [{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]
    }

    const getFields = () => {

        // prevent moving forward if rule state has not been set
        if(!rule) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: rule.id
            },{
                key: 'date',
                title: 'Created',
                value: Utils.formatDate(rule.date)
            },{
                key: 'name',
                title: 'Name',
                value: rule.name
            }]
        }];
    }

    const getRoutes = () => {
        if(loading === true) {
            return (
                <LayerItem 
                title={'Routes'}
                style={{
                    marginTop: LayerItemSpacing
                }}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        padding: 12
                    }}>
                        <LottieView
                        autoPlay={true}
                        loop={true}
                        source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                        style={{
                            height: 40,
                            width: 40
                        }}/>
                    </div>
                </LayerItem>
            )
        }
        return rule && (
            <LayerItem 
            title={'Routes'}
            style={{
                marginTop: LayerItemSpacing
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {rule.routes.map((route, index, routes) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== routes.length - 1,
                                hideIcon: true,
                                key: index,
                                subTitle: Support.Workflow.Rule.Route.overview.get(route),
                                title: route.name || 'Untitled Route'
                            })
                        )
                    })}
                    {rule.routes.length === 0 && (
                        Views.entry({
                            bottomBorder: false,
                            hideIcon: true,
                            subTitle: 'Edit this rule to configure a route',
                            title: 'No Routes Configured'
                        })
                    )}
                </div>
            </LayerItem>
        )
    }

    const getTasks = () => {
        if(loading === true) {
            return (
                <LayerItem 
                title={'Tasks'}
                style={{
                    marginTop: LayerItemSpacing
                }}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        padding: 12
                    }}>
                        <LottieView
                        autoPlay={true}
                        loop={true}
                        source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                        style={{
                            height: 40,
                            width: 40
                        }}/>
                    </div>
                </LayerItem>
            )
        }
        return rule && (
            <LayerItem 
            title={'Tasks'}
            style={{
                marginTop: LayerItemSpacing
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {rule.tasks.map((task, index, tasks) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== tasks.length - 1,
                                hideIcon: true,
                                key: index,
                                subTitle: Support.Workflow.Rule.Task.overview.get(task),
                                title: task.name || 'Untitled Task'
                            })
                        )
                    })}
                    {rule.tasks.length === 0 && (
                        Views.entry({
                            bottomBorder: false,
                            hideIcon: true,
                            subTitle: 'Edit this rule to configure a task',
                            title: 'No Tasks Configured'
                        })
                    )}
                </div>
            </LayerItem>
        )
    }

    const fetchDetails = async () => {
        try {

            // fetch details for rule from server
            setLoading(true);
            let { routes, tasks } = await Request.get(utils, '/support/', {
                type: 'workflow_rule_details',
                id: abstract.getID()
            });

            // update workflow object for parent
            setLoading(false);
            abstract.object.routes = routes.map(route => Support.Workflow.Rule.Route.create(route));
            abstract.object.tasks = tasks.map(task => Support.Workflow.Rule.Task.create(task));
            setRule(abstract.object);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the overview for this workflow rule. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchDetails();
        utils.content.subscribe(layerID, ['support_workflow_rules'], {
            onUpdate: next => {
                setRule(next.compare(abstract, fetchDetails));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <FieldMapper
            fields={getFields()} 
            utils={utils}/>

            {getRoutes()}
            {getTasks()}
        </Layer>
    )
}

export const SupportWorkflowDetails = ({ abstract, index, options, utils }) => {

    const layerID = `support_workflow_details_${abstract.getID()}`;
    const limit = 5;

    const [landings, setLandings] = useState([]);
    const [landingsPaging, setLandingsPaging] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading, loadingProps] = useLoading();
    const [manager, setManager] = useResultsManager({ actions_offset: 0, landings_offset: 0, rules_offset: 0 });
    const [rules, setRules] = useState([]);
    const [rulesPaging, setRulesPaging] = useState(null);
    const [session, setWorkflow] = useState(abstract.object);

    const onLandingClick = landing => {
        utils.layer.open({
            id: `edit_workflow_landing_${landing.id}`,
            abstract: Abstract.create({
                type: 'support_workflow_landings',
                object: landing
            }),
            Component: AddEditSupportWorkflowLanding.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onDeleteSupportWorkflow = () => {
        utils.alert.show({
            title: 'Delete Support Workflow',
            message: 'Are you sure that you want to delete this support session? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteSupportWorkflowConfirm();
                    return;
                }
            }
        });
    }

    const onDeleteSupportWorkflowConfirm = async () => {
        try {
            setLoading('options');
            await Utils.sleep(1);

            await Request.post(utils, '/support/',{
                type: 'delete_support_workflow',
                id: abstract.getID()
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This support session has been deleted`,
                onClick: () => utils.content.fetch('support_workflows')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this support session. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_support_workflow_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditSupportWorkflow.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            switch(key) {
                case 'delete':
                onDeleteSupportWorkflow();
                break;
            }
        });
    }

    const onRuleClick = rule => {
        utils.layer.open({
            id: `support_workflow_rule_details_${rule.id}`,
            abstract: Abstract.create({
                type: 'support_workflow_rules',
                object: rule
            }),
            Component: SupportWorkflowRuleDetails
        });
    }

    const getBackgroundColor = () => {

        let color = session.options.display && session.options.display.background_color || null;
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%'
            }}>
                <span>{color || 'Default'}</span>
                <div style={{
                    backgroundColor: color || Appearance.colors.background(),
                    border: `1px solid ${Appearance.colors.divider()}`,
                    borderRadius: 9,
                    height: 18,
                    marginLeft: 8,
                    minWidth: 15,
                    width: 18
                }} />
            </div>
        )
    }

    const getButtons = () => {
        return [{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]
    }

    const getFields = () => {
        if(!session) {
            return [];
        }
        return [{
            key: 'about',
            title: 'About this Support Workflow',
            items: [{
                key: 'id',
                title: 'ID',
                value: session.id
            },{
                key: 'date',
                title: 'Created',
                value: Utils.formatDate(session.date)
            },{
                key: 'name',
                title: 'Name',
                value: session.name
            },{
                key: 'url',
                onClick: window.open.bind(this, session.url),
                title: 'URL',
                value: session.url
            }]
        },{
            key: 'options',
            title: 'Options',
            items: [{
                key: 'redirection_delay',
                title: 'Redirection Delay',
                value: session.options.redirection_delay ? 'Yes' : 'No'
            },{
                key: 'request_location',
                title: 'Request User Location',
                value: session.options.request_location ? 'Yes' : 'No'
            }]
        },{
            key: 'options.display',
            title: 'Display',
            items: [{
                key: 'background_color',
                title: 'Background Color',
                value: getBackgroundColor()
            },{
                key: 'embedded',
                title: 'Embedded',
                value: session.options.display && session.options.display.embedded ? 'Yes' : 'No'
            },{
                key: 'embedded_max_width',
                title: 'Maximum Width',
                value: session.options.display && session.options.display.embedded_max_width,
                visible: session.options.display && session.options.display.embedded || false
            },{
                key: 'embedded_min_width',
                title: 'Minimum Width',
                value: session.options.display && session.options.display.embedded_min_width,
                visible: session.options.display && session.options.display.embedded || false
            }]
        }];
    }

    const getItems = () => {
        return (
            <>
            <LayerItem
            title={'Landings'}
            lastItem={true}
            style={{
                marginTop: 25
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {landings.length === 0 && (
                        Views.entry({
                            bottomBorder: false,
                            hideIcon: true,
                            subTitle: 'There were no landings found in this support session',
                            title: 'No Landings Found'
                        })
                    )}
                    {landings.length > 0 && landings.map((landing, index) => {
                        return Views.entry({
                            ...landing.getDescription(),
                            bottomBorder: index !== landings.length - 1,
                            key: index,
                            onClick: onLandingClick.bind(this, landing)
                        })
                    })}
                    {landingsPaging && (
                        <PageControl
                        data={landingsPaging}
                        limit={limit}
                        offset={manager.landings_offset}
                        onClick={next => setManager('landings_offset', next)} />
                    )}
                </div>
            </LayerItem>

            <LayerItem
            title={'Rules'}
            lastItem={true}
            style={{
                marginTop: 25
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {rules.length === 0 && (
                        Views.entry({
                            bottomBorder: false,
                            hideIcon: true,
                            subTitle: 'There were no rules found in this support session',
                            title: 'No Rules Found'
                        })
                    )}
                    {rules.length > 0 && rules.map((rule, index) => {
                        return Views.entry({
                            ...rule.getDescription(),
                            bottomBorder: index !== rules.length - 1,
                            key: index,
                            onClick: onRuleClick.bind(this, rule)
                        })
                    })}
                    {rulesPaging && (
                        <PageControl
                        data={rulesPaging}
                        limit={limit}
                        offset={manager.rules_offset}
                        onClick={next => setManager('rules_offset', next)} />
                    )}
                </div>
            </LayerItem>
            </>
        )
    }

    const fetchItems = async () => {
        try {
            let { landings, landings_paging, rules_paging, rules } = await Request.get(utils, '/support/', {
                type: 'workflow_items',
                id: abstract.getID(),
                limit: limit,
                ...manager
            });

            setLoading(false);
            setLandingsPaging(landings_paging);
            setRulesPaging(rules_paging);

            setLandings(landings.map(landing => Support.Workflow.Landing.create(landing)));
            setRules(rules.map(rule => Support.Workflow.Rule.create(rule)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the session items for this support session. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== loadingProps.initVal) {
            setLoading(true);
        }
        setTimeout(fetchItems, loading === loadingProps.initVal ? 0 : 250);
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(layerID, ['support_workflows', 'support_workflow_landings', 'support_workflow_rules'], {
            onUpdate: next => {
                setWorkflow(next.compare(abstract, fetchItems));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        utils={utils}
        title={`Details for ${abstract.getTitle()}`}
        buttons={getButtons()}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <FieldMapper
            fields={getFields()} 
            utils={utils}/>

            {getItems()}
        </Layer>
    )
}

export const Text = props => {

    const { color, darkModeColor, fontSize, fontWeight, margin, onChange, padding, textAlign, value, width } = props;

    const [editable, setEditable] = useState(false);

    const { actions: { setProp }, connectors: { connect, drag }, selected } = useNode(state => ({ selected: state.events.selected }));
    const { enabled } = useEditor(state => ({ enabled: state.options.enabled }));

    const onTextChange = evt => {
        let value = evt.target.value.replace(/<\/?[^>]+(>|$)/g, "");
        setProp(props => props.value = value);
        if(typeof(onChange) === 'function') {
            onChange(value);
        }
    }

    useEffect(() => {
        !selected && setEditable(false);
    }, [selected]);

    return (
        <div
        ref={ref => connect(drag(ref))}
        onClick={() => setEditable(true)}
        style={{
            width: width,
            ...getSelectionStyles(selected, props),
            ...formatEditorMargin(margin),
            ...formatEditorPadding(padding),
            ...enabled && {
                cursor: editable ? 'text' : 'pointer',
            },
        }}>
            <ContentEditable
            disabled={editable && enabled ? false : true}
            html={value}
            onChange={onTextChange}
            tagName={'p'}
            style={{
                color: window.theme === 'dark' && darkModeColor ? darkModeColor : color,
                fontSize: fontSize,
                fontWeight: fontWeight,
                margin: 0,
                padding: 0,
                textAlign: textAlign
            }}/>
        </div>
    )
}

const TextSettings = () => {

    const { request, utils } = React.useContext(ApplicationContext) || {};
    const { color, darkModeColor, fontWeight, fontSize, height, margin, padding, textAlign, textOverflow, width, whiteSpace, actions: { setProp } } = useNode(node => ({ ...node.data.props }));

    const onSupportRequestItemChange = item => {
        Object.keys(request.values).forEach(key => {
            if(item && item.id === key) {
                switch(request.types[key]) {
                    case Support.Workflow.Landing.components.get().address_lookup:
                    setProp(props => props.value = Utils.formatAddress(request.values[key]));
                    break;

                    case Support.Workflow.Landing.components.get().date_picker:
                    setProp(props => props.value = Utils.formatDate(request.values[key]));
                    break;

                    case Support.Workflow.Landing.components.get().division_lookup:
                    case Support.Workflow.Landing.components.get().region_lookup:
                    setProp(props => props.value = request.values[key] && request.values[key].name);
                    break;

                    case Support.Workflow.Landing.components.get().phone_number_field:
                    setProp(props => props.value = Utils.formatPhoneNumber(request.values[key]));
                    break;

                    default:
                    if(request.values[key]) {
                        setProp(props => props.value = request.values[key].toString());
                    }
                }
            }
        })
    }

    const getFields = () => {
        return [{
            key: 'autofill.request',
            title: 'Support Request Details',
            visible: request ? true : false,
            items: [{
                component: 'list',
                items: getSupportRequestItems(),
                key: 'autofill',
                onChange: onSupportRequestItemChange,
                required: false,
                title: 'Autofill Content'
            }]
        },{
            key: 'alignment',
            title: 'Alignment',
            items: [
                getEditorItem(utils, 'textAlign', textAlign, setProp)
            ]
        },{
            key: 'formatting',
            title: 'Formatting',
            items: [
                getEditorItem({ ...utils }, 'color', color, setProp),
                getEditorItem({ ...utils }, 'darkModeColor', darkModeColor, setProp),
                getEditorItem(utils, 'fontSize', fontSize, setProp),
                getEditorItem(utils, 'fontWeight', fontWeight, setProp),
                getEditorItem(utils, 'textOverflow', textAlign, setProp),
                getEditorItem(utils, 'whiteSpace', whiteSpace, setProp)
            ]
        },{
            key: 'spacing',
            title: 'Spacing',
            items: [
                getEditorItem(utils, 'margin', margin, setProp),
                getEditorItem(utils, 'padding', padding, setProp),
            ]
        },{
            key: 'size',
            title: 'Size',
            items: [
                getEditorItem(utils, 'height', height, setProp),
                getEditorItem(utils, 'width', width, setProp)
            ]
        }].filter(section => {
            return section.visible !== false;
        });
    }

    const getSupportRequestItems = () => {

        /*
        We have identified that DNSFilter, the company that provides the backend filtering process for Eero devices, has been blocking the api that the Comm Link uses to communicate with the server.
        */

        // prepare a list of items with the landing name and the session variable name
        return request && request.map.reduce((array, entry) => {
            Object.keys(entry.values).forEach(key => {
                array.push({
                    id: key,
                    title: `${entry.name}: ${entry.values[key]}`
                });
            });
            return array;
        }, []).sort((a,b) => {
            return a.title.localeCompare(b.title);
        });
    }

    return (
        <AltFieldMapper
        utils={utils}
        fields={getFields()} />
    )
}

Text.craft = {
    props: {
        color: Appearance.colors.text(),
        darkModeColor: '#FFFFFF',
        fontSize: 12,
        fontWeight: 600,
        textAlign: 'left',
        textOverflow: 'ellipsis',
        value: 'Text goes here...',
        whiteSpace: 'normal',
        width: 'auto'
    },
    related: {
        settings: TextSettings
    }
}

export const Video = props => {

    const { utils } = React.useContext(ApplicationContext) || {};
    const { connectors: { connect, drag }, selected } = useNode(state => ({ selected: state.events.selected }));
    const { enabled } = useEditor(state => ({ enabled: state.options.enabled }));
    
    const getContent = () => {

        let { autoPlay, borderColor, borderRadius, borderWidth, controls, height, loop, margin, maxHeight, maxWidth, minHeight, minWidth, muted, objectFit, opacity, overflow, position, videoAsset, width } = props;

        if(videoAsset) {
            let { url } = utils.support.assets.get().find(a => a.id === videoAsset) || {};
            return (
                <animated.div
                ref={ref => connect(drag(ref))}
                className={enabled ? 'cursor-pointer' : ''}
                style={{
                    borderColor: borderColor || Appearance.colors.transparent,
                    borderRadius: borderRadius || 8,
                    height: height,
                    maxHeight: maxHeight,
                    minHeight: minHeight,
                    maxWidth: maxWidth,
                    minWidth: minWidth,
                    overflow: 'hidden',
                    position: position,
                    width: width,
                    ...formatEditorMargin(margin),
                    ...getSelectionStyles(selected, props)
                }}>
                    <video
                    autoPlay={autoPlay}
                    controls={controls}
                    key={url}
                    loop={loop}
                    muted={muted}
                    style={{
                        height: '100%',
                        objectFit: objectFit,
                        width: '100%'
                    }}>
                        <source src={url} />
                    </video>
                </animated.div>
            )
        }
        return (
            <animated.div
            className={enabled ? 'text-button' : ''}
            ref={ref => connect(drag(ref))}
            style={{
                alignItems: 'center',
                backgroundColor: '#EBECF0',
                borderColor: borderColor || Appearance.colors.transparent,
                borderStyle: 'solid',
                borderWidth: borderWidth || 1,
                borderRadius: borderRadius,
                display: 'flex',
                flexDirection: 'row',
                height: height,
                justifyContent: 'center',
                maxHeight: maxHeight,
                minHeight: minHeight,
                maxWidth: maxWidth,
                minWidth: minWidth,
                opacity: opacity,
                width: width,
                ...formatEditorMargin(margin),
                ...getSelectionStyles(selected, props)
            }}>
                <img
                src={`images/editor-icon-video.png`}
                style={{
                    height: 50,
                    objectFit: 'contain',
                    width: 50
                }}/>
            </animated.div>
        )
    }

    return getContent();
}

const VideoSettings = () => {

    const { utils } = React.useContext(ApplicationContext) || {};
    const { actions: { setProp }, node } = useNode(node => ({
        node: node,
        ...node.data.props
    }));

    const getFields = (props, setProp) => {

        let { autoPlay, borderColor, borderRadius, borderWidth, controls, height, loop, margin, muted, objectFit, opacity, overflow, position, videoAsset, width } = props;
        return [{
            key: 'config',
            title: 'Configuration',
            items: [
                getEditorItem(utils, 'autoPlay', autoPlay, setProp),
                getEditorItem(utils, 'controls', controls, setProp),
                getEditorItem(utils, 'loop', loop, setProp),
                getEditorItem(utils, 'muted', muted, setProp),
                getEditorItem(utils, 'videoAsset', videoAsset, setProp)
            ]
        },{
            key: 'formatting',
            title: 'Formatting',
            items: [
                getEditorItem(utils, 'borderColor', borderColor, setProp),
                getEditorItem(utils, 'borderRadius', borderRadius, setProp),
                getEditorItem(utils, 'borderWidth', borderWidth, setProp),
                getEditorItem(utils, 'objectFit', objectFit, setProp),
                getEditorItem(utils, 'opacity', opacity, setProp),
                getEditorItem(utils, 'overflow', overflow, setProp),
                getEditorItem(utils, 'position', position, setProp)
            ]
        },{
            key: 'spacing',
            title: 'Spacing',
            items: [
                getEditorItem(utils, 'margin', margin, setProp)
            ]
        },{
            key: 'size',
            title: 'Size',
            items: [
                getEditorItem(utils, 'height', height, setProp),
                getEditorItem(utils, 'width', width, setProp)
            ]
        }];
    }

    return (
        <AltFieldMapper
        utils={utils}
        fields={getFields(node.data.props, setProp)} />
    )
}

Video.craft = {
    displayName: 'Video',
    props: {
        autoPlay: false,
        borderRadius: 12,
        controls: true,
        height: 250,
        location: {},
        loop: false,
        muted: false,
        objectFit: 'contain',
        opacity: 1,
        position: 'relative',
        width: 375
    },
    related: {
        settings: VideoSettings
    }
}

const getContactContentPreview = ({ app, footer, generated, header, message, origin, response, type, utils }) => {

    const isReplicatedWebsiteOrigin = () => {
        return origin && origin.includes('replicated_website') === true;
    }

    const getEmailIcon = () => {

        // return replicated website logo for matching origins
        if(isReplicatedWebsiteOrigin() === true) {
            let logo = utils.dealership.get().preferences.replicated_website.logo;
            return logo && logo.url || 'images/support-response-logo.png';
        }

        return 'images/support-response-logo.png';
    }

    const getMobileAppIcon = () => {
        switch(app) {
            case 'aft':
            return 'images/aft-mobile-app-icon.jpg';
        
            case 'global_data':
            return 'images/global-data-mobile-app-icon.jpg';

            case 'omnishield':
            return 'images/omnishield-mobile-app-icon.jpg';

            default:
            return 'images/mobile-app-icon-placeholder.jpg';
        }
    }

    const getSenderName = () => {

        // return replicated website name for matching origins
        if(isReplicatedWebsiteOrigin() === true) {
            return utils.dealership.get().preferences.replicated_website.name;
        }

        return 'Applied Fire Technologies';
    }
  
    // prepare content flag and concatenated messages string
    let hasContent = generated || message ? true : false;
    let messages = [generated, message || 'Your custom message will appear here.', response, footer, `Sent by ${utils.user.get().full_name} with ${getSenderName()}`].filter(text => text).join(' ');

    // determine which type of preview components are required
    switch(type) {
        case 'email':
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                backgroundColor: Appearance.colors.background(),
                padding: 24,
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    padding: `16px 18px 16px 18px`
                }}>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        paddingRight: 4,
                        width: '100%'
                    }}>
                        <img
                        src={getEmailIcon()}
                        style={{
                            height: 25,
                            objectFit: 'contain',
                            width: 25
                        }} />
                    </div>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        fontWeight: 500,
                        marginBottom: 12
                    }}>{header}</span>
    
                    {hasContent === true && (
                        <>
                        {typeof(generated) === 'string' && (
                            <span style={{
                                color: Appearance.colors.text(),
                                fontSize: 12,
                                fontWeight: 500,
                                marginBottom: 12
                            }}>{generated}</span>
                        )}
                        <span style={{
                            color: message ? Appearance.colors.text() : Appearance.colors.subText(),
                            fontSize: 12,
                            fontWeight: 500,
                            marginBottom: 12,
                            whiteSpace: 'pre-line'
                        }}>{message || 'Your customer message appears here...'}</span>
                        {typeof(response) === 'string' && (
                            <span style={{
                                color: Appearance.colors.text(),
                                fontSize: 12,
                                fontWeight: 500,
                                marginBottom: 12
                            }}>{response}</span>
                        )}
                        </>
                    )}
    
                    {hasContent === false && (
                        <span style={{
                            color: Appearance.colors.subText(),
                            fontSize: 12,
                            fontWeight: 500,
                            marginBottom: 12
                        }}>{'Your customer message appears here...'}</span>
                    )}
                    
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        fontWeight: 500,
                        marginBottom: 12
                    }}>{footer}</span>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        fontWeight: 500,
                    }}>{`${utils.user.get().full_name},`}</span>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        fontWeight: 500,
                    }}>{getSenderName()}</span>
                </div>
            </div>
        )
        
        case 'push_notification':
        return (
            <div style={{
                alignItems: 'center',
                backgroundImage: 'url(images/push-notification-background-blur.jpg)',
                backgroundSize: 'cover',
                borderRadius: 15,
                display: 'flex',
                flexDirection: 'row',
                padding: '12px 16px 12px 16px',
                width: '100%'
            }}>
                <img 
                src={getMobileAppIcon()}
                style={{
                    borderRadius: 8,
                    height: 35,
                    marginRight: 12,
                    width: 35
                }} />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        fontWeight: 650
                    }}>{'New Support Message'}</span>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        fontWeight: 450,
                        lineHeight: 1.25
                    }}>{messages}</span>
                </div>
            </div>
        );

        case 'sms':
        return (
            <div style={{
                position: 'relative',
            }}>
                <img
                src={'images/imessage-tail-grey.png'}
                style={{
                    bottom: 0,
                    height: 12.5,
                    left: 0,
                    objectFit: 'contain',
                    position: 'absolute',
                    width: 31
                }} />
                <div style={{
                    alignItems: 'center',
                    backgroundColor: '#E9E9EB',
                    borderRadius: 20,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginLeft: 4,
                    padding: '8px 15px 8px 15px',
                    overflow: 'hidden'
                }}>
                    <span style={{
                        ...Appearance.colors.text(),
                        color: Appearance.colors.darkGrey,
                        whiteSpace: 'normal'
                    }}>
                        {`${header} ${messages}`}
                    </span>
                </div>
            </div>
        )

        default:
        return null;
    }
}

const getResponseLinks = (method, type, origin) => {
    switch(method) {
        case 'email':
        switch(type) {
            case 'additional_info':
            return `Please {{use_this_link}} to send us the information we've requested.`;

            case 'freeform':
            if(origin && origin.includes('replicated_website') === true) {
                return null;
            }
            return `Please {{use_this_link}} if you need to contact us.`;

            case 'issue_under_investigation':
            return 'Please {{use_this_link}} to share any additional information you may have for us.';

            case 'kb_article':
            return 'You can {{use_this_link}} to view the article that I sent you. Please {{use_this_link}} to let us know if this article helped resolve your issue.';

            case 'webpage':
            return 'Please {{use_this_link}} to let us know if this webpage helped resolve your issue.';

            default:
            return null;
        }
        
        case 'push_notification':
        return null;

        case 'sms':
        switch(type) {
            case 'additional_info':
            return `Please use the following link to send us the information we've requested. {{use_this_link}}.`;

            case 'freeform':
            return 'Please use the following link if you need to contact us. {{use_this_link}}.';

            case 'issue_under_investigation':
            return 'Please use the following link to share any additional information you may have for us. {{use_this_link}}.';

            case 'kb_article':
            return 'Use this link to view the article that I sent you. {{use_this_link}}. Please use the following link to let us know if this article helped resolve your issue. {{use_this_link}}.';

            case 'webpage':
            return 'Please use the following link to let us know if this webpage helped resolve your issue. {{use_this_link}}.';

            default:
            return null;
        }

        default:
        return null;
    }
}

const getResponseGeneratedMessage = type => {
    switch(type) {
        case 'additional_info':
        return 'We need more information before we can look into your issue. Please provide the following details so we can continue with your support request.';

        case 'issue_under_investigation':
        return 'We are aware of the issue that you have described and are actively working to find a resolution. We will reach out with next steps once we have completed the troubleshooting process.';

        case 'kb_article':
        return 'Attached below is a link to an article that should help answer your questions.';

        case 'webpage':
        return 'Attached below is a link to a webpage with some information that should help answer your questions.';

        default:
        return null;
    }
}

const getSelectionStyles = (selected, { borderColor, borderRadius, borderWidth }) => ({
    borderColor: borderColor || (selected ? Appearance.colors.softBorder() : Appearance.colors.transparent),
    borderRadius: borderRadius || 8,
    borderStyle: 'solid',
    borderWidth: borderWidth || 1
});