import React from 'react';

import Panel from 'structure/Panel.js';
import Payment from 'classes/Payment.js';
import Sector from 'classes/Sector.js';
import User from 'classes/User.js';

import { Cards, CardLocations } from 'managers/Cards.js';
import { CommLinks, CommLinkCommunicate, CommLinkReachabilityReport, ExpiringCOSensors, OmniShieldNetworkInstallationsReport, OmniShieldNetworkSensorAveragesReport, OmniShieldWhiteLabelApps, OmniShieldWhiteLabelChangeRequests, RapidlyUpdatingSensors, RegulatedSensors, Sensors, SensorsOverview } from 'managers/OmniShield.js';
import { Dealerships, DealershipLocations, GoogleReviews } from 'managers/Dealerships.js';
import { DealershipSubscriptions, Payments, PaymentMethods, Subscriptions } from 'managers/Payments.js';
import { FlipChartPresentations } from 'managers/FlipChart.js';
import { GlobalDataLeadPoolBreakdown, ReportPanel } from 'managers/Reports.js';
import { KBArticles, SupportRequests, SupportWorkflows } from 'managers/Support.js';
import { OmniShieldSensorEvents, OmniShieldSystemStatus } from 'managers/Health.js';
import { Products, ProductPerformance } from 'managers/Products.js';
import { SectorList, VestedDirectorsList } from 'managers/Sectors.js';
import { SystemBackups, SystemTasks } from 'managers/System.js';
import { Communications, NotificationsList, SystemEvents, Users, UserGroups, UserLocations } from 'managers/Users.js';

export const getContent = utils => {

    // declare current user account type
    let { level } = utils.user.get();
    
    // determine if current user is a specialty user
    // currently the only specialty user is an exigent administrator
    if(level === User.levels.get().exigent_admin) {
        return {
            dashboard: {
                key: 'dashboard',
                title: 'Dashboard',
                icon: 'navigation-icon-dashboard',
                panels: [{
                    key: 'omnishield_system_status',
                    title: 'System Status',
                    Component: OmniShieldSystemStatus
                },{
                    key: 'comm_links',
                    title: 'Comm Links',
                    Component: CommLinks
                },{
                    key: 'omnishield_events',
                    title: 'Sensor Events',
                    Component: OmniShieldSensorEvents
                }]
            },
            comm_links: {
                key: 'comm_links',
                title: 'Comm Links',
                icon: 'navigation-icon-comm-links',
                panels: [{
                    key: 'comm_link_reachability_report',
                    title: 'Comm Link Reachability Report',
                    Component: CommLinkReachabilityReport,
                },{
                    key: 'comm_links',
                    title: 'Comm Links',
                    Component: CommLinks
                },{
                    key: 'rapidly_updating_sensors',
                    title: 'Rapidly Updating Sensors',
                    Component: RapidlyUpdatingSensors
                }]
            },
            sensors: {
                key: 'sensors',
                title: 'Sensors',
                icon: 'navigation-icon-sensors',
                panels: [{
                    key: 'sensors',
                    title: 'Sensors',
                    Component: Sensors
                },{
                    key: 'sensors_overview',
                    title: 'Sensors Overview',
                    Component: SensorsOverview
                },{
                    key: 'rapidly_updating_sensors',
                    title: 'Rapidly Updating Sensors',
                    Component: RapidlyUpdatingSensors
                },{
                    key: 'regulated_sensors',
                    title: 'Regulated Sensors',
                    Component: RegulatedSensors
                }]
            },
            search: {
                key: 'search',
                title: 'Search',
                icon: 'navigation-icon-search',
                panels: [{
                    key: 'blank_panel',
                    Component: BlankPanel
                }]
            },
            users: {
                key: 'users',
                title: 'Users',
                icon: 'navigation-icon-users',
                panels: [{
                    key: 'exigent_admin_users',
                    title: 'Exigent Administrators',
                    Component: Users.bind(this, {
                        level: User.levels.get().exigent_admin,
                        title: 'Exigent Administrators',
                        shortName: 'Exigent Administrator',
                        panelID: 'exigent_admin_users'
                    })
                }]
            }
        }
    }

    // create user lists
    // only levels with less access than current user are shown
    let levels = [{
        key: 'admin',
        level: User.levels.get().admin,
        title: 'Administrators',
        shortName: 'Admin'
    },{
        key: 'exigent_admin',
        level: User.levels.get().exigent_admin,
        title: 'Exigent Administrators',
        shortName: 'Exigent Administrator'
    },{
        key: 'region_director',
        level: User.levels.get().region_director,
        title: 'Region Directors',
        shortName: 'Region Director'
    },{
        key: 'division_director',
        level: User.levels.get().division_director,
        title: 'Division Directors',
        shortName: 'Division Director'
    },{
        key: 'vested_director',
        level: User.levels.get().vested_director,
        title: 'Vested Directors',
        shortName: 'Vested Director'
    },{
        key: 'dealer',
        level: User.levels.get().dealer,
        title: 'Dealers',
        shortName: 'Dealer'
    },{
        key: 'booking_coordinator',
        level: User.levels.get().booking_coordinator,
        title: 'Booking Coordinators',
        shortName: 'Booking Coordinator'
    },{
        key: 'marketing_director',
        level: User.levels.get().marketing_director,
        title: 'Marketing Directors',
        shortName: 'Marketing Director'
    },{
        key: 'safety_advisor',
        level: User.levels.get().safety_advisor,
        title: 'Safety Advisors',
        shortName: 'Safety Advisor'
    },{
        key: 'trainee',
        level: User.levels.get().trainee,
        title: 'Trainees',
        shortName: 'Trainee'
    },{
        key: 'safety_associate',
        level: User.levels.get().safety_associate,
        title: 'Safety Associates',
        shortName: 'Safety Associate'
    }];

    return {
        dashboard: {
            key: 'dashboard',
            title: 'Dashboard',
            icon: 'navigation-icon-dashboard',
            panels: [{
                key: 'dealer_personal_sales',
                title: 'Dealer Personal Sales',
                visible: level < User.levels.get().dealer,
                Component: ReportPanel.bind(this, {
                    category: 'dealer',
                    default_range: 'month',
                    id: 'dealer_personal_sales'
                })
            },{
                key: 'safety_advisor_personal_sales',
                title: 'Safety Advisor Personal Sales',
                Component: ReportPanel.bind(this, {
                    category: 'safety_advisor',
                    default_range: 'month',
                    id: 'safety_advisor_personal_sales'
                })
            },{
                key: 'new_recruits_with_dealers',
                title: 'New Recruits (Dealers Included)',
                Component: ReportPanel.bind(this, {
                    id: 'new_recruits_with_dealers'
                })
            },{
                key: 'product_performance',
                title: 'Product Performance',
                Component: ProductPerformance
            }]
        },
        dealership: {
            key: 'dealership',
            title: 'Dealerships',
            icon: 'navigation-icon-dealerships',
            panels: [{
                key: 'dealership_locations',
                title: 'Dealership Locations',
                Component: DealershipLocations
            },{
                key: 'dealerships',
                title: 'Dealerships',
                Component: Dealerships
            },{
                key: 'dealerships',
                title: 'Dealership Subscriptions',
                Component: DealershipSubscriptions,
                visible: level <= User.levels.get().admin
            }]
        },
        demos: {
            key: 'demos',
            title: 'Demos',
            icon: 'navigation-icon-demos',
            visible: () => utils.dealership.get().gdl_active !== true,
            panels: [{
                key: 'blank_panel',
                Component: BlankPanel
            }]
        },
        ...level <= User.levels.get().dealer && ({
            flipchart: {
                key: 'flipchart',
                title: 'FlipChart',
                icon: 'navigation-icon-flipchart',
                panels: [{
                    key: 'published_flipchart_presentations',
                    title: level <= User.levels.get().admin ? 'Published Presentations' : 'Presentations',
                    Component: FlipChartPresentations.bind(this, { published: true })
                },{
                    key: 'unpublished_flipchart_presentations',
                    title: 'Unpublished Presentations',
                    Component: FlipChartPresentations.bind(this, { published: false }),
                    visible: level <= User.levels.get().admin
                }]
            }
        }),
        ...level <= User.levels.get().admin && ({
            health: {
                key: 'health',
                title: 'Health',
                icon: 'navigation-icon-health',
                subViews: {
                    backups: {
                        key: 'backups',
                        title: 'Backups',
                        panels: [{
                            key: 'system_backups',
                            title: 'Backups',
                            Component: SystemBackups
                        },{
                            key: 'system_tasks',
                            title: 'Tasks',
                            Component: SystemTasks
                        }]
                    },
                    omnishield: {
                        key: 'omnishield',
                        title: 'OmniShield',
                        panels: [{
                            key: 'omnishield_system_status',
                            title: 'System Status',
                            Component: OmniShieldSystemStatus
                        },{
                            key: 'omnishield_events',
                            title: 'Sensor Events',
                            Component: OmniShieldSensorEvents
                        }]
                    },
                    /*servers: {
                        key: 'servers',
                        title: 'Servers and Databases',
                        panels: [{
                            key: 'aws_metrics',
                            title: 'AWS Metrics',
                            Component: AWSMetrics
                        }]
                    }*/
                }
            },
        }),
        marketing: {
            capabilities: ['aft.google_business_profile.reviews_manager'],
            key: 'marketing',
            title: 'Marketing',
            icon: 'navigation-icon-marketing',
            visible: level <= User.levels.get().dealer,
            subViews: {
                dealership_website: {
                    key: 'dealership_website',
                    title: 'Dealership Website',
                    panels: [{
                        key: 'blank_panel',
                        Component: BlankPanel
                    }]
                },
                google_reviews: {
                    key: 'google_reviews',
                    title: 'Google Reviews',
                    panels: [{
                        key: 'google_reviews',
                        Component: GoogleReviews
                    }]
                }
            }
        },
        omnishield: {
            key: 'omnishield',
            title: 'OmniPortal',
            icon: 'navigation-icon-omnishield',
            subViews: {
                comm_links_and_sensors: {
                    key: 'comm_links_and_sensors',
                    title: 'Comm Links and Sensors',
                    panels: [{
                        key: 'comm_links',
                        title: 'Comm Links',
                        Component: CommLinks
                    },{
                        key: 'sensors_overview',
                        title: 'Sensors Overview',
                        Component: SensorsOverview,
                        visible: level <= User.levels.get().admin ? true : false,
                    },{
                        key: 'rapidly_updating_sensors',
                        title: 'Rapidly Updating Sensors',
                        Component: RapidlyUpdatingSensors,
                        visible: level <= User.levels.get().admin ? true : false,
                    },{
                        key: 'regulated_sensors',
                        title: 'Regulated Sensors',
                        Component: RegulatedSensors,
                        visible: level <= User.levels.get().admin ? true : false
                    },{
                        key: 'expiring_carbon_monoxide_sensors',
                        title: 'Expiring Carbon Monoxide Sensors',
                        Component: ExpiringCOSensors
                    },/*{
                        key: 'comcast_issue_notifications',
                        title: 'Automated Offline Comm Link Notifications',
                        Component: ComcastIssueNotifications
                    },*/]
                },
                ...level <= User.levels.get().admin && {
                    communications: {
                        key: 'communications',
                        title: 'Communications',
                        panels: [{
                            key: 'comm_link_communicate',
                            title: 'Comm Link Customer Communications',
                            Component: CommLinkCommunicate
                        }]
                    }
                },
                reports: {
                    key: 'reports',
                    title: 'Reports',
                    panels: [{
                        key: 'comm_link_reachability_report',
                        title: 'Comm Link Reachability Report',
                        Component: CommLinkReachabilityReport,
                        visible: level <= User.levels.get().admin ? true : false
                    },{
                        key: 'omnishield_network_sensor_averages',
                        title: 'OmniShield Network Sensor Averages',
                        Component: OmniShieldNetworkSensorAveragesReport
                    },{
                        key: 'omnishield_network_installations',
                        title: 'OmniShield Network Installations',
                        Component: OmniShieldNetworkInstallationsReport
                    }]
                },
                ...level <= User.levels.get().admin && {
                    white_label_apps: {
                        key: 'white_label_apps',
                        title: 'White Label Apps',
                        panels: [{
                            key: 'omnishield_white_label_apps',
                            title: 'White Label Apps',
                            Component: OmniShieldWhiteLabelApps
                        },{
                            key: 'omnishield_white_label_change_requests',
                            title: 'White Label Change Requests',
                            Component: OmniShieldWhiteLabelChangeRequests
                        }]
                    }
                }
            }
        },
        payments: {
            key: 'payments',
            title: 'Payments',
            icon: 'navigation-icon-payments',
            visible: level <= User.levels.get().dealer,
            panels: [{
                key: 'subscriptions',
                Component: Subscriptions
            },{
                key: 'dealership_payments',
                Component: Payments.bind(this, { 
                    category: Payment.Method.source_categories.get().dealership,
                    id: 'dealership_payments',
                    title: 'Dealership Payments'
                })
            },{
                key: 'user_payments',
                Component: Payments.bind(this, { 
                    category: Payment.Method.source_categories.get().user,
                    id: 'user_payments',
                    title: 'Personal Payments'
                })
            },{
                key: 'payment_methods',
                Component: PaymentMethods
            }]
        },
        products: {
            key: 'products',
            title: 'Products',
            icon: 'navigation-icon-products',
            subViews: {
                new: {
                    key: 'new',
                    title: 'Add New Product',
                    panels: [{
                        key: 'blank_panel',
                        Component: BlankPanel
                    }]
                },
                browse: {
                    key: 'browse',
                    title: 'Browse All Products',
                    panels: [{
                        key: 'product_performance',
                        title: 'Product Performance',
                        Component: ProductPerformance
                    },{
                        key: 'products',
                        title: 'All Products',
                        Component: Products
                    }]
                }
            }
        },
        cards: {
            key: 'cards',
            title: 'Protections',
            icon: 'navigation-icon-cards',
            subViews: {
                new: {
                    key: 'new',
                    title: 'Add New Protection',
                    panels: [{
                        key: 'blank_panel',
                        Component: BlankPanel
                    }]
                },
                browse: {
                    key: 'browse',
                    title: 'Browse All Protections',
                    panels: [{
                        key: 'card_locations',
                        title: 'Protection Locations',
                        Component: CardLocations
                    },{
                        key: 'cards',
                        title: 'Protections',
                        Component: Cards
                    }]
                }
            }
        },
        reports: {
            key: 'reports',
            title: 'Reports',
            icon: 'navigation-icon-reports',
            subViews: {
                weekly: {
                    key: 'weekly',
                    title: 'Weekly',
                    panels: [{
                        key: 'new_recruits',
                        title: 'New Recruits',
                        Component: ReportPanel.bind(this, {
                            id: 'new_recruits',
                            default_range: 'week'
                        })
                    },{
                        key: 'top_recruiting_offices',
                        title: 'Top Recruiting Offices',
                        Component: ReportPanel.bind(this, {
                            id: 'top_recruiting_offices',
                            default_range: 'week'
                        })
                    },{
                        key: 'dealership_sales',
                        title: 'Dealership Sales',
                        Component: ReportPanel.bind(this, {
                            id: 'dealership_sales',
                            default_range: 'week'
                        })
                    },{
                        key: 'dealer_personal_sales',
                        title: 'Dealer Personal Sales',
                        Component: ReportPanel.bind(this, {
                            id: 'dealer_personal_sales',
                            category: 'dealer',
                            default_range: 'week'
                        })
                    },{
                        key: 'safety_advisor_personal_sales',
                        title: 'Safety Advisor Personal Sales',
                        Component: ReportPanel.bind(this, {
                            id: 'safety_advisor_personal_sales',
                            category: 'safety_advisor',
                            default_range: 'week'
                        })
                    },{
                        key: 'director_inside_office',
                        title: 'Director Inside Office',
                        visible: level <= User.levels.get().division_director,
                        Component: ReportPanel.bind(this, {
                            id: 'director_inside_office',
                            default_range: 'week'
                        })
                    },{
                        key: 'division_team_sales',
                        title: 'Division Team Sales',
                        visible: level <= User.levels.get().division_director,
                        Component: ReportPanel.bind(this, {
                            id: 'division_team_sales',
                            sector: 'Division',
                            default_range: 'week'
                        })
                    },{
                        key: 'region_team_sales',
                        title: 'Region Team Sales',
                        visible: level <= User.levels.get().region_director,
                        Component: ReportPanel.bind(this, {
                            id: 'region_team_sales',
                            sector: 'Region',
                            default_range: 'week'
                        })
                    }]
                },
                monthly: {
                    key: 'monthly',
                    title: 'Monthly',
                    panels: [{
                        key: 'new_recruits',
                        title: 'New Recruits',
                        Component: ReportPanel.bind(this, {
                            id: 'new_recruits',
                            default_range: 'month'
                        })
                    },{
                        key: 'top_recruiting_offices',
                        title: 'Top Recruiting Offices',
                        Component: ReportPanel.bind(this, {
                            id: 'top_recruiting_offices',
                            default_range: 'month'
                        })
                    },{
                        key: 'dealership_sales',
                        title: 'Dealership Sales',
                        Component: ReportPanel.bind(this, {
                            id: 'dealership_sales',
                            default_range: 'month'
                        })
                    },{
                        key: 'dealer_personal_sales',
                        title: 'Dealer Personal Sales',
                        Component: ReportPanel.bind(this, {
                            id: 'dealer_personal_sales',
                            category: 'dealer',
                            default_range: 'month'
                        })
                    },{
                        key: 'safety_advisor_personal_sales',
                        title: 'Safety Advisor Personal Sales',
                        Component: ReportPanel.bind(this, {
                            id: 'safety_advisor_personal_sales',
                            category: 'safety_advisor',
                            default_range: 'month'
                        })
                    },{
                        key: 'director_inside_office',
                        title: 'Director Inside Office',
                        visible: level <= User.levels.get().division_director,
                        Component: ReportPanel.bind(this, {
                            id: 'director_inside_office',
                            default_range: 'month'
                        })
                    },{
                        key: 'division_team_sales',
                        title: 'Division Team Sales',
                        visible: level <= User.levels.get().division_director,
                        Component: ReportPanel.bind(this, {
                            id: 'division_team_sales',
                            sector: 'Division',
                            default_range: 'month'
                        })
                    },{
                        key: 'region_team_sales',
                        title: 'Region Team Sales',
                        visible: level <= User.levels.get().region_director,
                        Component: ReportPanel.bind(this, {
                            id: 'region_team_sales',
                            sector: 'Region',
                            default_range: 'month'
                        })
                    },{
                        key: 'thirty_for_thirty',
                        title: '30 for 30 Demos',
                        Component: ReportPanel.bind(this, {
                            id: 'thirty_for_thirty'
                        })
                    }]
                },
                ytd: {
                    key: 'ytd',
                    title: 'Year To Date',
                    panels: [{
                        key: 'new_recruits',
                        title: 'New Recruits',
                        Component: ReportPanel.bind(this, {
                            id: 'new_recruits',
                            default_range: 'ytd'
                        })
                    },{
                        key: 'top_recruiting_offices',
                        title: 'Top Recruiting Offices',
                        Component: ReportPanel.bind(this, {
                            id: 'top_recruiting_offices',
                            default_range: 'ytd'
                        })
                    },{
                        key: 'dealership_sales',
                        title: 'Dealership Sales',
                        Component: ReportPanel.bind(this, {
                            id: 'dealership_sales',
                            default_range: 'ytd'
                        })
                    },{
                        key: 'dealer_personal_sales',
                        title: 'Dealer Personal Sales',
                        Component: ReportPanel.bind(this, {
                            id: 'dealer_personal_sales',
                            category: 'dealer',
                            default_range: 'ytd'
                        })
                    },{
                        key: 'safety_advisor_personal_sales',
                        title: 'Safety Advisor Personal Sales',
                        Component: ReportPanel.bind(this, {
                            id: 'safety_advisor_personal_sales',
                            category: 'safety_advisor',
                            default_range: 'ytd'
                        })
                    },{
                        key: 'director_inside_office',
                        title: 'Director Inside Office',
                        visible: level <= User.levels.get().division_director,
                        Component: ReportPanel.bind(this, {
                            id: 'director_inside_office',
                            default_range: 'ytd'
                        })
                    },{
                        key: 'division_team_sales',
                        title: 'Division Team Sales',
                        visible: level <= User.levels.get().division_director,
                        Component: ReportPanel.bind(this, {
                            id: 'division_team_sales',
                            sector: 'Division',
                            default_range: 'ytd'
                        })
                    },{
                        key: 'region_team_sales',
                        title: 'Region Team Sales',
                        visible: level <= User.levels.get().region_director,
                        Component: ReportPanel.bind(this, {
                            id: 'region_team_sales',
                            sector: 'Region',
                            default_range: 'ytd'
                        })
                    },{
                        key: 'thirty_for_thirty',
                        title: '30 for 30 Demos',
                        Component: ReportPanel.bind(this, {
                            id: 'thirty_for_thirty'
                        })
                    }]
                },
                ...level <= User.levels.get().admin && {
                    weekly_omnis: {
                        key: 'weekly_omnis',
                        title: 'Weekly Omnis',
                        panels: [{
                            key: 'blank_panel',
                            Component: BlankPanel
                        }]
                    },
                    challenger_omnis: {
                        key: 'challenger_omnis',
                        title: 'Challenger Omnis',
                        panels: [{
                            key: 'blank_panel',
                            Component: BlankPanel
                        }]
                    },
                    misc: {
                        key: 'misc',
                        title: 'Miscellaneous',
                        panels: [{
                            key: 'global_data_lead_pool_breakdown',
                            title: 'Global Data Lead Pool Breakdown',
                            Component: GlobalDataLeadPoolBreakdown,
                            visible: level <= User.levels.get().admin
                        },{
                            key: 'incentives_tracking',
                            title: 'Incentives Tracking',
                            Component: ReportPanel.bind(this, {
                                id: 'incentives_tracking'
                            })
                        },{
                            key: 'vested_director_sales',
                            title: 'Vested Director Sales',
                            Component: ReportPanel.bind(this, {
                                id: 'vested_director_sales',
                                sector: 'Vested Director',
                                default_range: 'ytd'
                            })
                        }]
                    }
                },
                ...level > User.levels.get().admin && {
                    misc: {
                        key: 'misc',
                        title: 'Miscellaneous',
                        panels: [{
                            capabilities: ['aft.incentives_tracking'],
                            key: 'incentives_tracking',
                            title: 'Incentives Tracking',
                            Component: ReportPanel.bind(this, {
                                id: 'incentives_tracking'
                            })
                        }]
                    }
                },
                settings: {
                    key: 'settings',
                    title: 'Settings',
                    panels: [{
                        key: 'blank_panel',
                        Component: BlankPanel
                    }]
                }
            }
        },
        search: {
            key: 'search',
            title: 'Search',
            icon: 'navigation-icon-search',
            panels: [{
                key: 'blank_panel',
                Component: BlankPanel
            }]
        },
        support: {
            key: 'support',
            title: 'Support',
            icon: 'navigation-icon-support',
            panels: [{
                key: 'support_requests',
                title: 'Support Requests',
                Component: SupportRequests,
                visible: level <= User.levels.get().dealer
            },{
                key: 'support_workflows',
                title: 'Support Workflows',
                Component: SupportWorkflows,
                visible: level <= User.levels.get().admin
            },{
                key: 'kb_articles',
                title: 'Knowledge Base Articles',
                Component: KBArticles,
                visible: level <= User.levels.get().admin
            }]
        },
        users: {
            key: 'users',
            title: 'Users',
            icon: 'navigation-icon-users',
            subViews: {
                accounts: {
                    key: 'accounts',
                    title: 'Accounts',
                    panels: [{
                        key: 'user_locations',
                        title: 'User Locations',
                        Component: UserLocations
                    }].concat(levels.filter(entry => {
                        return entry.level >= level;
                    }).map(entry => {
                        return {
                            key: `${entry.key}_users`,
                            title: entry.title,
                            Component: Users.bind(this, {
                                level: entry.level,
                                title: entry.title,
                                shortName: entry.shortName,
                                panelID: `${entry.key}_users`
                            })
                        }
                    }))
                },
                ...level <= User.levels.get().dealer && {
                    communications: {
                        capabilities: ['aft.dealer_communications'],
                        key: 'communications',
                        title: 'Communications',
                        panels: [{
                            key: 'communications',
                            Component: Communications
                        }]
                    }
                },
                groups: {
                    key: 'groups',
                    title: 'Groups',
                    level: User.levels.get().dealer,
                    panels: [{
                        key: 'user_groups',
                        title: 'User Groups',
                        Component: UserGroups
                    }]
                },
                notifications: {
                    key: 'notifications',
                    title: 'Notifications',
                    panels: [{
                        key: 'notifications',
                        title: 'Notifications',
                        Component: NotificationsList
                    }]
                },
                system_events: {
                    key: 'system_events',
                    title: 'System Events',
                    panels: [{
                        key: 'system_events',
                        title: 'System Events',
                        Component: SystemEvents
                    }]
                },
                ...level <= User.levels.get().admin && {
                    teams: {
                        key: 'teams',
                        title: 'Teams',
                        panels: [{
                            key: 'sector_list_region',
                            title: 'Regions',
                            Component: SectorList.bind(this, {
                                id: 'sector_list_region',
                                sector_type: Sector.types.region,
                                title: 'Region'
                            })
                        },{
                            key: 'sector_list_division',
                            title: 'Divisions',
                            Component: SectorList.bind(this, {
                                id: 'sector_list_division',
                                sector_type: Sector.types.division,
                                title: 'Division'
                            })
                        },{
                            key: 'vested_directors_list',
                            title: 'Vested Directors',
                            Component: VestedDirectorsList
                        }]
                    }
                }
            }
        }
    };
}

const BlankPanel = props => {
    return <Panel panelID={'blank'} {...props}/>
}
