import lz from 'lzutf8';
import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import Dealership from 'classes/Dealership.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

class KBArticleClass {

    active = null;
    content = null;
    date = null;
    id = null;
    last_updated = null;
    name = null;
    url = null;
    user_id = null;

    constructor() {
        return this;
    }

    apply = (utils, isNewTarget, props) => {
        return isNewTarget ? this.submit(utils, props) : this.update(utils, props);
    }

    create = (props = {}) => {
        this.active = props.active;
        this.content = props.content && {
            ...props.content,
            layout: lz.decompress(lz.decodeBase64(props.content.layout))
        }
        this.date = props.date && moment.utc(props.date).local();
        this.id = props.id;
        this.last_updated = props.last_updated && moment.utc(props.last_updated).local();
        this.name = props.name;
        this.url = props.url;
        this.user_id = props.user_id;
        return this;
    }

    close = () => {
        Object.keys(this.edits).forEach(key => {
            this[key] = this.edits[key] || this[key];
        }); 
    }

    format = () => ({
        ...this.edits,
        content: compressContent(this.edits.content)
    })

    getDescription = () => ({
        badge: {
            color: Appearance.colors.grey(),
            text: this.active ? null : 'Not Active'
        },
        icon: {
            path: 'images/kb-article-placeholder-clear.png'
        },
        subTitle: `Last Updated: ${Utils.formatDate(this.last_updated)}`,
        title: this.name
    })

    open = () => {
        this.edits = {
            content: this.content,
            name: this.name
        }
        return this.edits;
    }

    set = (props = {}) => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/support/', {
                    type: 'new_kb_article',
                    ...this.format(),
                    ...props
                });

                // send fetch request to content subscribers
                utils.content.fetch('kb_articles');

                // close current edits and resolve
                this.close();
                this.id = id;
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/support/', {
                    id: this.id,
                    type: 'update_kb_article',
                    ...this.format(),
                    ...props
                });

                // close current edits and send update request to content subscribers
                this.close();
                utils.content.update({
                    type: 'kb_articles',
                    object: this
                });

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }
}

class SupportRequestClass {

    date = null;
    id = null;
    interaction = null;
    interaction_id = null;
    map = null;
    name = null;
    origin = null;
    status = null;
    targets = {};
    tasks = [];
    types = null;
    values = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.date = props.date && moment.utc(props.date).local();
        this.id = props.id;
        this.interaction = props.interaction;
        this.interaction_id = props.interaction_id;
        this.map = props.map || {};
        this.name = props.name;
        this.origin = props.origin;
        this.status = props.status;
        this.tags = props.tags;
        this.tasks = props.tasks;
        this.types = props.types || {};
        this.values = props.values || {};

        // prepare target objects
        this.targets = {
            ...props.targets,
            dealership: props.targets.dealership && Dealership.create(props.targets.dealership),
            user: props.targets.user && User.create(props.targets.user)
        }

        return this;
    }

    getDescription = () => ({
        badge: {
            color: this.status.color,
            text: this.status.text
        },
        icon: {
            path: this.values['__private_avatar'] || 'images/support-request-grey.png'
        },
        subTitle: this.id,
        title: Utils.formatDate(this.date),
        ...this.name && {
            subTitle: `Submitted ${Utils.formatDate(this.date)}`,
            supportingTitle: this.id,
            title: this.name,
        }
    })
}

class SupportRequestResponseClass {

    // shared variables
    channel = null;
    date = null;
    id = null;
    request_id = null;
    status = null;
    user = null;
    values = null;

    // external request
    attachment = null;
    message = null;

    // internal request
    content = null;
    destination = null;
    method = null;
    name = null;
    type = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        // shared variables
        this.channel = props.channel;
        this.date = props.date && moment.utc(props.date).local();
        this.id = props.id;
        this.name = props.name;
        this.request_id = props.request_id;
        this.status = props.status;
        this.user = props.user && User.create(props.user);

        // external request
        this.attachment = props.attachment;
        this.message = props.message;
        this.values = props.values;

        // internal request 
        this.content = props.content;
        this.destination = props.destination;
        this.method = props.method;
        this.type = props.type;
        return this;
    }

    getDescription = () => {
        switch(this.channel) {
            case 'internal':
            return {
                badge: { 
                    color: Appearance.colors.grey(),
                    text: 'Outgoing'
                },
                icon: { path: this.user && this.user.avatar || 'images/user-placeholder.png' },
                subTitle: Utils.formatDate(this.date),
                title: this.user ? this.user.full_name : this.id
            }

            case 'external':
            return {
                badge: { 
                    color: Appearance.colors.primary(),
                    text: 'Incoming'
                },
                icon: { path: this.user && this.user.avatar || 'images/user-placeholder.png' },
                subTitle: Utils.formatDate(this.date),
                title: this.user ? this.user.full_name : (this.name || this.id)
            }
        }
    }
}

class SupportWorkflowClass {

    active = null;
    count = null;
    date = null;
    id = null;
    name = null;
    options = null;
    url = null;

    constructor() {
        return this;
    }

    apply = (utils, isNewTarget, props) => {
        return isNewTarget ? this.submit(utils, props) : this.update(utils, props);
    }

    create = (props = {}) => {
        this.active = props.active;
        this.count = props.count;
        this.date = props.date ? moment(props.date) : null;
        this.id = props.id;
        this.name = props.name;
        this.options = props.options || {};
        this.url = props.url;
        return this;
    }

    close = () => {
        Object.keys(this.edits).forEach(key => {
            this[key] = this.edits[key] || this[key];
        }); 
    }

    open = () => {
        this.edits = {
            name: this.name,
            options: this.options || {}
        }
        return this.edits;
    }

    set = (props = {}) => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id, url } = await Request.post(utils, '/support/', {
                    type: 'new_workflow',
                    ...this.edits,
                    ...props
                });

                // send fetch request to content subscribers
                utils.content.fetch('support_workflows');

                // close current edits and resolve
                this.close();
                this.id = id;
                this.url = url;
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/support/', {
                    type: 'update_workflow',
                    id: this.id,
                    ...this.edits,
                    ...props
                });

                // close current edits and send update request to content subscribers
                this.close();
                utils.content.update({
                    type: 'support_workflows',
                    object: this
                });

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    getDescription = () => ({
        badge: [{
            text: this.count && `${this.count.landings} ${this.count.landings === 1 ? 'Landing' : 'Landings'}`,
            color: Appearance.colors.secondary()
        },{
            text: this.count && `${this.count.rules} ${this.count.rules === 1 ? 'Rule' : 'Rules'}`,
            color: Appearance.colors.primary()
        },{
            text: this.active ? null : 'Not Active',
            color: Appearance.colors.grey()
        }],
        icon: {
            path: 'images/support-workflow-placeholder-clear.png'
        },
        subTitle: `Created ${Utils.formatDate(this.date)}`,
        title: this.name || `Support Workflow #${this.id}`
    })
}

class SupportWorkflowLandingClass {

    active = null;
    content = null;
    date = null;
    id = null;
    name = null;
    root = false;
    workflow_id = null;

    constructor() {
        return this;
    }

    apply = (utils, isNewTarget, props) => {
        return isNewTarget ? this.submit(utils, props) : this.update(utils, props);
    }

    create = (props = {}) => {
        this.active = props.active;
        this.content = props.content && {
            ...props.content,
            layout: lz.decompress(lz.decodeBase64(props.content.layout))
        }
        this.date = props.date && moment.utc(props.date).local();
        this.id = props.id;
        this.name = props.name;
        this.root = props.root;
        this.workflow_id = props.workflow_id;
        return this;
    }

    close = () => {
        Object.keys(this.edits).forEach(key => {
            this[key] = this.edits[key] !== undefined ? this.edits[key] : this[key];
        });
    }

    format = () => ({
        ...this.edits,
        content: compressContent(this.edits.content)
    })

    open = () => {
        this.edits = {
            content: this.content,
            name: this.name
        }
        return this.edits;
    }

    set = (props = {}) => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id, root } = await Request.post(utils, '/support/', {
                    type: 'new_workflow_landing',
                    ...this.format(),
                    ...props
                });

                // send fetch request to content subscribers
                utils.content.fetch('support_workflow_landings');

                // close current edits and resolve
                this.close();
                this.active = true;
                this.id = id;
                this.root = root;
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { root } = await Request.post(utils, '/support/', {
                    type: 'update_workflow_landing',
                    id: this.id,
                    ...this.format(),
                    ...props
                });

                // close current edits and send update request to content subscribers
                this.close();
                this.root = root;

                // update target subscribers
                utils.content.update({
                    type: 'support_workflow_landings',
                    object: this
                });

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    getDescription = () => ({
        badge: [{
            text: this.active ? null : 'Not Active',
            color: Appearance.colors.grey()
        }],
        bottomBorder: false,
        icon: { 
            path: 'images/workflow-landing-placeholder-clear.png',
            imageStyle: {
                backgroundColor: Appearance.colors.primary()
            }
        },
        subTitle: `Landing ID: ${this.id}`,
        title: this.name || 'Name not available',

    })
}

class SupportWorkflowRuleClass {

    active = null;
    date = null;
    formatted = null;
    id = null;
    name = null;
    root = false;
    routes = null;
    tasks = null;
    workflow_id = null;

    constructor() {
        return this;
    }

    apply = (utils, isNewTarget, props) => {
        return isNewTarget ? this.submit(utils, props) : this.update(utils, props);
    }

    create = (props = {}) => {
        this.active = props.active;
        this.date = props.date && moment.utc(props.date).local();
        this.id = props.id;
        this.formatted = props.formatted;
        this.name = props.name;
        this.root = props.root;
        this.routes = props.routes && props.routes.map(route => new SupportWorkflowRuleRouteClass().create(route)) || [];
        this.tasks = props.tasks && props.tasks.map(task => new SupportWorkflowRuleTaskClass().create(task)) || [];
        this.workflow_id = props.workflow_id;
        return this;
    }

    close = () => {
        Object.keys(this.edits).forEach(key => {
            if(key === 'routes' || key === 'tasks') {
                this.edits[key].forEach(target => target.commit());
            }
            this[key] = this.edits[key] !== undefined ? this.edits[key] : this[key];
        });
    }

    format = () => ({
        ...this.edits,
        routes: this.edits.routes.map(route => route.toJSON()),
        tasks: this.edits.tasks.map(task => task.toJSON())
    })

    open = () => {

        // prepare default edits
        this.edits = {
            name: this.name,
            routes: this.routes || [],
            tasks: this.tasks || []
        }

        // open edits for routes if applicable
        if(this.edits.routes && this.edits.routes.length > 0) {
            this.edits.routes.forEach(route => route.open());
        }

        // open edits for tasks if applicable
        if(this.edits.tasks && this.edits.tasks.length > 0) {
            this.edits.tasks.forEach(task => task.open());
        }
        
        return this.edits;
    }

    set = (props = {}) => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id, formatted, root, routes } = await Request.post(utils, '/support/', {
                    type: 'new_workflow_rule',
                    ...this.format(),
                    ...props
                });

                // send fetch request to content subscribers
                utils.content.fetch('support_workflow_rules');

                // close current edits and resolve
                this.close();
                this.active = true;
                this.id = id;
                this.formatted = formatted;
                this.root = root;
                this.routes = routes.map(route => new SupportWorkflowRuleRouteClass().create(route));

                // commit task edits if applicable
                if(this.tasks) {
                    this.tasks.forEach(task => task.commit());
                }
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { formatted, root, routes } = await Request.post(utils, '/support/', {
                    type: 'update_workflow_rule',
                    id: this.id,
                    ...this.format(),
                    ...props
                });

                // close current edits and send update request to content subscribers
                this.close();
                this.formatted = formatted;
                this.root = root;
                this.routes = routes.map(route => new SupportWorkflowRuleRouteClass().create(route));

                // commit task edits if applicable
                if(this.tasks) {
                    this.tasks.forEach(task => task.commit());
                }
                
                // update target subscribers
                utils.content.update({
                    type: 'support_workflow_rules',
                    object: this
                });

                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    getDescription = () => ({
        badge: [{
            text: this.active ? null : 'Not Active',
            color: Appearance.colors.grey()
        }],
        icon: {
            path: 'images/workflow-rule-placeholder-clear.png',
            imageStyle: {
                backgroundColor: Appearance.colors.secondary()
            }
        },
        subTitle: this.formatted ? this.formatted.description : 'Description not available',
        title: this.name || (this.formatted && this.formatted.type ? this.formatted.type : 'Name not available'),

    })

    getExtDescription = () => {
        return this.getDescription();
    }
}

class SupportWorkflowRuleRouteClass {

    action = null;
    destination = null;
    id = null;
    key = null;
    name = null;
    operator = null;
    type = null;
    value = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.action = props.action || {};
        this.destination = props.destination;
        this.id = props.id;
        this.key = props.key;
        this.name = props.name;
        this.operator = props.operator;
        this.type = props.type;
        this.value = props.value;

        switch(props.destination) {
            case workflowDestinations.workflow_rule:
            this.action.target = this.action.target && new SupportWorkflowRuleClass().create(this.action.target);
            break;

            case workflowDestinations.workflow_landing:
            this.action.target = this.action.target && new SupportWorkflowLandingClass().create(this.action.target);
            break;
        }
        return this;
    }

    commit = () => {
        Object.keys(this.edits).forEach(key => {
            this[key] = this.edits[key] !== undefined ? this.edits[key] : this[key];
        });
    }

    open = () => {
        this.edits = {
            action: this.action,
            destination: this.destination,
            id: this.id || `${moment().unix()}.${Utils.randomString(5)}`,
            key: this.key,
            name: this.name,
            operator: this.operator,
            type: this.type,
            value: this.value
        }
        return this.edits;
    }

    set = (props = {}) => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    getDescription = () => ({
        icon: {
            path: 'images/workflow-rule-route-placeholder-clear.png',
            imageStyle: {
                backgroundColor: Appearance.colors.secondary()
            }
        },
        subTitle: this.formatted ? this.formatted.description : 'Description not available',
        title: this.name || (this.formatted && this.formatted.type ? this.formatted.type : 'Name not available'),

    })

    toJSON = () => {

        // prepare base result object
        let target = this.edits || this;
        let result = {
            action: { ...target.action },
            destination: target.destination,
            id: target.id,
            key: target.key,
            name: target.name,
            operator: target.operator,
            type: target.type,
            value: target.value
        }

        // determine if the target needs to be reduced for a query
        if(result.action && result.action.target) {
            switch(result.action.type) {
                case workflowActionTypes.redirect_to_workflow_landing:
                case workflowActionTypes.redirect_to_workflow_rule:
                result.action.target = result.action.target.id;
                break;

                case workflowActionTypes.redirect_to_public_web_page:
                case workflowActionTypes.download_local_file:
                if(result.action.target.url) {
                    result.action.target = { url: result.action.target.url };
                }
                break;
            }
        }
        return result;
    }
}

class SupportWorkflowRuleTaskClass {

    id = null;
    name = null;
    type = null;
    value = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.type = props.type;
        this.value = props.value;
        return this;
    }

    commit = () => {
        Object.keys(this.edits).forEach(key => {
            this[key] = this.edits[key] !== undefined ? this.edits[key] : this[key];
        });
    }

    open = () => {
        this.edits = {
            id: this.id || `${moment().unix()}.${Utils.randomString(5)}`,
            name: this.name,
            type: this.type,
            value: this.value
        }
        return this.edits;
    }

    set = (props = {}) => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    toJSON = () => {

        // prepare payload for json encode
        let target = this.edits || this;
        let payload = {
            id: target.id,
            name: target.name,
            type: target.type,
            value: target.value
        }

        // determine if additional formatting is needed based on the type
        if(target.value) {
            switch(target.type){
                case workflowTaskTypes.push_notification:
                payload.value = {
                    ...payload.value,
                    users: payload.value.users && payload.value.users.map(user => user.user_id) 
                }
                break;
            }
        }
        return payload;
    }
}

const compressContent = content => ({
    ...content,
    layout: lz.encodeBase64(lz.compress(content.layout))
})

const externalMethods = {
    get: 1,
    post: 2,
    put: 3,
    delete: 4
}

const externalMethodRequiresPayload = method => {
    return method !== externalMethods.get ? true : false;
}

const externalMethodToText = code => {
    switch(code) {
        case externalMethods.get:
        return 'GET';

        case externalMethods.post:
        return 'POST';

        case externalMethods.put:
        return 'PUT';

        case externalMethods.delete:
        return 'DELETE';

        default:
        return null;
    }
}

const formatConditionalContent = (type, rule, options = {}) => {

    let props = {
        bottomBorder: false,
        icon: {
            path: `images/${type}-redirect-icon.png`,
            imageStyle: {
                backgroundColor: Appearance.colors.transparent
            }
        },
        containerStyle: {
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 8,
            paddingBottom: 8
        }
    }

    const format = (type, destination, content = {}) => {
        switch(destination) {
            case workflowDestinations.workflow_rule:
            return rule ? Views.entry(rule.getDescription()) : null;

            case workflowDestinations.public_web_page:
            return Views.entry({
                ...props,
                subTitle: formatConditionalDescription(type, rule),
                supportingTitle: content.url,
                title: formatConditionalTitle(type, rule),
                ...options.clickHandler !== false && {
                    onClick: () => window.open(content.url)
                }
            });

            case workflowDestinations.local_file:
            return Views.entry({
                ...props,
                subTitle: formatConditionalDescription(type, rule),
                supportingTitle: content.url,
                title: formatConditionalTitle(type, rule),
                ...options.clickHandler !== false && {
                    onClick: () => window.open(content.url)
                }
            });

            default:
            return null;
        }
    }

    switch(type) {
        case 'else':
        return format(type, rule.else_destination, rule.else_content);

        case 'next':
        return format(type, rule.next_destination, rule.next_content);

        default:
        return null;
    }
}

const formatConditionalTitle = (type, rule) => {
    let target = type === 'else' ? rule.else_destination : rule.next_destination;
    return target ? workflowDestinationsToText(target) : 'No destination set';
}

const formatConditionalDescription = (type, rule) => {

    const format = (type, destination, content = {}, rule) => {
        switch(destination) {
            case workflowDestinations.workflow_rule:
            return rule ? rule.getDescription().title : null;

            case workflowDestinations.public_web_page:
            return content && content.title ? content.title : 'Webpage title not available';

            case workflowDestinations.local_file:
            return content ? `${content.name || 'File name not available'} - ${Utils.formatBytes(content.size)}` : 'File description not available'

            default:
            return null;
        }
    }

    switch(type) {
        case 'else':
        return format(type, rule.else_destination, rule.else_content, rule.else_rule);

        case 'next':
        return format(type, rule.next_destination, rule.next_content, rule.next_rule);

        default:
        return null;
    }
}

const forwardDestinations = {
    url: 1,
    sms: 3,
    push_notification: 4
}

const forwardDestinationToText = code => {
    switch(code) {
        case forwardDestinations.url:
        return 'Public Url';

        case forwardDestinations.sms:
        return 'SMS Text Message';

        case forwardDestinations.push_notification:
        return 'Push Notification';

        default:
        return 'Unknown data forwarding destination';
    }
}

const forwardValueTypes = {
    preset: 1,
    custom: 2,
    scripted: 3
}

const forwardValueTypesToText = code => {
    switch(code) {
        case forwardValueTypes.preset:
        return 'Preset';

        case forwardValueTypes.custom:
        return 'Custom';

        case forwardValueTypes.scripted:
        return 'Scripted';

        default:
        return 'Unknown forward value type';
    }
}

const getRouteOverview = route => {

    // return a generated name based on the contents of the action
    let { download_local_file, redirect_to_public_web_page, redirect_to_workflow_landing, redirect_to_workflow_rule} = workflowActionTypes;
    switch(route.action.type) {
        case redirect_to_workflow_landing:
        return `Redirect to ${route.action.target && route.action.target.name ? `"${route.action.target.name}" ` : ''}Landing`;

        case redirect_to_workflow_rule:
        return `Redirect to ${route.action.target && route.action.target.name ? `"${route.action.target.name}" ` : ''}Rule`;

        case redirect_to_public_web_page:
        return `Redirect to ${route.action.target ? `${route.action.target.title || route.action.target.url || 'Webpage'}` : ''}`;
            
        case download_local_file:
        return `Download ${workflowActionTypeToText(route.action.type)} ${route.action.target ? `"${route.action.target.name || route.action.target.file_name}"` : 'file'}`;

        default:
        return 'Action has not been set';
    }
}

const getTaskOverview = task => {

    // return a generated name based on the contents of the task
    switch(task.type) {
        case workflowTaskTypes.email:
        let emailAddresses = task.value && task.value.email_addresses || [];
        return emailAddresses.length > 0 ? `Send an email to ${Utils.oxfordImplode(emailAddresses)}` : 'Action overview not available';

        case workflowTaskTypes.push_notification:
        let users = task.value && task.value.users || [];
        return users.length > 0 ? `Send an AFT push notification to ${Utils.oxfordImplode(users.map(user => `${user.first_name} ${user.last_name}`))}` : 'Action overview not available';

        case workflowTaskTypes.sms:
        let phoneNumbers = task.value && task.value.phone_numbers || [];
        return phoneNumbers.length > 0 ? `Send a text message to ${Utils.oxfordImplode(phoneNumbers.map(n => Utils.formatPhoneNumber(n)))}` : 'Action overview not available';

        case workflowTaskTypes.webhook:
        let url = task.value && task.value.url;
        return url ? `Send session data to webhook url "${url}"` : 'Action overview not available';

        default:
        return 'Action has not been set';
    }
}

const workflowActionTypes = {
    redirect_to_workflow_landing: 1,
    redirect_to_workflow_rule: 2,
    redirect_to_public_web_page: 3,
    download_local_file: 4
}

const workflowActionTypeToText = code => {
    switch(code) {
        case workflowActionTypes.redirect_to_workflow_landing:
        return 'Redirect to Workflow Landing';

        case workflowActionTypes.redirect_to_workflow_rule:
        return 'Redirect to Workflow Rule';

        case workflowActionTypes.redirect_to_public_web_page:
        return 'Redirect to Public Web Page';

        case workflowActionTypes.download_local_file:
        return 'Download Local File';

        default:
        return 'Unknown action type';
    }
}

const workflowLandingComponents = {
    address_lookup: 1,
    bool_list: 2,
    button: 3,
    checkbox: 4,
    color_picker: 5,
    container: 6,
    date_picker: 7,
    division_lookup: 8,
    region_lookup: 9,
    duration_picker: 10,
    file_picker: 11,
    image: 12,
    image_picker: 13,
    list: 14,
    multiple_list: 15,
    multiple_textfield: 16,
    phone_number_field: 17,
    text: 18,
    textfield: 19,
    textview: 20,
    time_picker: 21,
    email_address_field: 22,
    comm_link_serial_number_field: 23,
    video: 24
}

const workflowLandingComponentToKey = code => {
    switch(code) {
        case workflowLandingComponents.address_lookup:
        return 'address_lookup';

        case workflowLandingComponents.bool_list:
        return 'bool_list';

        case workflowLandingComponents.button:
        return 'button';

        case workflowLandingComponents.checkbox:
        return 'checkbox';

        case workflowLandingComponents.color_picker:
        return 'color_picker';

        case workflowLandingComponents.date_picker:
        return 'date_picker';

        case workflowLandingComponents.division_lookup:
        return 'division_lookup';

        case workflowLandingComponents.region_lookup:
        return 'region_lookup';

        case workflowLandingComponents.duration_picker:
        return 'duration_picker';

        case workflowLandingComponents.file_picker:
        return 'file_picker';

        case workflowLandingComponents.image:
        return 'image';

        case workflowLandingComponents.image_picker:
        return 'image_picker';

        case workflowLandingComponents.list:
        return 'list';

        case workflowLandingComponents.multiple_list:
        return 'multiple_list';

        case workflowLandingComponents.multiple_textfield:
        return 'multiple_textfield';

        case workflowLandingComponents.text:
        return 'text';

        case workflowLandingComponents.textfield:
        return 'textfield';

        case workflowLandingComponents.textview:
        return 'textview';

        case workflowLandingComponents.time_picker:
        return 'time_picker';

        case workflowLandingComponents.container:
        return 'container';

        case workflowLandingComponents.phone_number_field:
        return 'phone_number_field';

        case workflowLandingComponents.email_address_field:
        return 'email_address_field';

        case workflowLandingComponents.comm_link_serial_number_field:
        return 'comm_link_serial_number_field';

        case workflowLandingComponents.video:
        return 'video';

        default:
        return 'Unknown component type';
    }
}

const workflowLandingComponentToText = code => {
    switch(code) {
        case workflowLandingComponents.address_lookup:
        return 'Address Lookup';

        case workflowLandingComponents.bool_list:
        return 'Yes/No Toggle';

        case workflowLandingComponents.button:
        return 'Button';

        case workflowLandingComponents.checkbox:
        return 'Checkbox';

        case workflowLandingComponents.color_picker:
        return 'Color Picker';

        case workflowLandingComponents.date_picker:
        return 'Date Selector';

        case workflowLandingComponents.division_lookup:
        return 'Division Lookup';

        case workflowLandingComponents.region_lookup:
        return 'Region Lookup';

        case workflowLandingComponents.duration_picker:
        return 'Duration Selector';

        case workflowLandingComponents.file_picker:
        return 'File Upload';

        case workflowLandingComponents.image:
        return 'Image';

        case workflowLandingComponents.image_picker:
        return 'Image Upload';

        case workflowLandingComponents.list:
        return 'List';

        case workflowLandingComponents.multiple_list:
        return 'Multiple Lists';

        case workflowLandingComponents.multiple_textfield:
        return 'Multiple Text Fields';

        case workflowLandingComponents.text:
        return 'Text';

        case workflowLandingComponents.textfield:
        return 'Text Field';

        case workflowLandingComponents.textview:
        return 'Text View';

        case workflowLandingComponents.time_picker:
        return 'Time Selector';

        case workflowLandingComponents.container:
        return 'Container';

        case workflowLandingComponents.phone_number_field:
        return 'Phone Number Field';

        case workflowLandingComponents.email_address_field:
        return 'Email Address Field';

        case workflowLandingComponents.comm_link_serial_number_field:
        return 'Comm Link Serial Number Field';

        case workflowLandingComponents.video:
        return 'Video';

        default:
        return 'Unknown component type';
    }
}

const workflowDataSources = {
    interaction: 1,
    workflow_landing: 2,
    custom: 3,
    workflow: 4
}

const workflowDataSourceToText = code => {
    switch(code) {
        case workflowDataSources.interaction:
        return 'Interaction';

        case workflowDataSources.workflow_landing:
        return 'Workflow Landing';

        case workflowDataSources.custom:
        return 'Custom Values';

        case workflowDataSources.workflow:
        return 'Workflow Data';

        default:
        return 'Unknown workflow data source';
    }
}

const workflowRouteAcceptsCollectionValue = route => {
    return [
        workflowRouteTypes.workflow_count,
        workflowRouteTypes.linear_feet,
        workflowRouteTypes.linear_miles,
        workflowRouteTypes.browser_version,
        workflowRouteTypes.time,
        workflowRouteTypes.date,
        workflowRouteTypes.os_type,
        workflowRouteTypes.os_version,
        workflowRouteTypes.browser_version,
        workflowRouteTypes.browser_type,
        workflowRouteTypes.user_appears_in_list,
        workflowRouteTypes.external_request
    ].includes(route.type) ? true : false;
}

const workflowRouteAcceptsCustomOperator = route => {
    return [
        workflowRouteTypes.workflow_count,
        workflowRouteTypes.linear_feet,
        workflowRouteTypes.linear_miles,
        workflowRouteTypes.coarse_location_zone,
        workflowRouteTypes.fine_location_zone,
        workflowRouteTypes.time,
        workflowRouteTypes.time_range,
        workflowRouteTypes.date,
        workflowRouteTypes.date_range,
        workflowRouteTypes.os_type,
        workflowRouteTypes.os_version,
        workflowRouteTypes.browser_version,
        workflowRouteTypes.browser_type,
        workflowRouteTypes.external_request,
        workflowRouteTypes.session_variable,
        workflowRouteTypes.user_appears_in_list
    ].includes(route.type) ? true : false;
}
const workflowRouteAcceptsCustomKey = route => {
    return route.type === workflowRouteTypes.session_variable ? true : false;
}

const workflowRouteAcceptsCustomValue = route => {
    if([workflowRouteTypes.request_aft_login, workflowRouteTypes.submit_support_session].includes(route.type) === true) {
        return false;
    }
    return workflowRouteAcceptsCustomOperator(route) === true && ![ workflowRouteOperators.is_null, workflowRouteOperators.is_not_null ].includes(route.operator) ? true : false;
}

const workflowRouteAllowsExtensions = () => {
    return false;
}

const workflowRouteAllowsDirectOperator = route => {
    return route && route.operator !== workflowRouteOperators.value_has_changed ? true : false;
}

const workflowRouteRequiresCollectionValue = route => {
    return [
        workflowRouteTypes.coarse_location_zone,
        workflowRouteTypes.date_range,
        workflowRouteTypes.fine_location_zone,
        workflowRouteTypes.time_range
    ].includes(route.type) ? true : false;
}

const workflowDestinations = {
    workflow_rule: 1,
    public_web_page: 2,
    local_file: 3,
    workflow_landing: 4
}

const workflowRouteOperators = {
    eq: 1,
    ne: 2,
    gt: 3,
    gte: 4,
    lt: 5,
    lte: 6,
    in: 7,
    not_in: 8,
    like: 9,
    is_null: 10,
    is_not_null: 11,
    value_has_changed: 12
}

const workflowRouteValueOperators = {
    exact_time: 1,
    time_since: 2,
    lead_time: 3
}

const workflowRouteTypes = {
    workflow_count: 1,
    linear_feet: 2,
    linear_miles: 3,
    coarse_location_zone: 4,
    fine_location_zone: 5,
    time: 6,
    time_range: 7,
    date: 8,
    date_range: 9,
    is_android: 10,
    is_apple: 11,
    is_app_clip: 12,
    is_browser: 13,
    is_desktop: 14,
    is_ios: 15,
    is_ipad_os: 16,
    is_linux: 17,
    is_mac_os: 18,
    is_mobile: 19,
    is_native: 20,
    is_tablet: 21,
    is_watch_os: 22,
    is_windows: 23,
    os_type: 24,
    os_version: 25,
    browser_version: 26,
    browser_type: 27,
    external_request: 28,
    session_variable: 29,
    submit_support_session: 30,
    forward_to_destination: 31,
    request_aft_login: 32,
    user_is_employee: 33,
    submit_replicated_website_contact_form: 34,
    submit_replicated_website_app_support_form: 35
}

const workflowTaskTypes = {
    push_notification: 1,
    sms: 2,
    email: 3,
    webhook: 4
}

const workflowTaskTypeToText = type => {
    switch(type) {
        case workflowTaskTypes.push_notification:
        return 'Send a push notification';

        case workflowTaskTypes.sms:
        return 'Send a text message';

        case workflowTaskTypes.email:
        return 'Send an email';

        case workflowTaskTypes.webhook:
        return 'Send session data to webhook url';

        default:
        return 'Unknown destination';
    }
}

const workflowDestinationsToText = destination => {
    switch(destination) {
        case workflowDestinations.workflow_rule:
        return 'Redirect to workflow rule';

        case workflowDestinations.public_web_page:
        return 'Redirect to a public web page';

        case workflowDestinations.local_file:
        return 'Download file hosted by GHS';

        case workflowDestinations.workflow_landing:
        return 'Redirect to workflow landing';

        default:
        return 'Unknown destination';
    }
}

const workflowRouteOperatorToText = operator => {
    switch(operator) {
        case workflowRouteOperators.eq:
        return 'equal to';

        case workflowRouteOperators.ne:
        return 'not equal to';

        case workflowRouteOperators.gt:
        return 'greater than';

        case workflowRouteOperators.gte:
        return 'greater than or equal to';

        case workflowRouteOperators.lt:
        return 'less than';

        case workflowRouteOperators.lte:
        return 'less than or equal to';

        case workflowRouteOperators.in:
        return 'within';

        case workflowRouteOperators.not_in:
        return 'not within';

        case workflowRouteOperators.like:
        return 'like';

        case workflowRouteOperators.is_null:
        return 'not defined';

        case workflowRouteOperators.is_not_null:
        return 'defined';

        case workflowRouteOperators.value_has_changed:
        return 'value has changed';

        default:
        return 'Unknown rule operator';
    }
}

const workflowRouteValueOperatorToText = operator => {
    switch(operator) {
        case workflowRouteValueOperators.exact_time:
        return 'exact time of occurence';

        case workflowRouteValueOperators.time_since:
        return 'amount of time since';

        case workflowRouteValueOperators.lead_time:
        return 'amount of time leading up to';

        default:
        return 'Unknown rule value operator';
    }
}

const workflowRouteTypeToText = type => {
    switch(type) {
        case workflowRouteTypes.workflow_count:
        return 'Number of interactions';

        case workflowRouteTypes.linear_feet:
        return 'Number of feet from location';

        case workflowRouteTypes.linear_miles:
        return 'Number of miles from location';

        case workflowRouteTypes.coarse_location_zone:
        return 'Coarse Location Zone';

        case workflowRouteTypes.fine_location_zone:
        return 'Fine Location Zone';

        case workflowRouteTypes.time:
        return 'Interaction time';

        case workflowRouteTypes.time_range:
        return 'Interaction time range';

        case workflowRouteTypes.date:
        return 'Interaction date';

        case workflowRouteTypes.date_range:
        return 'Interaction date range';

        case workflowRouteTypes.is_android:
        return 'Accessed from an Android device';

        case workflowRouteTypes.is_apple:
        return 'Accessed from an Apple device';

        case workflowRouteTypes.is_app_clip:
        return 'Accessed from an App Clip';

        case workflowRouteTypes.is_browser:
        return 'Accessed from a web browser';

        case workflowRouteTypes.is_desktop:
        return 'Accessed from a desktop or laptop computer';

        case workflowRouteTypes.is_ios:
        return 'Accessed from an iOS device';

        case workflowRouteTypes.is_ipad_os:
        return 'Accessed from an iPad';

        case workflowRouteTypes.is_linux:
        return 'Accessed from a Linux computer';

        case workflowRouteTypes.is_mac_os:
        return 'Accessed from a Mac';

        case workflowRouteTypes.is_mobile:
        return 'Accessed from a mobile device';

        case workflowRouteTypes.is_native:
        return 'Accessed from a native app';

        case workflowRouteTypes.is_tablet:
        return 'Accessed from a tablet';

        case workflowRouteTypes.is_watch_os:
        return 'Accessed from an Apple Watch';

        case workflowRouteTypes.is_windows:
        return 'Accessed from a Windows computer';

        case workflowRouteTypes.os_type:
        return 'Operating system type';

        case workflowRouteTypes.os_version:
        return 'Operating system version';

        case workflowRouteTypes.browser_version:
        return 'Browser version';

        case workflowRouteTypes.browser_type:
        return 'Browser type';

        case workflowRouteTypes.external_request:
        return 'Analyze data from an external source';

        case workflowRouteTypes.session_variable:
        return 'Analyze session variable';

        case workflowRouteTypes.submit_support_session:
        return 'Submit support session information';

        case workflowRouteTypes.forward_to_destination:
        return 'Forward to destination';

        case workflowRouteTypes.request_aft_login:
        return 'Request AFT Login';

        case workflowRouteTypes.user_is_employee:
        return 'User is an employee';

        case workflowRouteTypes.submit_replicated_website_contact_form:
        return 'Submit replicated website contact form';

        case workflowRouteTypes.submit_replicated_website_app_support_form:
        return 'Submit replicated website mobile app support form';

        default:
        return 'Unknown rule type';
    }
}

const supportRequestStatusCodes = {
    pending: null,
    accepted: 1,
    rejected: 2,
    cancelled: 3,
    internal_update: 4,
    external_update: 5,
    resolved: 6,
    not_resolved: 7
}

const supportRequestStatusCodeToText = code => {
    switch(code) {
        case supportRequestStatusCodes.pending:
        return 'Pending';

        case supportRequestStatusCodes.accepted:
        return 'Accepted';

        case supportRequestStatusCodes.rejected:
        return 'Rejected';

        case supportRequestStatusCodes.cancelled:
        return 'Cancelled';

        case supportRequestStatusCodes.internal_update:
        return 'Updated';

        case supportRequestStatusCodes.external_update:
        return 'New Content Available';

        case supportRequestStatusCodes.resolved:
        return 'Resolved';

        case supportRequestStatusCodes.not_resolved:
        return 'Not Resolved';
    }
}

const fetchSupportRequest = async (utils, id, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { request } = await Request.get(utils, '/support/', {
                id: id,
                type: 'request_details',
                ...props
            });
            resolve(new SupportRequestClass().create(request));

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    Article: {
        create: props => new KBArticleClass().create(props),
        new: () => new KBArticleClass()
    },
    Request: {
        create: props => new SupportRequestClass().create(props),
        get: fetchSupportRequest,
        Response: {
            create: props => new SupportRequestResponseClass().create(props)
        },
        status: {
            get: () => supportRequestStatusCodes,
            toText: supportRequestStatusCodeToText
        }
    },
    Workflow: {
        create: props => new SupportWorkflowClass().create(props),
        new: () => new SupportWorkflowClass(),
        Action: {
            types: {
                get: () => workflowActionTypes,
                toText: workflowActionTypeToText
            }
        },
        Landing: {
            new: () => new SupportWorkflowLandingClass(),
            create: props => new SupportWorkflowLandingClass().create(props),
            components: {
                get: () => workflowLandingComponents,
                toKey: workflowLandingComponentToKey,
                toText: workflowLandingComponentToText
            },
            content: {
                compress: compressContent
            }
        },
        data_sources: {
            get: () => workflowDataSources,
            toText: workflowDataSourceToText
        },
        destinations: {
            get: () => workflowDestinations,
            toText: workflowDestinationsToText
        },
        forward: {
            destinations: {
                get: () => forwardDestinations,
                toText: forwardDestinationToText
            },
            values: {
                types: {
                    get: () => forwardValueTypes,
                    toText: forwardValueTypesToText
                }
            }
        },
        Rule: {
            new: () => new SupportWorkflowRuleClass(),
            create: props => new SupportWorkflowRuleClass().create(props),
            accepts: {
                collection: workflowRouteAcceptsCollectionValue,
                key: workflowRouteAcceptsCustomKey,
                operator: workflowRouteAcceptsCustomOperator,
                value: workflowRouteAcceptsCustomValue
            },
            allows: {
                extensions: workflowRouteAllowsExtensions,
                direct_operator: workflowRouteAllowsDirectOperator
            },
            format: {
                content: formatConditionalContent,
                description: formatConditionalDescription,
                title: formatConditionalTitle
            },
            operators: {
                get: () => workflowRouteOperators,
                toText: workflowRouteOperatorToText,
                values: {
                    get: () => workflowRouteValueOperators,
                    toText: workflowRouteValueOperatorToText,
                }
            },
            requires: {
                collection: workflowRouteRequiresCollectionValue
            },
            Route: {
                new: () => new SupportWorkflowRuleRouteClass(),
                create: props => new SupportWorkflowRuleRouteClass().create(props),
                overview: { 
                    get: getRouteOverview
                }
            },
            Task: {
                new: () => new SupportWorkflowRuleTaskClass(),
                create: props => new SupportWorkflowRuleTaskClass().create(props),
                overview: { 
                    get: getTaskOverview
                },
                types: {
                    get: () => workflowTaskTypes,
                    toText: workflowTaskTypeToText
                }
            },
            types: {
                external: {
                    get: () => externalMethods,
                    requires: {
                        payload: externalMethodRequiresPayload
                    },
                    toText: externalMethodToText
                },
                get: () => workflowRouteTypes,
                toText: workflowRouteTypeToText
            }
        }
    }
}
