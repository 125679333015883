import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import BoolToggle from 'views/BoolToggle.js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import Content from 'managers/Content.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { CollapseArrow, LayerItem } from 'structure/Layer.js';
import ListField from 'views/ListField.js';
import LottieView from 'views/Lottie.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Payment from 'classes/Payment.js';
import Request from 'files/Request.js';
import { TableListHeader, TableListRow } from 'views/TableList.js';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';

export const AddPaymentMethod = ({ dealership, onChange, sourceCategory }, { index, options, utils }) => {

    const stripeProps = useRef(null);

    const layerID = 'add_payment_method';
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [newMethodCompleted, setNewMethodCompleted] = useState(false);

    const onAddNewMethod = async () => {
        try {

            // prevent moving forward if method input is not complete
            if(newMethodCompleted === false) {
                throw new Error('Please fill out your card number, expiration date, and cvc code to continue.');
            }

            // set loading flag and prepare token for card
            setLoading('submit');
            let element = stripeProps.current.elements.getElement(CardElement);
            let { token } = await stripeProps.current.stripe.createToken(element);

            // submit request to server
            let { method } = await Request.post(utils, '/payments/', {
                dealership_id: dealership && dealership.id || utils.dealership.get().id,
                source_category: sourceCategory,
                source_id: token.card.id,
                token: token.id,
                type: 'new_payment_method'
            });

            // end loading and clear payment method field
            setLoading(false);
            element.clear();

            // notify subscribers that a new payment method has been created
            utils.content.fetch('payment_method');

            // notify listeners that a change has posted
            if(typeof(onChange) === 'function') {
                onChange(Payment.Method.create(method));
            }

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `Your new payment method has been added to ${sourceCategory === Payment.Method.source_categories.get().dealership ? 'the dealership' : 'your account'}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding your payment method. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            key: 'submit',
            text: 'Submit',
            loading: loading === 'submit',
            color: newMethodCompleted ? 'primary' : 'dark',
            onClick: onAddNewMethod
        }];
    }

    const getStripeCardComponent = () => {
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                padding: 12
            }}>
                <Elements stripe={utils.stripe}>
                    <ElementsConsumer>
                        {props => {
                            stripeProps.current = props;
                            return (
                                <CardElement
                                onChange={({ complete }) => setNewMethodCompleted(complete)}
                                options={{
                                    hidePostalCode: true,
                                    style: {
                                        invalid: {
                                            color: Appearance.colors.red,
                                        },
                                        base: {
                                            color: Appearance.colors.text(),
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            '::placeholder': {
                                                color: Appearance.colors.subText(),
                                            },
                                        }
                                    }
                                }} />
                            )
                        }}
                    </ElementsConsumer>
                </Elements>
            </div>
        )
    }

    const getTitle = () => {
        return `New ${sourceCategory === Payment.Method.source_categories.get().dealership ? 'Dealership' : 'Personal'} Payment Method`;
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={getTitle()}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            {getStripeCardComponent()}
        </Layer>
    )
}

export const AddEditPromotion = ({ isNewTarget, onChangeAsync, promotion }, { index, options, utils }) => {

    const layerID = isNewTarget ? 'new_promotion' : `edit_promotion_${promotion.id}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [edits, setEdits] = useState(promotion || {});

    const onDoneClick = async () => {
        try {

            // verify that variables were provided
            setLoading('done');
            await validateRequiredFields(getFields);

            // verify that start date is not after end date
            if(edits.end_date && edits.end_date < edits.start_date) {
                throw new Error('The end date must be after the start date');
            }

            // call async onChange handler and wait for a resolve before closing the layer
            // the handler validates the requested dates against previously requested dates
            await onChangeAsync(edits);
            setLayerState('close');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your changes. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits(edits => ({ ...edits, ...props }));
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onDoneClick,
            text: 'Done'
        }];
    }

    const getFields = () => {
        return [{
            collapsed: null,
            key: 'details',
            title: 'Details',
            items: [{
                component: 'textfield',
                key: 'title',
                onChange: text => onUpdateTarget({ title: text }),
                title: 'Title',
                value: edits.title
            },{
                component: 'textfield',
                key: 'amount',
                onChange: val => onUpdateTarget({ amount: val }),
                props: {
                    format: 'currency',
                    prepend: '$'
                },
                title: 'Cost',
                value: edits.amount
            },{
                component: 'date_picker',
                key: 'start_date',
                onChange: date => onUpdateTarget({ start_date: date }),
                title: 'Start Date',
                value: edits.start_date
            },{
                component: 'date_picker',
                key: 'end_date',
                onChange: date => onUpdateTarget({ end_date: date }),
                required: false,
                title: 'End Date',
                value: edits.end_date
            }]
        }];
    }

    useEffect(() => {
        setEdits({
            amount: '0.00',
            ...promotion
        });
    }, [])
    
    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? 'New Promotion' : `Editing "${promotion.title}" Promotion`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const DealershipSubscriptions = ({ index, options, utils }) => {

    const panelID = 'dealerships_subscriptions';
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef({ 
        sort_key: 'dealership_name', 
        sort_type: Content.sorting.type.ascending 
    });

    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onSubscriptionClick = subscription => {
        utils.layer.open({
            abstract: Abstract.create({
                object: subscription,
                type: 'subscription'
            }),
            Component: SubscriptionDetails,
            id: `subscription_details_${subscription.id}`
        });
    }

    const getContent = () => {
        if(subscriptions.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No dealership subscriptions were found',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {subscriptions.map((subscription, index) => {
                        return getFields(subscription, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (subscription, index) => {

        let target = subscription || {};
        let fields = [{
            key: 'dealership_name',
            title: 'Dealership',
            value: target.dealership && target.dealership.name
        },{
            key: 'category',
            sortable: false,
            title: 'Description',
            value: target.category && (
                <AltBadge content={{
                    color: target.category.color,
                    text: `${target.category.text} Subscription`
                }} />
            )
        },{
            key: 'date',
            title: 'Registration Date',
            value: target.date && Utils.formatDate(target.date)
        },{
            key: 'renewal_date',
            title: 'Renewal Date',
            value: target.renewal_date ? Utils.formatDate(target.renewal_date, true) : 'No upcoming payments scheduled'
        },{
            key: 'card_fingerprint',
            title: 'Payment Method',
            value: target.card_fingerprint || 'Default Payment Method'
        },{
            key: 'schedule',
            title: 'Schedule',
            value: target.schedule && target.schedule.text
        },{
            key: 'amount',
            title: 'Amount',
            value: target.amount && (
                <AltBadge content={{
                    color: Appearance.colors.green,
                    text: Utils.toCurrency(target.amount.value)
                }} />
            )
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!subscription) {
            return (
                <TableListHeader
                fields={fields}
                onChange={props => {
                    sorting.current = props;
                    fetchSubscriptions();
                }} 
                value={sorting.current} />
            )
        }
        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === subscriptions.length - 1}
            onClick={onSubscriptionClick.bind(this, subscription)} />
        )
    }

    const fetchSubscriptions = async () => {
        try {

            setLoading(true);
            let { paging, subscriptions } = await Request.get(utils, '/payments/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'all_dealership_subscriptions',
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setSubscriptions(subscriptions.map(subscription => Payment.Subscription.create(subscription)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the dealership subscriptions list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchSubscriptions();
    }, [searchText]);

    useEffect(() => {
        utils.content.subscribe(panelID, ['subscription'], {
            onFetch: fetchSubscriptions,
            onUpdate: fetchSubscriptions
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Dealership Subscriptions'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    setLoading(true);
                    fetchSubscriptions();
                }
            },
            search: {
                placeholder: 'Search by dealership name or subscription id...',
                onChange: setSearchText
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const NewSubscriptionRequest = ({ category, onComplete, schedule, sourceCategory }, { abstract, index, options, utils }) => {

    const layerID = 'new_subscription_request';

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [promotions, setPromotions] = useState([]);
    const [request, setRequest] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [selectedSchedule, setSelectedSchedule] = useState(schedule);

    const onAddNewPromotion = () => {
        utils.layer.open({
            id: 'new_promotion',
            Component: AddEditPromotion.bind(this, {
                isNewTarget: true,
                onChangeAsync: async result => {

                    // verify that requested promotion does not overlay with other promotions
                    await Payment.Subscription.promotions.validate(result, promotions);

                    // update local state with new promotion
                    setPromotions(promotions => {
                        return update(promotions, {
                            $push: [{
                                ...result,
                                id: `${moment().unix()}.${Utils.randomString(10)}`
                            }]
                        })
                    });
                }
            })
        });
    }

    const onEditPromotion = promotion => {
        utils.layer.open({
            id: `edit_promotion_${promotion.id}`,
            Component: AddEditPromotion.bind(this, {
                isNewTarget: false,
                onChangeAsync: async result => {

                    // verify that requested promotion does not overlay with other promotions
                    await Payment.Subscription.promotions.validate(result);

                    // update local state with new values
                    setPromotions(promotions => {
                        let index = promotions.findIndex(p => p.id === promotion.id);
                        return update(promotions, {
                            [index]: {
                                $set: result
                            }
                        })
                    });
                },
                promotion: promotion
            })
        });
    }

    const onRemovePromotion = (id, evt) => {
        evt.stopPropagation();
        setPromotions(promotions => promotions.filter(promotion => promotion.id !== id));
    }

    const onSubmit = async () => {
        try {

            // prevent moving forward if a payment schedule has not been selected
            if(!selectedSchedule) {
                throw new Error('Please select a plan option to continue.');
            }

            // prevent moving forward if a payment method has not been selected
            if(!selectedMethod) {
                throw new Error('Please select a payment method to continue.');
            }

            // filter out non-matching promotions and format for query
            let targets = promotions.filter(promotion => {
                return promotion.schedule ? promotion.schedule === selectedSchedule : true;
            }).map(promotion => ({
                ...promotion,
                end_date: promotion.end_date && promotion.end_date.format('YYYY-MM-DD'),
                start_date: promotion.start_date && promotion.start_date.format('YYYY-MM-DD'),
            }))
            
            // submit request to server to create subscription
            setLoading('submit');
            let { id } = await Request.post(utils, '/payments/', {
                card_fingerprint: selectedMethod.fingerprint,
                category: category,
                dealership_id: abstract.getID(),
                promotions: targets,
                schedule: selectedSchedule,
                source_category: sourceCategory,
                type: 'new_subscription'
            });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The subscription for ${abstract.getTitle()} has been created and the first payment has been processed (if applicable). We have sent an invoice to the email address on file with the final subscription cost.`,
                onClick: onSubscriptionCreated.bind(this, id)
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your subscription. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubscriptionCreated = async id => {
        try {

            // fetch details for subscription
            let subscription = await Payment.Subscription.get(utils, id);

            // close layer and notify subscribers of new data
            setLayerState('close');
            utils.content.fetch('subscription');

            // notify subscribers that a subscription has been added
            utils.events.emit('subscription_added', {
                id: abstract.getID(),
                subscription: subscription
            });

            // notify subscribers of completion if applicable
            if(typeof(onComplete) === 'function') {
                onComplete(subscription);
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'submit',
            loading: loading === 'submit',
            onClick: onSubmit,
            text: 'Submit'
        }];
    }

    const getOverview = () => {
        if(!request || loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                {request.items.map((item, index, items) => {
                    if(item.key === 'description') {
                        return (
                            <div style={{
                                padding: '8px 12px 8px 12px',
                                width: '100%'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.key(),
                                    maxWidth: null,
                                    whiteSpace: 'normal',
                                    width: '100%'
                                }}>{item.value}</span>
                            </div>
                        )
                    }
                    return (
                        Views.row({
                            bottomBorder: index !== items.length - 1,
                            key: index,
                            label: item.title,
                            value: item.value,
                            style: {
                                value: {
                                    maxWidth: '50%'
                                }
                            }
                        })
                    )
                })}
            </div>
        )
    }

    const getPlanOptions = () => {

        if(!request || loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                {request.options.map((option, index, options) => {
                    return (
                        Views.entry({
                            bottomBorder: index !== options.length - 1,
                            key: index,
                            hideOnClickArrow: true,
                            icon: { 
                                path: 'images/subscription-icon-clear.png',
                                imageStyle: {
                                    backgroundColor: Appearance.colors.grey()
                                }
                            },
                            onClick: setSelectedSchedule.bind(this, option.schedule),
                            title: `${Utils.toCurrency(option.amount)} ${option.descriptor}`,
                            rightContent: (
                                <>
                                {selectedSchedule === option.schedule && (
                                    <img
                                    src={'images/checkmark-green.png'}
                                    style={{
                                        height: 18,
                                        marginLeft: 8,
                                        width: 18
                                    }} />
                                )}
                                {selectedSchedule !== option.schedule &&  (
                                    <img
                                    src={'images/next-arrow-grey-small.png'}
                                    style={{
                                        height: 12,
                                        marginLeft: 8,
                                        objectFit: 'contain',
                                        opacity: 0.75,
                                        width: 12
                                    }} />
                                )}
                                </>
                            ),
                            subTitle: `Renews ${Utils.formatDate(option.renewal_date, true)}`
                        })
                    )
                })}
            </div>
        )
    }

    const getPromotions = () => {

        if(!request || loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                {promotions.length === 0 && (
                    <div style={{
                        alignItems: 'center',
                        borderBottom: `1px solid ${Appearance.colors.divider()}`,
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 10,
                        width: '100%',
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            flexGrow: 1
                        }}>{'No promotions found for this subscription'}</span>
                    </div>
                )}
                {promotions.filter(promotion => {
                    return promotion.schedule ? promotion.schedule === selectedSchedule : true;
                }).map((promotion, index) => {
                    return (
                        Views.entry({
                            bottomBorder: true,
                            key: index,
                            icon: { 
                                path: 'images/promotions-icon-clear.png',
                                imageStyle: {
                                    backgroundColor: Appearance.colors.green
                                }
                            },
                            onClick: utils.user.get().level <= User.levels.get().admin ? onEditPromotion.bind(this, promotion) : null,
                            rightContent: (
                                <img 
                                className={'text-button'}
                                onClick={onRemovePromotion.bind(this, promotion.id)}
                                src={'images/red-x-icon.png'}
                                style={{
                                    height: 18,
                                    marginLeft: 8,
                                    width: 18
                                }} />
                            ),
                            title: promotion.title,
                            subTitle: Payment.Subscription.promotions.toOverview(promotion)
                        })
                    )
                })}
                {utils.user.get().level <= User.levels.get().admin && (
                    <div 
                    className={`view-entry ${window.theme}`}
                    onClick={onAddNewPromotion}
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 10,
                        width: '100%',
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            flexGrow: 1
                        }}>{'Add New Promotional Period'}</span>
                        <img
                        src={'images/next-arrow-grey-small.png'}
                        style={{
                            height: 12,
                            marginLeft: 8,
                            objectFit: 'contain',
                            opacity: 0.75,
                            width: 12
                        }} />
                    </div>
                )}
            </div>
        )
    }

    const fetchSubscriptionRequest = async () => {
        try {

            // set loading flag and retrieve subscription details from the server
            setLoading(true);
            let response = await Request.get(utils, '/payments/', {
                category: category,
                dealership_id: abstract.getID(),
                source_category: sourceCategory,
                type: 'subscription_request'
            });

            // end loading and show alert with subscription confirmation request
            setLoading(false);
            setRequest(response);

            // determine if server generated promotions need to be formatted
            if(response && response.promotions) {
                let promotions = response.promotions.map(promotion => ({
                    ...promotion,
                    end_date: moment.utc(promotion.end_date).local(),
                    start_date: moment.utc(promotion.start_date).local()
                }));
                setPromotions(promotions);
                setSelectedSchedule(schedule || response.options[0].schedule);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the subscription details. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        fetchSubscriptionRequest();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'New Subscription Confirmation'}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <LayerItem 
            collapsed={false}
            title={'Overview'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {getOverview()}
                </div>
            </LayerItem>

            <LayerItem
            title={'Plan Options'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {getPlanOptions()}
                </div>
            </LayerItem>

            <LayerItem
            title={'Promotional Periods'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                   {getPromotions()}
                </div>
            </LayerItem>
    
            <LayerItem
            title={'Payment Methods'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    <PaymentMethodSelector 
                    dealership={abstract.object}
                    onChange={setSelectedMethod} 
                    utils={utils}
                    sourceCategory={sourceCategory}/>
                </div>
            </LayerItem>
        </Layer>
    )
}

export const Payments = ({ category, id, title }, { index, options, utils }) => {

    const limit = 10;
    const offset = useRef(0);
    const platformFilter = useRef(null);
    const sorting = useRef({ sort_key: 'date', sort_type: Content.sorting.type.descending });

    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [payments, setPayments] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onDealershipChange = () => {
        offset.current = 0;
        fetchPayments();
    }

    const onPaymentClick = async id => {
        try {

            // set loading flag and retrieve payment details
            setLoading(id);
            let payment = await Payment.get(utils, id);
            setLoading(false);

            // determine if payment is marked as unpaid
            if(payment.status.code === Payment.status.get().unpaid) {
                utils.layer.open({
                    abstract: Abstract.create({
                        object: payment.source_category.code === Payment.Method.source_categories.get().dealership ? payment.dealership : payment.user,
                        type: payment.source_category.code === Payment.Method.source_categories.get().dealership ? 'dealership' : 'user'
                    }),
                    Component: UnpaidInvoiceManagement.bind(this, { payment }),
                    id: `unpaid_invoice_management_${payment.id}`
                });
                return;
            }
            
            // fallback to showing stadard details layer for payment
            utils.layer.open({
                abstract: Abstract.create({
                    object: payment,
                    type: 'payment'
                }),
                Component: PaymentDetails,
                id: `payment_details_${id}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this payment. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getCategoryIdentifiers = () => {
        switch(category) {
            case Payment.Method.source_categories.get().dealership:
            return { dealership_id: utils.dealership.get().id }

            case Payment.Method.source_categories.get().user:
            return { user_id: utils.user.get().user_id }
        }
    }

    const getContent = () => {
        if(payments.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No payments were found for your account',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {payments.map((payment, index) => {
                        return getFields(payment, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (payment, index) => {

        let target = payment || {};
        let fields = [{
            key: 'id',
            title: 'ID',
            value: target.internal_id
        },{
            key: 'date',
            title: 'Date',
            value: target.date ? Utils.formatDate(target.date) : null
        },{
            key: 'card_fingerprint',
            title: 'Payment Method',
            value: target.card_fingerprint
        },{
            key: 'platform',
            title: 'Platform',
            value: target.platform && target.platform.text
        },{
            key: 'category',
            title: 'Description',
            value: target.category && target.category.text
        },{
            key: 'status',
            title: 'Status',
            value: target.status && (
                <AltBadge content={target.status} />
            )
        },{
            key: 'amount',
            title: 'Amount',
            value: (
                <AltBadge content={{
                    color: target.amount > 0 ? Appearance.colors.green : Appearance.colors.grey(),
                    text: Utils.toCurrency(target.amount || 0)
                }} />
            )
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!payment) {
            return (
                <TableListHeader
                fields={fields}
                onChange={props => {
                    sorting.current = props;
                    fetchPayments();
                }} 
                value={sorting.current} />
            )
        }
        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === payments.length - 1}
            onClick={onPaymentClick.bind(this, payment.id)} />
        )
    }

    const getPlatformsFilter = () => {

        // prepare list of platform options
        let items = Object.values(Payment.platforms.get()).map(code => ({
            id: code,
            title: Payment.platforms.toText(code)
        })).sort((a,b) => {
            return a.title.localeCompare(b.title);
        });

        return (
            <ListField 
            items={items}
            onChange={item => {
                platformFilter.current = item && item.id;
                fetchPayments();
            }} 
            placeholder={'Choose a platform from the list below'} 
            style={{
                marginLeft: 8,
                width: 300
            }}/>
        )
    }

    const fetchPayments = async () => {
        try {

            setLoading(true);
            let { paging, payments } = await Request.get(utils, '/payments/', {
                limit: limit,
                offset: offset.current,
                platform: platformFilter.current,
                search_text: searchText,
                source_category: category,
                type: 'all',
                ...sorting.current,
                ...getCategoryIdentifiers()
            });

            setLoading(false);
            setPaging(paging);
            setPayments(payments.map(payment => Payment.create(payment)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the payments list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchPayments();
    }, [searchText]);

    useEffect(() => {
        utils.events.on(id, 'dealership_change', onDealershipChange);
        utils.content.subscribe(id, ['payment'], {
            onFetch: fetchPayments
        });
        return () => {
            utils.content.unsubscribe(id);
            utils.events.off(id, 'dealership_change', onDealershipChange);
        }
    }, []);

    return (
        <Panel
        panelID={id}
        name={title}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    setLoading(true);
                    fetchPayments();
                }
            },
            search: {
                placeholder: 'Search by id or description...',
                onChange: setSearchText,
                rightContent: getPlatformsFilter()
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const PaymentDetails = ({ abstract, index, options, utils }) => {

    const layerID = `payment_details_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [payment, setPayment] = useState(abstract.object);
    const [transactions, setTransactions] = useState([]);
    const [refunds, setRefunds] = useState([]);

    const onCancelPayment = () => {
        utils.alert.show({
            title: 'Cancel Payment',
            message: 'Are you sure that you want to cancel this payment? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setTimeout(onCancelPaymentConfirm, 250);
                    return;
                }
            }
        });
    }

    const onCancelPaymentConfirm = async () => {
        try {

            setLoading('options');
            let { status } = await Request.post(utils, '/payments/', {
                id: abstract.getID(),
                type: 'cancel'
            });

            // update status and local state
            abstract.object.status = status;
            setPayment(abstract.object);

            // notify subscribers of data change
            utils.content.fetch('payment');

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'This payment has been cancelled',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancelling this payment. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onOptionsClick = evt => {
        let isAdmin = utils.user.get().level <= User.levels.get().admin
        utils.sheet.show({
            items: [{
                key: 'set_cancel',
                title: 'Cancel Payment',
                style: 'destructive',
                visible: isAdmin && payment.status.code === Payment.status.get().unpaid
            },{
                key: 'refund',
                title: 'Create Refund',
                style: 'destructive',
                visible: isAdmin && payment.status.code !== Payment.status.get().unpaid
            },{
                key: 'process',
                title: 'Manually Process Payment',
                style: 'default',
                visible: isAdmin && payment.status.code === Payment.status.get().unpaid
            },{
                key: 'invoice',
                title: 'Resend Invoice',
                style: 'default',
                visible: payment.status.code === Payment.status.get().paid
            }],
            target: evt.target
        }, key => {
            switch(key) {

                case 'invoice':
                onResendInvoice();
                break;

                case 'process':
                onProcessPayment();
                break;

                case 'set_cancel':
                onCancelPayment();
                break;

                case 'refund':
                onRefundPayment();
                break;
            }
        });
    }

    const onPaymentMethodClick = async () => {
        try {

            // set loading flag and retrieve payment method details
            setLoading('payment_method');
            let method = await Payment.Method.get(utils, payment.card_fingerprint, { 
                dealership_id: payment.dealership && payment.dealership.id,
                user_id: payment.user && payment.user.user_id  
            });

            // end loading and show details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: method,
                    type: 'payment_method'
                }),
                Component: PaymentMethodDetails,
                id: `payment_method_details_${method.id}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this payment method. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onProcessPayment = () => {
        utils.layer.open({
            id: `process_payment_${abstract.getID()}`,
            abstract: abstract,
            Component: ProcessPayment.bind(this, {})
        });
    }

    const onRefundClick = (refund, evt) => {
        utils.sheet.show({
            items: [{
                key: 'confirmation',
                title: 'Resend Confirmation',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'confirmation') {
                onResendRefundConfirmation(refund);
                return;
            }
        });
    }

    const onResendRefundConfirmation = refund => {

        // declare default email address using source category for payment
        let emailAddress  = payment.source_category.code === Payment.Method.source_categories.get().dealership ? payment.dealership.dealer.email_address : payment.user.email_address;

        // show alert requesting email address
        utils.alert.show({
            title: 'Resend Refund Confirmation',
            message: 'Where would you like to send this refund confirmation?',
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <TextField
                    onChange={text => emailAddress = text}
                    placeholder={'Email address'}
                    value={emailAddress} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Send',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(emailAddress && key === 'confirm') {
                    onResendRefundConfirmationConfirm(refund, emailAddress);
                    return;
                }
            }
        });
    }

    const onResendRefundConfirmationConfirm = async (refund, emailAddress) => {
        try {

            // set loading flag and send request to server
            setLoading('options');
            await Request.post(utils, '/payments/', {
                email_address: emailAddress,
                id: refund.id,
                type: 'resend_refund_confirmation'
            });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The confirmation for this refund has been sent to ${emailAddress}.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue resending the confirmation for this refund. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onRefundPayment = () => {
        let amount = payment.amount;
        utils.alert.show({
            title: 'Create Refund',
            message: `How much of this ${Utils.toCurrency(payment.amount)} payment would you like to refund?`,
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <TextField
                    fieldStyle={{
                        textAlign: 'center'
                    }}
                    format={'currency'}
                    onChange={text => amount = text}
                    placeholder={'Refund amount'}
                    prepend={'$'}
                    value={amount} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Submit',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(amount > 0 && key === 'confirm') {
                    setTimeout(onRefundPaymentConfirm.bind(this, amount), 500);
                    return;
                }
            }
        });
    }

    const onRefundPaymentConfirm = async amount => {
        try {

            // prevent moving forward if requested amount is greater than the payment amount
            if(amount > payment.amount) {
                throw new Error(`The refund amount can not exceed the payment amount of ${Utils.toCurrency(payment.amount)}`);
            }

            // set loading flag and send request to server
            setLoading('options');
            let { status } = await Request.post(utils, '/payments/', {
                amount: amount,
                id: abstract.getID(),
                type: 'new_refund'
            });

            // notify subscribers of data change
            utils.content.fetch('payment');

            // update abstract target and local state
            abstract.object.status = status;
            setPayment(abstract.object);

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The ${Utils.toCurrency(amount)} refund for this payment has been completed.`,
                onClick: fetchDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting the refund for this payment. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onResendInvoice = () => {

        // declare default email address using source category for payment
        let emailAddress  = payment.source_category.code === Payment.Method.source_categories.get().dealership ? payment.dealership.dealer.email_address : payment.user.email_address;

        // show alert requesting email address
        utils.alert.show({
            title: 'Resend Invoice',
            message: 'Where would you like to send this invoice?',
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <TextField
                    onChange={text => emailAddress = text}
                    placeholder={'Email address'}
                    value={emailAddress} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Send',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(emailAddress && key === 'confirm') {
                    onResendInvoiceConfirm(emailAddress);
                    return;
                }
            }
        });
    }

    const onResendInvoiceConfirm = async emailAddress => {
        try {

            // set loading flag and send request to server
            setLoading('options');
            await Request.post(utils, '/payments/', {
                email_address: emailAddress,
                id: abstract.getID(),
                type: 'resend_invoice'
            });

            // notify subscribers of data change
            utils.content.fetch('payment');

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The invoice for this payment has been sent to ${emailAddress}.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue resending the invoice for this payment. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        let showOptions = utils.user.get().level <= User.levels.get().admin || payment.status.code === Payment.status.get().paid;
        if(showOptions) {
            return [{
                color: 'secondary',
                text: 'Options',
                loading: loading === 'options',
                onClick: onOptionsClick,
                key: 'options'
            }];
        }
    }

    const getCostBreakdown = () => {

        // prepare list of cost breakdown targets
        let targets = payment.metadata.line_items || [];
        return targets.length > 0 && (
            <LayerItem 
            collapsed={false}
            title={'Cost Breakdown'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {targets.map((item, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== targets.length - 1,
                                hideIcon: true,
                                key: index,
                                subTitle: item.description,
                                title: item.title,
                                rightContent: (
                                    <AltBadge content={{
                                        color: item.color && Appearance.colors[item.color] || Appearance.colors.grey(),
                                        text: Utils.toCurrency(item.amount)
                                    }} />
                                )
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'source_category',
                title: 'Category',
                value: payment.source_category.code === Payment.Method.source_categories.get().user ? 'Personal' : 'Dealership'
            },{
                key: 'dealership',
                title: 'Dealership',
                value: payment.dealership.name
            },{
                key: 'id',
                title: 'ID',
                value: payment.internal_id
            },{
                key: 'platform',
                title: 'Platform',
                value: payment.platform.text
            },{
                key: 'user',
                title: 'User',
                value: payment.user && payment.user.full_name,
                visible: payment.source_category.code === Payment.Method.source_categories.get().user
            }]
        },{
            key: 'invoice',
            title: 'Invoice Details',
            items: [{
                key: 'category.text',
                title: 'Description',
                value: payment.category.text
            },{
                key: 'invoice_date',
                title: 'Due Date',
                value: payment.metadata.invoice_date && Utils.formatDate(payment.metadata.invoice_date)
            }]
        },{
            key: 'invoice',
            lastItem: false,
            title: 'Payment Processing',
            items: [{
                color: Appearance.colors.green,
                key: 'amount',
                title: 'Amount',
                value: Utils.toCurrency(payment.amount)
            },{
                key: 'date',
                title: 'Date',
                value: Utils.formatDate(payment.date)
            },{
                key: 'failure_message',
                title: 'Failure Message',
                value: payment.metadata.failure_message,
                visible: payment.metadata.failure_message ? true : false
            },{
                key: 'processing_attempts',
                title: 'Number of Previous Attempts',
                value: payment.metadata.processing_attempts,
                visible: payment.metadata.processing_attempts > 0
            },{
                key: 'card_fingerprint',
                loading: loading === 'payment_method',
                onClick: onPaymentMethodClick,
                title: 'Payment Method',
                value: payment.card_fingerprint
            },{
                color: payment.status.color,
                key: 'status',
                title: 'Status',
                value: payment.status.text
            }]
        }];
    }

    const getRefunds = () => {
        return refunds.length > 0 && (
            <LayerItem 
            collapsed={false}
            lastItem={true}
            title={'Refunds'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {loading === true && (
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            height: 100,
                            justifyContent: 'center',
                            width: '100%'
                        }}>
                            <LottieView
                            autoPlay={true}
                            loop={true}
                            source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                            style={{
                                height: 40,
                                width: 40
                            }}/>
                        </div>
                    )}
                    {refunds.map((refund, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== refunds.length - 1,
                                hideIcon: true,
                                key: index,
                                onClick: onRefundClick.bind(this, refund),
                                rightContent: (
                                    <AltBadge content={{
                                        color: Appearance.colors.red,
                                        text: Utils.toCurrency(refund.amount)
                                    }} />
                                ),
                                subTitle: `Refund ID: ${refund.refund_id}`,
                                title: Utils.formatDate(refund.date),
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getTransactions = () => {
        return transactions.length > 0 && (
            <LayerItem 
            collapsed={false}
            lastItem={refunds.length === 0}
            title={'Transactions'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {loading === true && (
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            height: 100,
                            justifyContent: 'center',
                            width: '100%'
                        }}>
                            <LottieView
                            autoPlay={true}
                            loop={true}
                            source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                            style={{
                                height: 40,
                                width: 40
                            }}/>
                        </div>
                    )}
                    {transactions.map((transaction, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== transactions.length - 1,
                                hideIcon: true,
                                key: index,
                                rightContent: (
                                    <AltBadge content={{
                                        color: Appearance.colors.green,
                                        text: Utils.toCurrency(transaction.amount)
                                    }} />
                                ),
                                subTitle: `Transaction ID: ${transaction.external_id || 'Not available'}`,
                                title: Utils.formatDate(transaction.date),
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const fetchDetails = async () => {
        try {
            // send request to server
            setLoading(true);
            let { refunds, transactions } = await Request.get(utils, '/payments/', {
                id: abstract.getID(),
                type: 'details'
            });

            // end loading and update local state
            setLoading(false);
            setTransactions(transactions.map(transaction => Payment.Transaction.create(transaction)));
            setRefunds(refunds.map(refund => Payment.Refund.create(refund)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this payment. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchDetails();
        utils.content.subscribe(layerID, ['payment'], {
            onFetch: fetchDetails,
            onUpdate: next => {
                if(abstract.getID() === next.getID()) {
                    setPayment(next.object);
                }
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`${abstract.object.category.text} Invoice Details`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <FieldMapper
            fields={getFields()}
            utils={utils} />
            {getCostBreakdown()}
            {getTransactions()}
            {getRefunds()}
        </Layer>
    )
}

export const PaymentMethods = ({ index, options, utils }) => {

    const panelID = 'payment_methods';
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef({ sort_key: 'name', sort_type: Content.sorting.type.ascending });

    const [loading, setLoading] = useState(false);
    const [methods, setMethods] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onDealershipChange = () => {
        offset.current = 0;
        fetchMethods();
    }

    const onMethodClick = method => {
        utils.layer.open({
            id: `payment_method_details_${method.id}`,
            abstract: Abstract.create({
                object: method,
                type: 'payment_method'
            }),
            Component: PaymentMethodDetails
        });
    }

    const onNewPaymentMethodClick = evt => {
        utils.sheet.show({
            items: [{
                key: Payment.Method.source_categories.get().user,
                title: 'Personal',
                style: 'default'
            },{
                key: Payment.Method.source_categories.get().dealership,
                title: 'Dealership',
                style: 'default'
            }],
            message: 'Would you like to add a personal payment method or a dealership-wide payment method? Dealership-wide payment methods are available for all users in the dealership.',
            position: 'bottom',
            target: evt.target,
            title: 'Add Payment Method'
        }, key => {
            if(key !== 'cancel') {
                utils.layer.open({
                    id: 'add_payment_method',
                    Component: AddPaymentMethod.bind(this, { 
                        sourceCategory: key 
                    })
                });
            }
        });
    }

    const getButtons = () => {
        return [{
            key: 'new',
            title: 'Add Payment Method',
            style: 'default',
            onClick: onNewPaymentMethodClick
        }];
    }

    const getContent = () => {
        if(methods.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No payment methods were found for your account',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {methods.map((method, index) => {
                        return getFields(method, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (method, index) => {

        let target = method || {};
        let fields = [{
            key: 'description',
            title: 'Description',
            value: method && (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <i 
                    className={method.getIcon()} 
                    style={{
                        color: Appearance.colors.grey(),
                        fontSize: 16,
                        marginRight: 8
                    }}
                    />
                    {method.description}
                </div>
            )
        },{
            key: 'name',
            title: 'Name',
            value: target.name
        },{
            key: 'fingerprint',
            title: 'ID',
            value: target.fingerprint
        },{
            key: 'expiration',
            title: 'Expiration Date',
            value: method && method.getExpiration()
        },{
            key: 'category',
            sortable: false,
            title: 'Category',
            value: target.source_category && (
                <AltBadge content={{
                    color: target.source_category.code === Payment.Method.source_categories.get().user ? Appearance.colors.green : Appearance.colors.blue,
                    text: target.source_category.code === Payment.Method.source_categories.get().user ? 'Personal' : 'Dealership'
                }} />
            )
        },{
            key: 'is_default',
            sortable: false,
            title: 'Default For Category',
            value: target.is_default ? 'Yes' : 'No'
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!method) {
            return (
                <TableListHeader
                fields={fields}
                onChange={props => {
                    sorting.current = props;
                    fetchMethods();
                }} 
                value={sorting.current} />
            )
        }
        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === methods.length - 1}
            onClick={onMethodClick.bind(this, method)} />
        )
    }

    const fetchMethods = async () => {
        try {

            // set loading flag and send request to server
            setLoading(true);
            let { methods } = await Request.get(utils, '/payments/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'all_methods',
                ...sorting.current
            });

            // end loading and update local state with formatted methods
            setLoading(false);
            setMethods(methods.map(method => Payment.Method.create(method)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the payment methods list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchMethods();
    }, [searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', onDealershipChange);
        utils.content.subscribe(panelID, ['payment_method'], {
            onFetch: fetchMethods
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', onDealershipChange);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Payment Methods'}
        index={index}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            search: {
                placeholder: 'Search by id or name...',
                onChange: setSearchText
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const PaymentMethodDetails = ({ abstract, index, options, utils }) => {

    const layerID = `payment_method_details_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [method, setMethod] = useState(abstract.object);

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'set_default',
                title: 'Set as Default',
                style: 'default',
                visible: method.is_default === false
            },{
                key: 'remove',
                title: `Remove from ${method.source_category.code === Payment.Method.source_categories.get().dealership ? 'Dealership' : 'Account'}`,
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            switch(key) {
                case 'set_default':
                onSetDefaultMethod();
                break;

                case 'remove':
                onRemoveMethod();
                break;
            }
        });
    }

    const onRemoveMethod = () => {
        utils.alert.show({
            title: 'Remove Payment Method',
            message: 'Are you sure that you want to remove this payment method? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveMethodConfirm();
                    return;
                }
            }
        });
    }

    const onRemoveMethodConfirm = async () => {
        try {

            // set loading flag and send request to server
            setLoading('options');
            await Request.post(utils, '/payments/', {
                card_id: method.id,
                source_category: method.source_category.code,
                type: 'remove_payment_method'
            });

            // notify subscribers of data change
            utils.content.fetch('payment_method');

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'This payment method has been removed from the account',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this payment method. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetDefaultMethod = () => {
        utils.alert.show({
            title: 'Set Default Payment Method',
            message: 'Are you sure that you want to set this payment method as your default payment method? This means we will use this payment method when a preferred payment method is unavailable.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetDefaultMethodConfirm();
                    return;
                }
            }
        });
    }

    const onSetDefaultMethodConfirm = async () => {
        try {

            // set loading flag and send request to server
            setLoading('options');
            await Request.post(utils, '/payments/', {
                card_id: method.id,
                source_category: method.source_category.code,
                type: 'set_default_payment_method'
            });

            // update abstract target and notify subscribers of data change
            abstract.object.is_default = true;
            setMethod(abstract.object);
            utils.content.fetch('payment_method');

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'This payment method has been set as the default payment method for the account'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this payment method. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            key: 'options',
            text: 'Options',
            loading: loading === 'options',
            color: 'secondary',
            onClick: onOptionsClick
        }];
    }

    const getFields = () => {
        return [{
            key: 'details',
            items: [{
                key: 'brand',
                title: 'Brand',
                value: Utils.ucFirst(method.brand)
            },{
                key: 'is_default',
                title: 'Default for Account',
                value: method.is_default ? 'Yes' : 'No'
            },{
                color: method.expiration.valid === false && Appearance.colors.red,
                key: 'expiration',
                title: 'Expiration Date',
                value: `${method.expiration.month}/${method.expiration.year}`
            },{
                key: 'fingerprint',
                title: 'Fingerprint',
                value: method.fingerprint
            },{
                key: 'funding',
                title: 'Funding',
                value: Utils.ucFirst(method.funding)
            },{
                key: 'id',
                title: 'ID',
                value: method.id
            },{
                key: 'last4',
                title: 'Last 4',
                value: method.last4
            },{
                key: 'name',
                title: 'Name',
                value: method.name
            }]
        }];
    }

    useEffect(() => {
        utils.content.subscribe(layerID, ['payment_method'], {
            onUpdate: next => {
                if(abstract.getID() === next.getID()) {
                    setMethod(next.object);
                }
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`${abstract.object.fingerprint} Payment Method Details`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <FieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const ProcessPayment = ({ onComplete }, { abstract, index, options, utils }) => {

    const isNewTarget = abstract.isNewTarget();
    const layerID = isNewTarget ? 'process_payment' : `process_payment_${abstract.getID()}`;

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [payment, setPayment] = useState(abstract.object);
    const [selectedMethod, setSelectedMethod] = useState(null);

    const onMethodsLoaded = methods => {

        // set pre-selected payment method if applicable
        if(payment.card_fingerprint) {
            setSelectedMethod(methods.find(target => target.fingerprint === payment.card_fingerprint));
        }
    }

    const onSubmit = async () => {
        try {

            // prevent moving forward if a payment method has not been selected
            if(!selectedMethod) {
                throw new Error('Please select a payment method to continue.');
            }
            
            // submit request to server to create subscription
            setLoading('submit');
            await Request.post(utils, '/payments/', {
                amount: payment.amount,
                card_fingerprint: selectedMethod.fingerprint,
                category: payment.category.code,
                dealership_id: payment.dealership && payment.dealership.id,
                payment_id: payment.id,
                source_category: payment.source_category.code,
                target_id: payment.target && payment.target.id,
                type: 'new',
                user_id: payment.user && payment.user.user_id
            });

            // notify subscribers of data change
            utils.content.fetch('payment');

            // notify subscribers of payment completion if applicable
            if(typeof(onComplete) === 'function') {
                onComplete();
                setLayerState('close');
                return;
            }

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `Your payment for ${Utils.toCurrency(payment.amount)} has been completed.`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue processing your payment. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setPayment(prev => ({ ...prev, ...props }));
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'submit',
            loading: loading === 'submit',
            onClick: onSubmit,
            text: 'Submit'
        }];
    }

    const getFieldComponents = () => {
        return (
            <LayerItem 
            collapsed={false}
            title={'Amount'}>
                <TextField
                format={'currency'}
                onChange={text => onUpdateTarget({ amount: text })}
                placeholder={'$0.00'}
                prepend={'$'}
                value={payment.amount} />
            </LayerItem>
        )
    }

    const getOverview = () => {

        // no overview is needed if the payment is not already tied to a target
        if(isNewTarget === true) {
            return null;
        }

        // determine if a subscription overview is needed
        if(payment.isSubscriptionCategory() === true) {
            return (
                <LayerItem 
                collapsed={false}
                title={'Subscription'}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        {Views.entry({
                            bottomBorder: false,
                            icon: { 
                                path: 'images/payment-icon-clear.png',
                                imageStyle: {
                                    backgroundColor: Appearance.colors.green
                                }
                            },
                            subTitle: `Registered to ${payment.source_category.code === Payment.Method.source_categories.get().dealership ? payment.dealership.name : payment.user.full_name}`,
                            title: payment.category.text
                        })}
                    </div>
                </LayerItem>
            )
        }

        // no other overviews are supported at this time
        return null;
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? 'New Payment' : `Process Payment for ${abstract.object.internal_id}`}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            layerState: layerState,
            sizing: 'medium'
        }}>
            {getOverview()}
            {getFieldComponents()}
            <LayerItem
            title={'Payment Methods'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    <PaymentMethodSelector 
                    dealership={payment.dealership}
                    onChange={setSelectedMethod} 
                    onLoad={onMethodsLoaded}
                    sourceCategory={payment.source_category.code}
                    utils={utils}
                    value={selectedMethod}/>
                </div>
            </LayerItem>
        </Layer>
    )
}

export const PaymentMethodSelector = ({ dealership, onChange, onLoad, showSetAsDefault = true, sourceCategory, utils, value }) => {

    const componentID = useRef(`payment_method_selector_${moment().unix()}`);

    const [loading, setLoading] = useState(false);
    const [methods, setMethods] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(value);

    const onAddNewMethodClick = () => {

        // remove previously selected method
        setSelectedMethod(null);

        // open layer to add new payment method
        utils.layer.open({
            id: 'add_payment_method',
            Component: AddPaymentMethod.bind(this, { 
                dealership: dealership,
                onChange: method => {

                    // set newly created payment method as the selected method
                    setSelectedMethod(method);

                    // add newly created method to list of available payment methods
                    setMethods(methods => {
                        methods.push(method)
                        return methods.sort((a,b) => {
                            return a.description.localeCompare(b.description);
                        });
                    });
                },
                sourceCategory: sourceCategory 
            })
        });
    }

    const onDetailsClick = (method, evt) => {
        evt.stopPropagation();
        utils.sheet.show({
            items: [{
                key: 'set_default',
                title: `Set as ${method.source_category.code === Payment.Method.source_categories.get().dealership ? 'Dealership' : 'Account'} Default`,
                style: 'default',
                visible: method.is_default === false && showSetAsDefault === true
            },{
                key: 'remove',
                title: `Remove from ${method.source_category.code === Payment.Method.source_categories.get().dealership ? 'Dealership' : 'Account'}`,
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            switch(key) {
                case 'set_default':
                onSetDefaultMethod(method);
                break;

                case 'remove':
                onRemoveMethod(method);
                break;
            }
        });
    }

    const onRemoveMethod = method => {
        utils.alert.show({
            title: 'Remove Payment Method',
            message: 'Are you sure that you want to remove this payment method? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveMethodConfirm(method);
                    return;
                }
            }
        });
    }

    const onRemoveMethodConfirm = async method => {
        try {

            // set loading flag and send request to server
            setLoading(true);
            await Request.post(utils, '/payments/', {
                card_id: method.id,
                dealership_id: dealership && dealership.id,
                source_category: method.source_category.code,
                type: 'remove_payment_method'
            });

            // notify subscribers of data change
            setLoading(false);
            utils.content.fetch('payment_method');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this payment method. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetDefaultMethod = method => {
        utils.alert.show({
            title: 'Set Default Payment Method',
            message: 'Are you sure that you want to set this payment method as your default payment method? This means we will use this payment method when a preferred payment method is unavailable.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetDefaultMethodConfirm(method);
                    return;
                }
            }
        });
    }

    const onSetDefaultMethodConfirm = async method => {
        try {

            // set loading flag and send request to server
            setLoading(true);
            await Request.post(utils, '/payments/', {
                card_id: method.id,
                dealership_id: dealership && dealership.id,
                source_category: method.source_category.code,
                type: 'set_default_payment_method'
            });

            // notify subscribers of data change
            utils.content.fetch('payment_method');

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'This payment method has been set as the default payment method for the account'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this payment method. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getPaymentMethods = () => {
        if(methods === null || loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }
        return (
            <>
            {methods.map((method, index) => {
                return (
                    <div
                    className={`view-entry ${window.theme}`}
                    key={index}
                    onClick={setSelectedMethod.bind(this, method)}
                    style={{
                        alignItems: 'center',
                        borderBottom: `1px solid ${Appearance.colors.divider()}`,
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 10,
                        width: '100%',
                    }}>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            paddingRight: 8
                        }}>
                            <i
                            className={method.getIcon()}
                            style={{
                                color: Appearance.colors.text(),
                                fontSize: 32,
                                marginRight: 2
                            }}/>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1
                        }}>
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                color: Appearance.colors.text()
                            }}>{method.description}</span>
                            <span style={{
                                ...Appearance.textStyles.subTitle()
                            }}>{`${method.source_category.code === Payment.Method.source_categories.get().dealership ? 'Dealership' : 'Personal'} payment method`}</span>
                        </div>
                        {selectedMethod && selectedMethod.id === method.id && (
                            <AltBadge content={{
                                color: Appearance.colors.green,
                                text: 'Selected'
                            }} />
                        )}
                        <img
                        className={'text-button'}
                        onClick={onDetailsClick.bind(this, method)}
                        src={'images/details-button-light-grey.png'}
                        style={{
                            height: 20,
                            objectFit: 'contain',
                            width: 20
                        }} />
                    </div>
                )
            })}
            <div 
            className={`view-entry ${window.theme}`}
            onClick={onAddNewMethodClick}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                padding: 10,
                width: '100%',
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: Appearance.colors.text(),
                    flexGrow: 1
                }}>{'Add New Payment Method'}</span>
                <img
                src={'images/next-arrow-grey-small.png'}
                style={{
                    height: 12,
                    marginLeft: 8,
                    objectFit: 'contain',
                    opacity: 0.75,
                    width: 12
                }} />
            </div>
            </>
        )
    }

    const fetchPaymentMethods = async () => {
        try {

            // start loading and send request to server
            setLoading(true);
            let { methods } = await Request.get(utils, '/payments/', {
                dealership_id: dealership && dealership.id,
                type: 'all_methods'
            });

            // format payment methods as local method class
            let targets = methods.map(method => {
                return Payment.Method.create(method);
            }).filter(method => {
                return method.source_category.code === sourceCategory;
            });

            // end loading and update local state
            setLoading(false);
            setMethods(targets);

            // notify subscribers on methods load completion if applicable
            if(typeof(onLoad) === 'function') {
                onLoad(targets);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the payment methods list. ${e.message || 'An unknown error occurred'}`,
            });
        }
    }

    useEffect(() => {
        if(typeof(onChange) === 'function') {
            onChange(selectedMethod);
        }
    }, [selectedMethod]);

    useEffect(() => {
        setSelectedMethod(value);
    }, [value]);

    useEffect(() => {
        fetchPaymentMethods();
        utils.content.subscribe(componentID.current, ['payment_method'], {
            onFetch: fetchPaymentMethods,
            onUpdate: abstract => {
                setMethods(methods => {
                    return methods.map(method => {
                        return method.id === abstract.getID() ? abstract.object : method;
                    });
                });
            }
        });
        return () => {
            utils.content.unsubscribe(componentID.current);
        }
    }, []);

    return getPaymentMethods()
}

export const SelectPaymentMethod = ({ defaultFingerprint, onChange, sourceCategory }, { abstract, index, options, utils }) => {

    const layerID = 'payment_method_selector';
    const [layerState, setLayerState] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(null);

    const onLoadMethods = methods => {
        let selected = methods.find(method => method.fingerprint === defaultFingerprint);
        setSelectedMethod(selected);
    }

    const onSelectPaymentMethod = () => {

        // prevent moving forward if no payment method was selected
        if(!selectedMethod) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please select an existing payment method or add a new payment method to continue.'
            });
            return;
        }

        // notify subscribers that payment method has changed if applicable
        if(selectedMethod) {
            onChange(selectedMethod);
        }

        // close layer
        setLayerState('close');
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            onClick: onSelectPaymentMethod,
            text: 'Done'
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Payment Methods'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <div style={{
                marginBottom: 15,
                textAlign: 'center',
                width: '100%'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    whiteSpace: 'normal'
                }}>{'Your personal and dealership payment methods are listed below. You can click a payment method to select it as your preferred payment method or you can tap the details button to make changes to your payment method.'}</span>
            </div>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                <PaymentMethodSelector 
                dealership={abstract.object}
                onChange={setSelectedMethod}
                onLoad={onLoadMethods} 
                utils={utils}
                showSetAsDefault={false}
                sourceCategory={sourceCategory}
                value={selectedMethod}/>
            </div>
        </Layer>
    )
}

export const Subscriptions = ({ index, options, utils }) => {

    const panelID = 'subscriptions';
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef({ sort_key: 'date', sort_type: Content.sorting.type.descending });

    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onDealershipChange = () => {
        offset.current = 0;
        fetchSubscriptions();
    }

    const onSubscriptionClick = subscription => {
        utils.layer.open({
            id: `subscription_details_${subscription.id}`,
            abstract: Abstract.create({
                object: subscription,
                type: 'subscription'
            }),
            Component: SubscriptionDetails
        });
    }

    const getContent = () => {
        if(subscriptions.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No subscriptions were found for this dealership',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {subscriptions.map((subscription, index) => {
                        return getFields(subscription, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (subscription, index) => {

        let target = subscription || {};
        let fields = [{
            key: 'id',
            title: 'ID',
            value: `sub.${target.id}`
        },{
            key: 'category',
            sortable: false,
            title: 'Description',
            value: target.category && (
                <AltBadge content={{
                    color: target.category.color,
                    text: `${target.category.text} Subscription`
                }} />
            )
        },{
            key: 'date',
            title: 'Registration Date',
            value: target.date && Utils.formatDate(target.date)
        },{
            key: 'renewal_date',
            title: 'Renewal Date',
            value: target.renewal_date ? Utils.formatDate(target.renewal_date, true) : 'No upcoming payments scheduled'
        },{
            key: 'card_fingerprint',
            title: 'Payment Method',
            value: target.card_fingerprint || 'Default Payment Method'
        },{
            key: 'schedule',
            title: 'Schedule',
            value: target.schedule && target.schedule.text
        },{
            color: target.active === false && Appearance.colors.red,
            key: 'active',
            title: 'Status',
            value: (
                <AltBadge content={{
                    color: target.active ? Appearance.colors.green : Appearance.colors.red,
                    text: target.active ? 'Active' : 'Inactive'
                }} />
            )
        },{
            key: 'amount',
            title: 'Amount',
            value: target.amount && (
                <AltBadge content={{
                    color: Appearance.colors.green,
                    text: Utils.toCurrency(target.amount.value)
                }} />
            )
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!subscription) {
            return (
                <TableListHeader
                fields={fields}
                onChange={props => {
                    sorting.current = props;
                    fetchSubscriptions();
                }} 
                value={sorting.current} />
            )
        }
        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === subscriptions.length - 1}
            onClick={onSubscriptionClick.bind(this, subscription)} />
        )
    }

    const fetchSubscriptions = async () => {
        try {

            setLoading(true);
            let { paging, subscriptions } = await Request.get(utils, '/payments/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'subscriptions',
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setSubscriptions(subscriptions.map(subscription => Payment.Subscription.create(subscription)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the subscriptions list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchSubscriptions();
    }, [searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', onDealershipChange);
        utils.content.subscribe(panelID, ['subscription'], {
            onFetch: fetchSubscriptions,
            onUpdate: fetchSubscriptions
        });
        return () => {
            utils.events.off(panelID, 'dealership_change', onDealershipChange);
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Subscriptions'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    setLoading(true);
                    fetchSubscriptions();
                }
            },
            search: {
                placeholder: 'Search by id, description, or payment method...',
                onChange: setSearchText
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const SubscriptionCapabilities = ({ abstract, index, options, utils }) => {

    const layerID = `subscription_capabilities_${abstract.getID()}`;

    return (
        <Layer 
        id={layerID}
        index={index}
        title={`Capabilities for ${abstract.object.category.text} Subscription`}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {abstract.object.capabilities.sort((a,b) => {
                    return a.title.localeCompare(b.title);
                }).map((capability, index, capabilities) => {
                    return (
                        <div 
                        key={index}
                        style={{
                            borderBottom: index !== capabilities.length - 1 && `1px solid ${Appearance.colors.divider()}`,
                            padding: '8px 12px 8px 12px'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.key(),
                                whiteSpace: 'normal'
                            }}>{capability.title}</span>
                        </div>
                    )
                })}
            </div>
        </Layer>
    )
}

export const SubscriptionDetails = ({ abstract, index, options, utils }) => {

    const layerID = `subscription_details_${abstract.getID()}`;
    const limit = 5;
    const offset = useRef(0);

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);
    const [payments, setPayments] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [subscription, setSubscription] = useState(abstract.object);

    const onAddNewPromotion = () => {
        utils.layer.open({
            id: 'new_promotion',
            Component: AddEditPromotion.bind(this, {
                isNewTarget: true,
                onChangeAsync: async result => {

                    // verify that requested promotion does not overlay with other promotions
                    await Payment.Subscription.promotions.validate(result, subscription.promotions);

                    // send request to server to create subscription promotion
                    let { id } = await Request.post(utils, '/payments/', {
                        amount: result.amount,
                        end_date: result.end_date && result.end_date.format('YYYY-MM-DD'),
                        id: abstract.getID(),
                        start_date: result.start_date.format('YYYY-MM-DD'),
                        title: result.title,
                        type: 'new_subscription_promotion'
                    });

                    // update local state with new values
                    result.id = id;
                    abstract.object.promotions.push(result);
                    setSubscription(abstract.object);
                }
            })
        });
    }

    const onActivateSubscription = () => {

        // determine if user is required to process a payment when activating the subscription
        if(utils.user.get().level > User.levels.get().admin) {
            utils.alert.show({
                title: 'Activate Subscription',
                message: `Are you sure that you want to activate this subscription? This will reactivate all services associated with this subscription and the subscription payment of ${Utils.toCurrency(subscription.amount.value)} will be processed using the payment method on file.`,
                buttons: [{
                    key: 'activate',
                    title: 'Activate',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key !== 'cancel') {
                        onActivateSubscriptionConfirm({ process_payment: true });
                        return;
                    }
                }
            });
            return;
        }

        // fallback to requesting confirmation around payment processing from administrator
        utils.alert.show({
            title: 'Activate Subscription',
            message: `Would you like to activate this subscription and automatically process the subscription's ${Utils.toCurrency(subscription.amount.value)} payment or process the payment at a later date?`,
            buttons: [{
                key: 'activate',
                title: 'Activate',
                style: 'default'
            },{
                key: 'process',
                title: 'Activate and Process Payment',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key !== 'cancel') {
                    onActivateSubscriptionConfirm({ process_payment: key === 'process'});
                    return;
                }
            }
        });
    }

    const onActivateSubscriptionConfirm = async props => {
        try {

            // set loading flag and send request to server
            setLoading('options');
            let { renewal_date } = await Request.post(utils, '/payments/', {
                ...props,
                id: abstract.getID(),
                type: 'activate_subscription'
            });

            // end loading and notify subscribers of data change
            setLoading(false);
            utils.content.fetch('subscription');

            // update target and update local state
            abstract.object.active = true;
            abstract.object.renewal_date = renewal_date;
            setSubscription(abstract.object);

            // fetch list of payments for subscription
            fetchPayments();

            // notify subscribers of specialty data changes related to the subscription category
            abstract.object.emit(utils, 'active_status_change', true);

            // determine if confirmation alert should be geared towards a non-administrator
            if(utils.user.get().level > User.levels.get().admin) {
                utils.alert.show({
                    title: 'All Done!',
                    message: `This subscription has been activated and the associated services have been enabled. The ${Utils.toCurrency(subscription.amount.value)} subscription payment has been processed and an invoice has been emailed.`,
                });
                return;
            }

            // show confirmation alert for administrators
            utils.alert.show({
                title: 'All Done!',
                message: `This subscription has been activated and the associated services have been enabled. ${props.process_payment ? `The ${Utils.toCurrency(subscription.amount.value)} subscription payment has been processed (if applicable) and an invoice has been sent to the subscription owner.` : ''}`,
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deactivating this subscription. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onChangePaymentMethod = () => {
        utils.layer.open({
            id: 'payment_method_selector',
            abstract: Abstract.create({
                object: subscription.dealership,
                type: 'dealership'
            }),
            Component: SelectPaymentMethod.bind(this, {
                defaultFingerprint: subscription.card_fingerprint,
                onChange: onSetPaymentMethod,
                sourceCategory: subscription.source_category.code
            })
        });
    }

    const onDeactivateSubscription = () => {
        utils.alert.show({
            title: 'Deactivate Subscription',
            message: 'Are you sure that you want to deactivate this subscription? This will cancel all future payments and deactivate all services attached to this subscription.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeactivateSubscriptionConfirm();
                    return;
                }
            }
        });
    }

    const onDeactivateSubscriptionConfirm = async () => {
        try {

            // set loading flag and send request to server
            setLoading('options');
            await Request.post(utils, '/payments/', {
                id: abstract.getID(),
                type: 'deactivate_subscription'
            });

            // end loading and notify subscribers of data change
            setLoading(false);
            utils.content.fetch('subscription');

            // update target and update local state
            abstract.object.active = false;
            abstract.object.renewal_date = null;
            setSubscription(abstract.object);

            // notify subscribers of specialty data changes related to the subscription category
            abstract.object.emit(utils, 'active_status_change', false);

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: 'This subscription has been deactivated and all future payments have been cancelled.',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deactivating this subscription. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDeleteSubscription = () => {
        utils.alert.show({
            title: 'Delete Subscription',
            message: 'Are you sure that you want to delete this subscription? This will cancel all future payments, deactivate all services attached to this subscription, and remove any instance of this subscription from Applied Fire Technologies.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteSubscriptionConfirm();
                    return;
                }
            }
        });
    }

    const onDeleteSubscriptionConfirm = async () => {
        try {

            // set loading flag and send request to server
            setLoading('options');
            await Request.post(utils, '/payments/', {
                id: abstract.getID(),
                type: 'delete_subscription'
            });

            // end loading and notify subscribers of data change
            setLoading(false);
            utils.content.fetch('subscription');

            // notify subscribers that a subscription has been deleted
            utils.events.emit('subscription_removed', {id: abstract.getID()});

            // notify subscribers of specialty data changes related to the subscription category
            abstract.object.emit(utils, 'active_status_change', false);

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: 'This subscription has been deleted from Applied Fire Technologies and all future payments have been cancelled.',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this subscription. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditPromotion = index => {
        utils.layer.open({
            id: `edit_promotion_${subscription.promotions[index].id}`,
            Component: AddEditPromotion.bind(this, {
                isNewTarget: false,
                onChangeAsync: async result => {

                    // verify that requested promotion does not overlay with other promotions
                    await Payment.Subscription.promotions.validate(result, subscription.promotions);

                    // send request to server to update subscription
                    await Request.post(utils, '/payments/', {
                        amount: result.amount,
                        end_date: result.end_date && result.end_date.format('YYYY-MM-DD'),
                        id: abstract.getID(),
                        promotion_id: subscription.promotions[index].id,
                        start_date: result.start_date.format('YYYY-MM-DD'),
                        title: result.title,
                        type: 'update_subscription_promotion'
                    });

                    // update local state with new values
                    abstract.object.promotions[index] = result;
                    setSubscription(abstract.object);
                },
                promotion: subscription.promotions[index]
            })
        });
    }
    
    const onOptionsClick = evt => {
        
        let user = utils.user.get();
        utils.sheet.show({
            items: [{
                key: 'activate',
                title: 'Activate',
                style: 'default',
                visible: subscription.active === false,
            },{
                key: 'deactivate',
                title: 'Deactivate',
                style: 'destructive',
                visible: subscription.active === true
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive',
                visible: user.level <= User.levels.get().admin
            },{
                key: 'process',
                title: 'Manually Process Payment',
                style: 'default',
                visible: user.level <= User.levels.get().admin
            },{
                key: 'upgrade',
                title: 'Upgrade Subscription',
                style: 'default',
                visible: subscription.canUpgrade() === true
            },{
                key: 'capabilities',
                title: 'View Features',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'activate') {
                onActivateSubscription();
                return;
            }
            if(key === 'capabilities') {
                onViewCapabilities();
                return;
            }
            if(key === 'deactivate') {
                onDeactivateSubscription();
                return;
            }
            if(key === 'delete') {
                onDeleteSubscription();
                return;
            }
            if(key === 'process') {
                onProcessPayment();
                return;
            }
            if(key === 'upgrade') {
                onUpgradeSubscription();
                return;
            }
        });
    }

    const onPaymentClick = async id => {
        try {

            // set loading flag and retrieve payment details
            setLoading(id);
            let payment = await Payment.get(utils, id);
            setLoading(false);

            // determine if payment is marked as unpaid
            if(payment.status.code === Payment.status.get().unpaid) {
                utils.layer.open({
                    abstract: Abstract.create({
                        object: payment.source_category.code === Payment.Method.source_categories.get().dealership ? payment.dealership : payment.user,
                        type: payment.source_category.code === Payment.Method.source_categories.get().dealership ? 'dealership' : 'user'
                    }),
                    Component: UnpaidInvoiceManagement.bind(this, { payment }),
                    id: `unpaid_invoice_management_${payment.id}`
                });
                return;
            }
            
            // fallback to showing stadard details layer for payment
            utils.layer.open({
                abstract: Abstract.create({
                    object: payment,
                    type: 'payment'
                }),
                Component: PaymentDetails,
                id: `payment_details_${id}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this payment. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onPaymentMethodClick = async () => {
        try {

            // set loading flag and retrieve payment method details
            setLoading('payment_method');
            let method = await Payment.Method.get(utils, subscription.card_fingerprint, { 
                dealership_id: subscription.dealership.id,
                user_id: subscription.user.user_id 
            });

            // end loading and show details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: method,
                    type: 'payment_method'
                }),
                Component: PaymentMethodDetails,
                id: `payment_method_details_${method.id}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this payment method. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onProcessPayment = async () => {
        try {
            await onProcessPaymentRequest();
        } catch(e) {
            console.error(e.message);
        }
    }

    const onProcessPaymentRequest = async () => {
        return new Promise(resolve => {

            // prepare new payment object
            let payment = Payment.new();
            payment.amount = subscription.amount.value;
            payment.card_fingerprint = subscription.card_fingerprint;
            payment.category = subscription.payment_category;
            payment.dealership = subscription.dealership;
            payment.source_category = subscription.source_category;
            payment.target = subscription;

            // open processing layer for new payment
            utils.layer.open({
                id: 'process_payment',
                abstract: Abstract.create({
                    object: payment,
                    type: 'payment'
                }),
                Component: ProcessPayment.bind(this, {
                    onComplete: resolve
                })
            });
        });
    }

    const onRemovePromotion = (index, evt) => {
        
        evt.stopPropagation();
        utils.alert.show({
            title: 'Remove Promotion',
            message: 'Are you sure that you want to remove this promotion? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemovePromotionConfirm(index);
                    return;
                }
            }
        });
    }

    const onRemovePromotionConfirm = async index => {
        try {

            // send request to server to update subscription
            let promotion = abstract.object.promotions[index];
            setLoading(true);
            await Request.post(utils, '/payments/', {
                id: abstract.getID(),
                promotion_id: promotion.id,
                type: 'remove_subscription_promotion'
            });

            // update local state with new values
            setLoading(false);
            abstract.object.promotions = abstract.object.promotions.filter(p => p.id !== promotion.id);
            setSubscription(abstract.object);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this promotion. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetPaymentMethod = async method => {
        try {

            // set loading flag and send request to server
            setLoading('options');
            await Request.post(utils, '/payments/', {
                card_fingerprint: method.fingerprint,
                id: abstract.getID(),
                type: 'update_subscription_payment_method'
            });

            // end loading and notify subscribers of data change
            setLoading(false);
            utils.content.fetch('subscription');

            // update target and update local state
            abstract.object.card_fingerprint = method.fingerprint;
            setSubscription(abstract.object);

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: 'This payment method for this subscription has been updated.'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the payment method for this subscription. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpgradeSubscription = () => {

        let disabled = [];

        // prepare list of disabled subscription options
        let categories = Payment.Subscription.categories.get();
        switch(abstract.object.category.code) {
            case categories.bronze:
            disabled = [categories.bronze];
            break;

            case categories.silver:
            disabled = [categories.bronze, categories.silver];
            break;
        }

        // show layer to select new subscription
        utils.layer.open({
            Component: SubscriptionSelector.bind(this, { 
                disabled: disabled,
                onChange: onVerifyUpgradeSelection
            }),
            id: 'subscription_selector'
        });
    }

    const onUpgradeSubscriptionConfirm = async (category, schedule) => {
        try {

            setLoading('options');
            await Request.post(utils, '/payments/', {
                category: category,
                id: abstract.getID(),
                schedule: schedule,
                type: 'upgrade_subscription'
            });

            // end loading notify subscribers that data has changed
            utils.content.fetch('subscription');

            // show confirmation alert
            utils.alert.show({
                title: 'Upgrade Complete',
                message: `Your subscription has been upgraded and the first payment has been processed (if applicable). We have sent an invoice to the email address on file with the final subscription cost. All features and services associated with your new subscription have been activated and are ready to use.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue upgrading your subscription. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onVerifyUpgradeSelection = result => {

        // prevent moving forward if the selected subscription matches the current subscription
        if(result.option.category === abstract.object.category.code) {
            throw new Error(`You are already subscribed to the ${result.option.title}. Please choose a different subscription to continue`);
        }

        // prepare difference in costs between the two subscriptions
        let amount = result.amount - abstract.object.amount.default;

        // show alert requesting upgrade confirmation
        utils.alert.show({
            title: 'Upgrade Subscription',
            message: `Are you sure that you want to upgrade your subscription to the ${result.option.title}? We will charge your preferred payment method the difference of ${Utils.toCurrency(amount)} to account for the increased subscription cost.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onUpgradeSubscriptionConfirm(result.option.category, result.schedule);
                    return;
                }
            }
        });
    }

    const onViewCapabilities = () => {
        utils.layer.open({
            abstract: abstract,
            Component: SubscriptionCapabilities,
            id: `subscription_capabilities_${abstract.getID()}`
        });
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'change_payment_method',
            onClick: onChangePaymentMethod,
            text: 'Change Payment Method'
        }];
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'category.text',
                title: 'Description',
                value: `${subscription.category.text} Subscription`
            },{
                key: 'id',
                title: 'ID',
                value: `sub.${subscription.id}`
            },{
                key: 'date',
                title: 'Registration Date',
                value: Utils.formatDate(subscription.date)
            },{
                color: subscription.active ? Appearance.colors.green : Appearance.colors.red,
                key: 'active',
                title: 'Status',
                value: subscription.active ? 'Active' : 'Inactive'
            }]
        },{
            key: 'amount',
            lastItem: false,
            title: 'Costs and Billing',
            items: [{
                key: 'schedule.text',
                title: 'Billing Cycle',
                value: subscription.schedule.text
            },{
                key: 'schedule.text',
                title: 'Next Payment Date',
                value: subscription.renewal_date ? Utils.formatDate(subscription.renewal_date, true) : 'No upcoming payments scheduled'
            },{
                key: 'card_fingerprint',
                loading: loading === 'payment_method',
                onClick: subscription.card_fingerprint ? onPaymentMethodClick : null,
                title: 'Payment Method',
                value: subscription.card_fingerprint || 'Account default at time of processing' 
            },{
                key: 'amount.type',
                title: 'Rate',
                value: Utils.ucFirst(subscription.amount.type)
            },{
                color: Appearance.colors.green,
                key: 'amount.value',
                title: subscription.promotions.length > 0 ? 'Non-Promotional Cost' : 'Recurring Cost',
                value: Utils.toCurrency(subscription.amount.value)
            }]
        }];
    }

    const getPayments = () => {
        if(loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }
        if(payments.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    key: index,
                    title: 'Nothing to see here',
                    subTitle: 'No payments have been procesed for this subscription'
                })
            )
        }
        return (
            <>
            {payments.map((payment, index) => {
                return (
                    Views.entry({
                        bottomBorder: index !== payments.length - 1,
                        icon: {
                            path: 'images/payment-icon-clear.png',
                            imageStyle: {
                                backgroundColor: payment.status.color
                            }
                        },
                        key: index,
                        loading: loading === payment.id,
                        onClick: onPaymentClick.bind(this, payment.id),
                        title: Utils.toCurrency(payment.amount),
                        rightContent: (
                            <AltBadge content={payment.status} />
                        ),
                        subTitle: Utils.formatDate(payment.date)
                    })
                )
            })}
            {paging && (
                <PageControl
                data={paging}
                limit={limit}
                loading={loading === 'paging'}
                offset={offset}
                onClick={next => {
                    offset.current = next;
                    setLoading('paging');
                    fetchPayments();
                }}/>
            )}
            </>
        )
    }

    const getPromotions = () => {

        let admin = utils.user.get().level <= User.levels.get().admin;
        return (
            <LayerItem 
            collapsed={false}
            title={'Promotions'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    {subscription.promotions.map((promotion, index) => {
                        return (
                            Views.entry({
                                bottomBorder: true,
                                key: index,
                                icon: { 
                                    path: 'images/promotions-icon-clear.png',
                                    imageStyle: {
                                        backgroundColor: Appearance.colors.green
                                    }
                                },
                                onClick: admin ? onEditPromotion.bind(this, index) : null,
                                rightContent: admin && (
                                    <img
                                    className={'text-button'}
                                    onClick={onRemovePromotion.bind(this, index)}
                                    src={'images/red-x-icon.png'}
                                    style={{
                                        height: 18,
                                        marginLeft: 8,
                                        width: 18
                                    }} />
                                ),
                                subTitle: Payment.Subscription.promotions.toOverview(promotion),
                                title: promotion.title,
                            })
                        )
                    })}
                    <div 
                    className={`view-entry ${window.theme}`}
                    onClick={onAddNewPromotion}
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 10,
                        width: '100%',
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            flexGrow: 1
                        }}>{'Add New Promotional Period'}</span>
                        <img
                        src={'images/next-arrow-grey-small.png'}
                        style={{
                            height: 12,
                            marginLeft: 8,
                            objectFit: 'contain',
                            opacity: 0.75,
                            width: 12
                        }} />
                    </div>
                </div>
            </LayerItem>
        )
    }
    
    const fetchPayments = async () => {
        try {
            let { paging, payments, payment_methods } = await Request.get(utils, '/payments/', {
                id: abstract.getID(),
                limit: limit,
                offset: offset.current,
                type: 'subscription_payments'
            });

            setLoading(false);
            setPaging(paging);
            setPayments(payments.map(payment => Payment.create(payment)));
            setPaymentMethods(payment_methods.map(method => Payment.Method.create(method)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of payments for tyhis subscription. ${e.message || 'An unknown errror occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchPayments();
        utils.content.subscribe(layerID, ['payment','subscription'], {
            onFetch: type => {
                if(type === 'payment') {
                    offset.current = 0;
                    fetchPayments();
                }
            },
            onUpdate: next => {
                if(abstract.getID() === next.getID()) {
                    fetchPayments();
                    setSubscription(next.object);
                }
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`${abstract.object.category.text} Subscription Details`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <FieldMapper
            fields={getFields()}
            utils={utils} />
            
            {getPromotions()}
 
            <LayerItem 
            collapsed={false}
            lastItem={true}
            title={'Payments'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {getPayments()}
                </div>
            </LayerItem>
        </Layer>
    )
}

export const SubscriptionSelector = ({ disabled = [], onChange }, { index, options, utils }) => {

    const layerID = 'subscription_selector';

    const [activeSchedule, setActiveSchedule] = useState('monthly');
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [subscriptionOptions, setSubscriptionOptions] = useState([]);
    const [visibility, setVisibility] = useState({});

    const onDoneClick = () => {

        // prevent moving forward if no subscription was selected
        if(!selectedSubscription) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please select a subscription before moving on'
            });
            return;
        }

        // close layer and notify subscribers of data change 
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange({
                amount: selectedSubscription.schedules[activeSchedule].amount,
                option: selectedSubscription,
                schedule: selectedSubscription.schedules[activeSchedule].code
            });
        }
    }

    const onVisibilityClick = index => {
        setVisibility(props => {
            return update(props, {
                [index]: {
                    $set: props[index] === true ? false : true
                }
            });
        });
    }

    const getButtons = () => {
        return [{
            color: selectedSubscription ? 'primary' : 'grey',
            key: 'done',
            onClick: onDoneClick,
            text: 'Done'
        }];
    }

    const getRightContent = option => {
        if(isDisabled(option) === true) {
            return (
                <AltBadge content={{
                    color: Appearance.colors.grey(),
                    text: 'Not Available'
                }} />
            )
        }
        if(selectedSubscription && selectedSubscription.category === option.category) {
            return (
                <AltBadge content={{
                    color: Appearance.colors.green,
                    text: 'Selected'
                }} />
            )
        }
    }

    const getScheduleOptions = () => {
        return subscriptionOptions.map((option, index, options) => {

            // declare selected scehdule type for subscription option
            let schedule = option.schedules[activeSchedule];

            return (
                <div 
                key={index}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: index !== options.length - 1 ? 12 : 0,
                    position: 'relative',
                    width: '100%'
                }}>
                    {Views.entry({
                        bottomBorder: true,
                        icon: {
                            path: `images/${option.image}.png`,
                            imageStyle: {
                                backgroundColor: Appearance.colors.transparent,
                                borderRadius: 0,
                                boxShadow: 'none'
                            }
                        },
                        rightContent: getRightContent(option),
                        onClick: isDisabled(option) === false ? setSelectedSubscription.bind(this, option) : null,
                        subTitle: `${Utils.toCurrency(schedule.amount)} ${schedule.descriptor}`,
                        supportingTitle: typeof(schedule.savings) === 'string' && `Save ${schedule.savings}`,
                        style: {
                            supportingTitle: {
                                color: window.theme === 'dark' ? 'white' : Appearance.colors.secondary()
                            }
                        },
                        title: option.title
                    })}
                    <div style={{
                        padding: '8px 12px 8px 12px',
                        width: '100%'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            whiteSpace: 'normal'
                        }}>{option.description}</span>
                    </div>
                    <div style={{
                        maxHeight: visibility[index] === true ? null : 0,
                        minWidth: 0,
                        overflow: 'hidden',
                        textAlign: 'left'
                    }}>
                        {option.capabilities.map((capability, index) => {
                            return (
                                <span 
                                key={index}
                                style={{
                                    ...Appearance.textStyles.subTitle(),
                                    borderTop: `1px solid ${Appearance.colors.divider()}`,
                                    color: Appearance.colors.text(),
                                    display: 'block',
                                    overflow: 'hidden',
                                    padding: '8px 12px 8px 12px',
                                    whiteSpace: 'normal'
                                }}>{capability}</span>
                            )
                        })}
                    </div>
                    <div 
                    className={'text-button'}
                    onClick={onVisibilityClick.bind(this, index)}
                    style={{
                        alignItems: 'center',
                        borderTop: `1px solid ${Appearance.colors.divider()}`,
                        display: isDisabled(option) === true ? 'none' : 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        minWidth: 0,
                        padding: '8px 12px 8px 12px'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            marginRight: 8
                        }}>{visibility[index] === true ? 'Hide Features' : 'View Features'}</span>
                        <CollapseArrow 
                        collapsed={visibility[index] !== true} 
                        style={{
                            marginBottom: 4
                        }}/>
                    </div>
                    {isDisabled(option) === true && (
                        <div style={{
                            backgroundColor: Appearance.colors.grey(),
                            bottom: 0,
                            left: 0,
                            opacity: 0.2,
                            position: 'absolute',
                            right: 0,
                            top: 0
                        }} />
                    )}
                </div>
            )
        });
    }

    const isDisabled = option => {
        return disabled.includes(option.category) === true;
    }

    const fetchSubscription = async () => {
        try {
            
            setLoading(true);
            let { options } = await Request.get(utils, '/payments/', {
                type: 'subscription_options'
            });
            setLoading(false);
            setSubscriptionOptions(options);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the subscription options. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchSubscription();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Subscription Options'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 24,
                padding: '8px 12px 8px 12px',
                textAlign: 'left',
                width: '100%'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 8,
                    whiteSpace: 'normal'
                }}>{'The subscriptions offered for the GRACI suite of platforms includes web and mobile applications used to generate sales, manage customers, and offer branded products for your business. View each subscription below to learn more about the plan offerings.'}</span>
            </div>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%'
            }}>
                <BoolToggle 
                color={Appearance.colors.darkGrey}
                disabled={'Monthly'}
                enabled={'Yearly'}
                onChange={val => setActiveSchedule(val ? 'yearly' : 'monthly')}
                value={activeSchedule === 'yearly'}
                style={{
                    marginBottom: 12,
                    width: 250
                }}/>
            </div>
            {getScheduleOptions()}
        </Layer>
    )
}

export const UnpaidInvoiceManagement = ({ payment }, { abstract, index, options, utils }) => {

    const layerID = `unpaid_invoice_management_${payment.id}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState(null);

    const onLoadMethods = methods => {
        let selected = methods.find(method => method.fingerprint === payment.card_fingerprint);
        setSelectedMethod(selected);
    }

    const onPaymentClick = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: payment,
                type: 'payment'
            }),
            Component: PaymentDetails,
            id: `payment_details_${payment.id}`
        });
    }

    const onSubmit = async () => {
        try {

            // prevent moving forward if no payment method was selected
            if(!selectedMethod) {
                throw new Error('Please select an existing payment method or add a new payment method to continue.');
            }

            // determine if payment method should be used for future payments as well
            let updatePaymentMethod = await requestFutureMethodPreference();

            // submit request to server to process unpaid invoice
            setLoading('submit');
            await Request.post(utils, '/payments/', {
                card_fingerprint: selectedMethod.fingerprint,
                id: payment.id,
                type: 'process_unpaid',
                update_payment_method: updatePaymentMethod
            });

            // show confirmation message
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `A payment for ${Utils.toCurrency(payment.amount)} has been processed using ${selectedMethod.description}. An email invoice has been sent to the email address on file with your ${payment.source_category.code === Payment.Method.source_categories.get().dealership ? 'dealership' : 'account'}. ${updatePaymentMethod ? `We will use this payment method when processing future payments for your ${payment.target.category.text} subscription.` : ''}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue processing your payment. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: selectedMethod ? 'primary' : 'grey',
            key: 'submit',
            loading: loading === 'submit',
            onClick: onSubmit,
            text: 'Submit'
        }];
    }

    const getPayment = () => {
        return (
            <LayerItem title={'Unpaid Invoice'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    {Views.entry({
                        badge: {
                            color: Appearance.colors.red,
                            text: Utils.toCurrency(payment.amount)
                        },
                        bottomBorder: false,
                        hideIcon: true,
                        onClick: onPaymentClick,
                        subTitle: `Invoice date: ${Utils.formatDate(payment.date)}`,
                        title: payment.category.text || 'Description not available'
                    })}
                </div>
            </LayerItem>
        )
    }

    const requestFutureMethodPreference = () => {
        return new Promise(resolve => {
            utils.alert.show({
                title: 'Future Payments',
                message: `Should we also use this payment method for all future ${payment.target.category.text} subscription payments?`,
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'deny',
                    title: 'No Thanks',
                    style: 'cancel'
                }],
                onClick: key => {
                    resolve(key === 'confirm');
                    return;
                }
            });
        });
    }

    const validatePaymentStatus = () => {
        try {
            if(payment.status.code === Payment.status.get().cancelled) {
                throw new Error('This invoice has been cancelled and no further action is required');
            }
            if(payment.status.code === Payment.status.get().failed) {
                throw new Error('This invoice failed to process and no further action is required');
            }
            if(payment.status.code === Payment.status.get().paid) {
                throw new Error('This invoice has already been paid and no further action is required');
            }
        } catch(e) {
            utils.alert.show({
                title: 'Just a Second',
                message: e.message,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        validatePaymentStatus();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Unpaid Invoice Management'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 24,
                padding: '8px 12px 8px 12px',
                textAlign: 'left',
                width: '100%'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    whiteSpace: 'normal'
                }}>{`We were unable to process the invoice using the payment method on file. Please select a different payment method from the list below or add a new payment method to continue. By pressing the "Submit" button, you are authorizing Applied Fire Technologies to process a payment for ${Utils.toCurrency(payment.amount)} using the payment method selected below.`}</span>
            </div>
            {getPayment()}
            <LayerItem 
            lastItem={true}
            title={'Payment Methods'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    <PaymentMethodSelector 
                    dealership={abstract.object}
                    onChange={setSelectedMethod}
                    onLoad={onLoadMethods} 
                    utils={utils}
                    showSetAsDefault={false}
                    sourceCategory={payment.source_category.code}
                    value={selectedMethod}/>
                </div>
            </LayerItem>
        </Layer>
    )
}