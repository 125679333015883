import React, { useRef, useState, useEffect } from 'react';

import { getTotalUnitsBadge } from 'managers/Cards.js';
import moment from 'moment';

import Abstract from 'classes/Abstract.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import Content from 'managers/Content.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import { Line } from 'react-chartjs-2';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Product from 'classes/Product.js';
import Request from 'files/Request.js';
import SystemEventsList from 'views/SystemEventsList.js';
import { TableListHeader, TableListRow } from 'views/TableList.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';

// Panels
export const Products = ({ index, options, utils }) => {

    const panelID = 'products';
    const limit = 10;
    const offset = useRef(0);

    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [products, setProducts] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const [sorting, setSorting] = useState(null);

    const onChangeActiveStatus = async (product, evt) => {
        evt.stopPropagation();
        if(product.dealership) {
            onChangeActiveStatusConfirm(product);
            return;
        }
        utils.alert.show({
            title: 'Just a Second',
            message: 'Are you sure that you want to change the active status for this product? This is a globally offered product and changing the active status will effect all dealerships.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: product.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Do Not Change',
                style: product.active ? 'default' : 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onChangeActiveStatusConfirm(product);
                    return;
                }
            }
        });
    }

    const onChangeActiveStatusConfirm = async product => {
        try {
            let next_status = !product.active;
            await Request.post(utils, '/products/', {
                type: 'set_status',
                id: product.id,
                active: next_status
            });

            product.active = next_status;
            utils.content.update({
                type: 'product',
                object: product
            })

            setProducts(products => {
                return products.map(prev_product => {
                    if(prev_product.id === product.id) {
                        prev_product.active = next_status;
                    }
                    return prev_product;
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status of this product. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNewProductClick = () => {
        utils.layer.open({
            id: 'new_product',
            abstract: Abstract.create({
                type: 'product',
                object: Product.new()
            }),
            Component: AddEditProduct.bind(this, {
                isNewTarget: true
            })
        })
    }

    const onPrintProducts = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { products } = await Request.get(utils, '/products/', {
                    type: 'all_admin',
                    search_text: searchText,
                    ...sorting,
                    ...props
                });

                setLoading(false);
                resolve(products.map(entry => {
                    return {
                        ...Product.create(entry.product),
                        total_units: entry.total_units
                    }
                }));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onProductClick = product => {
        utils.layer.open({
            id: `product_details_${product.id}`,
            abstract: Abstract.create({
                type: 'product',
                object: product
            }),
            Component: ProductDetails
        })
    }

    const getActiveStatus = product => {
        if(!product) {
            return;
        }
        let color = product.active ? Appearance.colors.primary() : Appearance.colors.grey();
        let canEdit = product.dealership_id === utils.user.get().dealearshi_id || utils.user.get().level <= User.levels.get().admin ? true : false;
        return canEdit && (
            <div
            className={'text-button'}
            onClick={onChangeActiveStatus.bind(this, product)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: '100%',
                maxWidth: 75,
                textAlign: 'center',
                border: `1px solid ${color}`,
                background: Appearance.colors.softGradient(color),
                borderRadius: 5,
                overflow: 'hidden'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: '600',
                    width: '100%'
                }}>{product.active ? 'Active' : 'Not Active'}</span>
            </div>
        )
    }

    const getContent = () => {
        if(products.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No products were found in the system',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        if(Utils.isMobile()) {
            return products.map((product, index) => {
                return getFields(product, index)
            });
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {products.map((product, index) => {
                        return getFields(product, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (product, index) => {

        let target = product || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: target.name,
                    subTitle: `Added on ${moment(target.date).format('MMMM Do, YYYY')}`,
                    icon: {
                        path: target.icon.url,
                        imageStyle: {
                            backgroundColor: target.active ? Appearance.colors.primary() : Appearance.colors.grey()
                        }
                    },
                    badge: [{
                        text: target.dealership ? 'Dealership Exclusive' : null,
                        color: Appearance.colors.primary()
                    },{
                        text: target.active ? null : 'Not Active',
                        color: Appearance.colors.grey()
                    }],
                    firstItem: index === 0,
                    singleItem: products.length === 1,
                    lastItem: index === products.length - 1,
                    bottomBorder: index !== products.length - 1,
                    onClick: onProductClick.bind(this, target)
                })
            )
        }

        let fields = [{
            key: 'name',
            title: 'Name',
            padding: false,
            value: (
                Views.entry({
                    key: index,
                    title: target.name,
                    icon: target.icon && {
                        path: target.icon.url,
                        imageStyle: {
                            backgroundColor: target.active ? Appearance.colors.primary() : Appearance.colors.grey()
                        }
                    },
                    bottomBorder: false,
                    style: {
                        padding: '8px 12px 8px 12px',
                        textAlign: 'left'
                    }
                })
            )
        },{
            key: 'date',
            title: 'Created',
            value: target.date ? Utils.formatDate(target.date) : null
        },{
            key: 'total_units',
            title: 'Total Units',
            value: (
                <div style={{
                    display: 'inline-block'
                }}>
                    <AltBadge content={getTotalUnitsBadge(target)} />
                </div>
            )
        },{
            key: 'active',
            sortable: false,
            title: 'Status',
            value: getActiveStatus(target)
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!product) {
            let sorts = {
                [Content.sorting.type.alphabetically]: 'name',
                [Content.sorting.type.ascending]: 'date',
                [Content.sorting.type.descending]: 'date'
            };
            return (
                <TableListHeader
                fields={fields}
                onChange={props => setSorting(props)}
                {...sorting && sorting.general === true && {
                    value: {
                        key: sorts[sorting.sort_type],
                        direction: sorting.sort_type
                    }
                }} />
            )
        }
        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === products.length - 1}
            onClick={onProductClick.bind(this, product)} />
        )
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintProducts,
            onRenderItem: (item, index, items) => ({
                name: item.name,
                created: item.date ? Utils.formatDate(item.date) : null,
                total_units: item.total_units || 0,
                active: getActiveStatus(item)
            }),
            headers: [{
                key: 'name',
                title: 'Name'
            },{
                key: 'date',
                title: 'Created'
            },{
                key: 'total_units',
                title: 'Total Units'
            },{
                key: 'active',
                title: 'Status'
            }]
        }
    }

    const fetchProducts = async () => {
        try {
            if(utils.fetching.get(panelID) === true) {
                return;
            }
            setLoading(true);
            utils.fetching.set(panelID, true);
            let { products, paging } = await Request.get(utils, '/products/', {
                type: 'all_admin',
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                ...sorting
            });

            setLoading(false);
            utils.fetching.set(panelID, false);

            setPaging(paging);
            setProducts(products.map(entry => {
                return {
                    ...Product.create(entry.product),
                    total_units: entry.total_units
                }
            }));

        } catch(e) {
            setLoading(false);
            utils.fetching.set(panelID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the products list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchProducts();
    }, [sorting, searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchProducts);
        utils.content.subscribe(panelID, 'product', {
            onFetch: fetchProducts,
            onUpdate: abstract => {
                setProducts(products => {
                    return products.map(product => {
                        return product.id === abstract.getID() ? abstract.object : product;
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchProducts);
        }
    }, [])

    return (
        <Panel
        panelID={panelID}
        name={'Products'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            onVisibilityChange: fetchProducts,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchProducts();
                }
            },
            search: {
                placeholder: 'Search by id or product name...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            },
            buttons: [{
                key: 'new',
                title: 'New Product',
                style: 'default',
                onClick: onNewProductClick
            }]
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const ProductPerformance = ({ index, options, utils }) => {

    const panelID = `product_performance`;
    const chart = useRef(null);

    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(Utils.ytd.end_date());
    const [products, setProducts] = useState([]);
    const [tooltip, setTooltip] = useState(null);
    const [selectedDatasets, setSelectedDatasets] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [startDate, setStartDate] = useState(Utils.ytd.start_date());

    const onDateChange = (key, date) => {
        setEndDate(key === 'end' ? date : endDate);
        setStartDate(key === 'start' ? date : startDate);
        utils.events.emit('report_date_range_change', {
            end: key === 'end' ? date : endDate,
            start: key === 'start' ? date : startDate
        });
    }

    const onProductClick = product => {
        utils.layer.open({
            id: `product_details_${product.id}`,
            abstract: Abstract.create({
                type: 'product',
                object: product
            }),
            Component: ProductDetails
        })
    }

    const onUpdateDateRange = props => {
        let { end, start } = props.detail || {};
        setEndDate(prev => prev !== end ? end : prev);
        setStartDate(prev => prev !== start ? start : prev);
    }

    const getChart = () => {
        if(!chartData || chartData.labels.length < 3) {
            return null;
        }
        return (
            <div style={{
                padding: 15,
                width: '100%'
            }}>
                <Line
                ref={chart}
                width={500}
                height={200}
                data={getChartData()}
                options={{
                    title: { display: false },
                    legend: { display: false },
                    responsive: true,
                    maintainAspectRatio: true,
                    interaction: {
                        mode: 'dataset'
                    },
                    onClick: (evt, element) => {
                        if(element.length < 1) {
                            return;
                        }
                        let { datasets } = getChartData();
                        let { _datasetIndex, _index } = element[0];
                        let product = datasets[_datasetIndex].product;
                        if(product) {
                            onProductClick(product);
                        }
                    },
                    tooltips: {
                        enabled: false,
                        custom: (model, data) => {

                            let { caretX, caretY, dataPoints, opacity } = model;
                            if(opacity === 0) {
                                setTooltip(null);
                                setSelectedDatasets([]);
                                return;
                            }
                            setSelectedDatasets(dataPoints.map(points => points.datasetIndex));
                            let position = chart.current.chartInstance.canvas.getBoundingClientRect();
                            setTooltip({
                                top: caretY,
                                left: caretX,
                                content: dataPoints.map(({ datasetIndex, index }) => {
                                    let { datasets } = getChartData();
                                    let dataset = datasets[datasetIndex];
                                    let val = dataset.data[index];
                                    return {
                                        title: dataset.label,
                                        subTitle: `${val} ${val === 1 ? 'unit' : 'units'} sold`,
                                        product: dataset.product,
                                    }
                                })
                            });
                        }
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 20
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                beginAtZero: true,
                                callback: (value, index, values) => {
                                    return Utils.softNumberFormat(value);
                                }
                            }
                        }]
                    }
                }} />
                {tooltip && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        padding: '8px 12px 8px 12px',
                        top: tooltip.top,
                        left: tooltip.left,
                        zIndex: 8500,
                        backgroundColor: Appearance.colors.layerBackground(),
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        {tooltip.content.map((entry, index, entries) => {
                            return (
                                <div
                                key={index}
                                className={'text-button'}
                                onClick={onProductClick.bind(this, entry.product)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: index !== entries.length - 1 ? 12 : 0
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <span style={{
                                            ...Appearance.textStyles.title()
                                        }}>{entry.title}</span>
                                        <span style={{
                                            ...Appearance.textStyles.subTitle()
                                        }}>{entry.subTitle}</span>
                                    </div>
                                    <img
                                    src={'images/next-arrow-grey-small.png'}
                                    style={{
                                        width: 12,
                                        height: 12,
                                        objectFit: 'contain',
                                        marginLeft: 20,
                                        opacity: 0.75
                                    }} />
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        )
    }

    const getChartData = () => {
        if(!selectedProduct) {
            return chartData;
        }
        return {
            labels: chartData.labels,
            datasets: chartData.datasets.filter(dataset => {
                return dataset.product.id === selectedProduct.id;
            })
        }
    }

    const getProductSelector = () => {
        return (
            <select
            className={`custom-select ${window.theme}`}
            value={selectedProduct ? selectedProduct.title : 'All Products'}
            style={{
                width: 150
            }}
            onChange={evt => {
                let index = Utils.attributeForKey.select(evt, 'index');
                setSelectedProduct(index >= 0 ? products[index] : null);
            }}>
                <option>{'All Products'}</option>
                {products.sort((a,b) => {
                    return a.name.localeCompare(b.name);
                }).map((product, index) => {
                    return (
                        <option key={index} index={index}>{product.name}</option>
                    )
                })}
            </select>
        )
    }

    const fetchProducts = async () => {
        try {
            if(utils.fetching.get(panelID) === true) {
                return;
            }
            utils.fetching.set(panelID, true);
            let { datasets, labels, products } = await Request.get(utils, '/products/', {
                type: 'performance_chart',
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD')
            })

            setLoading(false);
            utils.fetching.set(panelID, false);
            setChartData({
                labels: labels.map(label => moment(label, 'YYYY-MM').format('MMM YYYY')),
                datasets: datasets.map(dataset => ({
                    label: dataset.name,
                    fill: false,
                    data: dataset.data,
                    product: dataset.product,
                    pointStyle: 'circle',
                    borderDash: [5, 5],
                    borderWidth: 2,
                    borderColor: Appearance.colors.grey(),
                    hoverBorderColor: Appearance.colors.primary(),
                    pointHoverBackgroundColor: Appearance.colors.primary(),
                    pointHoverBorderColor: Appearance.colors.primary(),
                    pointRadius: 5,
                    pointBorderWidth: 3,
                    pointBackgroundColor: Appearance.colors.grey(),
                    pointBorderColor: Appearance.colors.grey()
                }))
            })
            setProducts(products.map(product => Product.create(product)));

        } catch(e) {
            setLoading(false);
            utils.fetching.set(panelID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for the products graph. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchProducts();
    }, [endDate, startDate]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchProducts);
        utils.events.on(panelID, 'report_date_range_change', onUpdateDateRange);
        return () => {
            utils.events.off(panelID, 'dealership_change', fetchProducts);
            utils.events.off(panelID, 'report_date_range_change', onUpdateDateRange);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Product Performance'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            onVisibilityChange: fetchProducts
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 15
            }}>
                <DualDatePickerField
                utils={utils}
                selectedStartDate={startDate}
                selectedEndDate={endDate}
                onStartDateChange={onDateChange.bind(this, 'start')}
                onEndDateChange={onDateChange.bind(this, 'end')} />
                {getProductSelector()}
            </div>
            <div
            className={chartData && chartData.labels.length < 3 ? 'pinstripes' : ''}
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                minHeight: 450,
                borderRadius: 10,
                border: `1px solid ${Appearance.colors.divider()}`,
                ...chartData && chartData.labels.length < 3 && {
                    backgroundColor: Appearance.colors.background()
                }
            }}>
                {getChart()}
                {chartData && chartData.labels.length < 3 && (
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '12px 16px 12px 16px',
                        textAlign: 'center',
                        maxWidth: 'calc(100% - 30px)'
                    }}>
                        <img
                        src={'images/no-data-found-icon.png'}
                        style={{
                            width: 50,
                            height: 50,
                            marginBottom: 8
                        }} />
                        <span style={{
                            ...Appearance.textStyles.title()
                        }}>{'Too Few Sales Found'}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            whiteSpace: 'normal'
                        }}>{'Product performance overviews will show here when available'}</span>
                    </div>
                )}
            </div>
        </Panel>
    )
}

// Layers
export const AddEditProduct = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_product' : `edit_product_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState(null);

    const onSubmitClick = async () => {
        try {

            setLoading(true);
            await validateRequiredFields(getFields);

            // create target
            if(isNewTarget) {
                await abstract.object.submit(utils);
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `${abstract.object.name} has been added to the list of products`,
                    onClick: () => setLayerState('close')
                });
                return;
            }

            // update target
            await abstract.object.update(utils);
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `${abstract.object.name} has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this product. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = async props => {
        try {
            let edits = await abstract.object.set(props);
            setProduct(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this product. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            onClick: onSubmitClick,
            text: isNewTarget ? 'Submit' : 'Save Changes'
        }];
    }

    const getFields = () => {
        if(!product) {
            return [];
        }
        let items = [{
            title: 'About this Product',
            items: [{
                component: 'textfield',
                key: 'name',
                onChange: text => onUpdateTarget({ name: text }),
                required: true,
                title: 'Name',
                value: product.name
            },{
                component: 'icon_picker',
                key: 'icon',
                onChange: icon => onUpdateTarget({ icon: icon }),
                required: true,
                title: 'Icon',
                value: product.icon
            },{
                component: 'dealership_lookup',
                description: 'What dealership does this product belong to?',
                key: 'dealership',
                onChange: dealership => onUpdateTarget({ dealership: dealership }),
                required: false,
                title: 'Dealership',
                value: product.dealership,
                visible: utils.user.get().level <= User.levels.get().admin
            }]
        }];
        return items;
    }

    const setupTarget = async () => {
        try {
            let edits = await abstract.object.open();
            setProduct(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the ${isNewTarget ? 'new product' : 'editing'} process. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? 'New Product' : `Editing ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils}/>
        </Layer>
    )
}

export const ProductDetails = ({ abstract, index, options, utils }) => {

    const layerID = `product_details_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDelete = () => {
        utils.alert.show({
            title: `Delete ${abstract.object.name}`,
            message: `Are you sure that you want to delete ${abstract.object.name}? This will remove the product from all product lists. This action can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteConfirm = async () => {
        try {
            setLoading('options');
            await Request.post(utils, '/products/', {
                type: 'delete',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('product');
            utils.alert.show({
                title: 'All Done!',
                message: `${abstract.object.name} has been deleted`,
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this product. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            abstract: abstract,
            Component: AddEditProduct.bind(this, {
                isNewTarget: false
            }),
            id: `edit_product_${abstract.getID()}`
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'status',
                title: `Set as ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            if(key === 'status') {
                onSetStatus();
                return;
            }
            if(key === 'delete') {
                onDelete();
                return;
            }
        })
    }

    const onSetStatus = () => {
        utils.alert.show({
            title: `Set as ${abstract.object.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set ${abstract.object.name} as ${abstract.object.active ? 'inactive' : 'active'}? ${abstract.object.active ? 'This will prevent new sales from being entered for this product' : 'This will allow new sales to be entered for this product'}.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Do Not Change',
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetStatusConfirm = async () => {
        try {

            let status = !abstract.object.active;

            setLoading('options');
            await Request.post(utils, '/products/', {
                type: 'set_status',
                id: abstract.getID(),
                active: status
            });

            setLoading(false);
            abstract.object.active = status;
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: `${abstract.object.name} has been set as ${status ? 'active' : 'inactive'}`
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this product. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            text: 'Edit'
        }];
    }

    const getFields = () => {

        let product = abstract.object;
        let items = [{
            key: 'details',
            lastItem: false,
            title: 'Details',
            items: [{
                key: 'created',
                title: 'Created',
                value: Utils.formatDate(product.date)
            },{
                key: 'dealership',
                title: 'Dealership',
                value: product.dealership && product.dealership.name
            },{
                key: 'icon',
                title: 'Icon',
                value: product.icon ? product.icon.name : null
            },{
                key: 'id',
                title: 'ID',
                value: product.id
            },{
                key: 'name',
                title: 'Name',
                value: product.name
            },{
                key: 'status',
                title: 'Status',
                value: product.active ? 'Active' : 'Not Active'
            }]
        }];
        return items;
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`${abstract.getTitle()} Details`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <FieldMapper
            fields={getFields()}
            utils={utils}/>

            <SystemEventsList
            abstract={abstract}
            utils={utils} />
        </Layer>
    )
}
