import React, { useRef, useState, useEffect } from 'react';
import 'styles/main.css';

import { animated, useSpring } from '@react-spring/web';
import { getContent } from 'files/Content.js';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment-timezone';
import smoothscroll from 'smoothscroll-polyfill';
import update from 'immutability-helper';

import API from 'files/api.js';
import { AddEditUser, SubmitDemosTotal } from 'managers/Users.js';
import Abstract from 'classes/Abstract.js';
import { AddEditCard } from 'managers/Cards.js';
import { AddEditDealership, DealershipDetails, DealershipReportSettings, EditReplicatedWebsitePreferences, EditWhiteLabelOmniShieldPreferences } from 'managers/Dealerships.js';
import { AddEditProduct } from 'managers/Products.js';
import Alert from 'views/Alert.js';
import AlertStack from 'views/AlertStack.js';
import Appearance from 'styles/Appearance.js';
import BoolToggle from 'views/BoolToggle.js';
import Card from 'classes/Card.js';
import ChallengerOmnisAlert from 'views/ChallengerOmnisAlert.js';
import Content from 'managers/Content.js';
import Cookies from 'js-cookie';
import DatePicker, { DualDatePicker } from 'views/DatePicker.js';
import Dealership from 'classes/Dealership.js';
import DesktopNotification from 'views/DesktopNotification.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import { EndIndex } from 'structure/Layer.js';
import Events from 'managers/Events.js';
import Loader from 'views/Loader.js';
import Login, { runLogin } from 'views/Login.js';
import { NewSubscriptionRequest } from 'managers/Payments.js';
import Notification from 'classes/Notification.js';
import NotificationCenter from 'files/NotificationCenter.js';
import Payment from 'classes/Payment.js';
import { PaymentDetails, UnpaidInvoiceManagement } from 'managers/Payments.js';
import Product from 'classes/Product.js';
import QueryString from 'query-string';
import Request from 'files/Request.js';
import Searching from 'views/Searching.js';
import Sockets from 'managers/Sockets.js';
import Sheet from 'views/Sheet.js';
import Sidebar from 'structure/Sidebar.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';
 
const ContentManager = Content.new();
const EventsManager = Events.new();
const SocketManager = Sockets.new();
const Stripe = loadStripe(API.stripe);

const App = () => {

    const dealershipRef = useRef(null);
    const fetching = useRef({});
    const groupsRef = useRef(null);
    const layersRef = useRef(null);
    const restrictToDealership = useRef(true);
    const supportWorkflowAssetsRef = useRef([]);
    const userRef = useRef(null);

    const [active, setActive] = useState({ view: null });
    const [activeDealership, setActiveDealership] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const [container, setContainer] = useSpring(() => ({
        top: 0,
        opacity: 1,
        config: { mass: 1, tension: 180, friction: 16 }
    }));
    const [content, setContent] = useState({});
    const [datePicker, setDatePicker] = useState(null);
    const [layers, setLayers] = useState([]);
    const [layerIndex, setLayerIndex] = useState([]);
    const [loader, setLoader] = useState(null);
    const [groups, setGroups] = useState(null);
    const [notification, setNotification] = useState(null);
    const [omnisAlert, setOmnisAlert] = useState(false);
    const [panels, setPanels] = useState([]);
    const [preflight, setPreflight] = useState(false);
    const [searching, setSearching] = useState(false);
    const [sheet, setSheet] = useState(null);
    const [sidebar, setSidebar] = useState(-500);
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [subscriptions, setSubscriptions] = useState([]);
    const [supportWorkflowAssets, setSupportWorkflowAssets] = useState([]);
    const [theme, setTheme] = useState(window.theme);
    const [user, setUser] = useState(null);

    const utils = {
        alert: {
            show: async props => {
                try {
                    if(loader) {
                        await utils.loader.hide();
                    }

                    // prepare id for alert
                    let id = `${moment().unix()}-${Math.random()}`;

                    // update local state with alert data
                    setAlerts(alerts => {
                        return update(alerts, {
                            $push: [{
                                id: id,
                                ...props
                            }]
                        });
                    });

                    // return a close function for sender
                    return { 
                        close: utils.events.emit.bind(this, 'close_alert', { id })
                    }

                } catch(e) {
                    console.log(e.message)
                }
            },
            showAsync: props => {
                return new Promise((resolve, reject) => {
                    utils.alert.show({ 
                        ...props,
                        onClick: key => props.onClick(key, resolve, reject)
                    });
                });
            },
            dev: async props => {
                try {
                    if(loader) {
                        await utils.loader.hide();
                    }
                    setAlerts(alerts => update(alerts, {
                        $push: [{
                            id: `${moment().unix()}-${Math.random()}`,
                            title: 'In Development',
                            message: 'This feature is currently under development and will become available at a later date'
                        }]
                    }))

                } catch(e) {
                    console.log(e.message)
                }
            },
        },
        api: {
            headers: () => {
                return {
                    'Content-Type': 'application/json',
                    'X-API': `Version ${API.version}`,
                    'X-Timezone': `TZ ${moment.tz.guess()}`,
                    'X-Web': `Build ${API.build}`,
                    ...userRef.current && {
                        'Authorization': `Bearer ${userRef.current.token}`,
                        'Identification': `User ${userRef.current.user_id}`
                    }
                }
            }
        },
        graci: {
            preferences: {
                get: () => {
                    let result = Cookies.get('auto_login_preferences');
                    return typeof(result) === 'string' ? JSON.parse(result) : {};
                },
                set: props => {
                    Cookies.set('auto_login_preferences', JSON.stringify(props));
                }
            }
        },
        content: ContentManager,
        datePicker: {
            show: props => setDatePicker({
                utils: utils,
                id: `${moment().unix()}-${Math.random()}`,
                ...props
            }),
            showDual: props => {
                utils.layer.open({
                    id: 'dual_date_picker_alert',
                    Component: DualDatePicker.bind(this, {
                        ...props,
                        utils: utils
                    })
                })
            }
        },
        dealership: {
            get: () => dealershipRef.current,
            set: dealership => {
                if(user && user.level <= User.levels.get().admin) {
                    restrictToDealership.current = true;
                    utils.events.emit('visibility_change_silent', restrictToDealership.current);
                }
                setActiveDealership(dealership);
            },
            restricted: {
                get: () => restrictToDealership.current,
                set: val => {
                    restrictToDealership.current = val;
                    utils.events.emit('visibility_change', val);
                }
            }
        },
        events: EventsManager,
        fetching: {
            get: key => {
                return fetching.current[key];
            },
            set: (key, value) => {
                fetching.current[key] = value;
            }
        },
        groups: {
            get: () => groupsRef.current,
            apply: (key, group, value) => {
                if(Array.isArray(key)) {
                    for(var i in key) {
                        if(utils.groups.check(group, key[i]) === false) {
                            return getRestrictedText();
                        }
                    }
                    return value;
                }
                if(utils.groups.check(group, key) === false) {
                    return getRestrictedText();
                }
                return value;
            },
            check: (category, type) => {
                if(!groupsRef.current || groupsRef.current.length === 0) {
                    return true;
                }
                let matches = groupsRef.current.filter(prevGroup => prevGroup.category.code === category);
                for(var i in matches) {
                    if(matches[i].props && matches[i].props[type] === false) {
                        return false;
                    }
                }
                return true;
            }
        },
        layer: {
            open: layer => onOpenLayer(layer),
            close: layer => onCloseLayer(layer),
            requestClose: id => {
                let closeEvent = new CustomEvent('onLayerAction', {
                    detail: {
                        action: 'close',
                        layerID: id
                    }
                });
                window.dispatchEvent(closeEvent);
            }
        },
        loader: {
            show: async () => {
                return new Promise(resolve => {
                    setLoader(true);
                    setTimeout(resolve, 500)
                })
            },
            hide: async () => {
                return new Promise(resolve => {
                    setLoader(false);
                    setTimeout(resolve, 500)
                })
            }
        },
        notification: {
            show: props => {
                ContentManager.fetch('notifications');
                NotificationCenter.notify(utils, props);
                setNotification(props);
            }
        },
        sheet: {
            show: (props, callback) => {
                setSheet({ ...props, onClick: callback })
            },
            showAsync: props => {
                return new Promise((resolve, reject) => {
                    setSheet({ ...props, onClick: key => {
                        if(key !== 'cancel') {
                            resolve(key);
                            return;
                        }
                        let error = new Error('user cancelled sheet request');
                        reject(error);
                    } });
                });
            }
        },
        sockets: SocketManager,
        stripe: Stripe,
        subscriptions: {
            capabilities: {
                get: (key, props) => {

                    // no additional logic is required if user is an administrator and an explicit dealership wasn't provided
                    let { dealership, strict = false } = props || {};
                    if(!dealership && userRef.current.level <= User.levels.get().exigent_admin && strict === false) {
                        return true;
                    }

                    // loop through dealership subscriptions and look for a key match
                    let targets = (dealership ? dealership.subscriptions : subscriptions) || [];
                    let match = targets.find(subscription => subscription.capabilities.find(capability => key === capability.key));
                    return match ? true : false;
                }
            }
        },
        support: {
            assets: {
                fetch: async () => {
                    try {
                        let { assets } = await Request.get(utils, '/support/', { type: 'workflow_assets' });
                        setSupportWorkflowAssets(assets);
                    } catch(e) {
                        console.error(e.message);
                    }
                },
                get: () => supportWorkflowAssetsRef.current || [],
                set: setSupportWorkflowAssets
            }
        },
        theme: {
            get: () => window.theme,
            set: val => {
                window.theme = val;
                onSetStyleSheetProperties();
            }
        },
        user: {
            get: () => userRef.current || {}
        }
    }

    const onActiveDealershipChange = async () => {
        try {

            // no additional logic is required if an active dealership has not been set
            if(!activeDealership) {
                return;
            }

            // update dealership ref and update subscriptions state
            dealershipRef.current = activeDealership;
            setSubscriptions(activeDealership.subscriptions || []);

            // notify listeners of dealership change
            utils.events.emit('dealership_change', activeDealership);

            // update cookies with default dealership id
            Cookies.set('default_dealership_id', activeDealership.id);

        } catch(e) {
            console.log(e.message);
        }
    }

    const onCardsDownload = props => {

        // determine if an error was generated 
        if(props.status === 2) {
            utils.alert.show({
                title: 'Your Protections Download',
                message: `There was an error preparing your protections download. ${props.error}`
            });
            return;
        }

        // show confirmation alert with options to download document
        utils.alert.show({
            title: 'Your Protections Are Ready',
            message: 'Your protections download is ready. Click the button below to start your download.',
            buttons: [{
                key: 'download',
                title: 'Download',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'download') {
                    window.open(props.url);
                    return;
                }
            }
        });
    }

    const onCloseLayer = layerID => {

        setLayerIndex(layerIndex => update(layerIndex, {
            $apply: ids => ids.filter(id => id !== layerID)
        }))
        setLayers(layers => {
            let updatedLayers = update(layers, {
                $apply: layers => layers.map(layer => {
                    if(layer.id === layerID) {
                        layer.visible = false;
                    }
                    return layer;
                })
            });
            let remainingLayers = updatedLayers.filter(layer => {
                return layer.visible !== false
            });
            if(remainingLayers.length === 0) {
                console.log('layers reset');
                document.body.style.overflowY = 'scroll';
                return [];
            }
            return updatedLayers;
        });
    }

    const onConfirmSubscription = props => {
        try {

            // prevent moving forward if a category or schedule were not provided
            let { category, schedule } = props;
            if(!category || !schedule) {
                return;
            }

            // prepare schedule code using schedule string if applicable
            if(isNaN(schedule) === true) {
                switch(schedule) {
                    case 'monthly':
                    schedule = Payment.Subscription.schedules.get().monthly;
                    break;

                    case 'yearly':
                    schedule = Payment.Subscription.schedules.get().annually;
                    break;

                    default:
                    console.warn(`unsupported subscription schedule "${schedule}"`);
                    return;
                }
            }

            // open layer to confirm new subscription request
            utils.layer.open({
                abstract: Abstract.create({
                    object: dealershipRef.current,
                    type: 'dealership'
                }),
                Component: NewSubscriptionRequest.bind(this, {
                    category: category,
                    schedule: schedule,
                    sourceCategory: Payment.Method.source_categories.get().dealership
                }),
                id: 'new_subscription_request'
            });

        } catch(e) {
            console.error(e.message);
        }
    }

    const onConnectToWebsockets = async (channel, user) => {
        try {

            // open a connection for the requested websockets server
            await SocketManager.connect(user, channel);

            // determine if channel specific listeners are required
            if(channel === 'aft') {

                // notifications for specific user and account types
                await SocketManager.persistOn(channel, 'notifications', `on_notification_${user.user_id}`, onNotification);
                await SocketManager.persistOn(channel, 'notifications', `on_notification_level_${user.level}`, onNotification);

                // add system listeners for direct user events
                await SocketManager.persistOn(channel, 'system', 'on_document_status_change', onDocumentStatusChange);

                // user groups by on user id
                await SocketManager.persistOn(channel, 'users', `on_new_group_${user.user_id}`, onNewGroup);
                await SocketManager.persistOn(channel, 'users', `on_update_group_${user.user_id}`, onUpdateGroup);
                await SocketManager.persistOn(channel, 'users', `on_remove_group_${user.user_id}`, onRemoveGroup);

                // user group listeners based on account type
                await SocketManager.persistOn(channel, 'users', `on_new_group_level_${user.level}`, onNewGroup);
                await SocketManager.persistOn(channel, 'users', `on_update_group_level_${user.level}`, onUpdateGroup);
                await SocketManager.persistOn(channel, 'users', `on_remove_group_level_${user.level}`, onRemoveGroup);

            }

        } catch(e) {
            console.error(`[${channel}-websockets]: ${e.message}`);
        }
    }

    const onDocumentStatusChange = props => {
        switch(props.type) {
            case 'cards_download':
            onCardsDownload(props);
            break;

            default:
            console.error('unsupported document type found in the payload');
        }
    }

    const onEditDealership = async () => {
        try {

            // fetch details for dealership from server
            let dealership = await Dealership.get(utils, dealershipRef.current.id);

            // open replicated website preferences layer
            utils.layer.open({
                abstract: Abstract.create({
                    object: dealership,
                    type: 'dealership'
                }),
                Component: AddEditDealership.bind(this, {
                    isNewTarget: false
                }),
                id: `edit_dealership_${dealership.id}`
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your dealership details. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditReplicatedWebsitePreferences = async () => {
        try {

            // fetch details for dealership from server
            let dealership = await Dealership.get(utils, dealershipRef.current.id);

            // open replicated website preferences layer
            utils.layer.open({
                abstract: Abstract.create({
                    object: dealership,
                    type: 'dealership'
                }),
                Component: EditReplicatedWebsitePreferences,
                id: `replicated_website_preferences_${dealership.id}`
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your dealership website preferences. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditWhiteLabelPreferences = async () => {
        try {

            // fetch details for dealership from server
            let dealership = await Dealership.get(utils, dealershipRef.current.id);

            // open white label preferences layer
            utils.layer.open({
                id: `omnishield_preferences_${dealership.id}`,
                abstract: Abstract.create({
                    object: dealership,
                    type: 'dealership'
                }),
                Component: EditWhiteLabelOmniShieldPreferences.bind(this, {
                    preferences: dealership.omnishield
                })
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your omnishield white label preferences. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onKeydown = evt => {
        if(!userRef.current) {
            return;
        }
        // these combinations represent control r for windows and command r for macos
        if((evt.metaKey || evt.ctrlKey) && evt.keyCode === 70) {
            evt.preventDefault();
            evt.stopPropagation();
            setSearching(val => !val);
        }
    }

    const onGenerateChallengerOmnis = () => {
        setOmnisAlert({
            onChooseReport: ({ end_date, start_date, ytd }) => {
                let reportType = ytd ? 'ytd_challenger_omnis' : 'monthly_challenger_omnis';
                let url = `${API.server}/reports/?type=${reportType}&user_id=${userRef.current.user_id}&token=${userRef.current.token}&end=${end_date.unix()}&start=${start_date.unix()}`;
                window.open(url);
            }
        });
    }

    const onGenerateWeeklyOmnis = () => {

        let salesEdition = true;
        let endDate = moment().subtract(1, 'weeks').startOf('week').add(7, 'days');
        let startDate = moment().subtract(1, 'weeks').startOf('week').add(1, 'days');

        utils.alert.show({
            title: 'Weekly Omnis',
            message: 'Please choose a start date, end date, and report type below',
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <BoolToggle
                    color={Appearance.colors.grey()}
                    isEnabled={salesEdition}
                    enabled={'Sales Edition'}
                    disabled={'Directors Edition'}
                    onChange={val => salesEdition = val}
                    style={{
                        marginBottom: 12
                    }}/>

                    <DualDatePickerField
                    utils={utils}
                    overrideAlerts={true}
                    selectedStartDate={startDate}
                    selectedEndDate={endDate}
                    onStartDateChange={date => startDate = date}
                    onEndDateChange={date => endDate = date}
                    style={{
                        width: '100%'
                    }} />
                </div>
            ),
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'done') {
                    let reportType = salesEdition ? 'weekly_omnis_sales_edition' : 'weekly_omnis_directors_edition';
                    let url = `${API.server}/reports/?type=${reportType}&user_id=${userRef.current.user_id}&token=${userRef.current.token}&end=${endDate.unix()}&start=${startDate.unix()}`;
                    window.open(url);
                    return;
                }
            }
        })
    }

    const onLayerReposition = ({ id, position }) => {

        let index = layers.findIndex(layer => id === layer.id);
        if(index < 0) {
            console.log('no layer index');
            return;
        }
        setLayers(layers => update(layers, {
            [index]: {
                position: {
                    $set: position
                }
            }
        }))
    }

    const onLogin = async ({ default_dealership, user }) => {
        try {

            // show loader and determine if dealership restrictions are required
            await utils.loader.show();
            restrictToDealership.current = [ User.levels.get().admin, User.levels.get().region_director, User.levels.get().division_director].includes(user.level) ? false : true;

            // set user with ref first so we don't have to wait for a state updated to access
            userRef.current = user;
            setUser(user);

            // set active dealership with ref first so we don't have to wait for a state updated to access
            dealershipRef.current = default_dealership || user.dealership;
            setActiveDealership(dealershipRef.current);

            // set user groups
            setGroups(groups);

            // connect to aft websockets
            onConnectToWebsockets('aft', user);

            // connect to omnishield websockets
            onConnectToWebsockets('omnishield', user);

            // request that user verify their information before moving forward
            await onRequestUserVerification(user);
            
            // end loading
            await utils.loader.hide();

            // animate components into view and hide sidebar if device is mobile
            setContainer({
                top: 0,
                opacity: 1
            });
            setActive({
                view: 'dashboard',
                subView: null
            });

            // fetch workflow assets if user is an administrator
            if(user.level <= User.levels.get().admin) {
                utils.support.assets.fetch();
            }

            // show sidebar automatically if device is tablet or desktop
            if(Utils.isMobile() === false) {
                setSidebar(0);
            }

            // process optional post-login query parameters
            onProcessPostLoginQueryParameters();

        } catch(e) {
            utils.loader.hide();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your content. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onNavigationChange = ({ subView, view }) => {

        // hide sidebar if device is mobile
        if(Utils.isMobile() === true) {
            setSidebar(-500);
        }

        // prevent changing to a view that is already active
        if(view === active.view) {
            if(!subView || subView === active.subView) {
                return;
            }
        }

        // loop through view options and apply logic if applicable
        switch(view) {
            case 'cards':
            switch(subView) {
                case 'new':
                utils.layer.open({
                    id: 'new_card',
                    abstract: Abstract.create({
                        type: 'card',
                        object: Card.new()
                    }),
                    Component: AddEditCard.bind(this, {
                        isNewTarget: true
                    })
                })
                return;
            }
            break;

            case 'dealership_profile':
            onShowDealershipProfile();
            return;

            case 'demos':
            onShowDemoSubmission();
            return;

            case 'marketing':
            switch(subView) {
                case 'dealership_website':
                onEditReplicatedWebsitePreferences();
                return;
            }
            break;

            case 'products':
            switch(subView) {
                case 'new':
                utils.layer.open({
                    id: 'new_product',
                    abstract: Abstract.create({
                        type: 'product',
                        object: Product.new()
                    }),
                    Component: AddEditProduct.bind(this, {
                        isNewTarget: true
                    })
                })
                return;
            }
            break;

            case 'reports':
            switch(subView) {

                case 'challenger_omnis':
                onGenerateChallengerOmnis();
                return;

                case 'weekly_omnis':
                onGenerateWeeklyOmnis();
                return;

                case 'settings':
                onShowDealershipReportSettings();
                return;
            }
            break;

            case 'support':
            utils.support.assets.fetch();
            break;

            case 'search':
            setSearching(val => !val);
            return;
        }

        // scroll to the top of the page and set the new view and/or subview
        window.scrollTo(0, 0);
        setActive({
            view: view,
            subView: subView
        });
    }

    const onNewGroup = ({ group }) => {
        try {
            console.log(`added group ${group.id}`);
            if(!groupsRef.current) {
                return;
            }
            setGroups(update(groupsRef.current, {
                $push: [ group ]
            }));
        } catch(e) {
            console.log(e.message);
        }
    }

    const onNotification = data => {
        try {
            let notification = Notification.create(data);
            setNotification(notification);
            utils.content.fetch('notification');
        } catch(e) {
            console.log(e);
        }
    }

    const onOpenLayer = nextLayer => {

        // move layer to front of stack if layer is already open
        let index = layerIndex.findIndex(id => id === nextLayer.id);
        if(index >= 0) {
            setTimeout(() => {
                setLayerIndex(indexes => {
                    let results = update(indexes, {
                        $splice: [[index, 1]],
                        $unshift: [nextLayer.id]
                    });
                    return results;
                });
            }, 0);
            return;
        }

        // add layer to list of layers and update layer index
        setTimeout(() => {
            setLayers(layers => update(layers, {
                $push: [nextLayer]
            }));
            setLayerIndex(indexes => update(indexes, {
                $unshift: [nextLayer.id]
            }));
        }, 0);
    }

    const onParseDealerResourcesContent = parameters => {
        switch(parameters.action) {
            case 'deep_link':
            onParseDealerResourcesContentDeepLink(parameters);
            break;
        }
    }

    const onParseDealerResourcesContentDeepLink = parameters => {
        switch(parameters.id) {
            case 'edit_dealership':
            onEditDealership();
            break;

            case 'edit_dealership_replicated_website':
            onEditReplicatedWebsitePreferences();
            break;

            case 'edit_omnishield_white_label_preferences':
            onEditWhiteLabelPreferences();
            break;
        }
    }

    const onProcessPostLoginQueryParameters = () => {

        // determine next steps for query route parameter
        let parameters = QueryString.parse(window.location.search);
        switch(parameters.route) {

            case 'dealer_resources_content':
            onParseDealerResourcesContent(parameters);
            break;

            case 'payment_invoice':
            onShowPaymentInvoice(parameters.id);
            break;

            case 'single_sign_on':
            onRequestAutoLoginPreference(userRef.current.refresh_token);
            break;

            case 'subscription_confirmation':
            onConfirmSubscription(parameters);
            break;

            case 'unpaid_invoice_management':
            onShowUnpaidInvoiceManagement(parameters.id);
            break;

            case 'user_details':
            onShowUserDetails(parameters.user_id);
            break;
        }

        // remove query parameters from the query string
        window.history.pushState(null, 'Applied Fire Technologies', '/');
    }

    const onRemoveGroup = ({ group }) => {
        try {
            console.log(`removed group ${group.id}`);
            if(!groupsRef.current) {
                return;
            }
            setGroups(groupsRef.current.filter(prevGroup => {
                return prevGroup.id !== group.id;
            }));
        } catch(e) {
            console.log(e.message);
        }
    }

    const onRequestAutoLoginPreference = token => {
        utils.alert.show({
            title: 'Remember My Login',
            message: 'Would you like us to automatically log you into this account next time you visit the website?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {

                    // update cookies with access token for cross-platform logins
                    let preferences = utils.graci.preferences.get();
                    preferences.auto_login = true;
                    preferences.last_login = moment().unix();
                    preferences.refresh_token = token;
                    utils.graci.preferences.set(preferences);
                    return;
                }
            }
        });
    }

    const onRequestLogout = () => {
        utils.alert.show({
            title: 'Logout',
            message: 'Are you sure that you want to logout of your account?',
            buttons: [{
                key: 'logout',
                title: 'Logout',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Logout',
                style: 'default'
            }],
            onClick: async key => {
                try {

                    if(key !== 'logout') {
                        return;
                    }

                    // reset ui back to pre-login state
                    setSidebar(-500);
                    setLayers([]);
                    setLayerIndex([]);
                    setContainer({
                        opacity: 0,
                        top: -200
                    });

                    // reset navigation back to pre-login state
                    await Utils.sleep(0.5);
                    setActive({ view: null });

                    // remove user, groups, and dealership details
                    setActiveDealership(null);
                    setGroups(null);
                    setUser(null);

                    // remove auto login variables from graci preferences cookie
                    let preferences = utils.graci.preferences.get();
                    preferences.auto_login = false;
                    preferences.last_login = null;
                    preferences.refresh_token = null;
                    utils.graci.preferences.set(preferences);

                } catch(e){
                    console.log(e.message);
                }
            }
        });
    }

    const onRequestUserVerification = async user => {
        return new Promise(resolve => {
            if(user.verified) {
                resolve();
                return;
            }

            utils.loader.hide();
            utils.alert.show({
                title: 'Just a Second',
                message: 'It looks like you have not verified the information on file with your account. Please take a moment to look over your account and verify that everthing looks correct.',
                buttons: [{
                    key: 'continue',
                    title: 'Continue',
                    style: 'default'
                }],
                onClick: key => {
                    if(key === 'continue') {
                        utils.layer.open({
                            id: `edit_user_${user.user_id}`,
                            abstract: Abstract.create({
                                type: 'user',
                                object: user
                            }),
                            Component: AddEditUser.bind(this, {
                                isNewTarget: false,
                                onUpdateUser: () => {
                                    utils.loader.show();
                                    resolve();
                                }
                            })
                        })
                        return;
                    }
                }
            })
        });
    }

    const onRestrictedTextClick = evt => {
        evt.stopPropagation();
        utils.alert.show({
            title: 'Restricted Information',
            message: 'Your Dealership has set this information as hidden for your account. Please speak with your Dealer if you have questions about this piece of information.'
        })
    }

    const onSetLayerIndex = layerID => {

        let index = layers.findIndex(layer => layer.id === layerID);
        if(index < 0) {
            console.log('no layer index');
            return;
        }
        setLayers(layers, update(layers, {
            $apply: layers => layers.map(layer => {
                layer.moveToFront = layer.id === layerID;
                return layer;
            })
        }))

        let _index = layerIndex.findIndex(id => id === layerID);
        setLayerIndex(layerIndex, update(layerIndex, {
            $splice: [
                [_index, 1],
                [0, 0, layerID]
            ]
        }))
    }

    const onSetStyleSheetProperties = () => {
        document.body.className = window.theme;
        document.documentElement.style.setProperty('--theme', window.theme);
        document.documentElement.style.setProperty('--text', Appearance.colors.text());
        document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
        document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());
        document.querySelector('meta[name="theme-color"]').setAttribute("content", Appearance.colors.background());
        setTheme(window.theme);
    }

    const onShowDealershipProfile = async () => {
        try {
                
            // set loading flag and fetch details for dealership
            utils.loader.show();
            let dealership = await Dealership.get(utils, utils.user.get().dealership.id);

            // end loading and show dealership details
            utils.loader.hide();
            utils.layer.open({
                abstract: Abstract.create({
                    object: dealership,
                    type: 'dealership'
                }),
                Component: DealershipDetails,
                id: `dealership_details_${dealership.id}`
            });

        } catch(e) {
            utils.loader.hide();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retreiving your dealership details. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowDealershipReportSettings = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: dealershipRef.current,
                type: 'dealership'
            }),
            Component: DealershipReportSettings,
            id: 'dealership_report_settings'
        });
    }

    const onShowDemoSubmission = () => {
        let dealership = utils.dealership.get();
        if(dealership.gdl_active === false) {
            utils.layer.open({
                id: `submit_demos_total`,
                Component: SubmitDemosTotal
            });
            return;
        }
        utils.alert.show({
            title: 'Submitting Monthly Demos',
            message: `It looks like the "${dealership.name}" dealership is enrolled with Global Data. Demo totals for each month are automatically calculated for dealerships enrolled in Global Data. You no longer need to manually submit your monthly demo totals for this dealership.`
        });
    }

    const onShowPaymentInvoice = async id => {
        try {

            // submit request to server
            let { payment } = await Request.get(utils, '/payments/', {
                id: id,
                type: 'details'
            });

            // show payment details layer
            utils.layer.open({
                abstract: Abstract.create({
                    object: Payment.create(payment),
                    type: 'payment'
                }),
                Component: PaymentDetails,
                id: `payment_details_${payment.id}`
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for your invoice. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowUnpaidInvoiceManagement = async id => {
        try {

            // fetch details for payment from server
            let payment = await Payment.get(utils, id);

            // open layer to manage payment method for subscription
            utils.layer.open({
                abstract: Abstract.create({
                    object: payment.dealership,
                    type: 'dealership'
                }),
                Component: UnpaidInvoiceManagement.bind(this, { payment }),
                id: `unpaid_invoice_management_${payment.id}`
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for your payment. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowUserDetails = async userID => {
        try {

            // fetch details for user account
            let user = await User.get(utils, userID);

            // present user details layer
            utils.layer.open({
                id: `user_details_${user.user_id}`,
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this user account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateContentComponents = () => {

        const contentHasCapability = entry => {
            if(!entry.capabilities) {
                return true;
            }
            let match = entry.capabilities.find(key => utils.subscriptions.capabilities.get(key, { dealership: dealershipRef.current }) === false);
            return match ? false : true;
        }

        const contentHasReportVisibility = entry => {
            if(!entry.visibility) {
                return true;
            }
            let match = entry.visibility.find(key => {
                if(key.includes('reports.safety_advisors') === true) {
                    return userRef.current.level < User.levels.get().safety_advisor || dealershipRef.current.preferences.reports.safety_advisor_visibility[key] === false;
                }
                return false;
            });
            return match ? false : true;
        }

        // prepare list of targets and filter out content that does not match the dealership's capabilities
        let content = getContent(utils);
        let targets = Object.keys(content).reduce((array, key) => {

            // validate permissions at a section level if applicable
            if(content[key].capabilities) {
                if(contentHasCapability(content[key]) === true) {
                    array.push(content[key]);
                }
                return array;
            }

            // loop through panels and determine if panel level validation needs to occur
            if(content[key].panels) {
                let panels = content[key].panels.filter(panel => contentHasCapability(panel)) || [];
                if(panels.length > 0) {
                    array.push({ ...content[key], panels });
                }
                return array;
            }

            // determine if subview panel validation needs to occur
            if(content[key].subViews) {

                let subViews = {};
                Object.keys(content[key].subViews).forEach(view => {

                    // validate capabilities subview level if applicable
                    let target = content[key].subViews[view];
                    if(target.capabilities && contentHasCapability(target) === false) {
                        return array;
                    }

                    // determine if content has a visibility key setup
                    if(target.visibility && contentHasReportVisibility(target) === false) {
                        return array;
                    }

                    // no additional logic is required if no panels are set
                    if(!target.panels) {
                        subViews[view] = target;
                        return;
                    }

                    // filter out panels that fail the capabilities check and remove subview if all panels fail
                    let panels = target.panels.filter(panel => contentHasCapability(panel)) || [];
                    if(panels.length > 0) {
                        subViews[view] = { ...target, panels };
                    }
                });
                array.push({ ...content[key], subViews });
            }
            return array;
            
        }, []);

        // update local state with new content components
        setContent(targets);
    }

    const onUpdateGroup = ({ group }) => {
        try {
            console.log(`updated group ${group.id}`);
            if(!groupsRef.current) {
                return;
            }
            setGroups(groupsRef.current.map(prevGroup => {
                return prevGroup.id === group.id ? group : prevGroup;
            }));
        } catch(e) {
            console.log(e.message);
        }
    }

    const onUpdateTheme = evt => {
        window.theme = evt.matches ? 'dark' : 'light';
        onSetStyleSheetProperties();
    }

    const onWindowSizeChange = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    const getLayers = () => {
        return layers.map(({ id, abstract, onMount, options, title, visible, Component }, index) => {
            if(visible === false) {
                // must return null instead of using filter
                // using filter for visible does not preseve other visible layers
                return null;
            }
            return (
                <Component
                key={index}
                title={title}
                index={index}
                utils={utils}
                abstract={abstract}
                options={{
                    ...options,
                    index: index,
                    onMount: onMount,
                    zIndex: EndIndex - layerIndex.findIndex(indexID => id === indexID),
                    onClose: onCloseLayer,
                    onSetLayerIndex: onSetLayerIndex,
                    onReposition: onLayerReposition
                }}/>
            )
        })
    }

    const getMainContent = () => {

        let panels = getPanels();
        return (
            <>
            {getLayers()}
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <VelocityComponent
                    easing={[250, 20]}
                    duration={750}
                    delay={250}
                    animation={{
                        left: sidebar
                    }}>
                        <aside
                        className={'main-sidebar col-12 col-md-4 col-lg-3 col-xl-2 px-0'}
                        style={{
                            borderWidth: 0,
                            zIndex: 1000
                        }}>
                            <Sidebar
                            user={user}
                            utils={utils}
                            active={active}
                            content={content}
                            activeDealership={activeDealership}
                            onLogoutClick={onRequestLogout}
                            onMobileClose={() => setSidebar(-window.innerWidth)}
                            onClick={onNavigationChange} />
                        </aside>
                    </VelocityComponent>

                    <main
                    className={'main-content col-12 col-md-8 col-lg-9 col-xl-10 p-0 offset-md-4 offset-lg-3 offset-xl-2'}
                    style={{
                        zIndex: '900'
                    }}>
                        {getMobileHeader()}
                        <animated.div
                        className={'main-content-container container-fluid px-0 pt-0'}
                        style={{
                            position: 'relative',
                            paddingBottom: 250,
                            ...container
                        }}>
                            <div
                            className={'row w-100 p-0 m-0'}
                            style={{
                                position: 'relative'
                            }}>
                                {panels && (
                                    panels.map(({ Component }, index) => (
                                        <Component
                                        key={index}
                                        utils={utils}
                                        index={index} />
                                    ))
                                )}
                            </div>
                        </animated.div>
                    </main>
                </div>
            </div>
            </>
        )
    }

    const getMobileHeader = () => {
        return (
            <nav className={`main-navbar ${theme} navbar navbar-light sticky-top d-flex d-md-none flex-md-nowrap p-0 w-100 text-center`}>
                <div style={{
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <a
                    href={'#'}
                    className={'nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none d-lg-none text-center'}>
                        <img
                        className={'text-button'}
                        src={`images/navigation-${theme === 'dark' ? 'white' : 'dark-grey'}.png`}
                        style={{
                            width: 25,
                            height: 25,
                            objectFit: 'contain'
                        }}
                        onClick={() => setSidebar(0)} />
                    </a>
                    <div className={'nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none d-lg-none text-center'}/>
                </div>
            </nav>
        )
    }

    const getRestrictedText = () => {
        return (
            <img
            className={'text-button'}
            onClick={onRestrictedTextClick}
            src={'images/text-content-hidden.png'}
            style={{
                height: 20,
                objectFit: 'contain',
                width: 70
            }} />
        )
    }

    const getPanels = () => {

        // prevent moving forward if no primary view is set
        let { view, subView } = active;
        if(!view) {
            return [];
        }

        // prepare an array of the requested panels
        return content.reduce((array, entry) => {

            // skip entry if view doesnt match
            if(entry.key !== view) {
                return array;
            }

            // determine if the requested view contains subviews
            if(subView) {
                return entry.subViews[subView].panels.filter(panel => panel.visible !== false);
            }
            
            // fallback to returning all panels for selected view
            return entry.panels.filter(panel => panel.visible !== false);

        }, []);
    }

    const findContent = () => {

        // return main application content if a user state has been set
        if(user) {
            return getMainContent();
        }

        // determine if pre-login tasks have completed
        if(preflight === false) {
            return null;
        }

        // fallback to showing user login
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                padding: 25,
                width: '100%'
            }}>
                {getLayers()}
                <Login
                onLogin={onLogin}
                utils={utils}/>
            </div>
        )
    }

    const onLoginAsUser = async token => {
        try {

            // set loading flag and run login with single-use token
            await utils.loader.show();
            let result = await Request.post(utils, '/users/', {
                token: decodeURIComponent(token),
                type: 'validate_single_use_login_token'
            });

            // set user manually so login ui doesn't render after preflight flag is updated
            let user = User.create(result.user);
            setUser(user);
            
            // run post-login logic
            onLogin(result);

        } catch(e) {
            utils.loader.hide();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing the login process. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const runPreflightTasks = async () => {
        try {

            // run pre-login tasks  if applicable
            let parameters = QueryString.parse(window.location.search);
            switch(parameters.route) {
                case 'admin_login_as_user':
                case 'single_sign_on':
                return onLoginAsUser(parameters.token);
            }

            // fetch and decode cross-platform cookie
            let preferences = utils.graci.preferences.get();

            // determine if a token is present and auto login is enabled for the platform
            if(preferences.refresh_token && preferences.auto_login === true) {

                // set loading flag and run login for access token
                await utils.loader.show();
                let result = await runLogin(utils, { refresh_token: preferences.refresh_token });

                // set user manually so login ui doesn't render after preflight flag is updated
                setUser(result.user);
                
                // run post-login logic
                onLogin(result);
            }

            // set preflight flag as complete
            setPreflight(true);

        } catch(e) {
            console.error(e.message);
            setPreflight(true);
            utils.loader.hide();
        }
    }

    const setupExtensions = () => {
        Object.assign(String.prototype, {
            capitalize() {
                return Utils.capitalize(this)
            }
        });
    }

    useEffect(() => {
        onActiveDealershipChange();
    }, [activeDealership]);


    useEffect(() => {
        groupsRef.current = groups;
    }, [groups]);

    useEffect(() => {
        userRef.current = user;
    }, [user]);

    useEffect(() => {
        layersRef.current = layers;
    }, [layers]);

    useEffect(() => {
        document.body.style.overflowY = searching ? 'hidden' : 'scroll';
    }, [searching]);

    useEffect(() => {
        if(subscriptions && user) {
            onUpdateContentComponents();
        }
    }, [subscriptions, user]);

    useEffect(() => {
        supportWorkflowAssetsRef.current = supportWorkflowAssets || [];
    }, [supportWorkflowAssets]);

    useEffect(() => {

        setupExtensions();
        runPreflightTasks();

        // setup scroll polyfill and window listeners
        smoothscroll.polyfill();
        window.addEventListener('resize', onWindowSizeChange);
        window.addEventListener('beforeunload', (e) => {
            (e || window.event).returnValue = null;
            return null;
        });

        // setup global search listener
        window.addEventListener('keydown', onKeydown);

        // css variables
        document.documentElement.style.setProperty('--text', Appearance.colors.text());
        document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
        document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());

        // theme and theme listeners
        window.theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        document.documentElement.style.setProperty('--theme', window.theme);
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', onUpdateTheme);
        onSetStyleSheetProperties();

    }, []);

    return (
        <div
        className={`root-container ${theme}`}
        style={{
            height: size.height,
            position: 'relative',
            width: '100%'
        }}>
            {findContent()}
            <Loader animate={loader}/>

            {datePicker && datePicker.overrideAlerts !== true && (
                <DatePicker
                {...datePicker}
                onClose={id => {
                    setDatePicker(null);
                    if(typeof(datePicker.onClose) === 'function') {
                        datePicker.onClose();
                    }
                }} />
            )}
            {sheet && (
                <Sheet
                {...sheet}
                onClose={() => setSheet(null)}/>
            )}
            {notification && (
                <DesktopNotification
                utils={utils}
                notification={notification}
                onClose={() => setNotification(null)}/>
            )}
            {user && (
                <Searching
                user={user}
                utils={utils}
                visible={searching}
                onClose={() => setSearching(false)}/>
            )}
            
            {omnisAlert && (
                <ChallengerOmnisAlert
                {...omnisAlert}
                utils={utils}
                onClose={() => setOmnisAlert(false)} />
            )}

            <AlertStack>
                {alerts.map((alert, index) => (
                    <Alert
                    {...alert}
                    key={index}
                    utils={utils}
                    index={(alerts.length - 1) - index}
                    onClose={id => {
                        if(typeof(alert.onClose) === 'function') {
                            alert.onClose();
                        }
                        setAlerts(alerts => {
                            return alerts.filter(alert => {
                                return id !== alert.id;
                            });
                        })
                    }} />
                ))}
            </AlertStack>

            {datePicker && datePicker.overrideAlerts === true && (
                <DatePicker
                {...datePicker}
                onClose={id => {
                    setDatePicker(null);
                    if(typeof(datePicker.onClose) === 'function') {
                        datePicker.onClose();
                    }
                }} />
            )}
        </div>
    )
}

export default App;
