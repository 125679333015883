import moment from 'moment-timezone';
import Dealership from 'classes/Dealership.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

class PaymentClass {

    amount = null;
    card_fingerprint = null;
    category = null;
    date = null;
    dealership = null;
    external_id = null;
    id = null;
    internal_id = null;
    metadata = null;
    platform = null;
    source_category = null;
    status = null;
    target = null;
    user = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.amount = props.amount;
        this.card_fingerprint = props.card_fingerprint;
        this.category = props.category;
        this.date = props.date && moment.utc(props.date).local();
        this.dealership = props.dealership && Dealership.create(props.dealership);
        this.external_id = props.external_id;
        this.id = props.id;
        this.internal_id = props.internal_id;
        this.metadata = props.metadata;
        this.platform = props.platform;
        this.source_category = props.source_category;
        this.status = props.status;
        this.target = this.setTarget(props.target);
        this.user = props.user && User.create(props.user);

        // determine if a metadata date needs to be prepared
        if(this.metadata.invoice_date) {
            this.metadata.invoice_date = moment.utc(this.metadata.invoice_date).local();
        }
        return this;
    }

    isSubscriptionCategory = () => {
        return [
            categories.bronze_subscription,
            categories.gold_subscription, 
            categories.global_data_subscription,
            categories.omnishield_white_label_subscription,
            categories.silver_subscription
        ].includes(this.category.code);
    }

    setTarget = target => {
        if(this.isSubscriptionCategory() === true) {
            return target && new SubscriptionClass().create(target);
        }
    }
}

class MethodClass {

    brand = null;
    description = null;
    expiration = null;
    fingerprint = null;
    funding = null;
    id = null;
    is_default = false;
    last4 = null;
    name = null;
    source_category = false;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.brand = props.brand;
        this.description = props.description;
        this.expiration = props.expiration;
        this.fingerprint = props.fingerprint;
        this.funding = props.funding;
        this.id = props.id;
        this.is_default = props.is_default || false;
        this.last4 = props.last4;
        this.name = props.name;
        this.source_category = props.source_category;
        return this;
    }

    getExpiration = () => {
        return `Expires ${this.expiration.month}/${this.expiration.year}`;
    }

    getIcon = () => {
        switch(this.brand) {
            case 'Visa':
            return 'fab fa-cc-visa';

            case 'American Express':
            return 'fab fa-cc-amex';

            case 'Discover':
            return 'fab fa-cc-discover';

            case 'MasterCard':
            return 'fab fa-cc-mastercard';

            default:
            return 'far fa-credit-card';
        }
    }
}

class RefundClass {

    amount = null;
    date = null;
    dealership = null;
    external_id = null;
    id = null;
    payment_id = null;
    status = null;
    user = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.amount = props.amount;
        this.date = props.date && moment(props.date);
        this.dealership = props.dealership && Dealership.create(props.dealership);
        this.external_id = props.external_id;
        this.id = props.id;
        this.payment_id = props.payment_id;
        this.refund_id = props.refund_id;
        this.status = props.status;
        this.user = props.user && User.create(props.user);
        return this;
    }
}

class SubscriptionClass {

    active = null;
    amount = null;
    capabilities = null;
    card_fingerprint = null;
    category = null;
    date = null;
    dealership = null;
    dealership_id = null;
    id = null;
    payment_category = null;
    promotions = null;
    renewal_date = null;
    schedule = null;
    source_category = null;
    target = null;
    user = null;
    user_id = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.amount = props.amount;
        this.capabilities = props.capabilities || [];
        this.card_fingerprint = props.card_fingerprint;
        this.category = props.category;
        this.date = props.date && moment(props.date);
        this.dealership = props.dealership && Dealership.create(props.dealership);
        this.dealership_id = props.dealership_id;
        this.id = props.id;
        this.payment_category = props.payment_category;
        this.promotions = props.promotions.map(promotion => ({
            ...promotion,
            end_date: promotion.end_date && moment(promotion.end_date),
            start_date: moment(promotion.start_date)
        }));
        this.renewal_date = props.renewal_date;
        this.schedule = props.schedule;
        this.source_category = props.source_category;
        this.target = props.target;
        this.user = props.user && User.create(props.user);
        this.user_id = props.user_id;
        return this;
    }

    canUpgrade = () => {
        return this.active === true && [
            subscriptionCategories.bronze,
            subscriptionCategories.global_data,
            subscriptionCategories.omnishield_white_label,
            subscriptionCategories.silver
        ].includes(this.category.code);
    }

    emit = (utils, type, value) => {

        // determine which type of event needs to be emitted
        switch(type) {
            case 'active_status_change':
            this.onActiveStatusChange(utils, value);
            break;
        }
    }

    onActiveStatusChange = (utils, value) => {

        // determine which type of listeners need to be notified of specialty data changes
        switch(this.category.code) {
            case subscriptionCategories.global_data:
            utils.events.emit('dealership_gdl_active_status_change', {
                active: value,
                id: this.dealership_id,
                subscription: this
            });
            break;

            case subscriptionCategories.omnishield_white_label:
            utils.events.emit('dealership_omnishield_white_label_active_status_change', {
                active: value,
                id: this.dealership_id,
                subscription: this
            });
            break;

            default:
            console.warn('[on_active_status_change]: no specialty listeners need to be notified of data changes');
        }
    }
}

class TransactionClass {

    amount = null;
    card_fingerprint = null;
    date = null;
    external_id = null;
    id = null;
    payment_id = null;
    processed_by_user_id = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.amount = props.amount;
        this.card_fingerprint = props.card_fingerprint;
        this.date = props.date && moment(props.date);
        this.external_id = props.external_id;
        this.id = props.id;
        this.payment_id = props.payment_id;
        this.processed_by_user_id = props.processed_by_user_id;
        return this;
    }
}

const categories = {
    sms_credits: 1,
    omnishield_white_label_subscription: 2,
    global_data_subscription: 3,
    bronze_subscription: 4,
    silver_subscription: 5,
    gold_subscription: 6
}

const fetchPayment = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { payment } = await Request.get(utils, '/payments/', {
                id: id,
                type: 'details'
            });
            resolve(new PaymentClass().create(payment));

        } catch(e){
            reject(e);
        }
    });
}

const fetchPaymentMethod = async (utils, fingerprint, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { method } = await Request.get(utils, '/payments/', {
                fingerprint: fingerprint,
                type: 'payment_method_details',
                ...props
            });
            resolve(new MethodClass().create(method));

        } catch(e){
            reject(e);
        }
    });
}

const fetchSubscription = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { subscription } = await Request.get(utils, '/payments/', {
                id: id,
                type: 'subscription_details'
            });
            resolve(new SubscriptionClass().create(subscription));

        } catch(e){
            reject(e);
        }
    });
}

const platforms = {
    aft: 1,
    dreamcatcher: 2,
    flipchart: 3,
    global_data: 4,
    omnishield: 5
}

const platformToText = code => {
    switch(code) {
        case platforms.aft:
        return 'Applied Fire Technologies';

        case platforms.dreamcatcher:
        return 'DreamCatcher';

        case platforms.flipchart:
        return 'FlipChart';

        case platforms.global_data:
        return 'Global Data';

        case platforms.omnishield:
        return 'OmniShield';

        default:
        return 'Unknown platform';
    }
}

const paymentStatus = {
    paid: 1,
    refunded: 2,
    unpaid: 3,
    failed: 4,
    partial_paid: 5,
    cancelled: 6
}

const paymentStatusToText = code => {
    switch(code) {
        case paymentStatus.paid:
        return 'Paid';

        case paymentStatus.refunded:
        return 'Refunded';
        
        case paymentStatus.unpaid:
        return 'Unpaid';
        
        case paymentStatus.failed:
        return 'Failed';
        
        case paymentStatus.partial_paid:
        return 'Partial Payment';
        
        case paymentStatus.cancelled:
        return 'Cancelled';
        
        default:
        return 'Unknown status';
    }
}

const promotionOverview = promotion => {

    // determine if an end date was supplied
    if(promotion.end_date) {
        let end = moment(promotion.end_date);
        let start = moment(promotion.start_date);
        return `${Utils.toCurrency(promotion.amount)} from ${start.format('MMM Do, YYYY')} to ${end.format('MMM Do, YYYY')}`
    }

    // fallback to showing an overview for an open ended promotion
    let date = moment(promotion.start_date);
    return `${Utils.toCurrency(promotion.amount)} starting ${date.format('MMM Do, YYYY')}`
}

const schedules = {
    daily: 1,
    weekly: 2,
    bi_weekly: 3,
    monthly: 4,
    quarterly: 5,
    annually: 6
}

const sourceCategories = {
    user: 1,
    dealership: 2
}

const sourceCategoryToText = code => {
    switch(code) {
        case paymentStatus.user:
        return 'Personal';

        case paymentStatus.dealership:
        return 'Dealership-Wide';
        
        default:
        return 'Unknown source category';
    }
} 

const subscriptionCategories = {
    omnishield_white_label: 1,
    global_data: 2,
    bronze: 3,
    silver: 4,
    gold: 5
}

const validatePromotions = (result, promotions) => {
    return new Promise((resolve, reject) => {
        try {

            // loop through promotions and look for a conflict
            promotions.forEach(promotion => {

                // skip promotion if the promotion matches the promotion that is being validated
                // this ensures that promotions can be editing without their previous states being used for validations
                if(result.id === promotion.id) {
                    return;
                }
                    
                // check if promotion represents an open ended date range and if the new dates conflict
                if(!promotion.end_date) {
                    if(result.start_date >= promotion.start_date || (result.end_date && result.end_date >= promotion.start_date)) {
                        throw new Error(`Your promotion dates conflict with the open ended "${promotion.title}" promotion. Please start and end your promotion before ${promotion.start_date.format('MMMM Do, YYYY')} or change the dates for the "${promotion.title}" promotion.`);
                    }
                    return;
                }

                // check if new start date falls within promotion date range
                if(result.start_date >= promotion.start_date && result.start_date <= promotion.end_date) {
                    throw new Error(`Your promotion start date conflicts with the "${promotion.title}" promotion. Please start and end your promotion before ${promotion.start_date.format('MMMM Do, YYYY')} or after ${promotion.end_date.format('MMMM Do, YYYY')}.`);
                }

                // check if new start date falls within promotion date range
                if(result.end_date >= promotion.start_date && result.end_date <= promotion.end_date) {
                    throw new Error(`Your promotion end date conflicts with the "${promotion.title}" promotion. Please end your promotion before ${promotion.start_date.format('MMMM Do, YYYY')}.`);
                }

                // check if requested date range overlaps this promotion, this can happen if the promotion date range is completely inside the requsted date range
                if((promotion.start_date >= result.start_date && promotion.start_date <= result.end_datae) || (promotion.end_date >= result.start_date && promotion.end_date <= result.end_date)) {
                    throw new Error(`Your promotion overlaps with "${promotion.title}" promotion from ${promotion.start_date.format('MMMM Do, YYYY')} to ${promotion.end_date.format('MMMM Do, YYYY')}`);
                }
            });
            resolve();

        } catch(e) {
            reject(e);
        }
    });
}

export default {
    categories: {
        get: () => categories
    },
    create: props => new PaymentClass().create(props),
    get: fetchPayment,
    new: () => new PaymentClass(),
    platforms: {
        get: () => platforms,
        toText: platformToText
    },
    Method: {
        create: props => new MethodClass().create(props),
        get: fetchPaymentMethod,
        new: () => new MethodClass(),
        source_categories: {
            get: () => sourceCategories,
            toText: sourceCategoryToText
        },
    },
    Refund: {
        create: props => new RefundClass().create(props),
    },
    status: {
        get: () => paymentStatus,
        toText: paymentStatusToText
    },
    Subscription: {
        categories: {
            get: () => subscriptionCategories
        },
        create: props => new SubscriptionClass().create(props),
        get: fetchSubscription,
        new: () => new SubscriptionClass(),
        promotions: {
            toOverview: promotionOverview,
            validate: validatePromotions
        },
        schedules: {
            get: () => schedules
        }
    },
    Transaction: {
        create: props => new TransactionClass().create(props),
    },
}
