import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import PageControl from 'views/PageControl.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import Views from 'views/Main.js';

const MultipleLinkField = ({ onChange, style, utils, value }) => {

    const limit = 5
    const [entries, setEntries] = useState(value || []);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [text, setText] = useState(null);

    const onConfirmClick = async () => {
        try {

            // send request to server
            setLoading(true);
            let { description, title } = await Request.get(utils, '/resources/', {
                type: 'open_graph_details',
                url: text
            });

            // add new open graph entry to list of urls
            setLoading(false);
            let next = update(entries, { 
                $push: [{
                    description: description,
                    title: title,
                    url: text
                }] 
            });

            // update entries state and text state
            setEntries(next);
            setText(null);

            // notify subscribers of data change
            if(typeof(onChange) === 'function') {
                onChange(next);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for your url. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEntryClick = index => {

        // prepare description and title variables
        let description = entries[index].description;
        let title = entries[index].title;

        // show alert with options to change description and title
        utils.alert.show({
            title: `Editing "${title || 'Your Link'}"`,
            message: `You can make changes to the link title and description below. The title is used in the mobile app to identify this link.`,
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        padding: 12,
                        width: '100%'
                    }}>
                        <TextField
                        containerStyle={{ marginBottom: 8 }}
                        placeholder={'Title'}
                        onChange={text => title = text}
                        value={title} />

                        <TextView
                        placeholder={'Description'}
                        onChange={text => description = text}
                        value={description} />
                    </div>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onUpdateTarget(index, { description, title });
                    return;
                }
            }
        });
    }

    const onRemoveEntryClick = (index, evt) => {

        // stop click event from propagating and update entries state
        evt.stopPropagation();
        let next = update(entries, { $splice: [[ index, 1 ]] });
        setEntries(next);

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const onUpdateTarget = (index, props) => {

        // update target with new props and update state
        let next = update(entries, {
            [index]: {
                $set: {
                    ...entries[index],
                    ...props
                }
            }
        });
        setEntries(next);

        // notify subscribers of data change
        if(typeof(onChange) === 'function') {
            onChange(next);
        } 
    }

    useEffect(() => {
        setPaging(entries && {
            total: entries.length,
            current_page: parseInt(offset / limit) + 1,
            number_of_pages: entries.length > limit ? Math.ceil(entries.length / limit) : 1
        })
    }, [entries, offset]);
    
    useEffect(() => {
        setEntries(value || []);
    }, [value]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            ...style
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
            }}>
                <TextField
                icon={'globe'}
                loading={loading}
                onChange={setText}
                placeholder={'Provide a url to get started...'}
                utils={utils} 
                value={text} />
                {text && text.length > 0 && (
                    <img
                    className={'text-button'}
                    onClick={onConfirmClick}
                    src={'images/next-arrow-blue-small.png'}
                    style={{
                        height: 25,
                        marginLeft: 8,
                        minWidth: 25,
                        width: 25
                    }} />
                )}
            </div>
            {entries.length > 0 && (
                <div 
                className={'custom-scrollbars'}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8
                }}>
                    {entries.filter((_, index) => {
                        if(!paging) {
                            return true;
                        }
                        return index >= offset && index < (offset + limit);
                    }).map((entry, index, entries) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== entries.length - 1,
                                hideIcon: true,
                                key: index, 
                                onClick: onEntryClick.bind(this, index),
                                rightContent: (
                                    <img
                                    className={'text-button'}
                                    name={'Remove Entry'}
                                    onClick={onRemoveEntryClick.bind(this, index)}
                                    src={'images/red-x-icon.png'}
                                    style={{
                                        height: 20,
                                        objectFit: 'contain',
                                        marginLeft: 8,
                                        width: 20
                                    }} />
                                ),
                                subTitle: entry.description || 'No description available',
                                supportingTitle: entry.url,
                                title: entry.title || 'Untitled Page'
                            })
                        )
                    })}
                    {paging && (
                        <PageControl
                        data={paging}
                        limit={limit}
                        offset={offset}
                        onClick={setOffset} />
                    )}
                </div>
            )}
        </div>
    )
}

export default MultipleLinkField;
