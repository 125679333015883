import React, { useCallback, useEffect, useRef, useState } from 'react';

import circle from '@turf/circle';
import dagre from 'dagre';
import { getFileIconPath } from 'views/FilePickerField.js';
import { getValueContainer } from 'managers/Reports.js';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import { AddEditDealership, DealershipDetails, GoogleReviewReply } from 'managers/Dealerships.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import BoolToggle from 'views/BoolToggle.js';
import Calendar from 'views/Calendar.js';
import Card from 'classes/Card.js';
import { CardDetails } from 'managers/Cards.js';
import Checkbox from 'views/Checkbox.js';
import CommLink from 'classes/CommLink.js';
import { CommLinkDetails } from 'managers/OmniShield.js';
import Content from 'managers/Content.js';
import Dealership from 'classes/Dealership.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import FieldMapper from 'views/FieldMapper.js';
import FlowSidebar from 'views/FlowSidebar.js';
import ImagePickerField from 'views/ImagePickerField.js';
import Layer, { CollapseArrow, LayerItem } from 'structure/Layer.js';
import LottieView from 'views/Lottie.js';
import { Map } from 'views/MapElements.js';
import MultipleUserLookupField from 'views/MultipleUserLookupField.js';
import Notification from 'classes/Notification.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Product from 'classes/Product.js';
import { ProductDetails } from 'managers/Products.js';
import ReactFlow, { Background, Controls, ReactFlowProvider, addEdge, removeElements, isNode } from 'react-flow-renderer';
import { ReportsLayer } from 'managers/Reports.js';
import Request from 'files/Request.js';
import Sector from 'classes/Sector.js';
import { SectorDetails } from 'managers/Sectors.js';
import SystemEvent from 'classes/SystemEvent.js';
import { TableListHeader, TableListRow } from 'views/TableList.js';
import TextView from 'views/TextView.js';
import User from 'classes/User.js';
import UserLookupField from 'views/UserLookupField.js';
import Utils from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

export const AddEditCommunication = ({ isNewTarget, targets }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_communication' : `edit_communication_${abstract.getID()}`;

    const [cities, setCities] = useState([]);
    const [dealerships, setDealerships] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [edits, setEdits] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [regions, setRegions] = useState([]);
    const [states, setStates] = useState([]);
    const [subscriptionCategories, setSubscriptionCategories] = useState([]);

    const onConfirmNotifications = async () => {
        try {

            // verify that all required fields have been filled out
            await validateRequiredFields(getFields);

            // verify that attachemtns have all required metadata if applicable
            if(edits.attachments && edits.attachments.length > 0) {
                edits.attachments.forEach(attachment => {
                    if(!attachment.name || !attachment.description) {
                        throw new Error(`The attachment named "${attachment.name || attachment.file_name}" has not been configured. Please check that a name and a description have been added for this attachment. We show this information to users in the AFT mobile app.`)
                    }
                });
            }
            
            // run preflight for new communication if neccessary
            if(isNewTarget === true) {
                onConfirmNewNotifications();
            }

            // run preflight for updated communication if neccessary
            if(isNewTarget === false) {
                onConfirmNotificationUpdates();
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the overview for this batch of notifications. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onConfirmNewNotifications = async () => {
        try {

            // send request to retrieve preflight count and remove attachemnts for preflight request
            setLoading(true);
            let { total_count } = await Request.post(utils, '/resources/', {
                ...getFormattedPayload(),
                attachments: null,
                preflight: true,
                type: 'new_communication'
            });

            // request confirmation from the user
            setLoading(false);
            utils.alert.show({
                title: 'Send Notifications',
                message: `Are you sure that you want to submit this batch of notifications? This content will be sent to ${Utils.softNumberFormat(total_count)} ${total_count === 1 ? 'user' : 'users'}`,
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Do Not Send',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'confirm') {
                        onSendNotifications();
                        return;
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the overview for this batch of notifications. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onConfirmNotificationUpdates = async () => {
        try {

            // prevent moving forward if notification send date is in the past
            if(edits.preferences.schedule === 'later' && edits.preferences.date < moment()) {
                throw new Error('Please select a date in the future for your scheduled delivery date');
            }

            // send request to retrieve preflight count and remove attachemnts for preflight request
            setLoading(true);
            let { total_count } = await Request.post(utils, '/resources/', {
                ...getFormattedPayload(),
                attachments: null,
                preflight: true,
                type: 'update_communication'
            });

            // request confirmation from the user
            setLoading(false);
            utils.alert.show({
                title: 'Update Notifications',
                message: `Are you sure that you want to update this batch of notifications? This content will be sent to ${Utils.softNumberFormat(total_count)} ${total_count === 1 ? 'user' : 'users'}`,
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Do Not Update',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'confirm') {
                        onUpdateNotifications();
                        return;
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the overview for this batch of notifications. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSendNotifications = async () => {
        try {

            setLoading(true);
            await Utils.sleep(0.5);
            await Request.post(utils, '/resources/', {
                type: 'new_communication',
                ...getFormattedPayload()
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Your notifications have been submitted and added to the delivery queue. ${edits.preferences.date ? `We will send your notifications on ${moment(edits.preferences.date).format('MMMM Do [at] h:mma')}` : 'We will begin delivering your notifications shortly'}.`,
                onClick: () => {
                    setLayerState('close');
                    utils.content.fetch('communication');
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your notifications. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateNotifications = async () => {
        try {

            setLoading(true);
            await Request.post(utils, '/resources/', {
                id: abstract.getID(),
                type: 'update_communication',
                ...getFormattedPayload()
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Your notification content has been updated. ${edits.preferences.date ? `We will send your notifications on ${moment(edits.preferences.date).format('MMMM Do [at] h:mma')}` : 'We will begin delivering your notifications shortly'}.`,
                onClick: () => {
                    setLayerState('close');
                    utils.content.fetch('communication');
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your notifications changes. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits(prev => ({
            ...prev,
            ...props
        }));
    }

    const onReportsClick = (key, category) => {
        utils.layer.open({
            id: 'reports',
            Component: ReportsLayer.bind(this, {
                category: category,
                onChange: results => {

                    // determine if results are multi-list items and format accordingly
                    if(['dealerships', 'divisions', 'regions'].includes(key)) {
                        onUpdateTarget({
                            restrictions: update(edits.restrictions, {
                                [key]: {
                                    $set: results.sort((a,b) => {
                                        return a.name.localeCompare(b.name);
                                    }).map(result => ({
                                        id: result.id,
                                        title: result.name
                                    }))
                                }
                            })
                        });
                    }

                    // determine if results are user objects
                    if(['recruited_by_users', 'users'].includes(key)) {
                        onUpdateTarget({
                            restrictions: update(edits.restrictions, {
                                [key]: {
                                    $set: results && results.sort((a,b) => {
                                        if(a.last_name === b.last_name) {
                                            return a.first_name.localeCompare(b.first_name);
                                        }
                                        return a.last_name.localeCompare(b.last_name);
                                    })
                                }
                            })
                        });
                    }
                }
            })
        });
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onConfirmNotifications,
            text: isNewTarget ? 'Submit' : 'Update'
        }]
    }

    const getContent = () => {

        // prevent moving forward if edits have not been setup
        if(!edits) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 100,
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        return (
            <AltFieldMapper 
            fields={getFields()}
            utils={utils} />
        )
    }

    const getDeliveryMethods = () => {
        return [{
            id: 'email',
            title: 'Email'
        },{
            id: 'push_notification',
            title: 'Push Notification'
        },{
            id: 'sms',
            title: 'Text Message',
            visible: utils.user.get().level <= User.levels.get().admin
        }].filter(entry => {
            return entry.visible !== false
        });
    }

    const getFields = () => {

        // prevent moving forward if edits have not posted 
        if(!edits) {
            return [];
        }

        // determine if current user can see extended features
        let showExtendedFeatures = utils.user.get().level <= User.levels.get().admin;

        return [{
            key: 'content',
            title: 'Content',
            items: [{
                component: 'textfield',
                key: 'title',
                onChange: text => onUpdateTarget({ title: text }),
                title: edits.preferences.delivery_method === 'email' ? 'Subject' : 'Title',
                value: edits.title
            },{
                component: 'textview',
                key: 'message',
                onChange: text => onUpdateTarget({ message: text }),
                title: 'Message',
                value: edits.message
            },{
                component: 'multiple_file_picker',
                key: 'attachments',
                onChange: results => onUpdateTarget({ attachments: results }),
                props: { metadata: true },
                required: false,
                title: 'Attachments',
                value: edits.attachments
            },{
                component: 'multiple_link_field',
                key: 'urls',
                onChange: results => onUpdateTarget({ urls: results }),
                required: false,
                title: 'Links',
                value: edits.urls
            }]
        },{
            key: 'preferences',
            title: 'Preferences',
            items: [{
                component: 'list',
                items: getDeliveryMethods(),
                key: 'delivery_method',
                onChange: item => {
                    onUpdateTarget({ 
                        preferences: update(edits.preferences, {
                            delivery_method: {
                                $set: item && item.id
                            }
                        }) 
                    });
                },
                props: {
                    disablePlaceholder: true
                },
                title: 'Delivery Method',
                value: getSelectedDeliveryMethodItem(),
                visible: getDeliveryMethods().find(item => item.id === edits.preferences.delivery_method)
            },{
                component: 'list',
                items: getScheduleItems(),
                key: 'schedule',
                onChange: item => {
                    onUpdateTarget({ 
                        preferences: update(edits.preferences, {
                            schedule: {
                                $set: item && item.id
                            }
                        }) 
                    });
                },
                props: {
                    disablePlaceholder: true
                },
                required: false,
                title: 'Schedule',
                value: getSelectedScheduleItem()
            },{
                component: 'date_time_picker',
                key: 'date',
                onChange: date => {
                    onUpdateTarget({ 
                        preferences: update(edits.preferences, {
                            date: {
                                $set: date
                            }
                        }) 
                    });
                },
                required: edits.preferences.schedule === 'later' ? true : false,
                title: 'Scheduled Date and Time',
                value: edits.preferences.date,
                visible: edits.preferences.schedule === 'later'
            }]
        },{
            key: 'restrictions',
            title: 'Restrictions',
            items: [{
                component: 'multiple_list',
                items: getLevels(),
                key: 'levels',
                onChange: items => {
                    onUpdateTarget({ 
                        restrictions: update(edits.restrictions, {
                            levels: {
                                $set: items && items.map(result => result.id)
                            }
                        })
                    });
                },
                required: false,
                title: 'Account Type',
                value: getLevels(edits.restrictions.levels || [])
            },{
                component: 'multiple_list',
                items: getStringItems(cities),
                key: 'cities',
                onChange: items => {
                    onUpdateTarget({ 
                        restrictions: update(edits.restrictions, {
                            cities: {
                                $set: items && items.map(result => result.id)
                            }
                        })
                    });
                },
                required: false,
                title: 'City',
                value: getStringItems(cities, edits.restrictions.cities || []),
                visible: showExtendedFeatures
            },{
                component: 'multiple_list',
                items: dealerships,
                key: 'dealerships',
                onChange: items => {
                    onUpdateTarget({ 
                        restrictions: update(edits.restrictions, {
                            dealerships: {
                                $set: items
                            }
                        })
                    });
                },
                props: {
                    rightContent: (
                        <img
                        className={'text-button'}
                        onClick={onReportsClick.bind(this, 'dealerships', 'dealerships')}
                        src={window.theme === 'dark' ? 'images/reports-button-dark-grey-small.png' : 'images/reports-button-light-grey-small.png'}
                        style={{
                            height: 25,
                            marginLeft: 8,
                            width: 25
                        }} />
                    )
                },
                required: false,
                title: 'Dealership',
                value: edits.restrictions.dealerships,
                visible: showExtendedFeatures
            },{
                component: 'multiple_list',
                items: divisions,
                key: 'divisions',
                onChange: items => {
                    onUpdateTarget({ 
                        restrictions: update(edits.restrictions, {
                            divisions: {
                                $set: items
                            }
                        })
                    });
                },
                props: {
                    rightContent: (
                        <img
                        className={'text-button'}
                        onClick={onReportsClick.bind(this, 'divisions', 'divisions')}
                        src={window.theme === 'dark' ? 'images/reports-button-dark-grey-small.png' : 'images/reports-button-light-grey-small.png'}
                        style={{
                            height: 25,
                            marginLeft: 8,
                            width: 25
                        }} />
                    )
                },
                required: false,
                title: 'Division',
                value: edits.restrictions.divisions,
                visible: showExtendedFeatures
            },{
                component: 'multiple_list',
                items: subscriptionCategories,
                key: 'graci_subscriptions',
                onChange: items => {
                    onUpdateTarget({ 
                        restrictions: update(edits.restrictions, {
                            graci_subscriptions: {
                                $set: items && items.map(result => result.id)
                            }
                        })
                    });
                },
                required: false,
                title: 'GRACI Subscription',
                value: getGraciSubscriptions(),
                visible: showExtendedFeatures
            },{
                component: 'location_radius_picker',
                key: 'locations',
                onChange: locations => {
                    onUpdateTarget({ 
                        restrictions: update(edits.restrictions, {
                            locations: {
                                $set: locations
                            }
                        })
                    });
                },
                required: false,
                title: 'Location',
                value: edits.restrictions.locations,
                visible: showExtendedFeatures
            },{
                component: 'multiple_list',
                items: programs,
                key: 'programs',
                onChange: items => {
                    onUpdateTarget({ 
                        restrictions: update(edits.restrictions, {
                            programs: {
                                $set: items && items.map(result => result.id)
                            }
                        })
                    });
                },
                required: false,
                title: 'Program',
                value: getPrograms(),
                visible: showExtendedFeatures
            },{
                component: 'multiple_user_lookup',
                key: 'recruited_by_users',
                onChange: users => {
                    onUpdateTarget({ 
                        restrictions: update(edits.restrictions, {
                            recruited_by_users: {
                                $set: users
                            }
                        })
                    });
                },
                props: {
                    restrictToDealership: false,
                    rightContent: (
                        <img
                        className={'text-button'}
                        onClick={onReportsClick.bind(this, 'recruited_by_users', 'users')}
                        src={window.theme === 'dark' ? 'images/reports-button-dark-grey-small.png' : 'images/reports-button-light-grey-small.png'}
                        style={{
                            height: 25,
                            marginLeft: 8,
                            width: 25
                        }} />
                    )
                },
                required: false,
                title: 'Recruited By',
                value: edits.restrictions.recruited_by_users,
                visible: showExtendedFeatures
            },{
                component: 'dual_date_picker',
                key: 'recruit_date',
                props: {
                    canRemoveDates: true,
                    selectedEndDate: edits.restrictions.recruit_date && edits.restrictions.recruit_date.end_date,
                    selectedStartDate: edits.restrictions.recruit_date && edits.restrictions.recruit_date.start_date,
                    onEndDateChange: date => {
                        onUpdateTarget({ 
                            restrictions: update(edits.restrictions, {
                                recruit_date: {
                                    $apply: props => ({
                                        ...props,
                                        end_date: date
                                    })
                                }
                            })
                        });
                    },
                    onStartDateChange: date => {
                        onUpdateTarget({ 
                            restrictions: update(edits.restrictions, {
                                recruit_date: {
                                    $apply: props => ({
                                        ...props,
                                        start_date: date
                                    })
                                }
                            })
                        });
                    }
                },
                required: false,
                title: 'Recruit Date',
                visible: showExtendedFeatures
            },{
                component: 'multiple_list',
                items: regions,
                key: 'regions',
                onChange: items => {
                    onUpdateTarget({ 
                        restrictions: update(edits.restrictions, {
                            regions: {
                                $set: items
                            }
                        })
                    });
                },
                props: {
                    rightContent: (
                        <img
                        className={'text-button'}
                        onClick={onReportsClick.bind(this, 'regions', 'regions')}
                        src={window.theme === 'dark' ? 'images/reports-button-dark-grey-small.png' : 'images/reports-button-light-grey-small.png'}
                        style={{
                            height: 25,
                            marginLeft: 8,
                            width: 25
                        }} />
                    )
                },
                required: false,
                title: 'Region',
                value: edits.restrictions.regions,
                visible: showExtendedFeatures
            },{
                component: 'multiple_list',
                items: getStringItems(states),
                key: 'states',
                onChange: items => {
                    onUpdateTarget({ 
                        restrictions: update(edits.restrictions, {
                            states: {
                                $set: items && items.map(result => result.id)
                            }
                        })
                    });
                },
                required: false,
                title: 'State',
                value: getStringItems(states, edits.restrictions.states || []),
                visible: showExtendedFeatures
            },{
                component: 'multiple_timezone_picker',
                key: 'timezone',
                onChange: zones => {
                    onUpdateTarget({ 
                        restrictions: update(edits.restrictions, {
                            timezones: {
                                $set: zones
                            }
                        })
                    });
                },
                required: false,
                title: 'Timezone',
                value: edits.restrictions.timezones,
                visible: showExtendedFeatures
            },{
                component: 'multiple_user_lookup',
                key: 'users',
                onChange: users => {
                    onUpdateTarget({ 
                        restrictions: update(edits.restrictions, {
                            users: {
                                $set: users
                            }
                        })
                    });
                },
                props: {
                    restrictToDealership: utils.user.get().level >= User.levels.get().dealer,
                    restrictToGenealogy: utils.user.get().level >= User.levels.get().admin, 
                    rightContent: showExtendedFeatures && (
                        <img
                        className={'text-button'}
                        onClick={onReportsClick.bind(this, 'users', 'users')}
                        src={window.theme === 'dark' ? 'images/reports-button-dark-grey-small.png' : 'images/reports-button-light-grey-small.png'}
                        style={{
                            height: 25,
                            marginLeft: 8,
                            width: 25
                        }} />
                    )
                },
                required: false,
                title: 'Users',
                value: edits.restrictions.users
            }]
        }];
    }

    const getFormattedPayload = () => {
        
        let { date } = edits.preferences;
        let { dealerships, divisions, locations, recruited_by_users, recruit_date, regions, users } = edits.restrictions;
        return {
            ...edits,
            preferences: {
                ...edits.preferences,
                ...date && {
                    date: moment(date).utc().unix()
                }
            },
            restrictions: {
                ...edits.restrictions,
                ...dealerships && {
                    dealerships: dealerships.map(dealership => dealership.id)
                },
                ...divisions && {
                    divisions: divisions.map(division => division.id)
                },
                ...locations && locations.length > 0 && {
                    locations: locations.map(location => ({
                        address: location.address,
                        lat: location.location.latitude,
                        long: location.location.longitude,
                        name: location.name,
                        radius: location.radius
                    }))
                },
                ...recruited_by_users && recruited_by_users.length > 0 && {
                    recruited_by_users: recruited_by_users.map(user => user.user_id)
                },
                ...recruit_date && {
                    recruit_date: {
                        end_date: recruit_date.end_date && moment(recruit_date.end_date).utc().unix(),
                        start_date: recruit_date.start_date && moment(recruit_date.start_date).utc().unix()
                    }
                },
                ...regions && {
                    regions: regions.map(region => region.id)
                },
                ...users && users.length > 0 && {
                    users: users.map(user => user.user_id)
                }
            }
        }
    }

    const getGraciSubscriptions = () => {
        return edits.restrictions && edits.restrictions.graci_subscriptions && subscriptionCategories.filter(subscription => {
            return edits.restrictions.graci_subscriptions.includes(subscription.id);
        });
    }

    const getLevels = values => {
        let levels = User.levels.get();
        return Object.values(levels).filter(level => {
            if(values) {
                return values.includes(level);
            }
            if([levels.system_admin, levels.dreamcatcher].includes(level) === true) {
                return false;
            }
            if(utils.user.get().level > User.levels.get().admin) {
                return level > utils.user.get().level && level !== levels.safety_associate;
            }
            return  true;
            
        }).map(code => ({
            id: code,
            title: User.levels.toText(code)
        })).sort((a,b) => {
            return a.title.localeCompare(b.title);
        });
    }

    const getPrograms = () => {
        return edits.restrictions && edits.restrictions.programs && programs.filter(program => {
            return edits.restrictions.programs.includes(program.id);
        });
    }

    const getScheduleItems = () => {
        return [{
            id: 'now',
            title: 'Send Now'
        },{
            id: 'later',
            title: 'Send Later'
        }]
    }

    const getSelectedDeliveryMethodItem = () => {
        let selected = getDeliveryMethods().find(item => item.id === edits.preferences.delivery_method);
        return selected && selected.title;
    }

    const getSelectedScheduleItem = () => {
        let selected = getScheduleItems().find(item => item.id === edits.preferences.schedule);
        return selected && selected.title;
    }

    const getStringItems = (items, values) => {
        return items.filter(string => {
            if(values) {
                return values.includes(string);
            }
            return string !== 'null';
        }).sort((a,b) => {
            return a.localeCompare(b);
        }).map(string => ({
            id: string,
            title: string
        }))
    }

    const fetchPreflightContent = async () => {
        try {   

            // setup editing for target
            setEdits({ 
                preferences: { 
                    delivery_method: 'push_notification',
                    schedule: 'now'
                },
                restrictions: {},
                target_dealership_id: utils.user.get().level > User.levels.get().admin && utils.dealership.get().id,
                ...isNewTarget === false && {
                    ...abstract.object,
                    attachments: abstract.object.metadata.attachments,
                    preferences: {
                        ...abstract.object.metadata.preferences,
                        date: abstract.object.metadata.preferences.date && moment.unix(abstract.object.metadata.preferences.date).local()
                    },
                    restrictions: {
                        ...abstract.object.metadata.restrictions,
                        ...targets
                    },
                    urls: abstract.object.metadata.urls
                }
            });

            // no additional logic is required if current user is not an administrator
            if(utils.user.get().level > User.levels.get().admin) {
                return;
            }

            // send request to server
            setLoading('init');
            let { dealerships, divisions, regions, subscription_categories } = await Request.get(utils, '/resources/', {
                type: 'notification_targets'
            });

            // end loading
            setLoading(false);

            // format cities for list component
            setCities(dealerships.reduce((array, dealership) => {
                if(dealership.city && array.includes(dealership.city) === false) {
                    array.push(dealership.city);
                }
                return array;
            }, []));

            // format regions for list component
            setDivisions(divisions.map(division => ({
                id: division.id,
                title: division.name
            })));

            // format dealerships for list component
            setDealerships(dealerships.map(dealership => ({
                id: dealership.id,
                title: dealership.name
            })));

            // format programs for list component
            setPrograms([{
                id: 'one_to_one',
                title: '1:1'
            },{
                id: 'hybrid',
                title: 'Hybrid'
            }]);

            // format regions for list component
            setRegions(regions.map(region => ({
                id: region.id,
                title: region.name
            })));

            // format states for list component
            setStates(dealerships.reduce((array, dealership) => {
                if(dealership.state && array.includes(dealership.state) === false) {
                    array.push(dealership.state);
                }
                return array;
            }, []));

            // format subscription categories for multiple list component
            setSubscriptionCategories(subscription_categories.map(subscription => ({
                id: subscription.category,
                title: subscription.title
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the editing process. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        fetchPreflightContent();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'New Communication'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            {getContent()}
        </Layer>
    )
}

export const AddEditUser = ({ isNewTarget, level, onUpdateUser }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_user' : `edit_user_${abstract.getID()}`;
    const [genealogy, setGenealogy] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [levels, setLevels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [recruiting, setRecruiting] = useState(null);
    const [user, setUser] = useState(null);

    const canEditAccountType = () => {

        // newly created account must have the ability to choose an account type
        if(isNewTarget && level !== User.levels.get().exigent_admin) {
            return true;
        }

        // administrators can change account type for any user
        if(utils.user.get().level <= User.levels.get().admin) {
            return true;
        }

        // dealers and directors can change account types for anyone in their dealership
        if([
            User.levels.get().region_director,
            User.levels.get().division_director,
            User.levels.get().area_director,
            User.levels.get().vested_director,
            User.levels.get().dealer
        ].includes(utils.user.get().level)) {
            return user.dealership && user.dealership.id === utils.user.get().dealership_id;
        }

        // booking coodinators, exigent administrators, marketing directors, and safety advisors can not change their account type
        return false;
    }

    const canEditRecruiting = () => {
        return user && user.level === User.levels.get().exigent_admin ? false : true;
    }

    const canShowDealership = () => {
        if(user && user.level === User.levels.get().exigent_admin) {
            return false;
        }
        return (isNewTarget && utils.user.get().level <= User.levels.get().admin) || !abstract.object.dealership;
    }

    const canEditGenealogy = () => {
        if(user && user.level === User.levels.get().exigent_admin) {
            return false;
        }
        if(isNewTarget === true || utils.user.get().level <= User.levels.get().admin) {
            return true;
        }
        if(typeof(onUpdateUser) === 'function') {
            return true;
        }
        return false;
    }

    const onDoneClick = async () => {
        try {

            // check that all required fields have been filled out
            await validateRequiredFields(getFields);

            // submit to server if user if not a vested director
            /*
            if(user.level !== User.levels.get().vested_director) {
                onSubmitUser();
                return;
            }

            // request confirmation to create target
            if(isNewTarget) {
                utils.alert.show({
                    title: 'New Vested Director',
                    message: `Vested Directors are treated as their own entity in the Global Health and Safety ecosystem. This means we'll create a dealership for ${user.first_name} ${user.last_name} to track their activity and sales. The previous current dealership for ${user.first_name} ${user.last_name} will no longer receieve any credit for future sales made by the Vested Director.`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Okay',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            onSubmitUser();
                            return;
                        }
                    }
                })
                return;
            }

            // request confirmation to update target if applicable
            if(abstract.object.level !== user.level) {
                utils.alert.show({
                    title: 'Change to Vested Director',
                    message: `Vested Directors are treated as their own entity in the Global Health and Safety ecosystem. This means we'll create a dealership for ${user.first_name} ${user.last_name} to track their activity and sales. The previous current dealership for ${user.first_name} ${user.last_name} will no longer receieve any credit for future sales made by the Vested Director.`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Okay',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            onSubmitUser();
                            return;
                        }
                    }
                })
                return;
            }
            */

            // fallback to submitting user
            onSubmitUser();

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onFormatAdditionalProps = () => {
        return {
            should_set_verification: typeof(onUpdateUser) === 'function',
            genealogy: genealogy && {
                region: genealogy.region ? genealogy.region.id : null,
                division: genealogy.division ? genealogy.division.id : null,
                area: genealogy.area ? genealogy.area.id : null
            },
            recruitment: recruiting && {
                notes: recruiting.notes,
                recruited_by: recruiting.recruited_by ? recruiting.recruited_by.user_id : null
            }
        }
    }

    const onRequestVerificationCode = async () => {
        return new Promise(async (resolve, reject) => {
            utils.alert.show({
                title: 'Verification Code',
                message: `We have sent a verification code to ${user.phone_number}. Please enter the verification code below to continue setting up this account.`,
                textFields: [{
                    key: 'code',
                    placeholder: '000000'
                }],
                buttons: [{
                    key: 'confirm',
                    title: 'Done',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onCancel: () => {
                    let error = new Error('The phone number for this account must be verified before moving on.');
                    setTimeout(reject.bind(this, error), 1000);
                },
                onClick: async ({ code }) => {
                    try {
                        await Utils.sleep(1);
                        if(!code) {
                            let error = new Error('The phone number for this account must be verified before moving on.');
                            reject(error);
                            return;
                        }
                        await Request.post(utils, '/users/', {
                            type: 'verify_new_user_code',
                            code: code,
                            dealership_id: user.dealership.id,
                            phone_number: user.phone_number
                        });
                        resolve();
                    } catch(e) {
                        reject(e);
                    }
                }
            })
        });
    }

    const onSubmitUser = async () => {
        try {

            setLoading('done');
            await Utils.sleep(0.25);

            // create target
            if(isNewTarget) {

                // check if the user already has an account in the system for the selected dealership/phone number combination
                // require a text message verification if current user is not an admin
                if(utils.user.get().level > User.levels.get().exigent_admin) {
                    await onValidatePhoneNumber();
                }

                await abstract.object.submit(utils, onFormatAdditionalProps());
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `${user.full_name}'s account has been created`,
                    onClick: () => {
                        setLayerState('close');
                        if(user.level === User.levels.get().vested_director) {
                            utils.content.fetch('dealership');
                        }
                    }
                });
                return;
            }

            // update target
            await abstract.object.update(utils, onFormatAdditionalProps());
            setLoading(false);

            // show user verification confirmation if applicable
            if(typeof(onUpdateUser) === 'function') {
                utils.alert.show({
                    title: 'All Done!',
                    message: `Thank you for taking the time to verify the information on file with your account.`,
                    onClick: () => {
                        onUpdateUser();
                        setLayerState('close');
                    }
                });
                return;
            }

            // show default update alert
            utils.alert.show({
                title: 'All Done!',
                message: `${user.full_name}'s account has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateTarget = async props => {
        try {
            let edits = await abstract.object.set(props)
            setUser(edits);
        } catch(e) {
            console.log(e.message);
        }
    }

    const onValidatePhoneNumber = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let { verification_id } = await Request.post(utils, '/users/', {
                    type: 'validate_new_user_account',
                    dealership_id: user.dealership.id,
                    phone_number: user.phone_number,
                    username: user.username
                });
                if(!verification_id) {
                    resolve();
                    return;
                }
                await Utils.sleep(1);
                await onRequestVerificationCode();
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getFields = () => {

        if(!user) {
            return [];
        }

        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'first_name',
                title: 'First Name',
                description: `What is the first name for this account's primary user?`,
                value: user.first_name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ first_name: text })
            },{
                key: 'last_name',
                title: 'Last Name',
                description: `What is the last name for this account's primary user?`,
                value: user.last_name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ last_name: text })
            }]
        },{
            key: 'account',
            title: 'Account',
            items: [{
                key: 'username',
                title: 'Username',
                description: `The username for this account will be used whenever logging into the Global Data mobile app or website.`,
                value: user.username,
                component: 'textfield',
                onChange: text => onUpdateTarget({ username: text }),
            },{
                key: 'password',
                title: 'Password',
                description: `The password for this account will be used whenever logging into the Global Data mobile app or website.`,
                required: isNewTarget,
                value: user.password,
                component: 'textfield',
                visible: isNewTarget,
                onChange: text => onUpdateTarget({ password: text }),
                props: {
                    isSecure: true
                }
            },{
                key: 'level',
                title: 'Account Type',
                description: 'What type of account do you need for this user?',
                component: 'list',
                visible: canEditAccountType(),
                value: user.level ? User.levels.toText(user.level) : null,
                onChange: level => onUpdateTarget({ level: level ? level.code : null }),
                items: levels
            },{
                key: 'dealership',
                required: canShowDealership(),
                visible: canShowDealership(),
                title: 'Dealership',
                description: 'What dealership does this account belong to?',
                value: user.dealership,
                component: 'dealership_lookup',
                onChange: dealership => onUpdateTarget({ dealership: dealership })
            }]
        },{
            key: 'contact',
            title: 'Contact Information',
            items: [{
                key: 'email_address',
                title: 'Email Address',
                description: `What is the email address for this account's primary user?`,
                value: user.email_address,
                component: 'textfield',
                onChange: text => onUpdateTarget({ email_address: text })
            },{
                key: 'phone_number',
                title: 'Phone Number',
                description: `What is the phone number for this account's primary user?`,
                value: user.phone_number,
                component: 'textfield',
                onChange: text => onUpdateTarget({ phone_number: text }),
                props: {
                    format: 'phone_number'
                }
            }]
        },{
            key: 'location',
            title: 'Location',
            items: [{
                key: 'address',
                title: 'Physical Address',
                description: `What is the physical address for this account's primary user?`,
                component: 'address_lookup',
                onChange: onUpdateTarget,
                value: user.address && {
                    address: user.address,
                    location: user.location
                }
            },{
                key: 'timezone',
                title: 'Timezone',
                description: 'We use the timezone to format dates in the timezone where this Dealership is located',
                component: 'timezone_picker',
                value: user.timezone,
                onChange: zone => onUpdateTarget({ timezone: zone })
            }]
        },{
            key: 'genealogy',
            title: 'Genealogy',
            visible: canEditGenealogy(),
            items: [{
                key: 'region',
                required: false,
                title: 'Region',
                description: `What region does this user belong to?`,
                value: genealogy ? genealogy.region : null,
                component: 'sector_lookup',
                props: {
                    type: Sector.types.region
                },
                onChange: sector => {
                    setGenealogy(props => update(props || {}, {
                        region: {
                            $set: sector
                        }
                    }))
                }
            },{
                key: 'division',
                required: false,
                title: 'Division',
                description: `What division does this user belong to?`,
                value: genealogy ? genealogy.division : null,
                component: 'sector_lookup',
                props: {
                    type: Sector.types.division
                },
                onChange: sector => {
                    setGenealogy(props => update(props || {}, {
                        division: {
                            $set: sector
                        }
                    }))
                }
            },{
                key: 'one_to_one',
                required: false,
                title: 'Program Status',
                description: `Would this user be part of the 1:1 or Hybrid program?`,
                value: user.one_to_one === null ? true : user.one_to_one,
                component: 'bool_toggle',
                props: {
                    enabled: '1:1',
                    disabled: 'Hybrid'
                },
                onChange: val => onUpdateTarget({ one_to_one: val })
            }]
        },{
            key: 'recruting',
            title: 'Recruting',
            visible: canEditRecruiting(),
            items: [{
                key: 'recruited_by',
                required: canEditRecruiting(),
                title: 'Recruited By',
                description: `Who recruited this user? This information is used for reporting purposes`,
                value: recruiting ? recruiting.recruited_by : null,
                component: 'user_lookup',
                props: {
                    restrictToDealership: false
                },
                onChange: user => {
                    setRecruiting(props => {
                        return update(props || {}, {
                            recruited_by: {
                                $set: user
                            }
                        });
                    });
                }
            },{
                key: 'recruting_notes',
                required: false,
                title: 'Notes',
                description: `Do you have anything to say about the recruiting history for this account?`,
                value: recruiting ? recruiting.notes : null,
                component: 'textview',
                onChange: text => {
                    setRecruiting(props => {
                        return update(props || {}, {
                            notes: {
                                $set: text
                            }
                        });
                    });
                }
            }]
        }]

        return items;
    }

    const getTitle = () => {
        if(typeof(onUpdateUser) === 'function') {
            return 'Verify Account Details';
        }
        return isNewTarget ? 'New User' : `Editing ${abstract.getTitle()}`
    }

    const setupTarget = async () => {
        try {

            // fetch genealogy and recruiting details if applicable
            if(isNewTarget === false) {
                let { genealogy, recruiting } = await Request.get(utils, '/users/', {
                    type: 'ext_details',
                    user_id: abstract.getID()
                });

                setGenealogy(genealogy);
                setRecruiting(recruiting);
            }

            // setup levels to only allow values less than or equal to the current users level
            let codes = User.levels.get();
            let levels = Object.values(codes).map(code => ({
                code: code,
                title: User.levels.toText(code)
            })).filter(entry => {

                // filter out system administrators
                if(entry.code === User.levels.get().system_admin) {
                    return false;
                }

                // allow all account types if current user is an administrator
                if(utils.user.get().level <= User.levels.get().admin) {
                    return true;
                }

                // allow dealer account type and below by default
                return entry.code >= User.levels.get().dealer;

            }).sort((a,b) => {
                return a.title.localeCompare(b.title);
            });
            setLevels(levels);

            // setup target for editing
            let edits = await abstract.object.open();
            if(isNewTarget) {
                edits = await abstract.object.set({ dealership: utils.dealership.get(), level });
            }
            setUser(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this account. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            });
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={getTitle()}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: isNewTarget ? 'Done' : 'Save Changes',
            color: 'primary',
            loading: loading === 'done',
            onClick: onDoneClick
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditUserGroup = ({ category, isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_user_group' : `edit_user_group_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [group, setGroup] = useState(null);
    const [products, setProducts] = useState([]);

    const onDoneClick = async () => {

        // Valdiate fields
        let items = getFields().reduce((array, field) => {
            return array.concat(field.items);
        }, []);
        let required = items.find(item => {
            if(item.required === false) {
                return false;
            }
            return item.value === null || item.value === undefined;
        });
        if(required) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please fill out the "${required.title}" before moving on`
            });
            return;
        }

        try {
            if(isNewTarget) {
                setLoading('done');
                await Utils.sleep(1);
                await abstract.object.submit(utils);

                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `The "${abstract.object.title}" User Group has been created`,
                    onClick: () => setLayerState('close')
                });
                return;
            }

            setLoading('done');
            await Utils.sleep(1);
            await abstract.object.update(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.title}" User Group has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this user group. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onLevelChange = async (level, selected) => {
        try {
            if(!group.levels) {
                onUpdateTarget({ levels: [ level ]});
                return;
            }
            if(selected === false) {
                onUpdateTarget({
                    levels: group.levels.filter(prevLevel => {
                        return prevLevel !== level;
                    })
                });
                return;
            }
            onUpdateTarget({
                levels: update(group.levels, {
                    $push: [ level ]
                })
            });

        } catch(e) {
            console.log(e.message);
        }
    }

    const onUserClick = user => {
        utils.layer.open({
            id: `user_details_${user.user_id}`,
            abstract: Abstract.create({
                type: 'user',
                object: user
            }),
            Component: UserDetails
        })
    }

    const onUpdateTarget = async props => {
        try {
            let edits = await abstract.object.set(props);
            setGroup(edits);
        } catch(e) {
            console.log(e.message);
        }
    }

    const onValueChange = async (key, value) => {
        try {
            if([ 'description', 'group_type', 'levels', 'title', 'users' ].includes(key)) {
                let edits = await abstract.object.set({ [key]: value })
                setGroup(edits);
                return;
            }
            let edits = await abstract.object.set({
                props: {
                    ...group.props,
                    [key]: value
                }
            })
            setGroup(edits);
        } catch(e) {
            console.log(e.message);
        }
    }

    const onVisibilityChange = (item) => {
        onValueChange(item.key, item.selected === false ? true : false);
    }

    const getFields = () => {

        if(!group) {
            return [];
        }

        return [{
            key: 'details',
            title: 'About this Group',
            items: [{
                key: 'title',
                title: 'Title',
                description: 'The title for a user group will be used to identify this group across your Dealership.',
                component: 'textfield',
                value: group.title,
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'dealership',
                visible: isNewTarget && utils.user.get().level <= User.levels.get().admin,
                title: 'Dealership',
                description: `The Dealership for a user group will dictate which users are effected by this group's restrictions`,
                value: group.dealership,
                component: 'dealership_lookup',
                onChange: dealership => onUpdateTarget({ dealership: dealership })
            },{
                key: 'group_type',
                required: true,
                title: 'Group Type',
                description: 'Do you want to select individual users for this group or do you want to choose all users with a matching account type?',
                value: group.group_type ? group.group_type.text : null,
                onChange: item => onUpdateTarget({ group_type: item }),
                component: 'list',
                items: [{
                    code: 1,
                    text: 'Account Type'
                },{
                    code: 2,
                    text: 'Individual Users'
                }]
            },{
                key: 'description',
                title: 'Description',
                description: 'The description for a user group should be used to explain the purpose of this user group.',
                component: 'textview',
                value: group.description,
                onChange: text => onUpdateTarget({ description: text })
            }]
        }]
    }

    const getLevels = () => {

        if(!group || !group.group_type || group.group_type.code !== User.Group.types.levels) {
            return null;
        }
        return (
            <LayerItem
            title={'Account Types'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%',
                    padding: 10
                }}>
                    {[
                        User.levels.get().booking_coordinator,
                        User.levels.get().marketing_director,
                        User.levels.get().safety_advisor,
                        User.levels.get().safety_associate
                    ].map((level, index, levels) => (
                        <div
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: index === levels.length - 1 ? 0 : 12,
                            width: '100%',
                            textAlign: 'left'
                        }}>
                            <Checkbox
                            checked={group.levels.includes(level)}
                            onChange={onLevelChange.bind(this, level)} />
                            <span style={{
                                ...Appearance.textStyles.title(),
                                marginLeft: 8
                            }}>{User.levels.toText(level)}</span>
                        </div>
                    ))}
                </div>
            </LayerItem>
        )
    }

    const getPropFields = () => {

        if(!group) {
            return [];
        }

        let items = [];
        if(category === User.Group.categories.protections) {
            items = [{
                key: 'customer',
                title: 'Customer',
                items: [{
                    key: 'first_name',
                    title: 'First Name'
                },{
                    key: 'last_name',
                    title: 'Last Name'
                },{
                    key: 'spouse',
                    title: 'Spouse'
                },{
                    key: 'phone_number',
                    title: 'Phone Number'
                },{
                    key: 'email_address',
                    title: 'Email Address'
                }]
            },{
                key: 'location',
                title: 'Location',
                items: [{
                    key: 'location',
                    title: 'Location'
                },{
                    key: 'address',
                    title: 'Address'
                },{
                    key: 'maps',
                    title: 'Directions'
                }]
            },{
                key: 'units',
                title: 'Units Sold',
                items: products.map(product => ({
                    key: product.key,
                    title: product.name
                }))
            },{
                key: 'details',
                title: 'Details',
                items: [{
                    key: 'id',
                    title: 'ID'
                },{
                    key: 'created',
                    title: 'Submitted'
                },{
                    key: 'start_date',
                    title: 'Protection Date'
                },{
                    key: 'dealership',
                    title: 'Dealership'
                },{
                    key: 'sold_by',
                    title: 'Sold By'
                },{
                    key: 'total_units',
                    title: 'Total Units Sold'
                },{
                    key: 'full_protection',
                    title: 'Full Protection'
                },{
                    key: 'tags',
                    title: 'Tags'
                },{
                    key: 'comments',
                    title: 'Comments'
                }]
            }];
        }

        if(category === User.Group.categories.users) {
            items = [{
                key: 'details',
                title: 'Account Details',
                collapsed: false,
                items: [{
                    key: 'user_id',
                    title: 'User ID'
                },{
                    key: 'first_name',
                    title: 'First Name'
                },{
                    key: 'last_name',
                    title: 'Last Name'
                },{
                    key: 'level',
                    title: 'Account Type'
                },{
                    key: 'dealership',
                    title: 'Dealership'
                }]
            },{
                key: 'contact',
                title: 'Contact Information',
                collapsed: false,
                items: [{
                    key: 'location',
                    title: 'Location'
                },{
                    key: 'email_address',
                    title: 'Email Address'
                },{
                    key: 'phone_number',
                    title: 'Phone Number'
                },{
                    key: 'address',
                    title: 'Physical Address'
                }]
            },{
                key: 'ext_details',
                title: 'Extended Details',
                collapsed: false,
                items: [{
                    key: 'last_login',
                    title: 'Last Login'
                },{
                    key: 'active',
                    title: 'Active'
                }]
            }];
        }

        return items.map(item => {
            item.items = item.items.map(innerItem => {
                return {
                    ...innerItem,
                    component: 'auto_toggle',
                    selected: group.props[innerItem.key] !== false,
                    value: (
                        <span style={{
                            ...Appearance.textStyles.value(),
                            color: group.props[innerItem.key] === false ? Appearance.colors.red : Appearance.colors.green
                        }}>{group.props[innerItem.key] === false ? 'Hidden' : 'Visible'}</span>
                    ),
                    rightContent: (
                        <img
                        src={group.props[innerItem.key] === false ? `images/non-visible-eye-${window.theme === 'dark' ? 'white' : 'grey'}.png` : `images/visible-eye-${window.theme === 'dark' ? 'white' : 'grey'}.png`}
                        style={{
                            width: 20,
                            height: 20,
                            objectFit: 'contain',
                            marginLeft: 8
                        }} />
                    )
                }
            })
            return item;
        });
    }

    const getUsers = () => {

        if(!group || !group.group_type || group.group_type.code !== User.Group.types.user_ids) {
            return null;
        }
        return (
            <LayerItem
            title={'Users'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    padding: 12
                }}>
                    <MultipleUserLookupField
                    utils={utils}
                    users={group.users}
                    onChange={users => onUpdateTarget({ users: users })} />
                </div>
            </LayerItem>
        )
    }

    const setupTarget = async () => {
        try {
            let edits = await abstract.object.open();
            if(isNewTarget === true) {
                edits = await abstract.object.set({
                    category: category,
                    dealership: utils.dealership.get()
                });
            }
            if(isNewTarget === false) {
                let { users } = await Request.get(utils, '/users/', {
                    type: 'user_group_members',
                    id: abstract.getID(),
                    paging: false
                });
                edits = await abstract.object.set({ users: users.map(user => User.create(user)) });
            }
            setGroup(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this user group. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New User Group' : `Editing ${abstract.getTitle()}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: isNewTarget ? 'Done' : 'Save Changes',
            loading: loading === 'done',
            color: 'primary',
            onClick: onDoneClick
        }]}>
            <AltFieldMapper
            utils={true}
            fields={getFields()} />

            {getUsers()}
            {getLevels()}

            <FieldMapper
            editable={utils}
            fields={getPropFields()}
            onEditClick={onVisibilityChange} />
        </Layer>
    )
}

// DEPRECIATED
export const AllRecruitsResultsList = ({ layerID, onRenderUser, owner, title, users }, { index, options, utils }) => {

    const [downlineProps, setDownlineProps] = useState({});
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [user, setUser] = useState(null);

    const onOwnerClick = () => {
        utils.layer.open({
            id: `user_details_${user.user_id}`,
            abstract: Abstract.create({
                type: 'user',
                object: user
            }),
            Component: UserDetails
        })
    }

    const onUserClick = async userID => {
        try {
            let user = await User.get(utils, userID);
            utils.layer.open({
                id: `user_details_${userID}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: user
                }),
                Component: UserDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getDownlineRecursive = (users, index = 0) => {
        return users.map((entry, i) => {
            return (
                <div key={entry.user_id}>
                    <div
                    className={`view-entry ${window.theme}`}
                    onClick={onUserClick.bind(this, entry.user_id)}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        borderBottom: i > 0 ? `1px solid ${Appearance.colors.divider()}` : null,
                        paddingRight: 12,
                        position: 'relative'
                    }}>
                        <div style={{
                            flexGrow: 1,
                            marginLeft: index * 32
                        }}>
                            {onRenderUser(entry, i, users)}
                        </div>
                        {getRightAccessory(entry)}
                    </div>
                    {entry.recruits && entry.recruits.length > 0 && downlineProps[entry.user_id] === false && (
                        getDownlineRecursive(entry.recruits, index + 1)
                    )}
                </div>
            )
        })
    }

    const getRightAccessory = entry => {
        if(entry.recruits && entry.recruits.length > 0) {
            return (
                <CollapseArrow
                collapsed={downlineProps[entry.user_id] === false ? false : true}
                onClick={(val, evt) => {
                    evt.stopPropagation();
                    setDownlineProps(props => update(props, {
                        [entry.user_id]: {
                            $set: val
                        }
                    }))
                }} />
            )
        }
        return (
            <div style={{
                width: 15,
                height: 15
            }} />
        );
    }

    const fetchOwnerDetails = async () => {
        try {
            let user = await User.get(utils, owner.user_id);
            setUser(user);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the account information for ${owner.first_name} ${owner.last_name}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchOwnerDetails();
    }, []);

    return (
        <Layer
        id={layerID}
        title={title}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}>
            {user && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: 12
                }}>
                    {Views.entry({
                        title: user.full_name,
                        subTitle: user.email_address,
                        icon: {
                            path: user.avatar
                        },
                        bottomBorder: false,
                        onClick: onOwnerClick.bind(this, user)
                    })}
                </div>
            )}
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getDownlineRecursive(users)}
            </div>
        </Layer>
    )
}

export const Communications = ({ index, options, utils }) => {

    const panelID = 'communications';
    const limit = 10;
    const offset = useRef(null);
    const sorting = useRef({ sort_key: 'delivery_date', sort_type: Content.sorting.type.descending })

    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onEntryClick = entry => {
        utils.layer.open({
            abstract: Abstract.create({
                object: entry,
                type: 'communication'
            }),
            Component: CommunicationDetails,
            id: `communication_details_${entry.id}`
        });
    }

    const onNewCommunication = () => {
        utils.layer.open({
            Component: AddEditCommunication.bind(this, { isNewTarget: true }),
            id: 'new_communication'
        });
    }

    const onUserTaskProgressChange = data => {
        try {
            setEntries(entries => {
                return entries.map(entry => {
                    if(entry.task && entry.task.id === data.id) {
                        entry.task = data;
                    }
                    return entry;
                })
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const getButtons = () => {
        return [{
            onClick: onNewCommunication,
            style: 'primary',
            title: 'New Communication'
        }];
    }

    const getContent = () => {
        if(entries.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No communication entries were found',
                    title: 'Nothing to see here'
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {entries.map((user, index) => {
                        return getFields(user, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getDeliveryMethodText = communication => {
        if(!communication) {
            return null;
        }
        switch(communication.metadata.preferences.delivery_method) {
            case 'email':
            return 'Email';

            case 'push_notification':
            return 'Push Notification';

            case 'sms':
            return 'SMS';
        }
    }

    const getFields = (communication, index) => {

        let target = communication || {};
        let fields = [{
            key: 'delivery_date',
            title: 'Delivery Date',
            value: target.delivery_date && Utils.formatDate(target.delivery_date)
        },{
            key: 'title',
            sortable: false,
            title: 'Title',
            value: target.title
        },{
            key: 'message',
            sortable: false,
            title: 'Message',
            value: target.message
        },{
            key: 'schedule',
            sortable: false,
            title: 'Schedule',
            value: communication && communication.metadata.preferences.schedule === 'now' ? 'Send Immediately' : 'Send at a later date'
        },{
            key: 'delivery_method',
            sortable: false,
            title: 'Delivery Method',
            value: getDeliveryMethodText(communication)
        }];

        // create table headers with custom sorting options
        if(!communication) {
            return (
                <TableListHeader
                fields={fields}
                onChange={props => {
                    sorting.current = props;
                    fetchEntries();
                }} 
                value={sorting.current}/>
            )
        }

        // loop through result rows
        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === entries.length - 1}
            onClick={onEntryClick.bind(this, communication)} />
        )
    }

    const connectToSockets = async () => {
        try {
            let user = utils.user.get();
            await utils.sockets.on('aft', 'tasks', `on_user_task_progress_change_${user.user_id}`, onUserTaskProgressChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            let user = utils.user.get();
            await utils.sockets.off('aft', 'tasks', `on_user_task_progress_change_${user.user_id}`, onUserTaskProgressChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchEntries = async () => {
        try {
            setLoading(true);
            let { entries, paging } = await Request.get(utils, '/resources/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                target_dealership_id: utils.user.get().level > User.levels.get().admin && utils.dealership.get().id,
                type: 'communications',
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setEntries(entries.map(entry => ({
                ...entry,
                date: moment.utc(entry.date).local(),
                delivery_date: moment.utc(entry.delivery_date).local()
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of communication entries. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchEntries();
    }, [searchText]);

    useEffect(() => {

        connectToSockets();
        utils.content.subscribe(panelID, ['communication'], {
            onFetch: fetchEntries,
            onUpdate: abstract => {
                setEntries(entries => {
                    return entries.map(entry => {
                        return entry.id === abstract.getID() ? { ...abstract.object } : entry
                    });
                });
            }
        });
        return () => {
            disconnectFromSockets();
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Communications'}
        index={index}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: next => {
                    offset.current = next;
                    fetchEntries();
                }
            },
            search: {
                placeholder: 'Search by message or title...',
                onChange: setSearchText
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const CommunicationDetails = ({ abstract, index, options, utils }) => {

    const layerID = `communication_details_${abstract.getID()}`;
    const [communication, setCommunication] = useState(abstract.object);
    const [dealerships, setDealerships] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [recruitedByUsers, setRecruitedByUsers] = useState(null);
    const [regions, setRegions] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [task, setTask] = useState(abstract.object.task);
    const [timezones, setTimezones] = useState(null);
    const [users, setUsers] = useState(null);
    
    const canShowOptions = () => {
        return communication.task && communication.task.status ? true : false;
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_communication_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditCommunication.bind(this, { 
                isNewTarget: false,
                targets: {
                    divisions: divisions && divisions.map(d => d.id), 
                    dealerships: dealerships && dealerships.map(d => d.id), 
                    regions: regions && regions.map(r => r.id),
                    recruited_by_users: recruitedByUsers,
                    timezones: timezones,
                    users: users
                }
            })
        });
    }

    const onDealershipClick = async id => {
        try {

            // fetch details for dealership
            setLoading(true);
            let dealership = await Dealership.get(utils, id);

            // present dealership details layer
            setLoading(false);
            utils.layer.open({
                id: `dealership_details_${dealership.id}`,
                abstract: Abstract.create({
                    object: dealership,
                    type: 'dealership'
                }),
                Component: DealershipDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this region. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'resend',
                style: 'default',
                title: 'Resend Messages',
                visible: communication.task.status.code === 5 ? false : true
            },{
                key: 'stop',
                style: 'destructive',
                title: 'Stop Sending Messages',
                visible: communication.task.status.code === 5 ? true : false
            }],
            target: evt.target
        }, key => {
            if(key === 'resend') {
                onResendMessages();
                return;
            }
            if(key === 'stop') {
                onStopSendingMessages();
                return;
            }
        });
    }

    const onResendMessages = () => {
        utils.alert.show({
            title: 'Resend Messages',
            message: 'Are you sure that you want to resend these messages? This will resend the text message content to all users who match the criteria for this communication.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onResendMessagesConfirm();
                    return;
                }
            }
        })
    }

    const onResendMessagesConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.5);
            let { task, total_count } = await Request.post(utils, '/resources/', {
                type: 'resend_communication',
                id: abstract.getID()
            });

            // notify subscribers that a new task has been submitted
            utils.events.emit('new_task', { task });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Your communication has been resubmitted and will begin sending shortly. We will be notifiying ${Utils.softNumberFormat(total_count)} ${total_count === 1 ? 'user' : 'users'}`,
                onClick: utils.content.fetch.bind(this, 'communication')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue resubmitting your customer communication. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSectorClick = async target => {
        try {

            // fetch details for sector
            setLoading(true);
            let sector = await Sector.get(utils, target.id, target.type);

            // present sector details layer
            setLoading(false);
            utils.layer.open({
                id: `sector_details_${sector.id}`,
                abstract: Abstract.create({
                    object: sector,
                    type: 'sector'
                }),
                Component: SectorDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this team. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onStopSendingMessages = () => {
        utils.alert.show({
            title: 'Stop Sending Messages',
            message: 'Are you sure that you want to stop sending these messages? This will prevent the rest of the users who are targeted by this communication from receiving their text message.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onStopSendingMessagesConfirm();
                    return;
                }
            }
        })
    }

    const onStopSendingMessagesConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.5);
            let { status } = await Request.post(utils, '/resources/', {
                type: 'cancel_communicatation',
                id: abstract.getID()
            });

            // update target with new status
            abstract.object.status = status;
            setCommunication(abstract.object);

            // notify subscribers of status change
            utils.content.update(abstract);

            // end loading and show confirmation alert
            setLoading(true);
            utils.alert.show({
                title: 'All Done!',
                message: 'The text messages for this communication have been stopped. It may take a moment for the text messages to end.'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue stopping these messages. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUserClick = async userID => {
        try {

            // fetch details for user account
            setLoading(true);
            let user = await User.get(utils, userID);

            // present user details layer
            setLoading(false);
            utils.layer.open({
                id: `user_details_${user.user_id}`,
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this user account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUserTaskProgressChange = data => {
        try {
            setTask(task => {
                if(!task || task.id !== data.id) {
                    return task;
                }   
                return {
                    ...task,
                    ...data
                };
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const getAttachments = () => {
        return communication.metadata.attachments && communication.metadata.attachments.length > 0 && (
            <LayerItem 
            title={'Attachments'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {communication.metadata.attachments.map((attachment, index, attachments) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== attachments.length - 1,
                                icon: { 
                                    path: getFileIconPath(attachment.type),
                                    imageStyle: {
                                        backgroundColor: Appearance.colors.transparent,
                                        borderRadius: 0,
                                        boxShadow: 'none',
                                        objectFit: 'contain'
                                    } 
                                },
                                key: index,
                                onClick: window.open.bind(this, attachment.url),
                                subTitle: attachment.description,
                                title: attachment.name
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getBottomFields = () => {

        // start with preferences and restrictions
        let items = [{
            key: 'preferences',
            lastItem: false,
            title: 'Preferences',
            items: [{
                key: 'date',
                title: 'Delivery Date',
                value: communication.metadata.preferences.date && Utils.formatDate(moment.unix(communication.metadata.preferences.date).local()),
                visible: communication.metadata.preferences.schedule === 'later' ? true : false
            },{
                key: 'delivery_method',
                title: 'Delivery Method',
                value: getDeliveryMethodText()
            },{
                key: 'schedule',
                title: 'Schedule',
                value: communication.metadata.preferences.schedule === 'now' ? 'Send Immediately' : 'Send at a later date'
            }]
        }];

        // add task details to fields list if applicable
        if(task) {
            items.push({
                key: 'task',
                lastItem: false,
                title: 'Task',
                items: [{
                    key: 'id',
                    title: 'ID',
                    value: task.id
                },{
                    key: 'progress',
                    title: 'Progress',
                    value: task.total && `${(((task.progress || 0) / task.total) * 100).toFixed(0)}%` || 'Not Available',
                    visible: task.total > 0 ? true : false
                },{
                    key: 'status',
                    title: 'Status',
                    value: task.status && task.status.text || 'Pending'
                },{
                    key: 'progress',
                    title: 'Sent',
                    value: Utils.softNumberFormat(task.progress || 0),
                    visible: task.progress > 0 ? true : false
                },{
                    key: 'total',
                    title: 'Total',
                    value: Utils.softNumberFormat(task.total),
                    visible: task.total > 0 ? true : false
                }]
            });
        }
        return items;
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            onClick: onOptionsClick,
            text: 'Options',
            visible: canShowOptions()
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            text: 'Edit'
        }];
    }

    const getDealerships = () => {
 
        // prevent moving forward if no dealership restrictions were added
        if(!communication.metadata.restrictions.dealerships || communication.metadata.restrictions.dealerships.length === 0) {
            return null;
        }

        // filter list of dealerships down to restrictions dealership ids list
        let targets = dealerships.filter(dealership => communication.metadata.restrictions.dealerships.includes(dealership.id));
        return targets.length > 0 && (
            <LayerItem title={'Dealerships'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {targets.map((dealership, index, dealerships) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== dealerships.length - 1,
                                hideIcon: true,
                                key: index,
                                onClick: onDealershipClick.bind(this, dealership.id),
                                subTitle: dealership.address ? Utils.formatAddress(dealership.address) : 'Address Not Available',
                                title: dealership.name
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getDeliveryMethodText = () => {
        switch(communication.metadata.preferences.delivery_method) {
            case 'email':
            return 'Email';

            case 'push_notification':
            return 'Push Notification';

            case 'sms':
            return 'SMS';
        }
    }

    const getDivisions = () => {
 
        // prevent moving forward if no division restrictions were added
        if(!communication.metadata.restrictions.divisions || communication.metadata.restrictions.divisions.length === 0) {
            return null;
        }

        // filter list of divisions down to restrictions division ids list
        let targets = divisions.filter(division => communication.metadata.restrictions.divisions.includes(division.id));
        return targets.length > 0 && (
            <LayerItem title={'Divisions'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {targets.map((division, index, divisions) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== divisions.length - 1,
                                icon: { path: division.director && division.director.avatar },
                                key: index,
                                onClick: onSectorClick.bind(this, division),
                                subTitle: division.director ? division.director.full_name : 'Director not available',
                                title: division.name
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getGraciSubscriptions = () => {

        // prevent moving forward if no graci subscription restrictions were added
        if(!communication.metadata.restrictions.graci_subscriptions || communication.metadata.restrictions.graci_subscriptions.length === 0) {
            return null;
        }

        // filter list of subscriptions down to restrictions graci subscription ids list
        console.log(subscriptions);
        let targets = subscriptions.filter(subscription => communication.metadata.restrictions.graci_subscriptions.includes(subscription.category));
        console.log(targets)
        return targets && targets.length > 0 && (
            <LayerItem title={'GRACI Subscriptions'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    {targets.map((subscription, index, subscriptions) => {
                        return (
                            <div 
                            key={index}
                            style={{
                                borderBottom: index !== subscriptions.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: '6px 12px 6px 12px'
                            }}>
                                <span style={Appearance.textStyles.key()}>{subscription.title}</span>
                            </div>
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getLevels = () => {
        let levels = communication.metadata.restrictions.levels;
        return levels && levels.length > 0 && (
            <LayerItem title={'Account Types'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    {levels.map((level, index, levels) => {
                        return (
                            <div 
                            key={index}
                            style={{
                                borderBottom: index !== levels.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: '6px 12px 6px 12px'
                            }}>
                                <span style={Appearance.textStyles.key()}>{User.levels.toText(level)}</span>
                            </div>
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getLocations = () => {

        // prevent moving forward if no location restrictions were added
        if(!communication.metadata.restrictions.locations || communication.metadata.restrictions.locations.length === 0) {
            return null;
        }

        // prepare circle geometries
        let locations = communication.metadata.restrictions.locations;
        let circles = locations.map(entry => {
            return circle([entry.long, entry.lat], entry.radius, {
                steps: 90, 
                units: 'miles'
            });
        });
        return (
            <LayerItem title={'Locations'}>
                <Map
                circles={circles}
                isScrollEnabled={true}
                isZoomEnabled={true}
                isRotationEnabled={true}
                style={{
                    height: 350,
                    width: '100%'
                }}/>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8
                }}>
                    {locations.map((location, index) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== locations.length - 1,
                                hideIcon: true,
                                key: index,
                                subTitle: `${location.radius} mile radius`,
                                title: location.name || location.address,
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getPrograms = () => {
 
        // prevent moving forward if no program restrictions were added
        if(!communication.metadata.restrictions.programs || communication.metadata.restrictions.programs.length === 0) {
            return null;
        }

        // filter list of programs down to restrictions program ids list
        return (
            <LayerItem title={'Programs'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    {communication.metadata.restrictions.programs.map((program, index, programs) => {
                        return (
                            <div 
                            key={index}
                            style={{
                                borderBottom: index !== programs.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: '6px 12px 6px 12px'
                            }}>
                               <span style={Appearance.textStyles.key()}>{program === 'one_to_one' ? '1:1' : 'Hybrid'}</span>
                            </div>
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getRegions = () => {
 
        // prevent moving forward if no region restrictions were added
        if(!communication.metadata.restrictions.regions || communication.metadata.restrictions.regions.length === 0) {
            return null;
        }

        // filter list of regions down to restrictions region ids list
        let targets = regions.filter(region => communication.metadata.restrictions.regions.includes(region.id));
        return targets.length > 0 && (
            <LayerItem title={'Regions'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {targets.map((region, index, regions) => {
                        return (
                            Views.entry({
                                key: index,
                                title: region.name,
                                subTitle: region.director ? region.director.full_name : 'Director not available',
                                icon: {
                                    path: region.director.avatar
                                },
                                badge: {
                                    text: region.active ? null : 'Not Active',
                                    color: Appearance.colors.grey()
                                },
                                firstItem: index === 0,
                                singleItem: regions.length === 1,
                                lastItem: index === regions.length - 1,
                                bottomBorder: index !== regions.length - 1,
                                onClick: onSectorClick.bind(this, region)
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getTimezones = () => {
        return timezones && timezones.length > 0 && (
            <LayerItem title={'Timezones'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    {timezones.map((timezone, index, timezones) => {
                        return (
                            <div 
                            key={index}
                            style={{
                                borderBottom: index !== timezones.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: '6px 12px 6px 12px'
                            }}>
                               <span style={Appearance.textStyles.key()}>{timezone}</span>
                            </div>
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getTopFields = () => {
        return [{
            key: 'details',
            lastItem: false,
            title: 'Details',
            items: [{
                key: 'date',
                title: 'Date',
                value: Utils.formatDate(communication.date)
            },{
                key: 'id',
                title: 'ID',
                value: abstract.getID()
            },{
                key: 'message',
                title: 'Message',
                value: communication.message
            },{
                key: 'title',
                title: 'Title',
                value: communication.title
            },{
                key: 'user_count',
                title: 'Users Targeted',
                value: Utils.softNumberFormat(communication.user_count || 0)
            }]
        }];
    }

    const getUrls = () => {
        return communication.metadata.urls && communication.metadata.urls.length > 0 && (
            <LayerItem 
            title={'Links'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {communication.metadata.urls.map((entry, index, urls) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== urls.length - 1,
                                hideIcon: true,
                                key: index,
                                onClick: window.open.bind(this, entry.url),
                                supportingTitle: entry.url,
                                subTitle: entry.description,
                                title: entry.title
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getUsers = key => {
        let targets = key === 'recruited_by_users' ? recruitedByUsers : users;
        return targets && targets.length > 0 && (
            <LayerItem title={key === 'recruited_by_users' ? 'Recruited By' : 'Users'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {targets.map((user, index, users) => {
                        return (
                            Views.entry({
                                bottomBorder: index !== users.length - 1,
                                icon: { path: user.avatar },
                                key: index,
                                onClick: onUserClick.bind(this, user.user_id),
                                subTitle: user.phone_number,
                                title: user.full_name,
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const connectToSockets = async () => {
        try {
            let user = utils.user.get();
            await utils.sockets.on('aft', 'tasks', `on_user_task_progress_change_${user.user_id}`, onUserTaskProgressChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            let user = utils.user.get();
            await utils.sockets.off('aft', 'tasks', `on_user_task_progress_change_${user.user_id}`, onUserTaskProgressChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchPreflightContent = async () => {
        try {
            let { communication, dealerships, divisions, graci_subscriptions, recruited_by_users, regions, timezones, users } = await Request.get(utils, '/resources/', {
                id: abstract.getID(),
                type: 'communication_details',
            });

            setLoading(false);
            setCommunication({
                ...communication,
                date: moment.utc(communication.date).local(),
                delivery_date: moment.utc(communication.delivery_date).local()
            })
            setDivisions(divisions);
            setDealerships(dealerships);
            setRegions(regions);
            setRecruitedByUsers(recruited_by_users);
            setSubscriptions(graci_subscriptions);
            setTimezones(timezones);
            setUsers(users);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving some of the details for this communication. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setTask(communication && communication.task);
    }, [communication]);

    useEffect(() => {

        connectToSockets();
        setTimeout(fetchPreflightContent, 250);

        utils.content.subscribe(layerID, ['communication'], {
            onFetch: fetchPreflightContent,
            onUpdate: next => {
                if(abstract.getID() === next.getID()) {
                    fetchPreflightContent();
                }
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
            disconnectFromSockets();
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Communication Details'}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium'
        }}>
            <LayerItem title={'Submitted By'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        icon: { path: communication.user.avatar },
                        onClick: onUserClick.bind(this, abstract.object.user.user_id),
                        subTitle: communication.user.email_address,
                        title: communication.user.full_name
                    })}
                </div>
            </LayerItem>

            <FieldMapper
            fields={getTopFields()}
            utils={utils} />

            <FieldMapper
            fields={getBottomFields()}
            utils={utils} />

            {getAttachments()}
            {getUrls()}
            {getLevels()}
            {getDealerships()}
            {getDivisions()}
            {getGraciSubscriptions()}
            {getLocations()}
            {getPrograms()}
            {getUsers('recruited_by_users')}
            {getRegions()}
            {getTimezones()}
            {getUsers('users')}

        </Layer>
    )
}

export const getNotificationIcon = (notification, purpose = false) => {

    // determine if notification originated from home office
    if(notification.category && notification.category === 'GHS_COMMUNICATION') {
        return {
            path: 'images/ghs-notification-clear.png',
            imageStyle: {
                backgroundColor: getNotificationIconColor(notification),
                borderRadius: purpose === 'alert' ? 32.5 : 15
            }
        }
    }

    // determine if notification represents a google review
    if(notification.category && notification.category.includes('GOOGLE_REVIEW') === true) {
        return {
            path: 'images/google-review-icon.png',
            imageStyle: {
                backgroundColor: Appearance.colors.transparent,
                borderRadius: purpose === 'alert' ? 32.5 : 15
            }
        }
    }

    // determine if the notification originated from omnishield
    if(notification.category && notification.category.includes('OMNISHIELD')) {
        return {
            path: 'images/navigation-icon-omnishield-white.png',
            imageStyle: {
                backgroundColor: getNotificationIconColor(notification),
                borderRadius: purpose === 'alert' ? 32.5 : 15,
                padding: purpose === 'alert' ? 8 : 4
            }
        }
    }
                    
    // determine if the notification originated from another user
    if(notification.from_user) {
        return {
            path: notification.from_user.avatar,
            style: {
                backgroundColor: getNotificationIconColor(notification),
                borderRadius: purpose === 'alert' ? 32.5 : 15
            }
        }
    }

    // determine if the notification contains at least one attachment
    let { attachments = [] } = notification.payload || {};
    if(attachments && attachments.length > 0) {
        return {
            path: Appearance.themeStyle() === 'dark' ? 'images/general-icon-white.png' : 'images/general-icon-grey.png',
            style: {
                backgroundColor: Appearance.colors.transparent,
                borderRadius: 0,
                objectFit: 'contain'
            }
        }
    }
    
    // fallback to traditional notification icon
    return {
        path: 'images/push-notification-icon-white.png',
        imageStyle: {
            backgroundColor: getNotificationIconColor(notification),
            borderRadius: purpose === 'alert' ? 32.5 : 15
        }
    }
}

const getNotificationIconColor = notification => {
    if(!notification.unread) {
        return Appearance.colors.grey();
    }
    if(notification.category && notification.category.includes('CANCELLED')) {
        return Appearance.colors.red;
    }
    if(notification.category && notification.category.includes('RESCHEDULED')) {
        return Appearance.colors.secondary();
    }
    return Appearance.colors.primary();
}

export const getSystemEventBadges = evt => {

    let badges = [];
    switch(evt.action.code) {
        case SystemEvent.actions.create:
        badges.push({
            text: 'Created',
            color: Appearance.colors.primary()
        });
        break;

        case SystemEvent.actions.update:
        badges.push({
            text: 'Updated',
            color: Appearance.colors.secondary()
        });
        break;

        case SystemEvent.actions.delete:
        badges.push({
            text: 'Deleted',
            color: Appearance.colors.red
        });
        break;

        case SystemEvent.actions.warning:
        badges.push({
            text: 'Warning',
            color: Appearance.colors.orange
        });
        break;
    }
    return [{
        text: Utils.formatDate(evt.date),
        color: Appearance.colors.grey()
    }].concat(badges);
}

export const getSystemEventProps = evt => {

    // create => use abstract generated title information
    if(evt.action.code === SystemEvent.actions.create) {
        let val = `created a ${evt.target.getTitle()}`;
        switch(evt.target.type) {
            case 'dealership':
            val = 'created a dealership';
            break;

            case 'user':
            val = 'created an account';
            break;
        }

        return {
            values: [ `${evt.user.full_name} ${val}` ],
            components: (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: 8,
                    padding: '6px 12px 6px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.text(),
                        whiteSpace: 'normal'
                    }}>
                        <span style={{
                            fontWeight: 700,
                            color: Appearance.colors.text()
                        }}>
                            {evt.user.full_name}
                        </span>
                        {` ${val}`}
                    </span>
                </div>
            )
        }
    }

    // warning => use abstract generated title information
    if([SystemEvent.actions.info, SystemEvent.actions.warning].includes(evt.action.code) === true) {
        return {
            values: [ evt.props.message ],
            components: (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: 8,
                    padding: '6px 12px 6px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.text(),
                        whiteSpace: 'normal'
                    }}>
                        {evt.props.message || 'Message is no longer available'}
                    </span>
                </div>
            )
        }
    }

    // delete => use abstract generated title information
    if(evt.action.code === SystemEvent.actions.delete) {
        let val = `${evt.user.full_name} deleted the ${evt.target.getTitle()}`;
        return {
            values: [ val ],
            components: (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: 8,
                    padding: '6px 12px 6px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.text(),
                        whiteSpace: 'normal'
                    }}>
                        <span style={{
                            fontWeight: 700,
                            color: Appearance.colors.text()
                        }}>
                            {evt.user.full_name}
                        </span>
                        {` deleted the ${evt.target.getTitle()}`}
                    </span>
                </div>
            )
        }
    }

    // check for original and current update properties
    let { original, current } = evt.props || {};
    if(!original || !current) {
        //console.log('missing original or current');
        return { values: [] };
    }

    // use key to create title for data point
    const getTitleFromKey = (key, target) => {

        // target specific data points
        switch(evt.target.type) {

            case 'card':
            switch(key) {
                case 'customer':
                return `Customer's information`;

                case 'lead':
                return 'Lead';

                case 'units':
                return 'Units sold';

                case 'total_units':
                return 'Total units';

                case 'dealership':
                return 'Dealership';

                case 'sold_by':
                return 'User who sold the protection';

                case 'serial_number':
                return 'Comm Link serial number';

                default:
                if(key.includes('product')) {
                    return target && target[key] ? `${target[key].name} total` : 'Product total';
                }
            }
            break;

            case 'dealership':
            switch(key) {

                case 'dealer':
                return 'Dealer';
                
                case 'name':
                return `Dealership Name`;

                case 'omnishield.android.app_icon':
                return 'Android app icon';

                case 'omnishield.android.name':
                return 'Android app name';

                case 'omnishield.android.status':
                return 'Android app status';

                case 'omnishield.assets.splash_image':
                return 'Splash Image';
                
                case 'omnishield.colors.primary':
                return 'Primary color';

                case 'omnishield.colors.secondary':
                return 'Secondary color';

                case 'omnishield.colors.tertiary':
                return 'Tertiary color';

                case 'omnishield.ios.app_icon':
                return 'iOS app icon';

                case 'omnishield.ios.name':
                return 'iOS app name';

                case 'omnishield.ios.status':
                return 'iOS app status';

                case 'omnishield.social_media.facebook':
                return 'Facebook account';

                case 'omnishield.social_media.instagram':
                return 'Instagram account';

                case 'omnishield.social_media.tiktok':
                return 'TikTok account';

                case 'omnishield.social_media.x':
                return 'X (formerly Twitter) account';

                case 'omnishield.temperature_units':
                return 'Temperature units';

                case 'omnishield.website':
                return 'Website';
            }
            break;

            case 'product':
            switch(key) {
                case 'name':
                return 'Name';

                case 'icon':
                return 'Icon';
            }
            break;

            case 'sector':
            switch(key) {
                case 'name':
                return `Team Name`;

                case 'director':
                return 'Director';

                case 'director_user_id':
                return 'Director User ID';
            }
            break;

            case 'user':
            switch(key) {
                case 'division':
                return 'Division';

                case 'recruit_date':
                return 'Recruit Date';

                case 'recruit_notes':
                return 'Recruit Notes';

                case 'recruited_by':
                return 'Recruiter';

                case 'region':
                return 'Region';
            }
            break;
        }

        // universal data points
        switch(key) {
            case 'id':
            return 'ID';

            case 'user_id':
            return 'User ID';

            case 'username':
            return 'Username';

            case 'first_name':
            return 'First Name';

            case 'last_name':
            return 'Last Name';

            case 'full_name':
            return 'Full Name';

            case 'level':
            return 'Account Type';

            case 'phone_number':
            return 'Phone Number';

            case 'email_address':
            return 'Email Address';

            case 'address':
            return 'Address';

            case 'location':
            return 'Location Coordinates';

            case 'dealership':
            return 'Dealership';

            case 'dealership_id':
            return 'Dealership ID';

            case 'date':
            return 'Date';

            case 'start_date':
            return 'Start Date';

            case 'end_date':
            return 'End Date';

            case 'timezone':
            return 'Timezone';

            case 'status':
            return 'Status';

            case 'notes':
            return 'Notes';

            case 'comments':
            return 'Comments';

            case 'tags':
            return 'Tags';

            case 'active':
            return 'Active status';

            case 'gdl_active':
            return 'Global Data access';
        }
        return Utils.ucFirst(key.replace('_', ''));
    }

    // check for specialty formatting based on data point key or value
    const getValue = (key, target) => {

        // target specific data points
        switch(evt.target.type) {
            case 'card':
            switch(key) {
                case 'lead':
                return target[key] ? target[key].full_name : null;

                case 'units':
                case 'total_units':
                return target[key] ? `${target[key]} ${target[key] === 1 ? 'unit' : 'units'}` : null;

                case 'dealership':
                return target[key] ? target[key].name : null;

                case 'sold_by':
                return target[key] ? target[key].full_name : null;

                default:
                if(key.includes('product')) {
                    return target[key] ? target[key].value : null;
                }
            }
            break;

            case 'sector':
            switch(key) {
                case 'director':
                return target[key] ? target[key].full_name : null;
            }
            break;

            case 'user':
            switch(key) {
                case 'genealogy':
                return target[key] ? `${target[key].region ? `region #${target[key].region}` : 'no region'} and ${target[key].division ? `division #${target[key].division}` : 'no division'}` : null
            }
            break;
        }

        // universal data points
        switch(key) {
            case 'address':
            return target[key] ? Utils.formatAddress(target[key]) : null;

            case 'location':
            return target[key] ? Utils.formatLocation(target[key]) : null;

            case 'level':
            return target[key] ? User.levels.toText(target[key]) : null;

            case 'date':
            case 'start_date':
            case 'end_date':
            return target[key] ? Utils.formatDate(target[key]) : null;

            case 'status':
            return target[key] ? target[key].text : null;

            case 'active':
            case 'gdl_active':
            return  target[key] ? 'Yes' : 'No';
        }

        if(typeof(target[key]) === 'object') {
            return 'Unsupported value';
        }
        return target[key];
    }

    // check for specific color based on data point or value
    const getValueColor = (key, val) => {
        if(!val) {
            return Appearance.colors.grey();
        }
        // set color based on key
        switch(key) {
            case 'active':
            case 'gdl_active':
            return val === 'Yes' ? Appearance.colors.green : Appearance.colors.red;
        }
        return null;
    }

    // create text overview for system event
    let keys = Object.keys(original);
    Object.keys(current).forEach(key => {
        if(!keys.includes(key)) {
            keys.push(key);
        }
    });
    
    // prepare list of formatted value changes
    let values = keys.filter(key => {
        return getValue(key, original) !== getValue(key, current);
    }).map(key => {

        // prepare event title, original values, and current values
        let currentVal = getValue(key, current);
        let originalVal = getValue(key, original);
        let title = getTitleFromKey(key, current);

        // determine if an image compare is needed
        if(['omnishield.android.app_icon', 'omnishield.ios.app_icon'].includes(key) === true) {
            if(currentVal && originalVal) {
                return `${evt.user.full_name} updated the ${title.toLowerCase()}`;
            }
            if(currentVal) {
                return `${evt.user.full_name} set the ${title.toLowerCase()}`;
            }
            return `${evt.user.full_name} removed the ${title.toLowerCase()}`;
        }

        // fallback to comparing values as needed
        return `${evt.user.full_name} changed the ${title.toLowerCase()} from ${originalVal || 'no value'} to ${currentVal || 'no value'}`;
    })

    // create components with text overview of system event
    let components = keys.map((key, index) => {

        // prepare event title, original values, and current values
        let currentVal = getValue(key, current);
        let originalVal = getValue(key, original);
        let title = getTitleFromKey(key, current);

        // prevent moving forward if no changes were found
        if(originalVal === currentVal) {
            return null;
        }

        // prepare default change content
        let content = (
            <>
            {` changed the ${key.includes('product') ? title : title.toLowerCase()} from `}
            <span style={{
                color: getValueColor(key, originalVal) || Appearance.colors.text(),
                fontWeight: 700
            }}>
                {originalVal || 'no value'}
            </span>
            {` to `}
            <span style={{
                color: getValueColor(key, currentVal) || Appearance.colors.green,
                fontWeight: 700
            }}>
                {currentVal || 'no value'}
            </span>
            </>
        );

        // determine if an image compare is needed
        if(['omnishield.android.app_icon', 'omnishield.ios.app_icon'].includes(key) === true) {
            if(currentVal && originalVal) {
                content = ` updated the ${title.toLowerCase()} `;
            } else if(currentVal) {
                content = ` set the ${title.toLowerCase()} `;
            } else {
                content = ` removed the ${title.toLowerCase()} `;
            }
        }

        return (
            <div
            key={index}
            style={{
                ...Appearance.styles.unstyledPanel(),
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 8,
                padding: '6px 12px 6px 12px',
                width: '100%'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: Appearance.colors.text(),
                    whiteSpace: 'normal'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontWeight: 700
                    }}>
                        {evt.user.full_name}
                    </span>
                    {content}
                </span>
            </div>
        )
    })

    return {
        values: values || [],
        components: components
    }
}

export const NewAccountPassword = ({ abstract, index, options, utils }) => {

    const layerID = `new_account_password_${abstract.getID()}`;
    const [creds, setCreds] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmitPassword = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            if(creds.password !== creds.confirmation) {
                throw new Error('The passwords you provided do not match.');
            }
            await Request.post(utils, '/users/', {
                type: 'set_password',
                user_id: abstract.getID(),
                password: creds.password
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The password for ${abstract.object.full_name} has been reset`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the password for this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setCreds(prev => ({
            ...prev,
            ...props
        }));
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            onClick: onSubmitPassword,
            text: 'Submit'
        }];
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Credentials',
            items: [{
                key: 'password',
                title: 'Password',
                description: 'This should be the desired password for the account.',
                component: 'textfield',
                value: creds.password,
                onChange: text => onUpdateTarget({ password: text }),
                props: {
                    isSecure: true
                }
            },{
                key: 'confirmation',
                title: 'Confirm Password',
                description: 'This should be the desired password for the account.',
                component: 'textfield',
                value: creds.confirmation,
                onChange: text => onUpdateTarget({ confirmation: text }),
                props: {
                    isSecure: true
                }
            }]
        }]
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Create New Account Password'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const NotificationsList = ({ index, options, utils }) => {

    const panelID = 'notifications';
    const limit = 10;

    const [loading, setLoading] = useState('init');
    const [paging, setPaging] = useState(null);
    const [offset, setOffset] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState(1);

    const onMarkAllAsRead = () => {
        utils.alert.show({
            title: 'Mark All As Read',
            message: 'Are you sure that you want to mark all of your notifications are read?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onMarkAllAsReadConfirm();
                    return;
                }
            }
        })
    }

    const onMarkAllAsReadConfirm = async () => {
        try {

            // submit request to server
            setLoading(true);
            await Request.post(utils, '/notifications/', {
                type: 'mark_all_as_read',
                user_id: utils.user.get().user_id
            });

            // end loading and update notifications list with new unread flag
            setLoading(false);
            setUnreadNotifications(0);
            setNotifications(notifications => {
                return notifications.map(notification => {
                    notification.unread = false;
                    return notification;
                })
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue marking your notifications as read. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onNotificationClick = async notification => {
        try {

            // set loading flag for async tasks inside notifications handler and run handler
            setLoading(notification.id);
            await onNotificationAction({
                notification, 
                onLoadingChange: val => setLoading(val ? notification.id : false),
                onClick: onSetAsRead, 
                utils
            });

            // end loading flag
            setLoading(false);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this notification. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetAsRead = async notification => {
        try {
            await notification.setAsRead(utils);
            setNotifications(notifications => {
                return notifications.map(prevNotification => {
                    if(prevNotification.id === notification.id) {
                        prevNotification.unread = false;
                    }
                    return prevNotification;
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue marking this notification as read. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getBadge = notification => {
        let date = moment(notification.date);
        if(moment().isSame(date, 'day')) {
            return {
                text: date.format('h:mma'),
                color: Appearance.colors.primary()
            }
        }
        if(moment().subtract(1, 'days').isSame(date, 'day')) {
            return {
                text: date.format('[Yesterday at] h:mma'),
                color: Appearance.colors.secondary()
            }
        }
        if(date > moment().subtract(6, 'days')) {
            return {
                text: date.format('dddd [at] h:mma'),
                color: Appearance.colors.tertiary()
            }
        }
        return {
            text: date.format('MMM Do [at] h:mma'),
            color: Appearance.colors.grey()
        }
    }

    const getButtons = () => {
        if(unreadNotifications === 0) {
            return null;
        }
        return [{
            key: 'mark_as_read',
            onClick: onMarkAllAsRead,
            style: 'default',
            title: 'Mark All as Read'
        }]
    }

    const getContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 100,
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            );
        }
        if(notifications.length === 0) {
            return (
                <div style={Appearance.styles.unstyledPanel()}>
                    {Views.entry({
                        bottomBorder: false,
                        hideIcon: true,
                        subTitle: 'Your account has not received any notifications',
                        title: 'No Notifications Found',
                        style: {
                            subTitle: {
                                whiteSpace: 'normal'
                            }
                        }
                    })}
                </div>
            )
        }
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {notifications.map((notification, index) => {
                    return (
                        Views.entry({
                            badge: getBadge(notification),
                            bottomBorder: index !== notifications.length - 1,
                            icon: {
                                ...getNotificationIcon(notification),
                                badge: notification.unread && { color: Appearance.colors.secondary() }
                            },
                            key: index,
                            loading: loading === notification.id,
                            onClick: onNotificationClick.bind(this, notification),
                            style: {
                                subTitle: {
                                    whiteSpace: 'normal'
                                }
                            },
                            subTitle: notification.message,
                            title: notification.title
                        })
                    )
                })}
            </div>
        )
    }

    const fetchNotifications = async () => {
        try {
            let { notifications, paging, unread_notifications } = await Request.get(utils, '/notifications/', {
                type: 'all',
                limit: limit,
                offset: offset
            });
            setLoading(false);
            setPaging(paging);
            setUnreadNotifications(unread_notifications);
            setNotifications(notifications.map(notification => Notification.create(notification)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your notifications. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchNotifications();
    }, [offset]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'notification', {
            onFetch: fetchNotifications
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Notifications'}
        index={index}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading === true,
            paging: {
                data: paging,
                limit: limit,
                loading: loading === 'paging',
                offset: offset,
                onClick: next => {
                    setLoading('paging');
                    setOffset(next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const NotifyUsersLegacy = ({ index, options, utils }) => {

    const layerID = 'notify_users';
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [levels, setLevels] = useState([]);
    const [message, setMessage] = useState(null);
    const [preflightCount, setPreflightCount] = useState(0);
    const [restrictToDealership, setRestrictToDealership] = useState(false);

    const onDoneClick = async () => {
        if(levels.length === 0) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please choose at least one account type before moving on'
            });
            return;
        }
        utils.alert.show({
            title: 'Send Notifications',
            message: `Are you sure that you want to send this notification to all ${Utils.oxfordImplode(levels.map(level => `${User.levels.toText(level)}s`))}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSendNotifications();
                    return;
                }
            }
        });
    }

    const onLevelChange = async (level, selected) => {
        try {
            setLevels(levels => update(levels, {
                $apply: levels => levels.filter(prev => {
                    return prev !== level;
                }),
                ...selected && {
                    $push: [level]
                }
            }))

        } catch(e) {
            console.error(e.message);
        }
    }

    const onSendNotifications = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { count } = await Request.post(utils, '/users/', {
                type: 'notify',
                levels: levels,
                message: message
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `We have sent your notification to ${count} ${count === 1 ? 'user' : 'users'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending your notifications. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtonLabel = () => {
        if(preflightCount === 0) {
            return 'Send Notification';
        }
        return `Send Notification to ${preflightCount} ${preflightCount === 1 ? 'User' : 'Users'}`
    }

    const getDealershipToggle = () => {
        if(utils.user.get().level >= User.levels.get().dealer) {
            return null;
        }
        return (
            <LayerItem
            title={'Dealership'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%',
                    padding: 10
                }}>
                    <BoolToggle
                    color={Appearance.colors.grey()}
                    isEnabled={restrictToDealership}
                    enabled={'Current Dealership'}
                    disabled={'All Dealerships'}
                    onChange={val => setRestrictToDealership(val)} />
                </div>
            </LayerItem>
        )
    }

    const getLevels = () => {
        return (
            <LayerItem
            title={'Account Types'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%',
                    padding: 10
                }}>
                    {Object.values(User.levels.get()).filter(level => {
                        return level !== User.levels.get().system_admin;
                    }).map((level, index, entries) => (
                        <div
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: index === entries.length - 1 ? 0 : 12,
                            width: '100%',
                            textAlign: 'left'
                        }}>
                            <Checkbox
                            checked={levels.includes(level) ? true : false}
                            onChange={onLevelChange.bind(this, level)} />
                            <span style={{
                                ...Appearance.textStyles.title(),
                                marginLeft: 8
                            }}>{User.levels.toText(level)}</span>
                        </div>
                    ))}
                </div>
            </LayerItem>
        )
    }

    const getMessage = () => {
        return (
            <LayerItem title={'Message'}>
                <TextView
                value={message}
                characterLimit={225}
                placeholder={'Type your message here...'}
                onChange={text => setMessage(text)} />
            </LayerItem>
        )
    }

    const fetchPreflightCount = async () => {
        try {
            if(levels.length === 0) {
                setPreflightCount(0);
                return;
            }
            let { count } = await Request.post(utils, '/users/', {
                type: 'notify',
                levels: levels,
                preflight: true
            });
            setPreflightCount(count);
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        fetchPreflightCount();
    }, [levels])

    return (
        <Layer
        id={layerID}
        title={'Notify'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: getButtonLabel(),
            loading: loading === 'done',
            color: 'primary',
            onClick: onDoneClick
        }]}>
            {getLevels()}
            {getDealershipToggle()}
            {getMessage()}
        </Layer>
    )
}

export const onNotificationAction = async ({ notification, onLoadingChange, onClick, utils }) => {
    return new Promise(async (resolve, reject) => {

        const setLoading = val => {
            if(typeof(onLoadingChange) === 'function') {
                onLoadingChange(val);
            }
        }
    
        const onCommLinkClick = async guid => {
            try {
    
                // start loading and request comm link details from server
                setLoading(true);
                let commLink = await CommLink.get(utils, guid);
    
                // end loading and show comm link details layer
                setLoading(false);
                utils.layer.open({
                    abstract: Abstract.create({
                        object: commLink,
                        type: 'comm_link'
                    }),
                    Component: CommLinkDetails,
                    id: `comm_link_details_${commLink.id}`
                });
    
            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue retrieving the information for this comm link. ${e.message || 'An unknown error occurred'}`
                });
            }
        }

        const onShowGoogleReviewReply = () => {
            utils.layer.open({
                Component: GoogleReviewReply.bind(this, {
                    dealershipID: notification.payload.dealership_id,
                    id: notification.payload.review_id
                }),
                id: 'google_review_reply'
            });
        }
    
        const getButtons = () => {
    
            let buttons = [];
            let { attachments = [], comm_link_guid, urls = [] } = notification.payload || {};
    
            // provide buttons to leave a google review reply if applicable
            if(notification.category.includes('GOOGLE_REVIEW') && utils.subscriptions.capabilities.get('aft.google_business_profile.reviews_manager') === true) {
                return [{
                    key: 'google_review_reply', 
                    title: 'Reply',
                    style: 'default'
                },{
                    key: 'cancel', 
                    title: 'Close',
                    style: 'cancel'
                }];
            }

            // determine if a comm link guid was provided and create buttons if applicable
            if(comm_link_guid) {
                buttons.push({
                    key: 'comm_link_guid',
                    title: 'View Comm Link',
                    style: 'default'
                });
            }
    
            // determine if at least one attachment was provided and create buttons if applicable
            if(attachments && attachments.length > 0) {
                attachments.forEach((attachment, index) => {
                    buttons.push({
                        key: `attachment.${index}`, 
                        title: attachment.name,
                        style: 'default'
                    });
                });
            }
    
            // determine if at least one url was provided and create buttons if applicable
            if(urls.length > 0) {
                urls.forEach((url, index) => {
                    buttons.push({
                        key: `url.${index}`, 
                        title: `Open ${url.title} Link`,
                        style: 'default'
                    });
                });
            }
    
            return buttons.concat([{
                key: 'cancel',
                title: 'Okay',
                style: 'cancel'
            }]);
        }

        const getMessage = () => {

            // provide context to leave a google review reply if applicable
            if(notification.category.includes('GOOGLE_REVIEW') && utils.subscriptions.capabilities.get('aft.google_business_profile.reviews_manager') === true) {
                return `${notification.message}. Would you like to reply to this customer? We'll submit this reply to Google for you.`;
            }
            return notification.message;
        }
    
        try {
    
            // determine if ghs communication tasks need to be executed 
            if(notification.category === 'GHS_COMMUNICATION') {
    
                // fetch metadata for ghs communication from server
                let { communication_id, metadata_key } = notification.payload || {};
                let { metadata } = await Request.get(utils, '/resources/', {
                    id: communication_id,
                    key: metadata_key,
                    type: 'communication_metadata'
                });
    
                // set attachments and urls for payload
                notification.payload = {
                    ...notification.payload,
                    attachments: metadata.attachments,
                    urls: metadata.urls
                }
            }
    
            // present alert with notification content
            utils.alert.show({
                buttons: getButtons(),
                icon: getNotificationIcon(notification, 'alert'),
                message: getMessage(),
                onClick: async key => {
    
                    // notify subscribers that alert has been closed
                    if(typeof(onClick) === 'function') {
                        onClick(notification);
                    }

                    // determine if a google review reply was requested
                    if(key === 'google_review_reply') {
                        onShowGoogleReviewReply();
                        return;
                    }
    
                    // determine if comm link details were requested
                    if(key === 'comm_link_guid') {
                        onCommLinkClick(notification.payload.comm_link_guid);
                        return;
                    }
    
                    // determine if an attachment was selected
                    if(key.includes('attachment') === true) {
    
                        let index = parseInt(key.replace('attachment.', ''));
                        let attachment = notification.payload.attachments[index];
                        window.open(attachment.url);
                        return;
                    }
    
                    // determine if a url was selected
                    if(key.includes('url') === true) {
    
                        let index = parseInt(key.replace('url.', ''));
                        let entry = notification.payload.urls[index];
                        window.open(entry.url);
                        return;
                    }
                },
                title: notification.title
            });
            resolve();

        } catch(e) {
            reject(e);
        }
    });
}

export const ResultsList = ({ items, layerID, limit, onFetch, onRenderItem, onRenderHeader, props, paging, title }, { index, options, utils }) => {

    const offset = useRef(0);

    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [_paging, setPaging] = useState(paging);
    const [results, setResults] = useState(items || []);

    const onFetchResults = async () => {
        try {
            setLoading(true);
            let { paging, results } = await onFetch({
                ...props,
                limit: limit,
                offset: offset.current
            });

            setLoading(false);
            setPaging(paging);
            setResults(results);
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue processing your request. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    return (
        <Layer
        id={layerID}
        title={title}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}>
            {typeof(onRenderHeader) === 'function' && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: 12
                }}>
                    {onRenderHeader()}
                </div>
            )}
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {results.map(onRenderItem)}
            </div>
            {_paging && (
                <PageControl
                data={_paging}
                limit={limit}
                offset={offset.current}
                style={{
                    borderTop: null
                }}
                onClick={next => {
                    offset.current = next;
                    onFetchResults(next);
                }} />
            )}
        </Layer>
    )
}

export const SubmitDemosTotal = ({ index, options, utils }) => {

    const layerID = 'submit_demos_total';
    const limit = 5;
    const offset = useRef(0);

    const [demos, setDemos] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [props, setProps] = useState({
        dealership: utils.dealership.get(),
        end_date: moment().endOf('month'),
        start_date: moment().startOf('month'),
        total: 0,
        user: utils.user.get()
    });

    const onSubmit = async () => {
        try {
            setLoading('submit');
            await Utils.sleep(1);
            await validateRequiredFields(getFields);

            await Request.post(utils, '/users/', {
                type: 'new_demos',
                dealership_id: props.dealership.id,
                end_date: moment(props.end_date).format('YYYY-MM-DD'),
                start_date: moment(props.start_date).format('YYYY-MM-DD'),
                total: props.total,
                user_id: props.user.user_id
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Your demo totals for ${moment(props.start_date).format('MMMM YYYY')} have been submitted.`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your demo totals. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setProps(prev => ({
            ...prev,
            ...props
        }));
    }

    const getButtons = () => {
        return [{
            key: 'submit',
            text: 'Submit',
            loading: loading === 'submit',
            color: 'primary',
            onClick: onSubmit
        }]
    }

    const getFields = () => {
        let items = [{
            key: 'details',
            title: 'Submit Monthly Totals',
            items: [{
                key: 'dealership',
                required: false,
                visible: utils.user.get().level <= User.levels.get().admin,
                title: 'Dealership',
                description: 'This should be the dealership that will receive credit for these demos',
                value: props.dealership,
                component: 'dealership_lookup',
                onChange: dealership => onUpdateTarget({ dealership: dealership })
            },{
                key: 'user',
                visible: utils.user.get().level <= User.levels.get().dealer,
                title: 'User',
                description: 'This should be the individual who ran these demos.',
                value: props.user,
                component: 'user_lookup',
                onChange: user => onUpdateTarget({ user: user })
            },{
                key: 'date',
                title: 'Date',
                description: 'This should be the month and year when these demos were ran.',
                value: props.start_date,
                component: 'month_year_picker',
                onChange: ({ end, start }) => {
                    onUpdateTarget({
                        end_date: end,
                        start_date: start
                    });
                }
            },{
                key: 'total',
                title: 'Demos Ran',
                description: 'This should be the total number of demos ran for this cycle.',
                value: props.total,
                component: 'textfield',
                onChange: text => onUpdateTarget({ total: isNaN(text) ? 0 : parseInt(text) }),
                props: {
                    format: 'number'
                }
            }]
        }]

        return items;
    }

    const getPastDemos = () => {
        if(demos.length === 0) {
            return null;
        }
        return (
            <LayerItem
            title={'Previously Submitted Totals'}
            style={{
                marginTop: 5
            }}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {demos.map((entry, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: entry.user.full_name,
                                subTitle: moment(entry.start_date).format('MMMM YYYY'),
                                icon: {
                                    path: entry.user.avatar
                                },
                                badge: {
                                    text: `${entry.total || 0} ${entry.total === 1 ? 'Demo' : 'Demos'}`,
                                    color: Appearance.colors.primary()
                                },
                                bottomBorder: index !== demos.length - 1
                            })
                        )
                    })}
                    {paging && (
                        <PageControl
                        data={paging}
                        limit={limit}
                        offset={offset.current}
                        onClick={next => {
                            offset.current = next;
                            fetchDemos();
                        }} />
                    )}
                </div>
            </LayerItem>
        )
    }

    const fetchDemos = async () => {
        try {
            setLoading(true);
            let { demos, paging } = await Request.get(utils, '/users/', {
                type: 'demos',
                limit: limit,
                offset: offset.current,
                dealership_id: props.dealership.id
            });

            setLoading(false);
            setPaging(paging);
            setDemos(demos.map(entry => ({
                ...entry,
                user: User.create(entry.user),
                dealership: Dealership.create(entry.dealership)
            })));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the previously submitted demos. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchDemos();
    }, [props.dealership]);

    return (
        <Layer
        id={layerID}
        title={'Monthly Demos'}
        index={index}
        utils={utils}
        buttons={getButtons()}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
            {getPastDemos()}
        </Layer>
    )
}

export const SystemEvents = ({ index, options, utils }) => {

    const panelID = 'system_events';
    const limit = 15;
    const offset = useRef(0);

    const [loading, setLoading] = useState(null);
    const [events, setEvents] = useState([]);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onEventClick = evt => {
        utils.layer.open({
            id: `system_event_details_${evt.id}`,
            abstract: Abstract.create({
                type: 'system_event',
                object: evt
            }),
            Component: SystemEventDetails
        })
    }

    const getContent = () => {
        if(events.length === 0) {
            return (
                Views.entry({
                    title: `No System Events Found`,
                    subTitle: `No system events were found for your dealership`,
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return events.map((evt, index) => {
            let { values } = getSystemEventProps(evt);
            return (
                Views.entry({
                    key: index,
                    title: evt.title,
                    subTitle: values.length > 0 ? `${values[0]}${values.length > 1 ? ` and ${values.length - 1} other ${values.length - 1 === 1 ? 'change' : 'changes'}` : ''}` : 'No overview available',
                    badge: getSystemEventBadges(evt),
                    icon: {
                        path: evt.user.avatar
                    },
                    bottomBorder: index !== events.length - 1,
                    onClick: onEventClick.bind(this, evt)
                })
            )
        })
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('aft', 'system', 'on_new_event', fetchEvents);
            await utils.sockets.on('aft', 'system', 'on_new_event_batch', fetchEvents);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('aft', 'system', 'on_new_event', fetchEvents);
            await utils.sockets.off('aft', 'system', 'on_new_event_batch', fetchEvents);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset.current,
                search_text: searchText
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchEvents();
    }, [searchText]);

    useEffect(() => {
        connectToSockets();
        utils.events.on(panelID, 'dealership_change', fetchEvents);
        utils.content.subscribe(panelID, 'system_event', {
            onFetch: fetchEvents
        });
        return () => {
            disconnectFromSockets();
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchEvents);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'System Events'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            search: {
                placeholder: 'Search by first or last name...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            },
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchEvents();
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const SystemEventDetails = ({ abstract, index, options, utils }) => {

    const layerID = `system_event_details_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState(null);

    const onCardClick = async () => {
        try {
            utils.layer.open({
                id: `card_details_${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'card',
                    object: target
                }),
                Component: CardDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this protection. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCreatorClick = async () => {
        try {
            let user = await User.get(utils, abstract.object.user.user_id);
            utils.layer.open({
                id: `user_details_${user.user_id}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: user
                }),
                Component: UserDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDealershipClick = async () => {
        try {
            utils.layer.open({
                id: `dealership_details_${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'dealership',
                    object: target
                }),
                Component: DealershipDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this dealership. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onProductClick = async () => {
        try {
            utils.layer.open({
                id: `product_details_${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'product',
                    object: target
                }),
                Component: ProductDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this product. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSectorClick = async () => {
        try {
            utils.layer.open({
                id: `sector_details_${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'sector',
                    object: target
                }),
                Component: SectorDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this sector. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUserClick = async () => {
        try {
            utils.layer.open({
                id: `user_details_${abstract.object.target.object.user_id}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: target
                }),
                Component: UserDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getContentDiff = () => {
        let { components } = getSystemEventProps(abstract.object);
        return (
            <LayerItem
            key={index}
            title={'Details'}>
                {components}
            </LayerItem>
        )
    }

    const getTarget = () => {

        let props = {};
        let title = Utils.ucFirst(abstract.object.target.type);

        // return loading placeholder if target is not prepared
        if(!target) {
            return (
                <LayerItem title={title}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        {Views.entry({
                            title: `Loading...`,
                            hideIcon: true,
                            bottomBorder: false,
                            bottomBorder: false,
                        })}
                    </div>
                </LayerItem>
            );
        }

        // wrap abstract target attemps in a try/catch for objects that are deleted from the database
        // crash will happend otherwise for undefined nested values
        try {
            switch(abstract.object.target.type) {
                case 'card':
                title = 'Protection';
                props = {
                    title: target.getCustomerNames(),
                    subTitle: target.customer.phone_number,
                    hideIcon: true,
                    onClick: onCardClick
                }
                break;

                case 'dealership':
                title = 'Dealership';
                props = {
                    title: target.name,
                    subTitle: target.dealer.full_name,
                    hideIcon: true,
                    onClick: onDealershipClick
                }
                break;

                case 'product':
                title = 'Product';
                props = {
                    title: target.name,
                    subTitle: `Added on ${moment(target.date).format('MMMM Do, YYYY')}`,
                    hideIcon: true,
                    onClick: onProductClick
                }
                break;

                case 'sector':
                title = Sector.typeToText(target.type);
                props = {
                    title: target.name,
                    subTitle: target.director ? target.director.full_name : 'Director not available',
                    hideIcon: true,
                    onClick: onSectorClick
                }
                break;

                case 'user':
                title = User.levels.toText(target.level);
                props = {
                    title: target.full_name,
                    subTitle: target.phone_number,
                    hideIcon: true,
                    onClick: onUserClick
                }
                break;

                default:
                return null;
            }

        } catch(e) {
            console.log(e.message);
            return null;
        }

        return (
            <LayerItem title={title}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {Views.entry({
                        ...props,
                        bottomBorder: false,
                        bottomBorder: false,
                    })}
                </div>
            </LayerItem>
        );
    }

    const getUser = () => {
        let { user } = abstract.object;
        if(!user) {
            return null;
        }

        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 20
            }}>
                {Views.entry({
                    title: user.full_name,
                    subTitle: user.email_address,
                    badge: {
                        text: Utils.formatDate(abstract.object.date),
                        color: Appearance.colors.primary()
                    },
                    icon: {
                        path: user.avatar
                    },
                    bottomBorder: false,
                    bottomBorder: false,
                    onClick: onCreatorClick
                })}
            </div>
        )
    }

    const fetchTarget = async () => {
        try {
            switch(abstract.object.target.type) {
                case 'card':
                let card = await Card.get(utils, abstract.object.target.object.id);
                setTarget(card);
                break;

                case 'dealership':
                let dealership = await Dealership.get(utils, abstract.object.target.object.id);
                setTarget(dealership);
                break;

                case 'product':
                let product = await Product.get(utils, abstract.object.target.object.id);
                setTarget(product);
                break;

                case 'sector':
                let sector = await Sector.get(utils, abstract.object.target.object.id, abstract.object.target.sub_type);
                setTarget(sector);
                break;

                case 'user':
                let user = await User.get(utils, abstract.object.target.object.user_id);
                setTarget(user);
                break;
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving some of the information for this system event. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for ${abstract.getTitle()}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            sizing: 'medium'
        }}>
            {getUser()}
            {getTarget()}
            {getContentDiff()}
        </Layer>
    )
}

export const Users = ({ level, panelID, shortName, title }, { index, options, utils }) => {

    const limit = 10;
    const offset = useRef(0);
    const showInactive = useRef(false);
    const sorting = useRef({ sort_key: 'full_name', sort_type: Content.sorting.type.descending });

    const [loading, setLoading] = useState(null);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [users, setUsers] = useState([]);

    const onEnableTraineeSignupLinks = async () => {
        try {

            // set loading flag and send request to server
            setLoading(true);
            await Request.post(utils, '/dealerships/', {
                status: true,
                type: 'set_trainee_signup_link_status'
            });

            // copy text to clipboard
            Utils.copyText(utils.dealership.get().preferences.trainee_signup_link.url);

            // show notification that copy request was successful
            utils.notification.show({
                title: 'Link Copied',
                message: 'YTrainee signup links have been enabled for your dealership and a link has been copied to your clipboard.'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue enabling trainee signup links. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onGenerateTraineeURL = async () => {
        try {

            // prevent moving forward if trainee signup links are not enabled
            let dealership = utils.dealership.get();
            if(dealership.preferences.trainee_signup_link.enabled === false) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'It looks like you have not enabled trainee signup links for your dealership. Would you like to enable those links now?',
                    buttons: [{
                        key: 'confirm',
                        title: 'Yes',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            onEnableTraineeSignupLinks();
                            return;
                        }
                    }
                });
                return;
            }

            // copy text to clipboard
            Utils.copyText(dealership.preferences.trainee_signup_link.url);

            // show notification that copy request was successful
            utils.notification.show({
                title: 'Link Copied',
                message: 'Your trainee signup link has been copied to your clipboard.'
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue copying your link. ${(typeof(e) === 'string' ? e : e.message) || 'An unknown error occurred'}`
            });
        }
    }

    const onNewUser = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: User.new(),
                type: 'user'
            }),
            Component: AddEditUser.bind(this, {
                isNewTarget: true,
                level: level
            }),
            id: 'new_user'
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'url',
                style: 'deafult',
                title: 'Share Trainee Signup Link'
            }],
            position: 'bottom',
            target: evt.target
        }, key => {
            if(key === 'url') {
                onGenerateTraineeURL();
                return;
            }
        });
    }

    const onPrintUsers = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { users } = await Request.get(utils, '/users/', {
                    levels: [ level ],
                    restrict_to_dealership: true,
                    type: 'all',
                    ...sorting.current,
                    ...props
                });

                setLoading(false);
                resolve(users.map(user => User.create(user)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        });
    }

    const onUpdateUser = data => {
        try {
            setUsers(users => {
                return users.map(user => {
                    return user.user_id == data.user.user_id ? User.create(data.user) : user;
                })
            })
        } catch(e) {
            console.log(e.message);
        }
    }

    const onUserClick = user => {
        utils.layer.open({
            abstract: Abstract.create({
                object: user,
                type: 'user'
            }),
            Component: UserDetails,
            id: `user_details_${user.user_id}`
        });
    }

    const getButtons = () => {

        let buttons = [];

        // add buttons to create new user for account type if applicable
        let { admin, dealer, division_director, region_director, trainee, vested_director } = User.levels.get();
        if(utils.user.get().level <= User.levels.get().admin || [admin, dealer, division_director, region_director, vested_director].includes(level) === false) {
            buttons.push({
                key: 'new',
                onClick: onNewUser,
                style: 'default',
                title: `New ${shortName}`
            });
        }

        // add button to show or hide inactive users
        buttons.push({
            key: 'active',
            onClick: () => {
                showInactive.current = !showInactive.current;
                fetchUsers();
            },
            style: showInactive.current ? 'default' : 'grey',
            title: `${showInactive.current ? 'Hide' : 'Show'} Inactive`
        });

        // add options button for trainees
        if(level === trainee) {
            buttons.push({
                key: 'options',
                onClick: onOptionsClick,
                style: 'grey',
                title: 'Options'
            });
        }

        return buttons
    }

    const getContent = () => {
        if(users.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: `No ${title} were found in the system`,
                    title: `No ${title} Found`
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {users.map((user, index) => {
                        return getFields(user, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (user, index) => {

        let target = user || {};
        let fields = [{
            key: 'full_name',
            title: 'Name',
            icon: target.avatar,
            value: utils.groups.apply([ 'first_name', 'last_name' ], User.Group.categories.users, target.full_name)
        },{
            key: 'email_address',
            sortable: false,
            title: 'Email',
            value: utils.groups.apply('email_address', User.Group.categories.users, target.email_address)
        },{
            key: 'phone_number',
            sortable: false,
            title: 'Phone',
            value: utils.groups.apply('phone_number', User.Group.categories.users, target.phone_number)
        }];

        if(level !== User.levels.get().customer) {
            fields = fields.concat([{
                key: 'last_login',
                title: 'Last Login',
                value: utils.groups.apply('last_login', User.Group.categories.users, target.last_login ? moment(target.last_login).format('MM/DD/YYYY [at] h:mma') : 'No Logins Found')
            }])
        }

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!user) {
            return (
                <TableListHeader
                fields={fields}
                onChange={props => {
                    sorting.current = props;
                    fetchUsers();
                }}
                value={sorting.current}/>
            )
        }

        // loop through result rows
        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === users.length - 1}
            onClick={onUserClick.bind(this, user)} />
        )
    }

    const getPrintProps = () => {

        let headers = [{
            key: 'full_name',
            title: 'Name'
        },{
            key: 'email_address',
            title: 'Email'
        },{
            key: 'phone_number',
            title: 'Phone'
        }];
        if(level !== User.levels.get().customer) {
            headers = headers.concat([{
                key: 'last_login',
                title: 'Last Login'
            }])
        }

        return {
            headers: headers,
            onFetch: onPrintUsers,
            onRenderItem: (item, index, items) => ({
                full_name: item.full_name,
                email_address: item.email_address,
                phone_number: item.phone_number,
                last_login: item.last_login ? moment(item.last_login).format('MM/DD/YYYY [at] h:mma') : 'No Logins Found'
            })
        }
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('aft', 'users', 'on_new_user', fetchUsers);
            await utils.sockets.on('aft', 'users', 'on_update_user', onUpdateUser);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('aft', 'users', 'on_new_user', fetchUsers);
            await utils.sockets.off('aft', 'users', 'on_update_user', onUpdateUser);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchUsers = async () => {
        try {
            setLoading(true);
            let { users, paging } = await Request.get(utils, '/users/', {
                levels: [level],
                limit: limit,
                offset: offset.current,
                restrict_to_dealership: true,
                search_text: searchText,
                show_inactive: showInactive.current,
                type: 'all',
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setUsers(users.map(user => User.create(user)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the users list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchUsers();
    }, [searchText]);

    useEffect(() => {
        connectToSockets();
        utils.events.on(panelID, 'dealership_change', fetchUsers);
        utils.content.subscribe(panelID, 'user', {
            onFetch: fetchUsers,
            onUpdate: abstract => {
                setUsers(users => {
                    return users.map(user => {
                        return user.user_id === abstract.getID() ? abstract.object : user
                    });
                });
            }
        });
        return () => {
            disconnectFromSockets();
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchUsers);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={title}
        index={index}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading,
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchUsers();
                }
            },
            print: getPrintProps(),
            search: {
                placeholder: 'Search by first or last name...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const UserAvailability = ({ abstract, index, options, utils }) => {

    const layerID = `user_availability_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [activeDate, setActiveDate] = useState(moment());
    const [targetDate, setTargetDate] = useState(moment());
    const [events, setEvents] = useState([]);
    const [eventBlocks, setEventBlocks] = useState([]);

    const fetchEvents = async () => {
        try {
             let { blocks, events } = await Request.get(utils, '/users/', {
                 type: 'availability',
                 user_id: abstract.getID(),
                 blocks: eventBlocks.length === 0,
                 start_date: moment(targetDate).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                 end_date: moment(targetDate).endOf('month').format('YYYY-MM-DD HH:mm:ss')
             });

             if(blocks) {
                 setEventBlocks(blocks.map(block => {
                     return {
                         start_date: moment(block.start_date),
                         end_date: moment(block.end_date)
                     }
                 }));
             }
             setEvents(events.map(calEvent => {
                 return {
                     id: calEvent.id,
                     start_date: moment(calEvent.start_date),
                     end_date: moment(calEvent.end_date)
                 }
             }));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the events for ${activeDate.format('MMMM Do, YYYY')}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNewEvent = () => {
        utils.datePicker.showDual({
            title: 'New Availability Timeslot',
            dateTime: true,
            startDate: moment(activeDate).startOf('day').add(8, 'hours'),
            endDate: moment(activeDate).startOf('day').add(10, 'hours'),
            onDateChange: ({ start, end }) => {
                if(!start || !end) {
                    return;
                }
                onAddNewEvent(start, end);
            }
        })
    }

    const onEditEvent = item => {
        utils.datePicker.showDual({
            title: 'Edit Timeslot',
            dateTime: true,
            startDate: moment(item.start_date),
            endDate: moment(item.end_date),
            onDateChange: ({ start, end }) => {
                if(!start || !end) {
                    return;
                }
                onUpdateEvent(item, start, end);
            }
        })
    }

    const onAddNewEvent = async (start, end) => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let { id } = await Request.post(utils, '/users/', {
                type: 'new_availability',
                user_id: abstract.getID(),
                start_date: start.format('YYYY-MM-DD HH:mm:ss'),
                end_date: end.format('YYYY-MM-DD HH:mm:ss')
            })

            setEvents(events => update(events, {
                $push: [{
                    id: id,
                    start_date: moment(start),
                    end_date: moment(end)
                }]
            }))
            setEventBlocks(blocks => update(blocks, {
                $push: [{
                    id: id,
                    start_date: moment(start),
                    end_date: moment(end)
                }]
            }))

            setLoading(false);
            utils.content.fetch('availability');
            utils.alert.show({
                title: 'All Done!',
                message: `Your timeslot for ${start.format('h:mma')} to ${end.format('h:mma')} on ${start.format('MMMM Do, YYYY')} has been added`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding your new timeslot for ${activeDate.format('MMMM Do, YYYY')}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateEvent = async (item, start, end) => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let { id } = await Request.post(utils, '/users/', {
                type: 'update_availability',
                id: item.id,
                user_id: abstract.getID(),
                start_date: start.format('YYYY-MM-DD HH:mm:ss'),
                end_date: end.format('YYYY-MM-DD HH:mm:ss')
            })

            setEvents(events => {
                return events.map(calEvent => {
                    if(calEvent.id === item.id) {
                        calEvent.start_date = start;
                        calEvent.end_date = end;
                    }
                    return calEvent
                })
            })
            setEventBlocks(blocks => {
                return events.map(calEvent => {
                    if(calEvent.id === item.id) {
                        calEvent.start_date = start;
                        calEvent.end_date = end;
                    }
                    return calEvent
                })
            })

            setLoading(false);
            utils.content.fetch('availability');
            utils.alert.show({
                title: 'All Done!',
                message: `Your timeslot has been updated`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating your timeslot. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEventClick = (calEvent, evt) => {
        utils.sheet.show({
            items: [{
                key: 'edit',
                title: 'Edit',
                style: 'default'
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            if(key === 'edit') {
                onEditEvent(calEvent);
                return;
            }
            if(key === 'delete') {
                onDeleteEvent(calEvent);
            }
        })
    }

    const onDeleteEvent = calEvent => {
        utils.alert.show({
            title: 'Delete Availability',
            message: 'Are you sure that you want to delete this timeslot? This can not be undone',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteEventConfirm(calEvent);
                }
            }
        })
    }

    const onDeleteEventConfirm = async calEvent => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            await Request.post(utils, '/users/', {
                type: 'delete_availability',
                id: calEvent.id
            });

            setLoading(false);
            utils.content.fetch('availability');

            setEvents(events => {
                return events.filter(prevEvent => {
                    return prevEvent.id !== calEvent.id
                })
            })
            setEventBlocks(blocks => {
                return blocks.filter(prevEvent => {
                    return prevEvent.id !== calEvent.id
                })
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this timeslot. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getEvents = () => {
        let filtered = events.filter(calEvent => {
            return activeDate.isSame(calEvent.start_date, 'day');
        });
        if(filtered.length === 0) {
            return (
                Views.entry({
                    title: 'No Availability Set',
                    subTitle: `Click to add new availability`,
                    icon: {
                        path: 'images/event-icon-grey.png',
                        imageStyle: {
                            borderRadius: 6,
                            height: 32,
                            backgroundColor: Appearance.colors.transparent
                        }
                    },
                    bottomBorder: false,
                    onClick: onNewEvent
                })
            )
        }

        return (
            <>
            {filtered.map((calEvent, index, events) => {
                return (
                    Views.entry({
                        key: index,
                        title: 'Available',
                        subTitle: `${calEvent.start_date.format('h:mma')} to ${calEvent.end_date.format('h:mma')}`,
                        icon: {
                            path: 'images/event-icon-blue.png',
                            imageStyle: {
                                borderRadius: 6,
                                height: 32,
                                backgroundColor: Appearance.colors.transparent
                            }
                        },
                        singleItem: false,
                        firstItem: index === 0,
                        lastItem: false,
                        bottomBorder: true,
                        onClick: onEventClick.bind(this, calEvent)
                    })
                )
            })}
            {Views.entry({
                title: 'Add New Timeslot',
                subTitle: `Click to add new availability`,
                icon: {
                    path: 'images/event-icon-grey.png',
                    imageStyle: {
                        borderRadius: 6,
                        height: 32,
                        backgroundColor: Appearance.colors.transparent
                    }
                },
                lastItem: true,
                onClick: onNewEvent
            })}
            </>
        )
    }

    useEffect(() => {
        fetchEvents();
    }, [targetDate]);

    return (
        <Layer
        id={layerID}
        title={`Availability for ${abstract.getTitle()}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'small',
            layerState: layerState
        }}
        buttons={[{
            key: 'new',
            text: 'New Availability',
            color: 'primary',
            onClick: onNewEvent
        }]}>
            <div style={{
                textAlign: 'center'
            }}>
                <span style={{
                    ...Appearance.textStyles.title(),
                    fontSize: 18,
                    fontWeight: 700,
                    width: '100%'
                }}>{targetDate.format('MMMM YYYY')}</span>
                <Calendar
                utils={utils}
                activeDate={targetDate}
                events={events}
                weekStyle={{
                    marginBottom: 12
                }}
                containerStyle={{
                    marginTop: 0,
                    marginBottom: 0
                }}
                eventFilter={date => {
                    return eventBlocks.find(block => {
                        return date.isSame(block.start_date, 'day') || date.isSame(block.end_date, 'day');
                    })
                }}
                onDateChange={date => {
                    setActiveDate(date);
                    if(date.isSame(targetDate, 'month')) {
                        return;
                    }
                    setTargetDate(moment(date).startOf('month'));
                }} />
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8,
                    textAlign: 'left'
                }}>
                    {getEvents()}
                </div>
            </div>
        </Layer>
    )
}

export const UserDetails = ({ abstract, index, options, utils }) => {

    const layerID = `user_details_${abstract.getID()}`;
    const eventsLimit = 5;

    const [devices, setDevices] = useState([]);
    const [dealership, setDealership] = useState(null);
    const [downline, setDownline] = useState([]);
    const [downlineProps, setDownlineProps] = useState({});
    const [endDate, setEndDate] = useState(Utils.ytd.end_date());
    const [events, setEvents] = useState([]);
    const [eventsOffset, setEventsOffset] = useState(0);
    const [eventsPaging, setEventsPaging] = useState(null);
    const [genealogy, setGenealogy] = useState(null);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(abstract.object.location);
    const [recruiting, setRecruiting] = useState(null);
    const [reporting, setReporting] = useState(null);
    const [startDate, setStartDate] = useState(Utils.ytd.start_date());

    const onAvatarClick = evt => {
        if(!abstract.object.avatar || abstract.object.avatar.includes('placeholder')) {
            onChooseAvatar();
            return;
        }
        utils.sheet.show({
            items: [{
                key: 'view',
                title: 'View',
                style: 'default'
            },{
                key: 'replace',
                title: 'Replace',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'view') {
                window.open(abstract.object.avatar);
                return;
            }
            if(key === 'replace') {
                onChooseAvatar();
                return;
            }
        })
    }

    const canChangeActiveStatus = () => {
        let user = utils.user.get();
        if(user.level <= User.levels.get().admin) {
            return true;
        }
        switch(user.level) {
            case User.levels.get().area_director:
            return user.area === abstract.object.area || user.dealership_id === abstract.object.dealership_id;

            case User.levels.get().division_director:
            return user.division === abstract.object.division || user.dealership_id === abstract.object.dealership_id;

            case User.levels.get().region_director:
            return user.region === abstract.object.region || user.dealership_id === abstract.object.dealership_id;

            case User.levels.get().dealer:
            return user.dealership_id === abstract.object.dealership_id;

            default:
            return false;
        }
    }

    const canChangeGDLActiveStatus = () => {

        // declare current dealership and active user
        let dealership = utils.dealership.get();
        let user = utils.user.get();

        // only admins are able to change gdl active status if the dealership does not have gdl enabled
        if(dealership.gdl_active === false) {
            return user.level <= User.levels.get().admin;
        }

        // allow dealers and directors to change the gdl status for their own dealerships
        // allow administrators to change the value for any dealership
        return user.level <= User.levels.get().admin || (dealership.id === user.dealership.id && user.level <= User.levels.get().dealer);
    }

    const onCardClick = async () => {
        try {
            let card = await Card.get(utils, recruiting.card.id);
            utils.layer.open({
                id: `card_details_${card.id}`,
                abstract: Abstract.create({
                    type: 'card',
                    object: card
                }),
                Component: CardDetails
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this protection. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onChangeActiveStatus = () => {
        utils.alert.show({
            title: `Change to ${abstract.object.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set ${abstract.object.full_name} as ${abstract.object.active ? 'inactive' : 'active'}?`,
            buttons: [{
                key: 'confirm',
                title: `Set as ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Do Not Change',
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onChangeActiveStatusConfirm();
                }
            }
        });
    }

    const onChangeActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !abstract.object.active;
            await Request.post(utils, '/users/', {
                active: next,
                type: 'set_active_status',
                user_id: abstract.getID()
            });

            abstract.object.active = next;
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This account has been set as ${next ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the active status for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onChangeGDLActiveStatus = () => {
        utils.alert.show({
            title: `${abstract.object.gdl_active ? 'Deactivate' : 'Activate'} Global Data Access`,
            message: `Are you sure that you want to ${abstract.object.gdl_active ? 'deactivate' : 'activate'} Global Data access for ${abstract.object.full_name}?`,
            buttons: [{
                key: 'confirm',
                title: abstract.object.gdl_active ? 'Deactivate' : 'Activate',
                style: abstract.object.gdl_active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onChangeGDLActiveStatusConfirm();
                }
            }
        });
    }

    const onChangeGDLActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !abstract.object.gdl_active
            await Request.post(utils, '/users/', {
                type: 'set_gdl_active_status',
                active: next,
                user_id: abstract.getID()
            });

            abstract.object.gdl_active = next;
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This account's Global Data access has been ${next ? 'activated' : 'deactivated'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the Global Data access for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onChooseAvatar = () => {
        let file = null;
        utils.alert.show({
            title: `${!abstract.object.avatar || abstract.object.avatar.includes('placeholder') ? 'Choose' : 'Replace'} Profile Photo`,
            message: 'Click the browse button to choose a file from your computer.',
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <ImagePickerField
                    utils={utils}
                    onChange={result => file = result} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(file && key === 'confirm') {
                    onUpdateAvatar(file);
                    return;
                }
            }
        })
    }

    const onCreateManualPassword = () => {
        utils.layer.open({
            id: `new_account_password_${abstract.getID()}`,
            abstract: abstract,
            Component: NewAccountPassword
        });
    }

    const onDealershipClick = async () => {
        utils.layer.open({
            id: `dealership_details_${dealership.id}`,
            abstract: Abstract.create({
                type: 'dealership',
                object: dealership
            }),
            Component: DealershipDetails
        });
    }

    const onDeviceClick = (device, evt) => {
        utils.sheet.show({
            items: [{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            if(key === 'remove') {
                onRemoveDevice(device);
                return;
            }
        });
    }

    const onEditClick = () => {
        utils.layer.open({
            abstract: abstract,
            Component: AddEditUser.bind(this, {
                isNewTarget: false
            }),
            id: `edit_user_${abstract.getID()}`
        });
    }
    
    const onLoginAsUser = async () => {
        try {
            setLoading(true);
            let { token } = await Request.get(utils, '/users/', {
                type: 'new_single_use_login_token',
                user_id: abstract.getID()
            });

            console.log(`${API.server}/?route=admin_login_as_user&token=${encodeURIComponent(token)}`);
            setLoading(false);
            window.open(`${API.server}/?route=admin_login_as_user&token=${encodeURIComponent(token)}`);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the lgoin credentials for this user account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onMergeAccounts = () => {
        let user = null;
        utils.alert.show({
            title: 'Merge Accounts',
            message: `Search for an account below to merge with ${abstract.object.first_name}'s account. We will merge all the protections and recruits found for the account you select with ${abstract.object.full_name}'s account. This can not be undone.`,
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <UserLookupField
                    utils={utils}
                    restrictToDealership={true}
                    dealership={abstract.object.dealership}
                    onChange={result => user = result} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Confirm',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(user && key === 'confirm') {
                    onMergeAccountsConfirm(user);
                    return;
                }
            }
        });
    }

    const onMergeAccountsConfirm = async user => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { cards, recruits } = await Request.post(utils, '/users/', {
                type: 'merge_accounts',
                source_user_id: abstract.getID(),
                target_user_id: user.user_id
            });

            setLoading(true);
            utils.alert.show({
                title: 'All Done!',
                message: `We have merged ${cards} ${cards === 1 ? 'protection' : 'protections'} and ${recruits} ${recruits === 1 ? 'recruit' : 'recruits'}. All information is now under the account for ${abstract.object.full_name} (User ID: ${abstract.getID()})`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue merging these accounts. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onOptionsClick = evt => {

        // prevent showing an empty sheet if user can not make changes to either active status
        if(!canChangeActiveStatus() && !canChangeGDLActiveStatus()) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Your account is not able to make adjustments to this user account`
            });
            return;
        }

        // determine if account is a trainee account
        if(abstract.object.level === User.levels.get().trainee) {
            utils.sheet.show({
                items: [{
                    key: 'active',
                    title: `Change to ${abstract.object.active ? 'Inactive' : 'Active'}`,
                    style: abstract.object.active ? 'destructive' : 'default',
                    visible: canChangeActiveStatus()
                },{
                    key: 'promote_to_advisor',
                    title: 'Promote to Safety Advisor',
                    style: 'default',
                },{
                    key: 'reset_password',
                    title: 'Reset Password',
                    style: 'default',
                    visible: abstract.object.level > User.levels.get().admin && utils.user.get().level <= User.levels.get().admin
                }],
                target: evt.target
            }, key => {
                if(key === 'active') {
                    onChangeActiveStatus();
                    return;
                }
                if(key === 'promote_to_advisor') {
                    onPromoteToSafetyAdvisor();
                    return;
                }
                if(key === 'reset_password') {
                    onSelectResetPasswordChannel();
                    return;
                }
            });
            return;
        }

        // present options to change active and gdl_active status
        utils.sheet.show({
            items: [{
                key: 'active',
                title: `Change to ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default',
                visible: canChangeActiveStatus()
            },{
                key: 'gdl_active',
                title: `${abstract.object.gdl_active ? 'Deactivate' : 'Activate'} Global Data Access`,
                style: abstract.object.gdl_active ? 'destructive' : 'default',
                visible: canChangeGDLActiveStatus()
            },{
                key: 'login',
                title: 'Login as User',
                style: 'default',
                visible: abstract.object.level > User.levels.get().admin && utils.user.get().level <= User.levels.get().admin
            },{
                key: 'merge',
                title: 'Merge Accounts',
                style: 'default',
                visible: abstract.object.level > User.levels.get().admin && utils.user.get().level <= User.levels.get().admin
            },{
                key: 'promote_to_dealer',
                title: 'Promote to Dealer',
                style: 'default',
                visible: abstract.object.level === User.levels.get().safety_advisor && utils.user.get().level <= User.levels.get().admin 
            },{
                key: 'reset_password',
                title: 'Reset Password',
                style: 'default',
                visible: abstract.object.level > User.levels.get().admin && utils.user.get().level <= User.levels.get().admin
            }],
            target: evt.target
        }, key => {
            if(key === 'active') {
                onChangeActiveStatus();
                return;
            }
            if(key === 'gdl_active') {
                onChangeGDLActiveStatus();
                return;
            }
            if(key === 'login') {
                onLoginAsUser();
                return;
            }
            if(key === 'merge') {
                onMergeAccounts();
                return;
            }
            if(key === 'promote_to_dealer') {
                onPromoteToDealer();
                return;
            }
            if(key === 'reset_password') {
                onSelectResetPasswordChannel();
                return;
            }
        });
    }

    const onPromoteToDealer = () => {
        utils.alert.show({
            title: 'Promote to Dealer',
            message: `Let's create a new dealership for ${abstract.object.full_name} and update ${abstract.object.first_name}'s account to a dealer account.`,
            buttons: [{
                key: 'confirm',
                title: 'Continue',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    let dealership = Dealership.new();
                    dealership.dealer = abstract.object;
                    utils.layer.open({
                        abstract: Abstract.create({
                            object: dealership,
                            type: 'dealership'
                        }),
                        Component: AddEditDealership.bind(this, {
                            isNewTarget: true,
                            onAddDealership: onPromoteToDealerConfirm,
                            showNewTargetConfirmation: false
                        }),
                        id: 'new_dealership'
                    })
                    return;
                }
            }
        });
    }

    const onPromoteToDealerConfirm = dealership => {

        // update user target
        abstract.object.dealership = dealership;
        abstract.object.level = User.levels.get().dealer;
        utils.content.update(abstract);

        // notify listeners of new content
        utils.content.fetch('dealership');
        utils.content.fetch('user');

        // show confirmation alert
        utils.alert.show({
            title: 'All Done!',
            message: `The "${dealership.name}" dealership has been created and ${abstract.object.full_name} has been assigned as the dealer`
        });
    }

    const onPromoteToSafetyAdvisor = () => {
        utils.alert.show({
            title: 'Promote to Safety Advisor',
            message: 'Are you sure that you want to promote this trainee to a safety advisor? This will give the trainee access to AFT and other dealership resources.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onPromoteToSafetyAdvisorConfirm();
                    return;
                }
            }
        });
    }

    const onPromoteToSafetyAdvisorConfirm = async () => {
        try {

            // open edits and account type
            await abstract.object.open();
            await abstract.object.set({ level: User.levels.get().safety_advisor });

            // submit changes to server
            setLoading('options');
            await abstract.object.update(utils);

            // show confirmation alert 
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The account for ${abstract.object.full_name} has been updated to a safety advisor account.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `There was an issue updating the account for ${abstract.object.full_name}. ${e.message || 'An unknown error occurred'}.`
            });
        }
    }

    const onRemoveDevice = device => {
        utils.alert.show({
            title: 'Remove Device',
            message: `Are you sure that you want to remove "${device.name}" from ${abstract.object.full_name}'s account?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveDeviceConfirm(device);
                    return;
                }
            }
        })
    }

    const onRemoveDeviceConfirm = async device => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            await Request.post(utils, '/users/', {
                type: 'remove_device',
                id: device.id,
                user_id: abstract.getID()
            });
            setLoading(false);
            setDevices(devices => {
                return devices.filter(prevDevice => {
                    return prevDevice.id !== device.id;
                })
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing the device from this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSectorClick = async (id, type) => {
        try {
            let sector = await Sector.get(utils, id, type)
            utils.layer.open({
                id: `sector_details_${id}`,
                abstract: Abstract.create({
                    type: 'sector',
                    object: sector
                }),
                Component: SectorDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this sector. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSendPasswordVerification = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/users/', {
                type: 'reset_password_verification_admin',
                user_id: abstract.getID()
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `We have sent a password verification text message to ${abstract.object.phone_number}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending the password verification text message. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSelectResetPasswordChannel = () => {
        utils.alert.show({
            title: 'Reset Password',
            message: `Would you like to send a password reset verification text message to ${abstract.object.full_name} or would you like to manually create a password for the account?`,
            buttons: [{
                key: 'sns',
                title: 'Send Text Message',
                style: 'default'
            },{
                key: 'manual',
                title: 'Manually Create a Password',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'sns') {
                    onSendPasswordVerification();
                    return;
                }
                if(key === 'manual') {
                    onCreateManualPassword();
                    return;
                }
            }
        })
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system_event_details_${evt.id}`,
            abstract: Abstract.create({
                type: 'system_event',
                object: evt
            }),
            Component: SystemEventDetails
        })
    }

    const onUpdateAvatar = async file => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { url } = await Request.post(utils, '/users/', {
                type: 'update_avatar',
                user_id: abstract.getID(),
                image_data: file
            });

            setLoading(false);
            abstract.object.avatar = url;
            utils.content.update(abstract);
            utils.alert.show({
                title: 'All Done!',
                message: `The profile photo for ${abstract.object.full_name} has been updated`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the profile photo for ${abstract.object.full_name}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateSystemEvent = data => {
        try {
            setEvents(events => events.map(evt => {
                return evt.id === data.evt.id ? SystemEvent.create(data.evt) : evt;
            }))

        } catch(e) {
            console.log(e.message);
        }
    }

    const onUserClick = async userID => {
        try {
            let user = await User.get(utils, userID);
            utils.layer.open({
                id: `user_details_${userID}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: user
                }),
                Component: UserDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            text: 'Edit'
        }];
    }

    const getDevices = () => {
        if(devices.length === 0) {
            return null;
        }
        return (
            <LayerItem 
            collapsed={false}
            title={'Devices'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                }}>
                    {devices.map((device, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: device.name || 'Name not available',
                                subTitle: `Last Used: ${Utils.formatDate(device.date)}`,
                                badge: {
                                    text: device.token ? 'Notifications Enabled' : null,
                                    color: Appearance.colors.primary()
                                },
                                icon: {
                                    path: device.os === 1 ? 'images/apple-icon-grey.png' : 'images/google-icon-grey.png'
                                },
                                firstItem: index === 0,
                                singleItem: devices.length === 1,
                                lastItem: index === devices.length - 1,
                                bottomBorder: index !== devices.length - 1,
                                onClick: onDeviceClick.bind(this, device)
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getDownlines = () => {
        if(!downline || !downline.recruits || downline.recruits.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Downline'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {getDownlineRecursive(downline.recruits)}
                </div>
            </LayerItem>
        )
    }

    const getDownlineRecursive = (users, index = 0) => {
        return users.map(entry => {
            return (
                <div key={entry.user_id}>
                    <div
                    className={`view-entry ${window.theme}`}
                    onClick={onUserClick.bind(this, entry.user_id)}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        borderBottom: `1px solid ${Appearance.colors.divider()}`,
                        paddingRight: 12,
                        position: 'relative'
                    }}>
                        <div style={{
                            flexGrow: 1,
                            marginLeft: index * 32
                        }}>
                            {Views.entry({
                                title: `${entry.first_name} ${entry.last_name}`,
                                subTitle: entry.recruit_date ? `Recruited ${moment(entry.recruit_date).format('MMMM Do, YYYY')}` : 'No sales have been submitted',
                                icon: {
                                    path: entry.avatar
                                },
                                bottomBorder: false
                            })}
                        </div>
                        {entry.recruits && entry.recruits.length > 0 && (
                            <CollapseArrow
                            collapsed={downlineProps[entry.user_id] === false ? false : true}
                            onClick={(val, evt) => {
                                evt.stopPropagation();
                                setDownlineProps(props => update(props, {
                                    [entry.user_id]: {
                                        $set: val
                                    }
                                }))
                            }} />
                        )}
                    </div>
                    {entry.recruits && entry.recruits.length > 0 && downlineProps[entry.user_id] === false && (
                        getDownlineRecursive(entry.recruits, index + 1)
                    )}
                </div>
            )
        })
    }

    const getFields = () => {

        // determine if account details are needed for an exigent account
        let user = abstract.object;
        if(isExigentUser() === true) {
            return [{
                key: 'details',
                title: 'Details',
                items: [{
                    key: 'level',
                    title: 'Account Type',
                    value: User.levels.toText(user.level)
                },{
                    key: 'name',
                    title: 'First and Last Name',
                    value: user.full_name
                },{
                    key: 'last_login',
                    title: 'Last Login',
                    value: user.last_login ? Utils.formatDate(user.last_login) : null
                },{
                    key: 'avatar',
                    title: 'Profile Photo',
                    value: 'Click for options',
                    onClick: onAvatarClick,
                    style: {
                        container: {
                            padding: '5px 12px 5px 12px'
                        }
                    },
                    prepend: (
                        <img
                        src={abstract.object.avatar}
                        style={{
                            width: 25,
                            height: 25,
                            minWidth: 25,
                            minHeight: 25,
                            borderRadius: 12.5,
                            overflow: 'hidden',
                            objectFit: 'cover',
                            marginRight: 8,
                            border: `1px solid ${Appearance.colors.divider()}`
                        }} />
                    )
                },{
                    color: user.active ? Appearance.colors.green : Appearance.colors.red,
                    key: 'active',
                    title: 'Status',
                    value: user.active ? 'Active' : 'Inactive'
                },{
                    key: 'username',
                    title: 'Username',
                    value: user.username
                },{
                    key: 'user_id',
                    title: 'User ID',
                    value: user.user_id
                }]
            },{
                key: 'contact',
                title: 'Contact Information',
                items: [{
                    key: 'email',
                    title: 'Email Address',
                    value: user.email_address
                },{
                    key: 'phone',
                    title: 'Phone Number',
                    value: user.phone_number
                }]
            },{
                key: 'location',
                title: 'Location',
                items: [{
                    key: 'location',
                    title: 'Location',
                    component: 'map',
                    visible:  user.address && location ? true : false,
                    value: location
                },{
                    key: 'address',
                    title: 'Physical Address',
                    value: user.address ? Utils.formatAddress(user.address) : null
                },{
                    key: 'timezone',
                    title: 'Timezone',
                    value: user.timezone
                }]
            }]
        }

        // fallback to rendering fields for all other account types
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'verified',
                title: 'Account Information Verified',
                value: user.verified ? Utils.formatDate(user.verified) : null,
                visible: user.verified ? true : false
            },{
                key: 'level',
                title: 'Account Type',
                value: User.levels.toText(user.level)
            },{
                key: 'dealership',
                title: 'Dealership',
                value: dealership ? dealership.name : null,
                ...dealership && {
                    onClick: onDealershipClick
                }
            },{
                key: 'name',
                title: 'First and Last Name',
                value: user.full_name
            },{
                key: 'gdl_active',
                title: 'Global Data Access',
                value: user.gdl_active ? 'Yes' : 'No',
                visible: user.level > User.levels.get().exigent_admin
            },{
                key: 'last_login',
                title: 'Last Login',
                value: user.last_login ? Utils.formatDate(user.last_login) : null
            },{
                key: 'avatar',
                title: 'Profile Photo',
                value: 'Click for options',
                onClick: onAvatarClick,
                style: {
                    container: {
                        padding: '5px 12px 5px 12px'
                    }
                },
                prepend: (
                    <img
                    src={abstract.object.avatar}
                    style={{
                        width: 25,
                        height: 25,
                        minWidth: 25,
                        minHeight: 25,
                        borderRadius: 12.5,
                        overflow: 'hidden',
                        objectFit: 'cover',
                        marginRight: 8,
                        border: `1px solid ${Appearance.colors.divider()}`
                    }} />
                )
            },{
                color: user.active ? Appearance.colors.green : Appearance.colors.red,
                key: 'active',
                title: 'Status',
                value: user.active ? 'Active' : 'Inactive'
            },{
                key: 'username',
                title: 'Username',
                value: user.username
            },{
                key: 'user_id',
                title: 'User ID',
                value: user.user_id
            }]
        },{
            key: 'contact',
            title: 'Contact Information',
            items: [{
                key: 'email',
                title: 'Email Address',
                value: user.email_address
            },{
                key: 'phone',
                title: 'Phone Number',
                value: user.phone_number
            }]
        },{
            key: 'location',
            title: 'Location',
            items: [{
                key: 'location',
                title: 'Location',
                component: 'map',
                visible:  user.address && location ? true : false,
                value: location
            },{
                key: 'address',
                title: 'Physical Address',
                value: user.address ? Utils.formatAddress(user.address) : null
            },{
                key: 'timezone',
                title: 'Timezone',
                value: user.timezone
            }]
        },{
            key: 'genealogy',
            lastItem: false,
            title: 'Genealogy',
            visible: user.level !== User.levels.get().vested_director,
            items: [{
                key: 'region',
                title: 'Region',
                ...genealogy && genealogy.region && {
                    value: genealogy.region.name,
                    onClick: onSectorClick.bind(this, genealogy.region.id, Sector.types.region)
                }
            },{
                key: 'division',
                title: 'Division',
                ...genealogy && genealogy.division && {
                    value: genealogy.division.name,
                    onClick: onSectorClick.bind(this, genealogy.division.id, Sector.types.division)
                }
            },{
                key: 'one_to_one',
                visible: user.level > User.levels.get().admin,
                title: 'Program Status',
                value: user.one_to_one ? '1:1' : 'Hybrid'
            }]
        },{
            key: 'recruiting',
            lastItem: false,
            title: 'Recruiting',
            visible: isExigentUser() === false && isTraineeUser() === false,
            items: [{
                key: 'first_sale',
                title: 'First Sale',
                ...recruiting && recruiting.card && {
                    onClick: onCardClick,
                    value: (
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end'
                        }}>
                            <span>{recruiting.card.getCustomerNames()}</span>
                            <AltBadge 
                            content={{
                                color: Appearance.colors.green,
                                text: `${recruiting.card.total_units} Units`
                            }} 
                            style={{
                                marginLeft: 8,
                                marginRight: 0
                            }} />
                        </div>
                    )
                }
            },{
                key: 'recruited_by',
                title: 'Recruited By',
                visible: user.level !== User.levels.get().vested_director,
                ...recruiting && recruiting.recruited_by && {
                    value: recruiting.recruited_by.full_name,
                    onClick: onUserClick.bind(this, recruiting.recruited_by.user_id)
                }
            },{
                key: 'recruit_date',
                title: 'Recruit Date',
                value: recruiting && recruiting.recruit_date ? Utils.formatDate(recruiting && recruiting.recruit_date, true) : 'Not Applicable'
            },{
                key: 'recruit_notes',
                title: 'Recruit Notes',
                value: recruiting ? recruiting.notes : null
            }]
        }]
        return items;
    }

    const getReportingComponent = () => {
        
        if(!reporting || isExigentUser() === true || isTraineeUser() === true) {
            return null;
        }

        let fields = [{
            key: 'cards',
            title: 'Protections',
            color: Appearance.colors.green,
            value: reporting.cards >= 0 ? Utils.softNumberFormat(reporting.cards) : 'Not available'
        },{
            key: 'total_units',
            title: 'Total Units',
            color: Appearance.colors.primary(),
            value: reporting.total_units >= 0 ? Utils.softNumberFormat(reporting.total_units) : 'Not available'
        },{
            key: 'recruits',
            title: 'Recruits',
            color: Appearance.colors.secondary(),
            value: reporting.recruits >= 0 ? Utils.softNumberFormat(reporting.recruits) : 'Not available'
        }];
        return (
            <LayerItem title={'Performance'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: 20
                }}>
                    <div style={{
                        padding: 12,
                        borderBottom: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        <DualDatePickerField
                        utils={utils}
                        overrideAlerts={true}
                        selectedStartDate={startDate}
                        selectedEndDate={endDate}
                        onStartDateChange={date => setStartDate(date)}
                        onEndDateChange={date => setEndDate(date)}
                        style={{
                            width: '100%'
                        }}/>
                    </div>
                    {fields.map((field, index) => {
                        return (
                            Views.row({
                                index: index,
                                label: field.title,
                                bottomBorder: index !== fields.length - 1,
                                value: (
                                    <div style={{
                                        display: 'inline-block',
                                        width: 95
                                    }}>
                                        {getValueContainer(field.value, field.color)}
                                    </div>
                                )
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'System Events'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {events.filter(evt => {
                        let { values } = getSystemEventProps(evt);
                        return values ? true : false;
                    }).map((evt, index, events) => {
                        let { values } = getSystemEventProps(evt);
                        if(!values) {
                            return null;
                        }
                        return (
                            Views.entry({
                                key: index,
                                title: evt.alt_title,
                                subTitle: values.length > 0 ? `${values[0]}${values.length > 1 ? ` and ${values.length - 1} other ${values.length - 1 === 1 ? 'change' : 'changes'}` : ''}` : 'No overview available',
                                badge: getSystemEventBadges(evt),
                                icon: {
                                    path: evt.user.avatar
                                },
                                bottomBorder: index !== events.length - 1,
                                onClick: onSystemEventClick.bind(this, evt)
                            })
                        )
                    })}
                    {eventsPaging && (
                        <PageControl
                        data={eventsPaging}
                        limit={eventsLimit}
                        offset={eventsOffset}
                        onClick={next => setEventsOffset(next)} />
                    )}
                </div>
            </LayerItem>
        )
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('aft', 'system', 'on_new_event', fetchEvents);
            await utils.sockets.on('aft', 'system', 'on_new_event_batch', fetchEvents);
            await utils.sockets.on('aft', 'system', 'on_update_event', onUpdateSystemEvent);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('aft', 'system', 'on_new_event', fetchEvents);
            await utils.sockets.off('aft', 'system', 'on_new_event_batch', fetchEvents);
            await utils.sockets.off('aft', 'system', 'on_update_event', onUpdateSystemEvent);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchDetails = async () => {
        try {
            let { devices, downline, genealogy, recruiting } = await Request.get(utils, '/users/', {
                type: 'ext_details',
                user_id: abstract.getID()
            });

            setDevices(devices);
            setDownline(downline);
            setGenealogy(genealogy);
            setRecruiting({
                ...recruiting,
                ...recruiting && recruiting.card && {
                    card: Card.create(recruiting.card)
                }
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving some of the information for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchEvents = async () => {
        try {
            if(utils.fetching.get(`${layerID}_fetch_events`) === true) {
                return;
            }
            setLoading('system_events');
            utils.fetching.set(`${layerID}_fetch_events`, true);

            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: eventsLimit,
                offset: eventsOffset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            utils.fetching.set(`${layerID}_fetch_events`, false);

            setEventsPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.fetching.set(`${layerID}_fetch_events`, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchLocation = async () => {
        if(abstract.object.location || !abstract.object.address) {
            return;
        }
        try {
            let { is_new, location } = await Request.post(utils, '/users/', {
                type: 'generate_location',
                user_id: abstract.getID()
            });

            abstract.object.location = {
                latitude: location.lat,
                longitude: location.long
            }
            setLocation(abstract.object.location);

        } catch(e) {
            console.error(`There was an issue retrieving the location for this user. ${e.message || 'An unknown error occurred'}`);
        }
    }

    const fetchReporting = async () => {
        try {
            if(utils.fetching.get(`${layerID}_fetch_reporting`) === true) {
                return;
            }
            setLoading(true);
            utils.fetching.set(`${layerID}_fetch_reporting`, true);

            let response = await Request.get(utils, '/reports/', {
                type: 'user_performance',
                end_date: endDate.format('YYYY-MM-DD'),
                start_date: startDate.format('YYYY-MM-DD'),
                user_id: abstract.getID()
            });
            utils.fetching.set(`${layerID}_fetch_reporting`, false);
            setLoading(false);
            setReporting(response);

        } catch(e) {
            setLoading(false);
            utils.fetching.set(`${layerID}_fetch_reporting`, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the performance details for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchTargets = () => {
        fetchDetails();
        fetchLocation();
        setupDealership();
    }

    const isExigentUser = () => {
        return abstract.object.level === User.levels.get().exigent_admin;
    }

    const isTraineeUser = () => {
        return abstract.object.level === User.levels.get().trainee;
    }

    const setupDealership = async () => {
        try {
            if(abstract.object.dealership) {
                setDealership(abstract.object.dealership);
                return;
            }
            if(abstract.object.dealership_id) {
                let dealership = await Dealership.get(utils, abstract.object.dealership_id);
                abstract.object.dealership = dealership;
                setDealership(dealership);
            }
        } catch(e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        fetchEvents();
    }, [eventsLimit, eventsOffset]);

    useEffect(() => {
        fetchReporting();
    }, [endDate, startDate]);

    useEffect(() => {

        connectToSockets();
        fetchTargets();
        utils.content.subscribe(layerID, 'user', {
            onFetch: fetchTargets,
            onUpdate: _abstract => {
                if(_abstract.getID() === abstract.getID()) {
                    fetchTargets();
                }
            }
        });

        return () => {
            disconnectFromSockets();
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            sizing: 'medium'
        }}>

            {getReportingComponent()}

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {getDownlines()}
            {getDevices()}
            {getSystemEvents()}
        </Layer>
    )
}

export const UserDownlinesFlowChart = ({ index, options, utils }) => {

    const panelID = 'user_downlines_flow_chart';
    const flowContainer = useRef(null);
    const nodeWidth = 150;
    const nodeHeight = 100;

    const [elements, setElements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [height, setHeight] = useState(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [width, setWidth] = useState(null);

    const onConnect = props => setElements((els) => {
        return addEdge({ ...props, type: 'smoothstep', animated: true }, els);
    });

    const onElementClick = async (evt, props) => {
        try {
            let { user_id } = props.data.element;
            let user = await User.get(utils, user_id);
            utils.layer.open({
                id: `user_details_${user_id}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: user
                }),
                Component: UserDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the information for this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onElementsRemove = elementsToRemove => {
        return setElements((els) => removeElements(elementsToRemove, els));
    }

    const onFormatElements = elements => {
        return elements.map((entry, index) => {
            if(!entry.element) {
                return entry;
            }
            return {
                ...entry,
                style: {
                    width: nodeWidth,
                    height: nodeHeight
                },
                data: {
                    element: entry.element,
                    label: (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center'
                        }}>
                            <img
                            src={entry.element.avatar}
                            style={{
                                width: 35,
                                height: 35,
                                objectFit: 'cover',
                                borderRadius: 17.5,
                                overflow: 'hidden',
                                marginBottom: 8
                            }} />
                            <span style={{
                                ...Appearance.textStyles.title(),
                                marginBottom: 2
                            }}>{`${entry.element.first_name} ${entry.element.last_name}`}</span>
                            <span style={{
                                ...Appearance.textStyles.subTitle()
                            }}>{entry.element.recruit_date ? `Recruited ${Utils.formatDate(entry.element.recruit_date, true)}` : 'No sales have been submitted'}</span>
                        </div>
                    )
                }
            }
        })
    }

    const onLayout = useCallback(direction => {
        const layoutedElements = getLayoutedElements(elements, direction);
        setElements(layoutedElements);
    }, [elements]);

    const onLoad = (_reactFlowInstance) => {
        setReactFlowInstance(_reactFlowInstance);
    }

    const getLayoutedElements = (elements, direction = 'TB') => {
        const isHorizontal = direction === 'LR';
        dagreGraph.setGraph({ rankdir: direction });

        elements.forEach((el) => {
            if(isNode(el)) {
                dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
            } else {
                dagreGraph.setEdge(el.source, el.target);
            }
        });

        dagre.layout(dagreGraph);

        return elements.map((el) => {
            if(isNode(el)) {
                const nodeWithPosition = dagreGraph.node(el.id);
                el.targetPosition = isHorizontal ? 'left' : 'top';
                el.sourcePosition = isHorizontal ? 'right' : 'bottom';

                // unfortunately we need this little hack to pass a slightly different position
                // to notify react flow about the change. Moreover we are shifting the dagre node position
                // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
                el.position = {
                    x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
                    y: nodeWithPosition.y - nodeHeight / 2,
                };
            }
            return el;
        });
    };

    const fetchDownlines = async () => {
        try {
            setLoading(true);
            let { users } = await Request.get(utils, '/users/', {
                type: 'flow_nodes_downlines',
                width: width,
                height: height
            });

            setLoading(false);
            let elements = getLayoutedElements(onFormatElements(users));
            setElements(elements);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the downlines information. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(flowContainer.current) {
            setHeight(flowContainer.current.clientHeight);
            setWidth(flowContainer.current.clientWidth);
        }
    }, [flowContainer.current])

    useEffect(() => {
        if(width && height) {
            fetchDownlines();
        }
    }, [height, width]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchDownlines);
        return () => {
            utils.events.off(panelID, 'dealership_change', fetchDownlines);
        }
    })

    return (
        <Panel
        panelID={panelID}
        name={'Dealership Downline'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading
        }}>
            <div
            ref={flowContainer}
            style={{
                width: '100%',
                height: 500,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <ReactFlowProvider>
                    <FlowSidebar utils={utils} />
                    <ReactFlow
                    onLoad={onLoad}
                    connectionLineType={'smoothstep'}
                    elements={elements.length > 2 ? elements : []}
                    elementsSelectable={true}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    paneMoveable={true}
                    panOnScroll={true}
                    onConnect={onConnect}
                    onElementsRemove={onElementsRemove}
                    onElementClick={onElementClick}
                    style={{
                        borderRadius: 10,
                        border: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        <Background
                        variant={'dots'}
                        gap={12}
                        size={1}
                        color={Appearance.colors.softBorder()}/>
                        <Controls />
                    </ReactFlow>
                </ReactFlowProvider>
                {elements.length === 2 && (
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '8px 12px 8px 12px',
                        textAlign: 'center',
                        maxWidth: 'calc(100% - 30px)'
                    }}>
                        <img
                        src={'images/no-data-found-icon.png'}
                        style={{
                            width: 50,
                            height: 50,
                            marginBottom: 8
                        }} />
                        <span style={{
                            ...Appearance.textStyles.title()
                        }}>{'No Downlines Found'}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            whiteSpace: 'normal'
                        }}>{'Downlines will show here when available'}</span>
                    </div>
                )}
            </div>
        </Panel>
    )
}

export const UserGroups = ({ index, options, utils }) => {

    const panelID = 'user_groups';
    const limit = 15;
    const offset = useRef(0);

    const [loading, setLoading] = useState(null);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [groups, setGroups] = useState([]);

    const getActiveStatus = group => {
        if(!group) {
            return;
        }
        let color = group.active ? Appearance.colors.primary() : Appearance.colors.grey();
        return (
            <div
            className={'text-button'}
            onClick={onChangeActiveStatus.bind(this, group)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: '100%',
                maxWidth: 75,
                textAlign: 'center',
                border: `1px solid ${color}`,
                background: Appearance.colors.softGradient(color),
                borderRadius: 5,
                overflow: 'hidden'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: '600',
                    width: '100%'
                }}>{group.active ? 'Active' : 'Not Active'}</span>
            </div>
        )
    }

    const getFields = (group, index) => {

        let target = group || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: target.title,
                    subTitle: target.description,
                    hideIcon: true,
                    bottomBorder: true,
                    onClick: onUserGroupClick.bind(this, group)
                })
            )
        }

        let fields = [{
            key: 'title',
            title: 'Title',
            value: target.title
        },{
            key: 'description',
            title: 'Description',
            value: target.description
        },{
            key: 'group_type',
            title: 'Group Type',
            value: target.group_type ? target.group_type.text : null
        },{
            key: 'category',
            title: 'Category',
            value: target.category ? target.category.text : null
        },{
            key: 'active',
            title: 'Status',
            value: getActiveStatus(target)
        }];

        // Headers
        if(!group) {
            return (
                <tr style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                {fields.map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 text-button flexible-table-column'}>
                            <span style={{
                                ...Appearance.textStyles.title()
                            }}>{field.title}</span>
                        </td>
                    )
                })}
                </tr>
            )
        }

        // Rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onUserGroupClick.bind(this, group)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const onChangeActiveStatus = async (group, e) => {
        try {
            e.stopPropagation();
            let nextActive = !group.active;
            await Request.post(utils, '/users/', {
                type: 'set_user_group_active_status',
                id: group.id,
                active: nextActive
            });

            setGroups(groups => {
                return groups.map(prevGroup => {
                    if(prevGroup.id === group.id) {
                        prevGroup.active = nextActive;
                    }
                    return prevGroup;
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status for this group. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNewUserGroup = evt => {

        utils.sheet.show({
            title: 'User Group Category',
            message: 'What type of user group would you like to create?',
            items: [{
                key: 'protections',
                title: 'Protections',
                style: 'default'
            },{
                key: 'users',
                title: 'User Accounts',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'cancel') {
                return;
            }
            utils.layer.open({
                id: 'new_user_group',
                abstract: Abstract.create({
                    type: 'user_groups',
                    object: User.Group.new()
                }),
                Component: AddEditUserGroup.bind(this, {
                    isNewTarget: true,
                    category: User.Group.categories[key]
                })
            })
        })
    }

    const onPrintGroups = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { groups } = await Request.get(utils, '/users/', {
                    type: 'groups',
                    ...props
                });

                setLoading(false);
                resolve(groups);

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const fetchGroups = async () => {
        try {
            setLoading(true);
            let { groups, paging } = await Request.get(utils, '/users/', {
                type: 'groups',
                limit: limit,
                offset: offset.current,
                search_text: searchText
            });

            setLoading(false);
            setPaging(paging);
            setGroups(groups.map(group => User.Group.create(group)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the user groups list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUserGroupClick = group => {
        utils.layer.open({
            id: `user_group_details_${group.id}`,
            abstract: Abstract.create({
                type: 'user_group',
                object: group
            }),
            Component: UserGroupDetails
        })
    }

    const getPrintProps = () => {

        let headers = [{
            key: 'title',
            title: 'Title'
        },{
            key: 'description',
            title: 'Description'
        },{
            key: 'group_type',
            title: 'Group Type'
        },{
            key: 'category',
            title: 'Category'
        },{
            key: 'active',
            title: 'Status'
        }];

        return {
            headers: headers,
            onFetch: onPrintGroups,
            onRenderItem: (item, index, items) => ({
                title: item.title,
                description: item.description,
                group_type: item.group_type ? item.group_type.text : null,
                category: item.category ? item.category.text : null,
                active: item.active ? 'Yes' : 'No'
            })
        }
    }

    const getContent = () => {
        if(groups.length === 0) {
            return (
                Views.entry({
                    title: `No Groups Found`,
                    subTitle: `No user groups were found in the system`,
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        if(Utils.isMobile()) {
            return groups.map((group, index) => {
                return getFields(group, index)
            })
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {groups.map((group, index) => {
                        return getFields(group, index)
                    })}
                </tbody>
            </table>
        )
    }

    useEffect(() => {
        fetchGroups();
    }, [searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchGroups);
        utils.content.subscribe(panelID, [ 'user_group' ], {
            onFetch: fetchGroups,
            onUpdate: abstract => {
                setGroups(groups => {
                    return groups.map(group => {
                        return group.id === abstract.getID() ? abstract.object : group
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchGroups);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'User Groups'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            search: {
                placeholder: 'Search by title or category name...',
                onChange: text => {
                    offset.current = 0;
                    setSearchText(text);
                }
            },
            print: getPrintProps(),
            buttons: [{
                key: 'new',
                title: 'New User Group',
                style: 'default',
                onClick: onNewUserGroup
            }],
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchGroups();
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const UserGroupDetails = ({ abstract, index, options, utils }) => {

    const offset = useRef(0);

    const layerID = `user_group_details_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [products, setProducts] = useState([]);
    const [users, setUsers] = useState([]);

    const getFields = () => {

        let group = abstract.object;
        return [{
            key: 'details',
            lastItem: false,
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: group.id
            },{
                key: 'group_type',
                title: 'Group Type',
                value: group.group_type ? group.group_type.text : null
            },{
                key: 'category',
                title: 'Category',
                value: group.category ? group.category.text : null
            },{
                key: 'title',
                title: 'Title',
                value: group.title
            },{
                key: 'description',
                title: 'Description',
                value: group.description
            },{
                key: 'dealership',
                title: 'Dealership',
                visible: utils.user.get().level <= User.levels.get().admin,
                value: group.dealership ? group.dealership.name : null
            }]
        }];
    }

    const getLevels = () => {
        if(abstract.object.group_type.code === User.Group.types.user_ids) {
            return null;
        }
        return (
            <LayerItem title={'Account Types'}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {abstract.object.levels.map((level, index, levels) => {
                        return (
                            Views.entry({
                                key: index,
                                title: User.levels.toText(level),
                                hideIcon: true,
                                bottomBorder: index !== levels.length - 1,
                            })
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getPropFields = () => {

        let items = [];
        let { props } = abstract.object;
        if(abstract.object.category.code === User.Group.categories.protections) {
            items = [{
                key: 'customer',
                title: 'Customer',
                items: [{
                    key: 'first_name',
                    title: 'First Name'
                },{
                    key: 'last_name',
                    title: 'Last Name'
                },{
                    key: 'spouse',
                    title: 'Spouse'
                },{
                    key: 'phone_number',
                    title: 'Phone Number'
                },{
                    key: 'email_address',
                    title: 'Email Address'
                }]
            },{
                key: 'location',
                title: 'Location',
                items: [{
                    key: 'location',
                    title: 'Location'
                },{
                    key: 'address',
                    title: 'Address'
                },{
                    key: 'maps',
                    title: 'Directions'
                }]
            },{
                key: 'units',
                title: 'Units Sold',
                items: products.map(product => ({
                    key: product.key,
                    title: product.name
                }))
            },{
                key: 'details',
                title: 'Details',
                items: [{
                    key: 'id',
                    title: 'ID'
                },{
                    key: 'created',
                    title: 'Submitted'
                },{
                    key: 'start_date',
                    title: 'Protection Date'
                },{
                    key: 'dealership',
                    title: 'Dealership'
                },{
                    key: 'sold_by',
                    title: 'Sold By'
                },{
                    key: 'total_units',
                    title: 'Total Units Sold'
                },{
                    key: 'full_protection',
                    title: 'Full Protection'
                },{
                    key: 'tags',
                    title: 'Tags'
                },{
                    key: 'comments',
                    title: 'Comments'
                }]
            }];
        }

        return items.map(item => {
            item.items = item.items.map(innerItem => {
                return {
                    ...innerItem,
                    value: (
                        <span style={{
                            ...Appearance.textStyles.value(),
                            color: props[innerItem.key] === false ? Appearance.colors.red : Appearance.colors.green
                        }}>{props[innerItem.key] === false ? 'Hidden' : 'Visible'}</span>
                    )
                }
            })
            return item;
        });
    }

    const getUsers = () => {
        if(users.length === 0 || abstract.object.group_type.code === User.Group.types.levels) {
            return null;
        }
        return (
            <LayerItem title={'Users'}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {users.map((user, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: user.full_name,
                                subTitle: user.phone_number,
                                icon: {
                                    path: user.avatar
                                },
                                onClick: onUserClick.bind(this, user),
                                singleItem: users.length === 1,
                                firstItem: index === 0,
                                bottomBorder: true,
                            })
                        )
                    })}
                    <PageControl
                    data={paging}
                    limit={5}
                    offset={offset}
                    onClick={next => {
                        offset.current = 0;
                        fetchUsers();
                    }}/>
                </div>
            </LayerItem>
        )
    }

    const onChangeActiveStatus = () => {
        utils.alert.show({
            title: `Change to ${abstract.object.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set "${abstract.object.title}" as ${abstract.object.active ? 'inactive' : 'active'}?`,
            buttons: [{
                key: 'confirm',
                title: `Set as ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Do Not Change',
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onChangeActiveStatusConfirm();
                    return;
                }
            }
        });
    }

    const onChangeActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            await Request.post(utils, '/users/', {
                type: 'set_user_group_active_status',
                active: !abstract.object.active,
                user_id: abstract.getID()
            });

            abstract.object.active = !abstract.object.active;
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This user group has been set as ${abstract.object.active ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the active status for this user group. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_user_group_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditUserGroup.bind(this, {
                isNewTarget: false,
                category: abstract.object.category.code
            })
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'active',
                title: `Change to ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'active') {
                onChangeActiveStatus();
                return;
            }
        });
    }

    const onUserClick = user => {
        utils.layer.open({
            id: `user_details_${user.user_id}`,
            abstract: Abstract.create({
                type: 'user',
                object: user
            }),
            Component: UserDetails
        })
    }

    const getButtons = () => {
        return [{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }];
    }

    const fetchProducts = async () => {
        try {
            let { products } = await Request.get(utils, '/products/', {
                type: 'all_admin',
                limit: 100
            });
            setProducts(products.map(product => Product.create(product)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the products list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchUsers = async () => {
        if(abstract.object.group_type !== User.Group.types.user_ids) {
            return;
        }
        try {
            let { paging, users } = await Request.get(utils, '/users/', {
                type: 'user_group_members',
                id: abstract.getID()
            });
            setPaging(paging);
            setUsers(users.map(user => User.create(user)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the user accounts for this user group. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchUsers();
    }, [offset]);

    useEffect(() => {
        fetchProducts();
        utils.content.subscribe(layerID, [ 'product', 'user', 'user_group' ], {
            onFetch: type => {
                if(type === 'product') {
                    fetchProducts();
                    return;
                }
                fetchUsers();
            },
            onUpdate: abstract => {
                if(abstract.type === 'product') {
                    fetchProducts();
                    return;
                }
                fetchUsers();
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading
        }}>
            <FieldMapper 
            fields={getFields()} 
            utils={utils}/>

            {getUsers()}
            {getLevels()}

            <FieldMapper 
            fields={getPropFields()} 
            utils={utils}/>

        </Layer>
    )
}

export const UserLocations = ({ index, options, utils }) => {

    const panelID = 'user_locations';
    const [loading, setLoading] = useState(false);
    const [locationData, setLocationData] = useState(null);
    const [region, setRegion] = useState(null);

    const onUserClick = async props => {
        try {
            let user = await User.get(utils, props.user_id);
            utils.layer.open({
                id: `user_details_${props.user_id}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: user
                }),
                Component: UserDetails
            })

        } catch(e) {
            console.log(e.message);
        }
    }

    const getFeatures = () => {
        if(!locationData) {
            return null;
        }
        return {
            id: 'dealership_locations',
            region: region,
            data: locationData,
            onClick: onUserClick,
            icons: [{
                key: 'location-icon-grey',
                path: 'images/location-icon-grey.png'
            }],

            layer: {
                type: 'symbol',
                layout: {
                    'icon-size': 0.2,
                    'icon-anchor': 'center',
                    'icon-image': 'location-icon-grey',
                    'icon-allow-overlap': true
                },
                paint: {
                    'icon-color': window.theme === 'dark' ? '#FFFFFF' : Appearance.colors.primary()
                }
            },
            onHover: feature => {
                try {
                    let { avatar, full_name, email_address } = feature.properties;
                    return {
                        title: full_name,
                        subTitle: email_address,
                        icon: {
                            path: avatar
                        }
                    }
                } catch(e) {
                    console.log(e.message);
                }
            }
        }
    }

    const fetchLocations = async () => {
        try {
            setLoading(true);
            let { data, region } = await Request.get(utils, '/users/', {
                type: 'locations'
            });

            setLoading(false);
            setLocationData(data);
            setRegion(region);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the location map data. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchLocations();
    }, [])

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchLocations);
        utils.content.subscribe(panelID, 'user', {
            onFetch: fetchLocations,
            onUpdate: fetchLocations
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchLocations);
        }
    }, []);

    return (
        <Panel
        id={panelID}
        name={'Locations'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading
        }}>
            <Map
            features={getFeatures()}
            isScrollEnabled={true}
            isZoomEnabled={true}
            isRotationEnabled={true}
            style={{
                width: '100%',
                height: 350
            }}/>
        </Panel>
    )
}
